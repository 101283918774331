/* Item Product Module/ Rayon / etc ...*/
.item_product,
.item.gondole,
#list_item > .item,
.product_page .item,
.alt_sld_view {
    position: relative;
     /* Eclat */
    .eclat_top {
        position: absolute;
        top: 8px;
        left: 13px;
        z-index: 5;

        img {
            width: auto;
            height: auto;
            max-height: 19px;
        }

        & > .eclat_rect {
            position: relative !important;
            top: auto;
            left: auto;
        }
    }

    .eclat_rond {
        position: absolute;
        top: 8px;
        right: 15px;
        z-index: 5;

        img {
            width: auto;
            height: auto;
            max-height: 19px;
        }
    }

    .eclat_rect {
        position: absolute;
        top: 8px;
        left: 15px;
        z-index: 5;

        img {
            width: auto;
            height: auto;
            max-height: 19px;
        }
    }

    .productVisualMulti {
        position: relative;
        margin-bottom: 10px;

        /* Video */
        .wrap_video,
        .swiper-slide.has_video {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 0;
            padding-top: calc(895 / 716 * 100%);
            transition: all 250ms ease-in-out;
            overflow: hidden;
            background-color: $black;

            iframe {
                position: absolute;
                left: 0;
                top: 50%;
                width: 100%;
                height: 100%;
                transition: .3s ease-in-out;
                z-index: 0;
                transform: translateY(-50%);

                & + a {
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        .swiper-lazy-preloader {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 0;
            padding-top: calc((795 / 636) * 100%);
            margin-left: 0;
            margin-top: 0;
            z-index: 1;
            transform-origin: center;
            transform: none;
            animation: none;
        }

        .block_lnk {
            position: relative;
            display: block;
            height: 0;
            padding-top: calc((795 / 636) * 100%);

            .no_lazy,
            .ill_img {
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                width: 100%;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: auto;
                }

                & + .no_lazy,
                & + .ill_img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: -1;
                    transition: all ease-in-out 0.35s;
                }
            }

            img {
                width: 100%;
                height: auto;
                display: block;
            }

            &:hover {
                .no_lazy,
                .ill_img {
                    & + .no_lazy,
                    & + .ill_img {
                        opacity: 1;
                        z-index: 1;
                    }
                }
            }
        }

        .swiper_container_look_buttons {
            opacity: 0;
            transition: opacity ease-in-out 0.35s;

            .swiper-button-next,
            .swiper-button-prev {
                width: 30px;
                height: 30px;
                cursor: pointer;
                left: 10px;
                right: auto;
                background: inherit;
                z-index: 15;

                &:after {
                    content: '';
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(29,8,8);
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }

                &.swiper-button-lock {
                    display: none;
                }

                &.disabled:not(.swiper-button-lock),
                &.swiper-button-disabled:not(.swiper-button-lock) {
                    opacity: 0.5;
                    display: block;
                    pointer-events: auto;
                }
            }

            .swiper-button-next {
                transform: rotate(180deg);
                right: 10px;
                left: auto;
            }
        }
    }

    .brand_n_price_wrapper {
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        width: 100%;
        box-sizing: border-box;

        .brand_and_description {
            width: calc(100% - 150px);

            .container_marques {
                margin-bottom: 5px;
            }

            .wishlist_title_wrapper {
                .wrapper_description {
                    .item_title_wrapper {
                        width: 100%;
                    }
                }
            }
        }

        .container_marques {
            width: 100%;
            font-family: $primaryFont;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.04rem;
            text-transform: uppercase;

            img {
                width: auto !important;
                height: 22px !important;
            }
        }

        .item_price {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            width: 150px;

            .pricetag {
                display: inline-block;
                height: fit-content;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                text-align: right;
                line-height: 18px;

                span {
                    display: inline;
                }

                &  + .pricetag {
                    font-size: 13px;
                    text-decoration: line-through;
                    color: $grey;
                    margin-right: 8px;
                    padding-top: 1px;
                }
            }
        }
    }

    .wrapper_description {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
        width: 100%;
        box-sizing: border-box;

        .container_marques {
            width: 100%;
            font-family: $primaryFont;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 1;
            letter-spacing: 0.04rem;
            text-transform: uppercase;
            margin-bottom: 5px;

            img {
                width: auto !important;
                height: 22px !important;
            }
        }

        .description_achat_express_details {
            display: flex;
        }

        .item_title,
        .item_title_wrapper {            
            text-align: left;

            .title_site {
                color: $black;
                font-family: $primaryFont;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            .item_title {
                width: 100%;
                font-family: $primaryFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 1;
                padding-bottom: 2px;
                margin-top: 2px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
            }

            .item_subtitle {
                font-family: $primaryFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 100%;
            }
        }

        .item_price {
            width: 150px;
            flex-wrap: wrap;
            flex-direction: row-reverse;
            display: flex;

            .pricetag {
                display: inline-block;
                height: fit-content;
                text-align: right;
                font-family: $primaryFont;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;

                span {
                    display: inline;
                }

                &  + .pricetag {
                    font-size: 13px;
                    text-decoration: line-through;
                    color: $grey;
                    margin-right: 8px;
                    padding-top: 1px;
                }
            }
        }
    }

    /* Wishlist */
    #addToWishlistButtonContainer {
        .btnWishlist {
            width: 22px;
            height: 22px;
            background-image: url(../svg/spritesheet.svg);
            @include bgIconCoord(20, 8, 8);
            display: block;
        }
    }

    &:hover {
        .wrap_rolloverproduit {
            opacity: 1;
            z-index: 5;
        }

        .swiper_container_look_buttons {
            opacity: 1;
        }
    }
}