/* Rayon */
.banner_category {
    margin: 0 auto 28px;
    width: 100%;
    max-width: 1680px;

    .text_seo_top {
        text-align: center;

        .seo_title_h1 {
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
        }
    }
}

.banner_brand_img {
    margin: 0 auto 30px;

    img {
        width: 100%;
        height: auto;
    }
}

.seo_category {
    display: block;
    text-align: center;

    .seo_title {
        display: block;
        margin-bottom: 0.5rem;
        font-style: italic;
        font-weight: 400;
        font-size: 35px;
        line-height: 1;
        font-family: $secondaryFont;
    }

    .seo_nb_product {
        display: block;
        margin-bottom: 1.3rem;
        color: $grey;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1;
        font-family: $primaryFont;
        letter-spacing: 0.75px;
    }

    .seo_description,
    .seo_description .txt,
    .seo_description + .txt {
        display: block;
        margin: 0 auto 22px;
        width: 100%;
        max-width: 980px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $primaryFont;
        text-align: justify;

        a {
            text-decoration: underline;
            text-underline-position: under;
        }

        .read_more,
        .read_less,
        & + .read_less {
            display: inline-block;
            cursor: pointer;
            font-style: italic;
            font-weight: bold;
            font-size: 14px;
            font-family: $primaryFont;
        }

        & + .read_less {
            display: block;
            margin-bottom: 22px;
            text-align: center;
        }

        &.cache {
            display: none;

            & + .read_less,
            & + .read_more {
                display: none;
            }
        }

        &.footer {
            margin-bottom: 66px;
        }
    }
}

#choix_recherche {
    margin-bottom: 26px;
    padding: 0 6.25vw;
    width: 100%;

    .filters_products {
        .filters_wrapper {
            #trigger_filtre {
                display: flex;
                justify-content: space-between;

                .trigger_filtre_swiper {
                    width: calc(100%  - 178px);
                }

                .filtre_elem {
                    cursor: pointer;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    &.all_filters {
                        width: auto;
                        text-align: right;
                        text-decoration: underline;
                        text-underline-position: under;
                    }
                }
            }
        }
    }
}

/* FILTRES */
#filter_sticky {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    z-index: 55;
    transition: transform .35s ease-in-out;
    box-sizing: border-box;
    background-color: $white;
    padding: 29px 25px 120px;
    width: 380px;
    height: 100vh;

    &.triggered {
        transform: translateX(0);
    }

    .title_filter {
        margin-bottom: 22px;
        font-style: italic;
        font-weight: 400;
        font-size: 25px;
        line-height: 1;
        font-family: $secondaryFont;
        text-align: center;
    }

    .close_pop {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
        background-image: url(../svg/spritesheet.svg);
        width: 12px;
        height: 12px;
        font-size: 0;
        @include bgIconCoord(30, 8, 8);
    }

    .filters_cta {
        position: absolute;
        right: 0;
        bottom: 20px;
        left: 0;
        margin: auto;
        width: calc(100% - 50px);

        #validate_filter {
            margin-bottom: 15px;
            width: 100%;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: normal;
            font-family: $primaryFont;
            letter-spacing: 0.04rem;
            text-transform: uppercase;
        }

        .filter_remove {
            display: block;
            cursor: pointer;
            border: none;
            background-color: $white;
            width: 100%;
            height: auto;
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: normal;
            font-family: $primaryFont;
            letter-spacing: 0.04rem;
            text-align: center;
            text-transform: uppercase;

            span {
                color: $black;
                text-decoration: underline;
                text-underline-position: under;
            }
        }
    }

    .filter_wrapper_container {
        width: 100%;
        height: calc(100vh - 195px);
        overflow-x: hidden;
        overflow-y: auto;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
    }

    /*Trier Par*/
    #filter_trier {
        .filter_name {
            position: relative;
            cursor: pointer;
            margin-bottom: 13px;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            font-family: $primaryFont;
            letter-spacing: 0.7px;
            text-transform: uppercase;

            &:after {
                background-image: url(../svg/spritesheet.svg);
                content: '';
                @include bgIconCoord(29,8,8);
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                transform: rotate(-90deg);
                transition: all 0.35s ease-in-out;
                width: 1rem;
                height: 1rem;
            }

            &.actif {
                &:after {
                    transform: rotate(90deg);
                }
            }

            #selectedSortFilter {
                display: none;
            }

            & + .selected_options {
                .form_itm {
                    display: block;
                    position: relative;
                    margin-bottom: 20px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    a,
                    label.tri_price {
                        display: flex;
                        position: relative;
                        align-items: center;
                        margin-bottom: 20px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1;
                        font-family: $primaryFont;

                        &:before {
                            display: block;
                            transition: all ease-in-out 0.35s;
                            margin-right: 10px;
                            border: 1px solid $lightgrey;
                            background-color: $white;
                            width: 14px;
                            height: 14px;
                            content: '';
                        }

                        &.actif {
                            &:before {
                                box-shadow: 2px 2px 0px 0px $white inset, -2px -2px 0px 0px $white inset;
                                border-color: $black;
                                background-color: $black;
                            }
                        }
                    }

                    input[type="radio"] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        width: 100%;
                        height: 15px;

                        & + label.tri_price {
                            margin-bottom: 0;
                        }

                        &:checked {
                            & + label.tri_price {
                                &:before {
                                    box-shadow: 2px 2px 0px 0px $white inset, -2px -2px 0px 0px $white inset;
                                    border-color: $black;
                                    background-color: $black;
                                }
                            }
                        }
                    }
                }

                &.actif {
                    margin-bottom: 0;
                }
            }
        }
    }

    .filters_dropdown_bloc {
        position: relative;
    }

    .filters_dropdown_content {
        box-sizing: border-box;

        .filters_container_wrapper {
            width: 100%;
        }

        .filter_checkbox_hide {
            display: none;
        }

        .toggleFilter {
            position: relative;
            cursor: pointer;
            margin-bottom: 13px;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            font-family: $primaryFont;
            letter-spacing: 0.7px;
            text-transform: uppercase;

            &:after {
                background-image: url(../svg/spritesheet.svg);
                content: '';
                @include bgIconCoord(29,8,8);
                display: inline-block;
                position: absolute;
                top: 0;
                right: 0;
                transform: rotate(-90deg);
                transition: all 0.35s ease-in-out;
                width: 1rem;
                height: 1rem;
            }

            &.actif {
                &:after {
                    transform: rotate(90deg);
                }
            }
        }

        .vue-slider {
            margin: 0 auto;
            width: calc(100% - 2rem) !important;
        }

        .ui-slider {
            position: relative;
            z-index: 0;
            margin: 0 auto;
            width: calc(100% - 2rem) !important;
            text-align: left;

            &:before {
                position: absolute;
                top: 2px;
                left: 0;
                z-index: -1;
                margin: auto;
                background-color: $lightgrey;
                width: 100%;
                height: 4px;
                content: '';
            }
        }

        .ui-slider .ui-slider-handle {
            position: absolute;
            top: -26px;
            z-index: 2;
            cursor: pointer;
            margin: 0 0 0 -25px;
            outline: none;
            width: 50px;
            font-weight: 500;
            font-size: 13px;

            &.ui-state-active {
                .handler_price {
                    transition: all ease-in-out 0.35s;
                    font-weight: 600;
                    font-size: 1rem;
                }
            }

            .handler_price {
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-start;
                align-items: center;
                font-weight: 400;
                font-size: 14px;
                font-family: $primaryFont;

                &:before {
                    display: block;
                    margin-top: 8px;
                    border: 2px solid $black;
                    border-radius: 50%;
                    background-color: $white;
                    width: 12px;
                    height: 12px;
                    content: '';
                }
            }
        }

        .ui-slider .ui-slider-range {
            display: block;
            position: absolute;
            z-index: 1;
            border: 0;
            background: $black;
            height: 4px;
        }

        .ui-slider-horizontal {
            margin-top: 40px;
            background-color: $white;
            padding-top: 2px;
            height: 35px;
        }

        .vue-slider {
            height: 2px !important;

            .vue-slider-rail {
                background-color: $grey;

                .vue-slider-process {
                    background-color: $black;
                }
            }
        }

        .vue-slider-dot {
            cursor: pointer;
            width: 8px;
            height: 8px;

            .vue-slider-dot-handle {
                margin-top: .05rem;
                box-shadow: inherit;
                border-radius: 100%;
                background-color: $black;
                width: 8px;
                height: 8px;
            }

            .vue-slider-dot-tooltip-bottom {
                bottom: 4px;
                padding-top: .3rem;
            }

            .vue-slider-dot-tooltip-inner {
                background-color: transparent;

                span {
                    display: inline-block;
                    color:  $black;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: $primaryFont;

                    &:after {
                        position: relative;
                        width: 1rem;
                        height: 1rem;
                        content:'€';
                    }
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    .selected_options {
        display: none;
        padding-bottom: 25px;
        max-width: 260px;


        &.actif {
            margin-bottom: 10px;
            height: auto;
        }

        .flex_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-left: 1px;
        }

        /* Couleurs */
        &.colorOptions {
            padding-bottom: 10px;

            .form_itm {
                position: relative;
                margin: 0 18.5px 10px 0px;
                width: 20px;

                &:nth-of-type(7n) {
                    margin-right: 0;
                }

                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    margin: auto;
                    width: 20px;
                    height: 20px;
                }

                label {
                    display: block;
                    position: relative;
                    box-shadow: inset 0 0 0 3px $white;
                    border-radius: 100%;
                    padding: 0;
                    width: 20px;
                    height: 20px;

                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -1px;
                        cursor: pointer;
                        margin: auto;
                        border: 1px solid $lightgrey;
                        border-radius: 100%;
                        background: transparent;
                        width: 20px;
                        height: 20px;
                        content: '';
                    }

                    p {
                        font-size: 0;
                    }
                }

                [type=checkbox]:checked+label {
                    &:before {
                        border: 1px solid $black;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }

        /* Tailles */
        &.sizeOptions {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-bottom: 0;
            max-width: 275px;

            .form_itm {
                display: inline-block;
                position: relative;
                margin: 0 11px 10px 0px;

                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                    margin: auto;
                    width: 20px;
                    height: 20px;
                }

                label {
                    display: inline-block;
                    transition: all ease-in-out 0.5s;
                    border-bottom: 1px solid $white;
                    padding: 0;
                    padding-bottom: 2px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-align: left;
                    text-transform: uppercase;
                    white-space: nowrap;
                }

                [type=checkbox]:checked+label {
                    border-bottom: 1px solid $black;

                    &:after {
                        display: none;
                    }
                }

                &:hover {
                    label {
                        border-bottom: 1px solid $black;
                    }
                }
            }
        }

        /* Marque */
        &.brands,
        &.objects {
            .form_itm {
                margin-bottom: 15px;
                width: 100%;

                &:last-of-type {
                    margin-bottom: 0;
                }

                input[type="checkbox"] {
                    display: none;

                    & + label {
                        display: flex;
                        position: relative;
                        align-items: center;
                        cursor: pointer;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;

                        &:before {
                            display: block;
                            transition: all ease-in-out 0.35s;
                            margin-right: 10px;
                            border: 1px solid $lightgrey;
                            background-color: $white;
                            width: 14px;
                            height: 14px;
                            content: '';
                        }

                        span {
                            display: block;
                            width: calc(100% - 24px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }

                    &:checked {
                        & + label {
                            font-weight: 700;

                            &:before {
                                box-shadow: 2px 2px 0px 0px $white inset, -2px -2px 0px 0px $white inset;
                                border-color: $black;
                                background-color: $black;
                            }
                        }
                    }
                }
            }
        }

        &.brands {
            max-width: 100%;

            &.actif {
                max-height: 39vh;
                overflow: auto;
            }
        }

        &.trier_par {
            .form_itm {
                &:last-of-type {
                    margin-bottom: 0;

                    a {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

#trigger_filtre {
    .trigger_filtre_swiper {
        width: calc(100%  - 178px);
        overflow: hidden;

        .swiper-wrapper {
            .swiper-slide {
                display: inline-block;
                position: relative;
                cursor: pointer;
                margin-right: 24px;
                width: auto;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                &:after {
                    position: absolute;
                    top: 2px;
                    right: -12px;
                    background: $black;
                    width: 0.5px;
                    height: 10px;
                    content: '';
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                &.all_filters {
                    width: auto;
                    text-align: right;
                    text-decoration: underline;
                    text-underline-position: under;
                }

                input[type="checkbox"] {
                    display: none;

                    & + label {
                        cursor: pointer;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                    }

                    &:checked {
                        & + label {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .all_filters {
        cursor: pointer;
        width: auto;
        color: $black;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        font-family: $primaryFont;
        letter-spacing: 0.6px;
        text-align: right;
        text-decoration: underline;
        text-underline-position: under;
        text-transform: uppercase;
    }
}

.topSearchWrapper {
    position: relative;
    padding: 0 6.25vw;

    .total_articles {
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: flex-end;
        cursor: text;
        margin: 1.3rem auto;
        border-bottom: 1px solid $grey;
        padding-bottom: 5px;
        width: calc(100% - 355px + 12.5vw);

        .searchTitle {
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 1;
            font-family: $primaryFont;
        }

        .nbResults {
            cursor: default;
            color: $grey;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            font-family: $primaryFont;
            text-align: right;

            &:after {
                background-image: url(../svg/spritesheet.svg);
                content: '';
                @include bgIconCoord(30, 8, 8);
                display: inline-block;
                cursor: pointer;
                margin-left: 40px;
                width: 10px;
                height: 10px;
            }
        }
    }
}

.sub_categ_wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;

    &.rayon_sub_categ_wrapper {
        box-sizing: border-box;
        padding: 0 clamp(0.5rem, fs-vw(30, 1920), 30px);
        width: 100%;

        .bloc_sub_categ {
            margin: 0;
        }
    }

    & > .all_filters {
        cursor: pointer;
        width: auto;
        color: $black;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        font-family: $primaryFont;
        letter-spacing: 0.6px;
        text-align: right;
        text-decoration: underline;
        text-underline-position: under;
        text-transform: uppercase;
    }

    .trigger_filtre_swiper,
    .bloc_sub_categ {
        // width: calc(100%  - 178px);
        overflow: hidden;

        .swiper-wrapper {
            .swiper-slide {
                display: inline-flex;
                position: relative;
                cursor: pointer;
                width: auto;
                -webkit-text-stroke-width: 0px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                &:after {
                    position: relative;
                    top: 2px;
                    margin: 0 12px;
                    background: $black;
                    width: 1px;
                    height: 10px;
                    content: '';
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                &.all_filters {
                    width: auto;
                    text-align: right;
                    text-decoration: underline;
                    text-underline-position: under;
                }

                input[type="checkbox"] {
                    display: none;

                    & + label {
                        cursor: pointer;
                        -webkit-text-stroke-width: 0px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                    }

                    &:checked {
                        & + label {
                            -webkit-text-stroke-width: 0.7px;
                        }
                    }
                }

                &.current,
                &:hover {
                    -webkit-text-stroke-width: 0.7px;
                }
            }
        }
    }

    .trigger_filtre_swiper {
        width: calc(100%  - 178px);
    }

    .bloc_sub_categ_wrapper {
        position: relative;
        width: calc(100%  - 178px);
        padding: 0 1rem;

        &:not(:has(.swiper-initialized)) {
            .swiper-button-next,
            .swiper-button-prev {
                display: none;
            }
        }

        .bloc_sub_categ_arrow {
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }

        .swiper-button-next {
            right: 0;
            transform: rotate(180deg) translateY(50%);
        }
        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-lock,
        .swiper-button-disabled {
            display: none;
        }
    }
}

#list_item {
    display: flex;
    column-gap: 0.5rem;
    row-gap: 2rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 auto 70px;
    width: 100%;

    .item {
        position: relative;
        width: calc(100% / 4 - 6px);

        @media screen and (max-width: 1080px) {
            width: calc(100% / 3 - 6px);
        }

        &:nth-of-type(3n) {
            margin-right: 0;
        }

        &.push {
            img {
                display: block;
                width: 100%;
                height: auto;
            }

            .video_container {
                position: relative;
                display: block;
                width: 100%;
                height: 0;
                padding-bottom: 125.055%;
                overflow: hidden;

                iframe {
                    position: absolute;
                    z-index: 0;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    border: 0;
                }
            }

            .infos {
                display: block;
                display: flex;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                margin: auto;
                width: 100%;
                height: 100%;
                color: $white;
                text-align: center;

                .alt_bandeau {
                    display: block;
                    margin-bottom: 17px;
                    width: 100%;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 1;
                    font-family: $secondaryFont;
                }

                .bandeau_link {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-decoration: underline;
                    text-underline-position: under;
                    text-transform: uppercase;
                }
            }
        }

        .brand_n_price_wrapper {
            .brand_and_description {
                .container_marques {
                    margin-bottom: 0;
                }
            }
        }

        .productVisualMulti {
            display: block;

            .block_lnk {
                display: block;

                .no_lazy,
                img {
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                }

                .no_lazy {
                    & + .no_lazy {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: -1;
                        transition: opacity 0.35s ease-in-out;
                    }
                }

                .img {
                    & + .img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: -1;
                        transition: opacity 0.35s ease-in-out;
                    }
                }
            }

            .swiper-slide.wrap_video {
                .link_product_iframe {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                }
            }
        }

        &:hover {
            .productVisualMulti {
                .block_lnk {
                    img {
                        & + img {
                            opacity: 1;
                            z-index: 1;
                        }
                    }

                    no_lazy {
                        & + .no_lazy {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }
        }
    }
}

.pager_wrapper_rayon {
    margin: 0 auto 73px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    font-family: $primaryFont;
    letter-spacing: 0.6px;
    text-align: center;
    text-transform: uppercase;
}

/* Pagination */
.pagination {
    display: inline-flex;
    align-content: center;
    justify-content: center;

    a,
    span {
        display: inline-block;
        margin-right: 4px;
        color: $black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: normal;
        font-family: $primaryFont;

        &.suivant,
        &.precedent {
            display: none;
        }

        &.active {
            border-bottom: 1px solid $black;
            padding-bottom: 2px;
        }
    }
}

.pagerNav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.nbProdDisplayed {
    display: block;
    margin: 0 auto 15px;
    width: 100%;
    color: $grey;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: normal;
    font-family: $primaryFont;
    letter-spacing: 0.6px;
    text-align: center;
    text-transform: uppercase;

    .see_all_product {
        display: inline-block;
        cursor: pointer;
        border-bottom: 1px solid $black;
        padding-bottom: 2px;
        color: $black;
    }
}

.loadedGauge {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0 auto;
    margin-bottom: 15px;
    border: 1px solid $beige_Fonce;
    border-radius: 30px;
    background-color: $white;
    width: 148px;
    height: 6px;

    span {
        display: block;
        margin: 0;
        border-radius: 30px;
        background-color: $beige_Fonce;
    }
}

.loader_scroll_wrapper {
    display: block;
    display: flex;
    align-content: flex-end;
    justify-content: center;
    margin: 0 auto 63px;
    width: 100%;

    .loader_scroll {
        position: relative;
        margin: 0 auto;
        width: 350px;

        .button.primary {
            border: 1px solid $black;
            border-radius: 50px;
            background-color: $white;
            width: 350px;

            span {
                color: $black;
            }

            &:hover {
                background-color: $black;

                span {
                    color: $white;
                }
            }

            &.loader {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -1;
                background: $black url(../svg/three_dots.svg) no-repeat center;
                background-size: 15% auto;
                height: 48px;

                &.loading {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }

        &.loading {
            .button.primary {
                &.loader {
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
    }
}


/* Recherche */
.dynasearchwrapper {
    min-height: 35vh;
    min-height: 35dvh;

    #search_page {
        margin-top: 50px;
    }

    /* Sans Resultat */
    .no_results {
        .search_reminder {
            margin-top: 22px;
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
        }
    }

    .home-module-body.image-image,
    .home-module-body.tg-module {
        margin-bottom: 30px;
    }

    #searchComponent {
        position: relative;
        height: 100%;

        .fullpage-loader {
            position: absolute;
            top: 130px;
            right: 0;
            left: 0;
            margin: auto;
            background: $white url(../svg/three_dots_black.svg) center center no-repeat;
            background-size: contain;
            width: 200px;
            height: 100px;
        }
    }
}

/* Recherche Sans Resultat */
.search_reminder {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    cursor: pointer;
    margin: 0 auto 30px;
    border-bottom: 1px solid $grey;
    padding-bottom: 5px;
    width: 81%;
    max-width: 1550px;

    .searchTitle {
        color: $black;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 1;
        font-family: $primaryFont;
    }

    .nbResults {
        color: $grey;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 1;
        font-family: $primaryFont;
        text-align: right;

        &:after {
            background-image: url(../svg/spritesheet.svg);
            content: '';
            @include bgIconCoord(30, 8, 8);
            display: inline-block;
            margin-left: 40px;
            width: 10px;
            height: 10px;
        }
    }
}

.no_results_txt_container {
    margin: 59px auto 15px;
    width: 891px;
    max-width: 100%;
    font-style: italic;
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    font-family: $secondaryFont;
    text-align: center;
}

.no_results_txt_list {
    margin: 0 auto 57px;
    width: 891px;
    max-width: 100%;

    .list {
        text-align: center;

        span {
            display: inline-block;
            position: relative;
            margin-bottom: 8px;
            padding-left: 13px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1;
            font-family: $primaryFont;

            &:before {
                display: inline-block;
                position: absolute;
                top: -10px;
                left: 0;
                width: 5px;
                height: 5px;
                content: '.';
                font-size: 20px;
                font-family: $secondaryFont;

            }
        }
    }
}

body.category,
body.search {
    #wrapper_top_menu {
        .main_menu {
            .menu {
                .categories {
                    & > .swiper-container {
                        &:not(.show) {
                            height: 0;
                        }
                    }
                }
            }
        }
    }

    /* BTN UP*/
    #btt_button {
        position: fixed;
        right: -2px;
        bottom: 60px;
        opacity: 0;
        z-index: -1;
        cursor: pointer;
        width: 100px;
        height: 100px;

        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background-image: url(../svg/spritesheet.svg);
            content: '';
            @include bgIconCoord(29, 8, 8);
            transform: rotate(90deg);
            width: 17px;
            height: 17px;
        }

        &.show {
            opacity: 1;
            z-index: 5;
        }
    }

    .container_products {
        .wrap_rolloverproduit {
            .expressAlertStock {
                button {
                    border: none !important;
                    border-radius: 0 !important;
                    background-image: url(../svg/spritesheet.svg);
                    background-color: transparent !important;
                    width: 15px;
                    height: 15px !important;
                    @include bgIconCoord(29, 8, 8);
                    transform: rotate(180deg);
                    position: absolute;
                    right: 0;
                    top: 1px;

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

body.has_banner_brand_img {
    padding-top: 0 !important;

    #breadcrumbs {
        position: absolute;
        top: 75px;
        z-index: 3;
    }

    &.hasBando {
        #breadcrumbs {
            position: absolute;
            top: 110px;
            z-index: 3;
        }
    }
}

#content_obj {
    padding: 0 clamp(0.5rem, fs-vw(30, 1920), 30px);

    #scroll_items {
        #list_item {
            .item {
                .productSwiper {
                    .swiper-wrapper {
                        .swiper-slide.has_video {
                            .link_product_iframe {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                display: inline-block;
                                width: 100%;
                                height: 100%;
                                z-index: 5;
                            }
                        }
                    }
                }
            }
        }
    }
}