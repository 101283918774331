body.body_login {
    #site_global_wrap {
        margin-bottom: 30px;

        .bandeau_container {
            #breadcrumbs {
                display: none;
            }
        }

        .wrapper_tunnel {
            .newlogin_page {
                display: block;
                padding-top: 0;
                position: relative;
                min-height: 885px;

                .wrapper_bandeau_connexion {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        width: 100%;
                        @media screen and(max-width: 1920px) {
                            width: fit-content;
                        }
                    }

                    img {
                        width: 100%;
                        height: auto;
                        
                        @media screen and(max-width: 1920px) {
                            width: auto;
                            height: 100%;
                        }
                    } 
                }

                .login_bloc {
                    .title {

                        &.my_account {
                            margin-top: 40px;
                        }
                        h4 {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0.7px;
                            text-transform: uppercase; 
                            margin-top: 30px;
                        }
                    }

                    .content_form {
                        &.sponsor {
                            margin-bottom: 0;

                            .wrapper_link_sponsor {
                                margin-bottom: 0;

                                .link_sponsor {
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    color: $black;
                                    border: none;
                                    text-decoration: underline;
                                }
                            }

                            .w-form-line {
                                .w-input-container {
                                    .w-input-error {
                                        top: 85%;
                                    }
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        min-height: 265px;
                    }
                }

                .login_wrapper {
                    z-index: 20;
                    position: inherit;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    min-height: 885px;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .login_bloc {
                        display: block;
                        width: 100%;
                        max-width: 850px;
                        margin: 0 auto;
                        padding: 0;
                        border: none;
                        background-color: $white;

                        .my_account {
                            .acc_login_title {
                                font-family: $secondaryFont;
                                font-size: 35px;
                                font-style: italic;
                                font-weight: 400;
                                line-height: 1;
                            }

                            .subtitle {
                                display: none;
                            }
                        }

                        .login_form_wrapper {
                            margin-top: 18px;

                            .title {
                                font-family: $primaryFont;
                                font-style: normal;
                                font-weight: 400;
                                color: $black;
                                margin-bottom: 8px;

                                h3 {
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    line-height: 21px;
                                    letter-spacing: 0.7px;
                                    text-transform: uppercase;
                                    font-weight: 400;
                                    margin-bottom: 0;
                                    margin-top: 0;
                                }

                                .subtitle {
                                    font-size: 14px;
                                    line-height: 18px;

                                    &#subtitleMigration {
                                        color: $red;
                                        font-size: 13px;
                                        margin-top: 1.2rem;
                                    }
                                }
                            }

                            .mdpSentEmail {
                                text-align: center;
                                font-size: 14px;
                                line-height: 18px;
                                color: $green;
                                padding: 1rem 0;
                            }

                            .w-newlogin-form {
                                width: 100%;
                                max-width: 600px;
                                margin: 0 auto;

                                .AuthErrContainer {
                                    .form-msg {
                                        &.error {
                                            background-color: $white;

                                            p {
                                                font-family: $primaryFont;
                                                font-size: 11px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                color: $red;
                                            }
                                            
                                            strong {
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }

                                .w-form-line {
                                    &.email-form-line {
                                        margin-bottom: 10px;

                                        .w-input-container {
                                            .w-input {
                                                &.w-email-input {
                                                    margin-top: 2px;

                                                    &.w-has-error {
                                                        margin-bottom: 5px;
                                                        
                                                        .w-input-element {
                                                            box-shadow: inherit;
                                                            border-bottom: 1px solid $red;
                                                        }

                                                        & + .w-input-note {
                                                            &.w-input-error {
                                                                display: block;
                                                            }
                                                        }
                                                    }

                                                    &.w-nonempty {
                                                        margin-top: 10px;

                                                        .w-input-label {
                                                            font-size: 12px;
                                                            line-height: 21px;
                                                        }
                                                    }

                                                    input.w-input-element {
                                                        position: relative;
                                                        z-index: 2;
                                                    }
                                                }
                                            }

                                            .w-input-note {
                                                top: 85%;
                                                font-size: 0.7rem;

                                                &:last-of-type {
                                                    display: initial;
                                                    background-color: $white;
                                                    z-index: 2;
                                                }
                                            }
                                        }
                                    }

                                    &.optin_container {
                                        justify-content: flex-start;

                                        &.column {
                                            & > .w-form-line {
                                                justify-content: flex-start;
                                                width: 100%;
                                                margin-bottom: 10px;

                                                .w-line-label {
                                                    font-family: $primaryFont;
                                                    font-size: 13.5px;
                                                    color: $black;
                                                    position: relative;
                                                    text-transform: capitalize;
                                                    min-width: 91px;

                                                    &:after {
                                                        content: ' :';
                                                        display: inline;
                                                        width: 2px;
                                                        height: 100%;
                                                    }
                                                }
                                            }

                                            .knowMoreOptin {
                                                display: none;
                                            }
                                        }

                                        .w-radio-group {
                                            .w-input {
                                                &.w-radio-input {
                                                    .w-input-label {
                                                        font-family: $primaryFont;
                                                        font-size: 14px;
                                                        color: $black;
                                                        font-weight: 600;

                                                        &:before {
                                                            width: 14px;
                                                            height: 14px;
                                                            border-color: $grey;
                                                        }
                                                    }

                                                    & + .w-radio-input {
                                                        margin-left: 22px;
                                                    }
                                                }
                                            }

                                            &.w-has-error {
                                                .w-input-element {
                                                    & + .w-input-label {
                                                        span {
                                                            color: $black;
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .w-input-note {
                                            text-align: left;

                                            &.w-input-error {
                                                text-align: left;
                                            }
                                        }
                                    }

                                    &.pwd-form-line {
                                        margin-bottom: 20px;

                                        & + .w-form-line {
                                            margin-bottom: 1rem;
                                        }
                                    }

                                    &.w-submit {
                                        width: 100%;
                                        max-width: 350px;
                                        margin-bottom: 11px;
                                    }

                                    .w-visibility-toggle {
                                        border: none;
                                        background-color: inherit;
                                        height: 24px;
                                        top: 40%;
                                        transition: inherit;
                                    }

                                    .w-pwd-reset {
                                        text-align: center;
                                        font-family: $primaryFont;
                                        font-size: 12px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        letter-spacing: 0.6px;
                                        text-transform: uppercase;
                                        color: $black;
                                        border-bottom: 1.5px solid $grey;
                                    }
                                }

                                .w-lines-group {
                                    padding-left: 3px;

                                    .w-fieldset-label {
                                        padding: 12px 0;
                                        margin: 0;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        font-family: $primaryFont;
                                    }
                                }
                            }

                            /* MOT DE PASSE OUBLIER */
                            .w-lostpass-form {
                                .w-form-line {
                                    .w-input-container {
                                        max-width: 600px;
                                        margin: auto;
                                    }

                                    &.w-submit {
                                        max-width: 100%;
                                        justify-content: center;

                                        .w-btn-wrapper {
                                            width: 200px;

                                            .w-submit-button {
                                                width: 100%;
                                            }
                                        }
                                    }
                                }
                            }

                        }

                        .acc_quick_access {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 21px;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                            margin-top: 14px;
                        }

                        .subtitle {
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            width: 100%;
                            margin: 0 auto;

                            &:not(#subtitleLogin) {
                                max-width: 452px;
                            }
                        }

                        .w-oneclick-connect {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-top: 15px;

                            .w-social-btn {
                                width: 36px;
                                height: 36px;
                                border: none;
                                background-color: $white;
                                margin-right: 24px;
                                margin-left: 0;

                                div {
                                    width: 100%;
                                    height: 100%;
                                }

                                &.fb-connect {
                                    order: 4;
                                    margin-right: 0;
                                }

                                &.go-connect {
                                    order: 3;
                                }

                                &.pp-connect {
                                    order: 2;
                                }

                                &.ap-connect {
                                    order: 1;
                                }
                            }

                            #googleConnect {
                                width: 38px;
                                height: 38px;
                                border: none;
                                background: $white;
                                margin-right: 13px;
                                margin-left: 0;
                                position: relative;
                                top: -4px;
                                left: -6px;
                                order: 3;
                            }
                        }

                        .login_protection_donnees_msg {
                            display: block !important;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-top: 30px;
                            margin-bottom: 10px;
                            text-align: center;
                        }
                    }

                    @media only screen 
                      and (min-device-width: 768px) 
                      and (max-device-width: 1024px) 
                      and (-webkit-min-device-pixel-ratio: 1) {
                        top: 9vh;
                    }
                }
            }
        }
    }

    &.en {
        #site_global_wrap {
            .wrapper_tunnel {
                .newlogin_page {
                    .login_wrapper {
                        .login_bloc {
                            .login_form_wrapper {
                                .w-newlogin-form {
                                    .w-form-line.optin_container.column>.w-form-line {
                                        .w-line-label {
                                            min-width: 105px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}