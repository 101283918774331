$white              : #ffffff;
$black              : #000000;

$primaryColor       : #000000;
$secondaryColor     : #F2E9DB;

/**
 * Color chart
 */
$beige-clair        : #F3EEE6;
$beige_Fonce        : #CFC3AF;
$grey               : #9A9996;
$grey2              : #7f7f7f;
$grey3              : #f7f7f7;
$lightgrey          : #D6D6D6;
$red                : #B10707;
$green              : #04AC6C;
$candy_red          : #FD1605;
$chili_pepper_red   : #BD141A;
$orange             : #FF9900;