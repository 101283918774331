.promo_bar {
    position: fixed;
    z-index: 50;
    background-color: $beige-clair;
    height: $preHeaderHeight;
    width: 100%;
    top: 0;

    .swiper-container.promo_swiper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .swiper-slide {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            background-color: $beige-clair;

            div {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                height: 100%;

                a {
                    display: inline-block;
                    margin-left: 5px;
                }
            }
        }

        p {
            position: absolute;
            padding: 0 30px;
            width: 100%;
            color: $white;
            font-weight: 400;
            font-size: 13px;
            line-height: 15.26px;
            font-family: $primaryFont;
            text-align: center;
        }

        img {
            height: 100%;
        }
    }

    .close_promo {
        background-image: url(../svg/spritesheet.svg);
        @include bgIconCoord(30,8,8);
        display: block;
        position: absolute;
        top: 0;
        right: 10px;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
        margin: auto;
        width: 11px;
        height: 11px;

        @media screen and (max-width: 1440px) {
          right: 32px;
        }
    }
}

#site_head_wrap {
    position: fixed;
    top: $preHeaderHeight;
    z-index: 50;
    margin: 0 auto;
    border-bottom: 1px solid transparent;
    background-color: rgba(255, 255, 255, 1);
    width: 100%;
    transition: top 0.2s ease-in-out, background-color 0.2s ease-in-out, border 0.2s ease-in-out;

    &:has(.headerContainer.show) {
        border-bottom: 1px solid $secondaryColor;
    }

    &:has(.headerContainer.sticky) {
        top: 0;

        .promo_bar{
            height: 0;
        }

        .headerContainer #search #form_recherche .wrap_search_field,
        .rollAccountEntries {
            top: $headerHeight;
        }

        .rollAccountEntries {
            height: calc(100vh - #{$headerHeight});
            height: calc(100dvh - #{$headerHeight});
        }
    }


    &:has(.headerContainer.show),
    &:has(.headerContainer.sticky),
    &.actif {
        background-color: rgba(255, 255, 255, 1);
    }

    .headerContainer {
        display: flex;
        width: 100%;
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 30px;
        color: $black;
        transition: background-color 0.2s cubic-bezier(0.4, 0, 1, 1), color 0.25s cubic-bezier(0.4, 0, 1, 1);

        &.show {
            &:not(.sticky) {
                z-index: 50;
            }

            .supercateg_item:has(+ .supercateg_item) {
                &::after {
                    background-color: $black !important;
                }
            }
        }

        &.sticky {
            top: 0;
            .rollAccountEntries {
                .btn_container {
                    bottom: 29px;
                }
            }

            .main_wrapper_menu {
                width: 469px;

                #wrapper_top_menu {
                    .main_menu {
                        .menu {
                            .superCategories {
                                .supercateg_item:has(+ .supercateg_item) {
                                    &::after {
                                        top: 50%;
                                        transform: translateY(-50%);
                                        background-color: $black;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            #wrapper_top_menu {
                .submenu_wrapper {
                    top: 90%;

                    .leftPart {
                        height: 52px;

                        .wrap_sscat_links {
                            padding: 1.6% 10.5%;
                        }

                        .wrap_push_sscat {
                            padding: 0 22% 0 2.6%;
                        }
                    }
                }
            }
        }

        .main_wrapper_menu {
            display: flex;
            width: 574px;
        }

        .headerLogo {
            z-index: 12;
            padding: 0.8rem 0;
            width: 135px;

            svg {
                width: 100%;
                height: 100%;
            }
        }

        .backdropArea {
            display: none;
        }

        .main_menu,
        .block_top_links {
            display: flex;
            height: 92px;
        }

        #search {
            #form_recherche {
                display: flex;
                align-items: center;
                margin: 0;

                .wrap_search_field {
                    display: none;
                    position: fixed;
                    top: $headerFull;
                    left: 0;
                    align-items: center;
                    background-color: $white;
                    width: 100%;
                    height: 80px;
                    transition: top 0.2s ease-in-out;

                    &.actif {
                        display: flex;
                    }

                    .wrap_field {
                        display: flex;
                        position: relative;
                        justify-content: space-between;
                        margin: 1rem auto;
                        border-bottom: 1px solid $grey;
                        width: calc(100% - 380px); // 380px = width of the logo on the left + the icons on the right

                        @media only screen and (max-width: 1024px) {
                            width: calc(100% - 60px);
                        }


                        .closeBtn {
                            position: absolute;
                            top: 8px;
                            right: 0;
                            background-image: url(../svg/spritesheet.svg);
                            width: 11px;
                            height: 11px;
                            @include bgIconCoord(30,8,8);
                            cursor: pointer;
                        }

                        .nbResults {
                            display: none;
                            position: absolute;
                            top: 8px;
                            right: 0;
                            margin-right: 50px;
                            color: $grey;
                            font-size: 14px
                        }
                    }

                    .search_results_wrapper  {
                        margin: 0 auto;
                        width: 100%;
                        max-width: 1920px;

                        &.search_active {
                            position: absolute;
                            top: 55px;
                            right: 0;
                            left: 0;
                            background-color: $white;
                            padding: 0.2% 0;
                        }

                        #search_results {
                            cursor: pointer;
                            margin: 0 auto;
                            width: calc(100% - 380px); // 380px = width of the logo on the left + the icons on the right

                            @media only screen and (max-width: 1024px) {
                                width: calc(100% - 60px);
                            }

                            li {
                                padding: 5px 0;
                            }
                        }
                    }

                    #search_home {
                        border: none;
                        padding: 0;
                        width: 100%;
                        color: $grey;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 27px;
                        font-family: $primaryFont;

                        &::placeholder {
                            color: $grey;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 27px;
                            font-family: $primaryFont;
                        }
                    }

                    .submit.search_ok {
                        display: none;
                    }
                }
            }
        }

        .alt_logo,
        .headerLogo h1 {
            opacity: 0;
            line-height: 0;
        }

        .headerRollover {
            display: flex;
            position: relative;
            align-items: flex-start;
            height: calc(92px - 2rem);
            margin-top: 2rem;
            padding: 0 5px;

            &:last-child {
                padding-right: 0;
            }

            &.is_logged,
            &.hasItem {
                .accountInitials {
                    display: none;
                }
            }

            .nbProd {
                display: flex;
                position: absolute;
                top: 9px;
                right: -5px;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                border-radius: 50%;
                background-color:$secondaryColor;
                padding-top: 0.1rem;
                width: 14px;
                height: 14px;
                color: $black;
                font-weight: 400;
                font-size: 12px;
                font-family: $primaryFont;
            }

            .bgSprite.bfr {
                &:before {
                    cursor: pointer;
                    width: 19px;
                    height: 20px;
                }

                &:hover {
                    &.account {
                        background-image: none;

                        &::before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(34,8,8)
                        }
                    }

                    &.birth {
                        background-image: none;

                        &::before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(35,8,8)
                        }
                    }

                    &.cart {
                        background-image: none;

                        &::before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(36,8,8)
                        }
                    }

                    &.locator {
                        &::before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(49,8,8)
                        }
                    }
                }
            }

            &.is_logged .bgSprite.bfr.account {
                @include bgIconCoord(34,8,8);
            }

            #giftlist_top {
                .title {
                    margin-bottom: 1.2rem;
                    color: $black;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 30px;
                    font-family: $secondaryFont;
                    text-align: center;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    a {
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 30px;
                        font-family: $primaryFont;
                        text-align: center;
                        text-transform: uppercase;

                        &:hover {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    &.prod_add_to_cart {
        z-index: 55;
        background-color: $white;
    }
}

.pheader:not(.promo_on) {
    .promo_bar {
        display: none;
    }

    #site_head_wrap {
        top: 0;
    }
}

// Exception #site_head_wrap
.body_login:not(:has(.dynasearchwrapper)):not(.in_tunnel),
body.gift_list_index:not(:has(.dynasearchwrapper)),
body.location:not(:has(.dynasearchwrapper)),
body.homepage:not(:has(.dynasearchwrapper)),
body.cms_new_page:not(.has_banner_brand_img):not(:has(.dynasearchwrapper)) {

    #site_head_wrap {
        background-color: rgba(255, 255, 255, 0);

        &:has(.headerContainer > .headerRollover:hover),
        &:has(.headerContainer > .block_top_links:hover),
        &:has(.headerContainer.show),
        &:has(.headerContainer.sticky) {
            background-color: rgba(255, 255, 255, 1);
        }

        .headerContainer {
            color: $white;
        }
    }
    #site_global_wrap {
        margin-top: -102px;
    }
}

body.search {
    #search #form_recherche {
        .wrap_field {
            .nbResults {
                display: block !important;
            }
        }
    }
}

/* Rollover */
#topLogin {
    .wrapper_menu_compte_container {
        position: relative;
        height: 100%;

        .wrapper_compte_hello {
            display: none;
        }
    }

    a {
        text-decoration: none;

        &.account_name {
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            font-family: $secondaryFont;
            text-align: center;
        }
    }

    .wrapper_menu_compte {
        margin-top: 4.3%;
        font-weight: 400;
        font-size: 12px;
        line-height: 30px;
        font-family: $primaryFont;
        text-align: center;
        text-transform: uppercase;

        a {
            &:hover {
                font-weight: 700;
            }
        }

        .wishlist_cnt {
            margin-left: 5px;
        }
    }

    .btn_container {
        position: absolute;
        right: 0;
        bottom: 70px;
        left: 0;

        #btn-disconnect {
            border: 0;
            background-color: $white;
            color: $black;
            font-weight: 500;
            font-size: 12px;
            text-decoration: underline;
            text-underline-offset: 4px;
        }

        .loader {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            box-sizing: border-box;
            background: url(../svg/three_dots.svg);
            width: 100%;
            height: 100%;
            font-size: 0;
        }
    }
}

.rollAccountEntries {
    position: fixed;
    top: $headerFull;
    right: 0;
    transform: translateX(100%);
    z-index: 5;
    transition: all ease-in-out 0.5s;
    box-sizing: border-box;
    background-color: $white;
    padding-top: 1.1%;
    height: calc(100vh - #{$headerFull});
    height: calc(100dvh - #{$headerFull});
    width: 100%;
    max-width: 350px;
    text-align: center;

    &.open {
        transform: translateX(0);
    }
}

@media screen and (min-width: 1921px) {
    .rollAccountEntries.open {
        max-width: calc(350px + ((100vw - 1920px) / 2));

        & > * {
            max-width: 350px;
        }
    }
}

#show_top_cart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.rollAccountEntries {
        &:has(.no_product) {
            justify-content: flex-start;
        }
    }

    .wrap_title {
        font-style: italic;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        font-family: $secondaryFont;
    }

    .no_product {
        padding-top: 1.5%;
    }

    #cart_list {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        padding: 1rem;
        overflow-y: auto !important;

        .product-link {
            margin-bottom: 20px;

            .product_wrapper {
                display: flex;
                max-width: 310px;

                .cart_thumb_holder {
                    display: block;
                    width: 100px;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }
                }
            }

            .options {
                display: flex;
                flex-direction: column;
                gap: 0.1rem;
                width: 100%;

                .sub_options {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: var(--opt-color, $beige-clair);
                    padding: 5px;
                    width: 100%;
                    font-size: 12px;
                }
            }
        }

        .cart_detail_box {
            padding-left: 10px;
            text-align: left;
        }

        .cart_product {
            display: flex;
            position: relative;
            flex-direction: column;
            gap: 0.3rem;
            font-weight: 400;
            font-family: $primaryFont;

            .wrap_top_info {
                display: block;
                font-size: 12px;

                .container_marques {
                    margin-bottom: 2px;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-transform: uppercase;
                }

                .site_name {
                    text-transform: uppercase;
                }

                .title {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 14px;
                    font-family: $primaryFont;

                    span {
                        font-weight: 400;
                    }
                }

                .itm_subtitle {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                .wrapper_price {
                    display: flex;
                    column-gap: 8px;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    font-size: 14px;
                    margin-bottom: 10px;

                    .pricetag {
                        .price {
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            font-family: $primaryFont;
                            text-align: right;
                        }

                        + .pricetag {
                            .price {
                                color: $grey;
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }

            .sub_quantity,
            .sub_couleur,
            .sub_taille {
                margin-bottom: 3px;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: normal;
                font-family: $primaryFont;
            }

            .sub_taille {
                margin-bottom: 0;
            }

            .bask_action {
                position: absolute;

                .remove_prod {
                    &::before {
                        position: absolute;
                        top: 0;
                        left: 186px;
                        width: 10px;
                        height: 10px;
                    }

                    span {
                        display: none;
                    }
                }

                #topBasketToWish {
                    display: none;
                }
            }
        }
    }

    .wrap_tot_panier{
        display: flex;
        flex-wrap: wrap;
        gap: 23px;
        border-top: 1px solid $lightgrey;
        background-color: $white;
        padding-top: 26px;
        height: auto;

        .cart_panier_total {
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
            width: 350px;

            .titre_tot {
                text-transform: uppercase;
            }
        }

        .upselling {
            display: flex;
            flex-direction: column-reverse;
            order: 1;
            padding: 0 21px;
            width: 100%;
            width: 100%;
            font-weight: 600;
            font-size: 11px;
            line-height: 12px;

            p {
                font-weight: 500;
                text-align: initial;
            }

            progress {
                vertical-align: top;
                appearance: none;
                margin-bottom: 6px;
                border: 1px solid $beige_Fonce;
                border-radius: 25px;
                background-color: $white;
                width: 100%;
                height: 5px;
                overflow: hidden;
            }

            progress::-webkit-progress-bar {
                background-color: $white;
            }

            progress::-webkit-progress-value {
                background-color: $beige_Fonce;
            }

            progress::-moz-progress-bar {
                background-color: $beige_Fonce;
            }
        }

        .form_submit {
            order: 0;

            .button {
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                box-sizing: border-box;
                margin: 0px 18px;
                border: 1px solid $black;
                border-radius: 25px;
                background-color: $black;
                width: 310px;
                height: 50px;
                color: $white;
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
            }

            .loader {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -1;
                box-sizing: border-box;
                border: 1px solid $black;
                background: url(../svg/three_dots.svg);
                background-position: center;
                background-size: auto 20%;
                background-repeat: no-repeat;
                background-color: $black;
                width: 100%;
                height: 100%;
                font-size: 0;
            }
        }

        .reassurance_wrapper {
            order: 1;
            overflow: hidden;

            .reassurance_cart {
                display: flex;
                justify-content: center;
                animation: marquee_cart 15s linear infinite;
                padding: 0 5rem;
                width: 100%;

                .txt {
                    display: inline-block;
                    margin-right: 28px;
                    margin-bottom: 30px;
                    width: auto;
                    color: $grey;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: normal;
                    font-family: $primaryFont;
                    white-space: nowrap;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.body_login:not(:has(.dynasearchwrapper)),
body.gift_list_index:not(:has(.dynasearchwrapper)),
body.location:not(:has(.dynasearchwrapper)),
body.homepage:not(.has_banner_brand_img):not(:has(.dynasearchwrapper)) {
    padding-top: 0 !important;

    #site_head_wrap {
        .headerContainer {
            &.show,
            &.sticky,
            &.cover {
                z-index: 10;
                color: $black;

                .submenu_wrapper {
                    background-color: rgba($white, 1);
                }
            }

            &:not(.show):not(.sticky):not(.cover) {
                .headerLogo {
                    svg {
                        color: $white;
                    }
                }

                .main_menu_itm {
                    &.supercateg_item:has(+ .supercateg_item) {
                        &::after {
                            background-color: $white;
                        }
                    }
                }

                .categories .swiper-container .menu_categ {
                    &:hover {
                        color: var(--hovercolor, $white);
                    }
                }

                .bgSprite {
                    &.search {
                        transition: background-image 0.5s ease-in-out;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(2,8,8);
                    }

                    &.birth {
                        background-image: none;

                        &:before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(4,8,8);

                        }
                    }

                    &.locator {
                        transition: background-image 0.5s ease-in-out;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(6,8,8);
                    }

                    &.account {
                        transition: background-image 0.5s ease-in-out;
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(8,8,8);
                    }

                    &.cart {
                        background-image: none;

                        &:before {
                            transition: background-image 0.5s ease-in-out;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(10,8,8);
                        }
                    }
                }

                .is_logged .bgSprite.account {
                    background: none;
                    &::before {
                        background: none;
                        @include maskIconPath('../svg/spritesheet.svg');
                        @include maskIconCoord(34,8,8);
                        background-color: white;
                    }
                }
            }
        }
    }
}

#shad_menu.actif {
    z-index: 25;
}

.shad.actif {
    z-index: 25;
}

@keyframes fadeheadercontainer {
    0%   {background-color: rgba(255, 255, 255, 0);}
    100% {background-color: rgba(255, 255, 255, 1);}
}