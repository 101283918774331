/* Lightbox general styles */
.lightbox {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease-in-out;
    background-color: $white;
    height: 100vh;
    overflow: auto;

    &.actif {
        display: block;
        opacity: 1;
        z-index: 101;
        transition: all 0.3s ease-in-out;
    }

    .close_pop,
    .btn_fermer,
    .modboxBtnClose {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
        border-radius: none !important;
        background-image: url(../svg/spritesheet.svg);
        background-color: inherit !important;
        padding: 0 !important;
        width: 1rem !important;
        height: 1rem !important;
        font-size: 0;
        @include bgIconCoord(30, 8, 8);
        border: none !important;
    }
}

/* shade */
.shad {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.shad.actif {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out, visibility 0.5s ease-in-out 0.5, z-index 0.5s ease-in-out 0.5s;
    height: 100%;
    pointer-events: auto;

    &.for_filter {
        top: 0;
        z-index: 53;
        transition: opacity 0.75s ease-in-out, height 0.75s ease-in-out, backdrop-filter 0.75s ease-in-out;
    }

    &.for_gdt {
        top: 0;
        z-index: 53;
    }
}

/* En savoir plus - page conexion */
#popup_savoir_plus {
    box-sizing: border-box;
    padding: 5%;
    width: 800px;

    #title_popup_savoir_plus {
        display: none;
    }

    #bottom_savoir_plus {
        font-size: 14px;
        line-height: 1.8;
        letter-spacing: 0.02em;
    }
}

#abox {
    box-sizing: border-box;
    padding: 3em 2em;
    width: 500px;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;

    .header_lightbox {
        .txt_alert {
            line-height: 1.2;
            .light_title,
            .titre {
                margin-bottom: 22px;
                font-style: italic;
                font-weight: 400;
                font-size: 35px;
                line-height: 1;
                font-family: $secondaryFont;
            }

            p {
                margin-bottom: 22px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-align: center;
            }
        }
    }

    .wrap_btn_abox {
        display: flex;
        justify-content: center;

        .form_submit .button {
            width: 350px;
        }
    }
}

#ligthbox_send_store_information {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: inherit;
    box-sizing: border-box;
    margin: auto;
    padding: 1.5rem;
    width: 500px;
    height: 250px;
    text-align: center;

    .header_lightbox {
        .popup_title {
            margin-bottom: 22px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
        }
    }

    .input_label {
        text-align: left;
    }

    .w-sendshop-form {
        & > p {
            position: relative;
            top: -1rem;
            background-color: $white;
            padding: 8px 0;
            font-size: 14px;
            font-family: $primaryFont;
            text-align: center;
        }

        .w-input-container {
            .w-input-note {
                text-align: left;
            }
        }
    }

    .w-submit {
        margin: auto;
    }
}

/* Newsletter */
#exit_overlay_OVI {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: inherit;
    margin: auto;
    width: 775px;
    height: 450px;
    overflow: hidden;

    .pop_contents {
        display: flex;
        align-content: center;
        justify-content: space-between;
        height: 450px;

        .wrapper_img {
            width: 47.48387%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .wrapper_newspopup_home {
            display: flex;
            align-content: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 38px;
            width: 52.51613%;

            .content_newspopup {
                display: flex;
                position: relative;
                justify-content: center;
                align-items: center;
                width: 100%;

                #formu_inscription_news,
                #conf_inscription_news {
                    display: flex;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    flex-direction: column;
                    justify-content: center;
                    transition: inherit !important;
                    margin: auto;
                    width: 100%;
                    height: 100%;

                    .title_popup_inscription_home,
                    .title_popup_inscription_home {
                        margin-bottom: 1rem;
                        padding: 0 35px;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 35px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .pop_txt_listing {
                        margin-bottom: 0 !important;

                        .common_txt,
                        .detail {
                            margin-bottom: 21px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            text-align: center;

                            div {
                                text-align: center !important;
                            }
                        }
                    }

                    .formRow {
                        .w-adwords-form {
                            .w-form-line-container {
                                .w-form-line {
                                    margin: 0;

                                    p.w-input-note {
                                        top: inherit;
                                        bottom: 0;
                                    }

                                    .w-input-container {
                                        margin-bottom: 29px;
                                    }

                                    &.w-submit {
                                        max-width: 100%;

                                        .w-submit-button {
                                            border-radius: 50px !important;

                                            &:hover,
                                            &:focus {
                                                border: 1px solid $black;
                                                background-color: $white !important;
                                                color: $black !important;

                                                span {
                                                    color: $black !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .pop_cgv_listing {
                                margin-top: 22px;
                                color: $grey;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                                text-align: center;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                #conf_inscription_news {
                    .form_submit {
                        margin-top: 0;

                        .button {
                            cursor: pointer;
                            border-radius: 50px !important;
                            width: 100%;
                            max-width: 350px;

                            &:hover,
                            &:focus {
                                border: 1px solid $black;
                                background-color: $white !important;
                                color: $black !important;

                                span {
                                    color: $black !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Carte cadeau */
#lightboxOrderGiftCard {
    width: 750px;

    .w-input::placeholder {
        font-style: normal;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;
        cursor: pointer;
        border-radius: none !important;
        background-image: url(../svg/spritesheet.svg);
        background-color: inherit !important;
        padding: 0 !important;
        width: 12px !important;
        height: 12px !important;
        font-size: 0;
        @include bgIconCoord(30, 8, 8);
        border: none !important;
    }

    .orderGiftCardForm {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        box-sizing: border-box;
        padding: 3.75rem;
        width: 750px;
        min-height: 425px;

        .step {
            margin: 0 auto;
            width: 60%;
            text-align: center;

            &#step1 {
                .step1_infos {
                    display: flex;
                    flex-direction: column;

                    #sendMethods {
                        position: relative;
                        order: 2;
                        margin: 2rem auto;
                        border: 1px solid $grey;
                        padding: 1rem 1.5rem;
                        width: 90%;

                        &:before,
                        &:after {
                            position: absolute;
                            top: -10px;
                            left: 50%;
                            transform: translateX(-50%);
                            content: '';
                        }

                        &:before {
                            border: 1px solid $grey;
                            background-color: white;
                            width: 15px;
                            height: 15px;
                        }

                        &:after {
                            top: -6.5px;
                            background: $black;
                            width: 10px;
                            height: 10px;
                        }

                        .sendMethodsOption {
                            margin-left: 8rem;
                            text-align: left;

                            &:before {
                                position: absolute;
                                top: 50%;
                                left: 50px;
                                transform: translateY(-50%);
                                background: url(../svg/send.svg);
                                width: 3.5rem;
                                height: 3.5rem;
                                content: '';
                            }

                            h2 {
                                margin-top: 2rem;
                                color: $black;
                                font-weight: 800;
                                font-size: 19px;

                                label {
                                    display: block !important;
                                    text-transform: uppercase;

                                    span {
                                        display: block;
                                        margin-top: -2px;
                                        color: $grey;
                                        font-weight: 200;
                                        font-size: 17px;
                                        text-transform: none;
                                    }
                                }
                            }

                            .infos_desc {
                                margin: 2rem 0;

                                p {
                                    color: $grey;
                                    font-size: 15px;

                                    &.typeOfGift {
                                        margin-bottom: 0.5rem;
                                        color: $black;
                                    }
                                }
                            }
                        }
                    }

                    .chooseBalance {
                        .selectBalanceLabel {
                            color: $black;
                            font-weight: 200;
                            font-size: 18px;
                            font-family: $primaryFont;

                            h2 {
                                color: $black;
                                font-weight: 800;
                                font-size: 2.1rem;
                                text-transform: uppercase;
                            }
                        }

                        /*Masquage des flèches pour augmenter/diminuer le montant*/
                        /* Chrome, Safari, Edge, Opera */
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type='number'] {
                            -moz-appearance: textfield;
                        }

                        input.w-input {
                            margin: 1rem auto 0;
                            border: 1px solid $grey;
                            padding-left: 0.5rem;
                            width: 90%;
                            height: 40px;

                            &::placeholder {
                                font-style: normal;
                            }

                            &.inputErr {
                                border: 2px solid $red;
                            }
                        }
                    }
                }

                .btn_container {
                    position: relative;
                    margin: 0 auto;
                    width: 100%;
                    max-width: 350px;

                    .button,
                    button {
                        cursor: pointer;
                        width: 100%;
                    }

                    .loader {
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        z-index: -1;
                        border-radius: 50px;
                        background: $black url('../svg/three_dots.svg') no-repeat center;
                        background-size: 15% auto;
                        width: 100%;
                        height: 48px;

                        span {
                            display: none;
                        }

                        &.loading {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }
            }

            &#step2 {
                display: none;
                margin: 1rem auto;
                width: 90%;

                .giftDetails {
                    .step2_infos {
                        .js-textDeliveryMail {
                            display: none;
                        }

                        .js-textDeliveryMail_2 {
                            display: block !important;
                            color: $black;
                            font-weight: 800;
                            font-size: 35px;
                            text-transform: uppercase;
                        }

                        h3 {
                            margin-top: 1rem;
                            margin-top: 0.3rem;
                            margin-bottom: 1.5rem;
                            color: $grey;
                            font-weight: 200;
                            font-size: 22px;
                        }

                        .infos_msg {
                            .formPerso {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 0.8rem;

                                .w-input {
                                    width: 49%;
                                    height: 40px;
                                }
                            }
                        }
                    }

                    .btn_container {
                        display: flex;
                        justify-content: center;
                        margin-top: 2rem;

                        .button {
                            margin: 0 15px;
                            width: 15%;
                        }

                        #js-submitStep2 {
                            span {
                                &:first-of-type {
                                    display: none;
                                }

                                &:last-of-type {
                                    display: block !important;
                                    margin-top: 8%;
                                }
                            }
                        }
                    }
                }
            }

            &#step3 {
                display: none;
                width: 95%;

                .giftDetails {
                    .infos {
                        .js-textDeliveryMail {
                            color: $black;
                            font-weight: 800;
                            font-size: 35px;
                            text-transform: uppercase;
                        }

                        .stepsList {
                            display: flex;
                            justify-content: space-evenly;
                            margin-top: 3.5rem;

                            li {
                                width: 33%;

                                p {
                                    position: relative;
                                    z-index: 1;
                                    color: $white;

                                    &::before {
                                        position: absolute;
                                        top: -7px;
                                        left: 50%;
                                        transform: translateX(-50%);
                                        z-index: -1;
                                        border-radius: 20px;
                                        background-color: $black;
                                        width: 35px;
                                        height: 35px;
                                        content: ' ';
                                    }
                                }

                                h3,
                                h4 {
                                    font-size: 15px;
                                }

                                h3 {
                                    margin-top: 1.5rem;
                                    color: $black;
                                    text-transform: uppercase;
                                }

                                h4 {
                                    color: $grey;
                                    font-weight: 400;
                                }
                            }
                        }

                        .resumeList {
                            margin: 2rem 0 0 4rem;

                            li {
                                display: flex;
                                line-height: 2;

                                &:first-of-type {
                                    h3.item {
                                        display: none;
                                    }
                                }

                                h3,
                                p {
                                    margin-right: 1rem;
                                }

                                h3,
                                p,
                                small {
                                    vertical-align: baseline;
                                    font-size: 13px;
                                }

                                h3 {
                                    color: $black;
                                    font-size: 12px;
                                    letter-spacing: 1.2px;
                                    text-transform: uppercase;

                                    &.item_2 {
                                        display: block !important;
                                    }
                                }

                                p {
                                    color: $black;
                                }

                                small {
                                    color: $grey;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .btn_container {
                        display: flex;
                        justify-content: center;
                        margin-top: 5rem;

                        .button {
                            margin: 0 15px;
                            width: 25%;

                            &.dark span {
                                &:first-of-type {
                                    display: none;
                                }

                                &:last-of-type {
                                    display: block !important;
                                    margin-top: 5%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Magazine */
.lightbox_magazine {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: inherit;
    opacity: 0;
    z-index: -1;
    margin: auto;
    border: 1px solid rgba($black, 0.5);
    background: $white;
    padding: 1.5rem;
    width: 90vw;
    height: 750px;
    max-height: 90vh;

    &.actif {
        justify-content: center;
        opacity: 1;
        z-index: 52;
    }
}

/* Product zoom */
#zoomBox {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: inherit;
    box-sizing: border-box;
    margin: auto;
    background-color: $white;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    .close_pop {
        top: 18px;
        right: 20px;

        span {
            width: 15px;
            height: 15px;
        }
    }

    #zoom_box_wrapper {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        overflow: auto;

        .productBigPictures {
            margin-bottom: 150px;
        }

        .thumbsZoomContainer {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            padding: 1rem;
            width: 8rem;
            height: calc(100vh - 66px);

            .zommThumbs, .swiper-wrapper {
                height: 100%;
            }

            .swiper-slide {
                position: relative;
                cursor: pointer;
                aspect-ratio: 9/11;
                height: fit-content !important;
                overflow: hidden;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: calc(100% - 2px);
                    height: calc(100% - 2px);
                    content: '';
                }

                img {
                    width: 100%;
                    height: auto;
                }

                &.minivideo {
                    &:after {
                        background: rgba($black, 0.5)
                            url(../svg/spritesheet.svg);
                        content: '';
                        @include bgIconCoord(38, 8, 8);
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }
                }

                &.swiper-slide-thumb-active {
                    &:before {
                        border: 1px solid $black;
                    }
                }
            }

            .zoomThumbsNext,
            .zoomThumbsPrev {
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(29, 8, 8);
                position: absolute;
                top: 10px;
                right: 0;
                left: 0;
                transform: rotate(90deg);
                z-index: 5;
                cursor: pointer;
                margin: 0 auto;
                width: 1rem;
                height: 1rem;
            }

            .zoomThumbsNext {
                top: auto;
                bottom: 10px;
                transform: rotate(-90deg);
            }
        }

        .zommMainVisuWrapper {
            position: relative;
            padding-top: 34vh;
            max-width: 1060px;

            @media only screen and (max-width: 1360px) {
                padding-top: 0;
                max-width: 860px;

                .zommMainVisu {
                    max-width: 740px;
                }
            }

            @media only screen and (max-width: 1160px) {
                max-width: 760px;

                .zommMainVisu {
                    max-width: 680px;
                }
            }

            @media only screen and (max-width: 1080px) {
                max-width: 720px;

                .zommMainVisu {
                    max-width: 660px;
                }
            }

            .zoomProdNext,
            .zoomProdPrev {
                display: none;
                position: absolute;
                top: 0;
                right: auto;
                bottom: 0;
                left: -30px;
                z-index: 15;
                cursor: pointer;
                margin: auto 0;
                background: inherit;
                width: 30px;
                height: 30px;

                &:after {
                    background-image: url(../svg/spritesheet.svg);
                    content: '';
                    @include bgIconCoord(29, 8, 8);
                    display: block;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 1rem;
                    height: 1rem;
                }

                &.visible {
                    display: block;
                }

                &.disabled,
                &.swiper-button-disabled {
                    display: block;
                    opacity: 0.5;
                }
            }

            .zoomProdNext {
                right: -30px;
                left: auto;
                transform: rotate(180deg);
            }
        }

        .zommMainVisu {
            position: relative;
            width: 100%;
            max-width: 1000px;

            .swiper-slide {
                cursor: pointer;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                &.slide_video {
                    display: block;
                    position: relative;
                    background-color: $black;
                    padding-bottom: calc((1250 / 1000) * 100%);
                    width: 100%;
                    height: 0;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -1;
                        background: $black;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }

    img.img_large {
        width: 100%;
        height: auto;
    }

    &.actif {
        display: flex;
    }
}

#modboxpromo {
    padding: 1.5rem !important;
    width: 500px;
    text-align: center;

    .texte {
        margin: 35px 0 10px;
        color: $black;
        font-size: 1rem;
        line-height: 18px;
        font-family: $primaryFont;
    }

    .button {
        &.primary {
            display: block;
            margin: 22px auto 0;
            border-radius: 25px;
            background-color: $black;
            width: 240px;
            height: 50px;
            color: $white;
            font-size: 12px;
            line-height: 50px;
            font-family: $primaryFont;
            text-transform: uppercase;
        }
    }

    .modboxBtnClose {
        display: block !important;
        top: 5px;
        font-size: 0 !important;
    }
}

.cart.step_1 {
    #modboxpromo {
        margin: 0 auto;
        padding: 40px;
        width: 100%;
        max-width: 600px;
        height: fit-content;
        
        .texte {
            margin: 0 auto;
            width: 100%;
            max-width: 350px;
        }
    }
}

/* Lightbox produit offert(code promo) */
#selection-offer {
    display: block !important;
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    transform: translateX(100%);
    opacity: 0;
    z-index: 55;
    transition: transform ease-in-out 0.5s;
    box-sizing: border-box;
    background-color: $white;
    padding: 30px 25px 25px;
    width: 380px;
    height: 100vh;
    overflow-y: auto;

    .close_pop {
        top: 15px;
        right: 15px;
        padding: 0;

        span {
            font-size: 0;
        }
    }

    .selection-offer-header {
        margin-bottom: 22px;
        font-style: italic;
        font-weight: 400;
        font-size: 25px;
        line-height: 1;
        font-family: $secondaryFont;
    }

    #selection-offer-content {
        margin: 0;
        padding: 0;
        width: 100%;
        max-height: calc(100vh - 85px);
        overflow: auto;

        .swiper-scrollbar {
            display: none;
        }

        #wrap_lion {
            display: block;
            box-sizing: border-box;
            width: 100%;

            .js-form-selection-offer {
                position: relative;
                opacity: 1; 
                box-sizing: border-box;
                margin-bottom: 30px !important;
                border: 1px solid $white;
                background-color: $white;
                padding: 8px 8px 8px 110px;
                width: 100%;
                min-height: 127px;
                font-weight: 400;
                font-size: 11px;
                font-family: $primaryFont;

                &:last-of-type {
                    margin-bottom: 0 !important;
                }

                & > div {
                    &:first-of-type {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        margin: auto;
                        width: 100px;
                        height: 125px;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                .quantity,
                .size {
                    span {
                        font-weight: 400;
                        font-size: 11px;
                        font-family: $primaryFont;
                        letter-spacing: .55px;
                    }
                }

                .size {
                    display: inline-block;

                    input[type="radio"] {
                        display: none;
                    }
                }

                .color_list {
                    margin-bottom: 0;

                    .form_itm.check.color {
                        display: none;
                    }
                }

                .titleOfferedProd {
                    order: 0;
                    margin: 0;
                    width: 100%;

                    span {
                        margin-top: 0.4rem;
                        margin-bottom: 30px;
                        font-style: italic;
                        font-weight: 300;

                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }

                    p {
                        width: 100%;
                        width: calc(100% - 5px);
                        overflow: hidden;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        & + p {
                            font-weight: 400;
                        }
                    }
                }

                .pricetag {
                    margin-bottom: 7px;

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: normal;
                        font-family: $primaryFont;
                    }
                }

                &:before {
                    display: none;
                }

                &.actif {
                    border: 1px solid $black;
                    background-color: $white;
                }

                &:hover {
                    border: 1px solid $grey;
                }
            }

            .color_list {
                margin-bottom: 10px;

                .txt {
                    margin-right: 10px;
                }

                &:not(.swiper-container-initialized) {
                    .swiper-slide {
                        margin-right: 10px;
                    }

                    .txt {
                        margin-right: 0;
                    }
                }

                .color {
                    label {
                        box-shadow: inset 0 0px 0px 3px $white;
                        border: 1px solid $lightgrey;
                        width: 1rem;
                        height: 1rem;
                    }

                    input[type="radio"]:checked {
                        & + label {
                            border: 1px solid $black;
                        }
                    }
                }
            }

            .color_list,
            .size_list {
                padding: 0;
                height: auto;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.55px;

                .txt_pre {
                    z-index: 5;
                    background: white;
                    padding-right: 15px;
                    font-weight: 500;
                    font-size: 13px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                }

                .prevColour,
                .prevSize,
                .nextColour,
                .nextSize {
                    display: none !important;
                }

                input[type="radio"]:checked {
                    & + label {
                        text-decoration: underline;
                    }
                }
            }

            .color_list .txt_pre {
                margin-top: 3px;
            }

            .size_list {
                .swiper-wrapper {
                    .size label::before {
                        content: none;
                    }

                    .swiper-slide {
                        padding-right: 10px;
                        min-width: inherit;

                        label {
                            min-width: inherit !important;
                        }

                        &:last-of-type {
                            padding-right: 20px;
                        }
                    }
                }
            }
        }

        .os-scrollbar.os-scrollbar-vertical {
            top: 20px;
            right: 25px;
            bottom: 0;
        }
    }

    .selection-offer-submit.btn_container {
        .button {
            transition: all 0.35s ease-in-out;
            cursor: pointer;
            border: 1px solid $black;
            border-radius: 50px;
            background-color: $black;
            width: 350px;
            height: 48px;
            color: $white;

            span {
                color: $white;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
            }
        }

        &.disabled {
            .button {
                opacity: 1;
                cursor: default;
                border: 1px solid $black;
                background-color: $white;
                pointer-events: none;
                color: $black;

                span {
                    color: $black;
                }
            }
        }
    }

    #errorLabel {
        padding: 1rem 0;
        height: auto;
    }

    &:not(.disabled) {
        &:hover,
        &:focus {
            background-color: $white;
            color: $black;

            span {
                color: $black;
            }
        }
    }

    &.actif {
        transform: translateX(0%);
        opacity: 1;
    }
}

/* Achat express v1 (produit associe)*/
.block_achat_express {
    position: fixed;
    top: 0;
    right: 0;
    transform: translateX(100%);
    z-index: 55;
    transition: transform ease-in-out 0.5s;
    box-sizing: border-box;
    background-color: $white;
    padding: 24px 0 28px;
    width: 380px;
    height: 100vh;

    .btn_fermer_express {
        display: none;
    }

    .close_pop,
    .close_modal {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
        cursor: pointer;
        border-radius: 0;
        background-image: url(../svg/spritesheet.svg);
        background-color: inherit !important;
        padding: 0;
        width: 13px !important;
        height: 13px !important;
        font-size: 0;
        @include bgIconCoord(30, 8, 8);
        border: none !important;

        span {
            display: none;
        }
    }

    .top_wrapper {
        height: calc(100vh - 150px);
        overflow-x: hidden;
        overflow-y: auto;
    }

    #formAchatExpressV1 {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 1.5rem;
        height: calc(100vh - 55px);

        .close_modal {
            top: -15px;
        }

        #lightboxAchatExpressV1Pictures {
            position: relative;

            .productVisualMulti {
                .swiper-wrapper {
                    .wrapper_img {
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }

            .rollover_rayonsNext,
            .rollover_rayonsPrev {
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(29,8,8);
                display: block;
                position: absolute;
                top: 0;
                right: auto;
                bottom: 0;
                left: 5px;
                opacity: 1;
                z-index: 5;
                cursor: pointer;
                margin: auto;
                width: 1rem;
                height: 1rem;

                &:after {
                    display: none;
                }
            }

            .rollover_rayonsNext {
                right: 8px;
                left: auto;
                transform: rotate(180deg);
            }

            &:hover {
                .rollover_rayonsNext,
                .rollover_rayonsPrev {
                    opacity: 1;

                    &.swiper-button-lock {
                        display: none;
                    }

                    &.disabled,
                    &.swiper-button-disabled{
                        display: block;
                        opacity: 0.5;
                    }
                }
            }
        }

        #lightboxAchatExpressV1Brand {
            display: flex;
            justify-content: space-between;
            margin-bottom: 11px;

            .brand {
                display: flex;
                align-items: center;

                .container_marques {
                    margin-bottom: 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-transform: uppercase;
            
                    img {
                        width: auto;
                        height: 1rem !important;
                    }
                }
            }

            .btnWishlist  {
                position: relative;
                padding: 8px 0 0 0;
                
                .wish {
                    background-image: url(../svg/spritesheet.svg);
                    width: 22px;
                    height: 22px;
                    @include bgIconCoord(20, 8, 8);
                    display: block;
                    margin: 0;

                    span {
                        font-size: 0;
                    }

                    &.existToWishlistButton {
                        @include bgIconCoord(21, 8, 8);
                    }
                }
            }
        }

        #lightboxAchatExpressV1Info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;

            .text {
                width: calc(100% - 100px);

                span {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: $primaryFont;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &.title {
                        font-weight: 600;
                    }
                }
            }

            .prices {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                align-items: flex-start;
                padding-top: 3px;
                text-align: right;

                .price {
                    display: block;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: normal;
                    font-family: $primaryFont;
                    text-align: right;

                    & + .price,
                    &.crossed {
                        margin-right: 5px;
                        color: $grey;
                        font-size: 13px;
                        text-decoration: line-through;
                    }

                    &.crossed {
                        order: 2;
                        width: 100%;
                    }
                }

                .ecotaxe {
                    display: none;
                }
            }
        }

        #lightboxAchatExpressV1ColorSize {
            margin-bottom: 14px;

            .ligne_form {
                overflow: hidden;

                &.productColorFieldset {
                    .choices_list {
                        margin: 10px 0 20px 0;
                        padding-left: 1px;
                        max-width: calc(100% - 25px);
                        height: 25px;

                        .swiper-slide {
                            display: inline-flex;
                            margin-right: 30px;
                            width: auto;

                            &:after {
                                display: block;
                                position: relative;
                                margin-left: 6px;
                                padding-top: 4px;
                                content: attr(data-color);
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;
                                text-transform: capitalize;
                            }
                        }

                        input[type="radio"] {
                            position: absolute;
                            opacity: 0;
                            z-index: 2;
                            cursor: pointer;
                            width: 100%;
                            height: 18px;

                            + label {
                                display: block;
                                position: relative;
                                cursor: pointer;
                                box-shadow: inset 0 0 0 3px $white;
                                border-radius: 100%;
                                background-position: center;
                                background-size: contain;
                                background-repeat: no-repeat;
                                padding: 1px;
                                width: 20px;
                                height: 20px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 0;
                                line-height: normal;
                                font-family: $primaryFont;

                                &:before {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0px;
                                    cursor: pointer;
                                    margin: auto;
                                    border: 1px solid $lightgrey;
                                    border-radius: 100%;
                                    background: transparent;
                                    width: 20px;
                                    height: 20px;
                                    content: '';
                                }
                            }

                            &:hover,
                            &:checked {
                                & + label {
                                    &:before {
                                        border: 1px solid $black;
                                    }
                                }
                            }

                            &:checked:hover {
                                cursor: default;
                            }
                        }
                    }
                }

                &.productSizeFieldset {
                    margin-bottom: 20px;

                    .choices_list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-end;
                        gap: 1rem;

                        .form_itm {
                            position: relative;
                            width: fit-content;

                            input[type="radio"] {
                                position: absolute;
                                opacity: 0;
                                z-index: 1;
                                cursor: pointer;
                                margin: 0;
                                width: 100%;
                                height: 100%;

                                & + label {
                                    display: inline-block;
                                    position: relative;
                                    cursor: pointer;
                                    cursor: pointer;
                                    border-bottom: 1px solid $white;
                                    padding-bottom: 3px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: normal;
                                    font-family: $primaryFont;

                                    &.disabled {
                                        color: $grey !important;
                                    }
                                }

                                &:hover,
                                &:checked {
                                    & + label {
                                        border-bottom: 1px solid $black;
                                        font-weight: 600;
                                    }
                                }

                                &:checked:hover {
                                    cursor: default;
                                }

                                &.false {
                                    & + label {
                                        color: $grey;
                                    }

                                    &:hover,
                                    &:checked {
                                        & + label {
                                            border-bottom: 1px solid $grey;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                    .size_select_wrapper .error {
                        color: $red;
                        font-weight: 500;
                        font-size: 14px;
                        font-family: $primaryFont;
                    }
                }
            }
        }

        #lightboxAchatExpressV1Carac {
            .desc_pannel {
                border-top: 1px solid $lightgrey;
                border-bottom: 1px solid $lightgrey;

                .toggleCarac {
                    display: block;
                    position: relative;
                    cursor: pointer;
                    color: $black;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 45px;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    &:after {
                        background-image: url(../svg/spritesheet.svg);
                        content: '';
                        @include bgIconCoord(29,8,8);
                        display: inline-block;
                        position: absolute;
                        top: 12px;
                        right: 0;
                        transform: rotate(-90deg);
                        transition: all 0.35s ease-in-out;
                        width: 1rem;
                        height: 1rem;
                    }

                    & + .content {
                        display: none;
                        transition: max-height 0.5s linear;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;

                        .product_ref {
                            margin-bottom: 30px;
                            color: $grey;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            font-family: $primaryFont;

                            & + br {
                                display: none;

                                & + br {
                                    display: none;
                                }
                            }
                        }

                        .caract_icon {
                            display: flex;
                            flex-wrap: wrap;

                            img {
                                display: inline-block;
                                margin-right: 8px;
                                width: auto;
                                height: 48px;
                            }
                        }
                    }


                    &.actif {
                        border-bottom: 1px solid $white;

                        & + .content {
                            padding-bottom: 20px;
                        }

                        &:after {
                            transform: rotate(90deg);
                        }
                    }
                }
            }
        }

        #lightboxAchatExpressV1Btn {
            position: relative;

            button {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
            }

            .expressAlertStock {
                .alert_stock {
                    cursor: pointer;
                    border: 1px solid $black;
                    border-radius: 50px;
                    background-color: $white;
                    width: 100%;
                    height: 48px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 48px;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-align: center;
                    text-transform: uppercase;

                    span {
                        line-height: 48px;
                    }
                }
            }

            .alert_available {
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                font-family: $primaryFont;
                text-align: center;
                text-transform: uppercase;
            }

            .bloc_add_alert_form {
                background-color: $white;

                .w-form-line {
                    position: relative;

                    .w-text-input {
                        font-weight: 500;
                        font-size: 0.8rem;
                        text-align: center;
                        text-transform: uppercase;
                    }

                    .form_submit {
                        position: absolute;
                        right: 0;
                        bottom: 5px;
                        width: 13px;

                        #send_mail_alert_stock_aev1 {
                            border: none !important;
                            border-radius: 0 !important;
                            background-image: url(../svg/spritesheet.svg);
                            background-color: inherit !important;
                            width: 13px;
                            height: 13px !important;
                            @include bgIconCoord(29,8,8);
                            transform: rotate(180deg);
                            opacity: 0.5;

                            span {
                                font-size: 0;
                            }
                        }
                    }
                }
            }

            .bloc_add_alert_confirmation {
                font-size: 0.8rem;
            }

            .bloc_add_alert_erreur {
                position: relative;
                padding: 10px 0;
                color: $red;

                p {
                    font-size: 12px;
                    font-family: $primaryFont;
                }

                .w-submit-button {
                    display: none;

                    span {
                        font-size: 0;
                    }
                }
            }

            #bloc_add_basket_aev1 {
                &.disabled {
                    border-color: $grey;
                    background-color: $grey;
                    pointer-events: none;

                    .askSize {
                        display: block;
                    }

                    .addCart {
                        display: none;
                    }
                }

                .askSize {
                    display: none;
                }

                .addCart {
                    display: block;
                }
            }
        }

        #bloc_see_product_aev1 {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 24px;
            border: 1px solid $black;
            border-radius: 25px;
            background-color: $white;
            width: 100%;
            height: 50px;
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: normal;
            font-family: $primaryFont;
            letter-spacing: 0.04rem;
            text-align: center;
            text-transform: uppercase;
        }

        .loader {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            z-index: -1;
            margin: auto;
            border-radius: 50px;
            background: $black url(../svg/three_dots.svg) no-repeat center;
            background-size: 15% auto;
            width: 100%;
            width: calc(100% - 32px);
            height: 48px;

            span {
                font-size: 0;
            }

            &.loading {
                opacity: 1;
                z-index: 2;
            }
        }
    }

    &.actif {
        display: block !important;
        transform: translateX(0%);
        z-index: 1000000;

        [id^="product_addcart_"] {
            position: fixed !important;
            box-sizing: border-box;
            width: 100% !important;
        }

        &.lookbook {
            #formAchatExpressV1 #lightboxAchatExpressV1Carac {
                padding: 15px 25px 0;
            }

            #lightboxAchatExpressV1ColorSize {
                display: none;
            }
        }
    }
}

.os-padding:has(.block_achat_express.actif) {
    z-index: 1000000;
}

/* Lightbox Pop-in*/
#lightbox_preco {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: inherit;
    margin: auto;
    padding: 2rem;
    width: 850px;
    max-width: calc(100% - 4rem);
    height: 360px;

    .close_pop {
        span {
            font-size: 0;
        }
    }

    .t_lightbox_preco {
        margin-bottom: 6px;
        padding: 0.5rem 1rem 0;
        font-size: 14px;
        font-family: $primaryFont;
        text-align: center;
        text-transform: uppercase;
    }

    .subt_lightbox_preco {
        font-size: 15px;
        line-height: 19px;
    }

    .blocPrecoWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 19px 0 0 7px;
    }

    .bloc_lightbox_preco {
        position: relative;
        cursor: pointer;
        margin-bottom: 15px;
        border: 1px solid $lightgrey;
        padding: 19px 31px 21px;
        width: calc(50% - 1rem);
        font-size: 15px;

        div {
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;

            span {
                display: block;
                margin-bottom: 1.5rem;
                font-style: italic;
                font-weight: 400;
                font-size: 30px;
                font-family: $secondaryFont;
                text-align: center;
            }
        }

        &.several {
            margin-bottom: 12px;
            padding: 19px 31px 11px;

            p span {
                display: block;
            }

            + p {
                color: $black;
                font-size: 15px;
            }
        }

        .precoWarning {
            margin-top: 1rem;
            font-style: italic;
        }

        &:before {
            position: absolute;
            top: 50%;
            left: -9px;
            transform: translateY(-50%);
            box-shadow: inset 0 0 0 1rem $white;
            border: 1px solid $lightgrey;
            border-radius: 50%;
            background: $primaryColor;
            width: 15px;
            height: 15px;
            content: '';
        }

        &:hover,
        &.actif {
            border-color: $primaryColor;

            &:before {
                box-shadow: inset 0 0 0 2px $white;
            }
        }

        p {
            margin-bottom: 3px;
            font-size: 13px;
            line-height: 1.6;

            span {
                margin-right: 10px;
                font-size: 12px;
                font-family: $primaryFont;
                letter-spacing: .07em;
                text-transform: uppercase;
            }

            &.stock_from {
                margin: 17px 0 0;
            }
        }
    }

    .btn_container {
        display: flex;
        justify-content: center;
        margin: 1rem auto 0;

        .button {
            width: 100%;
            max-width: 350px;
        }
    }
}

#mention_legales_pop_up {
    padding: 1.5rem;
    width: 100%;
    max-width: 500px;
    text-align: center;

    .txt_alert {
        .pop_mention_detail {
            margin-top: 1.5rem;
            font-size: 14px;
            font-family: $primaryFont;
        }
    }
}
