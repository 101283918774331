/* GLOBAL STYLES */

//header handling variables
$preHeaderHeight: 35px;
$headerHeight: 101px;
$headerFull: calc(#{$preHeaderHeight} + #{$headerHeight});

.cache,
[v-cloak] {
    display: none;
}

html[data-overlayscrollbars~=viewport] > body {
    overflow: hidden;
}

body {
    box-sizing: border-box;
    margin: 0 auto;
    background-color: $white;
    width: 100%;
    min-width: 1024px;
    min-height: 100vh;
    overflow-y: hidden;
    font-family: $primaryFont;


    &.fixed {
        overflow: hidden;
    }

    a {
        color: $black;
        text-decoration: none;
    }

    div {
        box-sizing: border-box;
    }
}

#site_global_wrap {
    margin: 0 auto clamp(1rem, fs-vw(30, 1920), 30px);
    max-width: 1920px;
    padding-top: $headerFull;
}

.homepage #site_global_wrap {
    max-width: unset;
}

/* Ariane */
#breadcrumbs {
    margin: 0 auto;
    padding: 1rem clamp(1rem, fs-vw(30, 1920), 30px) 0.5rem;
    width: 100%;
    min-height: 11px;
    
    .breadcrumbs {
        display: flex;
        align-items: center;

        .breadcrumb {
            display: flex;
            align-items: center;
            color: $grey;
            font-size: 11px;
            font-family: $primaryFont;

            &:not(.current) {
                &:after {
                    display: block;
                    position: relative;
                    margin: 0 10px;
                    content: '|';
                    color: $grey;
                }
            }

            &.current {
                color: $black;
                font-weight: 600;
            }

            a {
                color: $grey;
            }
        }
    }
}

button,
input,
textarea {
    box-sizing: border-box;
    border-radius: 0;
    background-color: inherit;

    &:active,
    &:focus {
        outline: none;
    }
}

.w-visibility-toggle {
    border: none;
}

.wrap_rolloverproduit form .ligne_form.productColorFieldset .choices_list .form_item [type="radio"] {
    z-index: 0;
}

//notification copied to clipboard
.copied-text-notification {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% + 10px));
    z-index: 1000;
    animation: slideIn 0.3s forwards;
    transition: transform 0.3s ease-in-out;
    margin: 10px auto;
    background-color: $primaryColor;
    padding: 10px 0;
    width: fit-content;
    min-width: 30ch;
    color: $white;
    font-weight: 400;
    font-size: 15px;
    font-family: $primaryFont;
    text-align: center;

    &.slide-out {
        animation: slideOut 0.3s forwards;
    }
}

.swiper-button-prev,
.swiper-button-next {
    background-image: url('../svg/spritesheet.svg');
    @include bgIconCoord(29,8,8);
    cursor: pointer;
    width: 1rem;
    height: 1rem;

    &:after {
        display: none;
    }

    &.disabled {
        display: block;
        opacity: 0.5;
    }
    
    &.swiper-button-lock {
        display: none;
    }
}

.swiper-button-next {
    transform: rotate(180deg);
}

*::-webkit-scrollbar {
    width: 1px;
}

*::-webkit-scrollbar-thumb {
    background: $black;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

* {
    scrollbar-color: $black transparent;
    scrollbar-width: thin;
}

// Hide native scrollbar to avoid content jumping on page load
body::-webkit-scrollbar {
    display: none;
}

.body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

@keyframes slideIn {
    from {
        transform: translateY(calc(100% + 10px));
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(calc(100% + 10px));
    }
}