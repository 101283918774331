body.cart,
body.checkout,
body.in_tunnel {
    .title_connexion_only {
        display: none;
    }

    @media only screen and (max-width: 1440px) {
        #main_cart_wrapper {
            padding: 0 3.25vw;
        }
    }

    /* Panier */
    &.step_1 {
        .tunnel_track_pusher {
            display: none;
        }

        #main_cart_wrapper .elem_tunnel.right_side #cart_total .bill_line.discount {
            display: none;
        }

        #breadcrumbs {
            .breadcrumbs {
                .breadcrumb {
                    &:nth-of-type(2) {
                        a {
                            color: $black;
                        }

                        &:after {
                            display: none;
                        }
                    }

                    &.current {
                        display: none;
                    }
                }
            }
        }

        #main_cart_wrapper {
            margin-bottom: 63px;

            .elem_tunnel {
                &.left_side {
                    width: 64.22%;

                    .empty_cart {
                        margin: 15px 0;
                        font-weight: 400;
                        font-size: 14px;
                        font-family: $primaryFont;
                    }

                    .dropdown_cart {
                        display: none;
                        margin: 1rem 0;
                        font-family: $primaryFont;

                        &:first-of-type {
                            margin: 0 0 1rem;
                        }

                        &.activPreorder {
                            display: block;
                        }

                        .dropdown_cart_link {
                            display: flex;
                            position: relative;
                            justify-content: space-between;
                            align-items: center;
                            font-size: clamp(10px, fs-vw(12, 1920), 14px);

                            h3 {
                                &::after{
                                    padding: 0 0.5rem;
                                    content: '|';
                                }
                            }

                            .precoSubtitle{
                                margin-right: auto;
                            }
                        }
                    }

                    .cart_list_header {
                        display: none;
                    }

                    .product_line_basket {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        border-bottom: 1px solid $grey;
                        padding: 1rem 0;

                        &.out_of_stock {
                            &:first-of-type {
                                margin-top: 35px;
                            }

                            .cart_product_pic,
                            .cart_product_desc {
                                opacity: .5;
                            }
                        }

                        .product_sfs_warning_txt {
                            margin-bottom: 15px;
                            background-color: $primaryColor;
                            padding: 1rem;
                            width: 100%;
                            color: $secondaryColor;
                            font-weight: 1rem;
                        }

                        .cart_product_pic {
                            margin-right: 10px;
                            width: 100px;

                            a, img {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }

                        .cart_product_desc {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: calc(100% - 110px);

                            &.pack_liv {
                                .wrapper_cart_product_desc {
                                    .cart_product_modify {
                                        width: auto;
                                    }
                                }
                            }

                            .wrapper_cart_product_desc {
                                display: flex;
                                position: relative;
                                flex-direction: column;
                                width: calc(100% - 200px);
                                color: $black;
                                font-family: $primaryFont;

                                .wrap_titre_prix {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    min-height: 82px;

                                    .product_mod {
                                        display: block;
                                        margin-bottom: 5px;

                                        .cart_product_brand {
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: normal;
                                            letter-spacing: 0.04rem;
                                            text-transform: uppercase;
                                        }

                                        .cart_product_title {
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }

                                        .cart_product_subtitle {
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                    }
                                }

                                .wrapper_cart_product_price {
                                    display: flex;
                                    margin-bottom: 7px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 15px;
                                    line-height: normal;
                                    font-family: $primaryFont;

                                    .pricetag {
                                        &.was {
                                            order: -1;
                                            margin-right: 0.5rem;
                                            color: $grey;
                                            text-decoration: line-through;
                                        }
                                    }
                                }

                                .cart_product_sizecol {
                                    margin-bottom: 3px;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: 1;
                                    font-family: $primaryFont;

                                    &.quantity {
                                        order: 1;

                                        span {
                                            display: inline !important;
                                        }
                                    }

                                    &.color {
                                        order: 2;
                                    }

                                    &.size {
                                        order: 3;
                                    }
                                }

                                .line_product_desc_preorder {
                                    display: none;
                                    margin: 0.15rem 0 0.5rem;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    letter-spacing: .2px;
                                }

                                .cart_product_nostock {
                                    display: inline-block;
                                    color: $red;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    letter-spacing: .6px;
                                    text-transform: uppercase;
                                }

                                .toggle_modal_actions {
                                    position: absolute;
                                    bottom: 0;
                                    left: 80px;
                                    order: 5;
                                    z-index: 1;
                                    cursor: pointer;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.6px;
                                    text-decoration: underline;
                                    text-underline-position: under;
                                    text-transform: uppercase;
                                }
                            }

                            .cart_product_modify {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .product_quantity_change {
                                    order: 1;
                                    margin-right: 30px;
                                    width: 82px;

                                    .qte_selector {
                                        display: flex;
                                        position: relative;
                                        width: 82px;
                                        height: 30px;

                                        img.load_qte {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            bottom: 0;
                                            left: 0;
                                            margin: auto;
                                        }
                                    }

                                    .qteWrapper {
                                        display: flex;
                                        position: relative;
                                        box-sizing: border-box;
                                        border: 1px solid $grey;
                                        border-radius: 30px;
                                        width: 82px;
                                        height: 30px;

                                        .selected_qte {
                                            position: absolute;
                                            top: 1px;
                                            left: 50%;
                                            transform: translateX(-50%);
                                            border: none;
                                            width: 33.3%;
                                            height: 27px;
                                            font-size: 14px;
                                            text-align: center;
                                        }

                                        .change_qte {
                                            position: absolute;
                                            top: 3px;
                                            appearance: none;
                                            cursor: pointer;
                                            border: 0;
                                            background: $white;
                                            width: 26%;
                                            height: 22px;
                                            font-size: 0;

                                            span {
                                                font-size: 0;
                                            }
                                        }

                                        .lessQte {
                                            left: 2px;

                                            &::before {
                                                display: block;
                                                position: absolute;
                                                top: 50%;
                                                left: calc(50% - 5px);
                                                background-color: $primaryColor;
                                                width: 10px;
                                                height: 1px;
                                                content: '';
                                            }
                                        }

                                        .moreQte {
                                            right: 2px;

                                            &::before {
                                                display: block;
                                                position: absolute;
                                                top: 50%;
                                                left: calc(50% - 5px);
                                                background: $primaryColor;
                                                width: 11px;
                                                height: 1px;
                                                content: '';
                                            }

                                            &:after {
                                                display: block;
                                                position: absolute;
                                                top: calc(50% - 5px);
                                                left: 50%;
                                                background: $primaryColor;
                                                width: 1px;
                                                height: 11px;
                                                content: '';
                                            }
                                        }
                                    }
                                }

                                .product_towish {
                                    display: flex;
                                    position: relative;
                                    align-items: center;
                                    order: 2;
                                    width: auto;
                                    height: 20px;

                                    &:before {
                                        display: none;
                                    }

                                    span {
                                        display: block;
                                        position: relative;
                                        background-color: $white;
                                        width: 20px;
                                        height: 20px;
                                        font-size: 0;

                                        &:before {
                                            background-image: url(../svg/spritesheet.svg);
                                            width: 20px;
                                            height: 20px;
                                            content: '';
                                            @include bgIconCoord(20, 8, 8);
                                            display: block;
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            margin: 0;
                                            font-size: 0;
                                        }
                                    }

                                    &:after {
                                        display: block;
                                        position: relative;
                                        margin: 0 26px;
                                        background-color: $grey;
                                        width: 1px;
                                        height: 12px;
                                        content: '';
                                    }

                                    &.existToWishlistButton {
                                        span {
                                            &:before {
                                                @include bgIconCoord(21, 8, 8);
                                            }
                                        }
                                    }
                                }

                                .product_del {
                                    display: block;
                                    position: relative;
                                    order: 3;
                                    width: 1rem;
                                    height: 1rem;

                                    &:before {
                                        background-image: url(../svg/spritesheet.svg);
                                        width: 12px;
                                        height: 12px;
                                        content: '';
                                        @include bgIconCoord(30, 8, 8);
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        margin: 0;
                                        font-size: 0;
                                    }

                                    span {
                                        font-size: 0;
                                    }
                                }
                            }
                        }

                        .product_option {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 0.5rem;
                            background-color: var(--opt-color, $beige-clair);
                            padding: 0.5rem;
                            width: 100%;
                            font-size: 0.75rem;

                            input[type="checkbox"] {
                                -webkit-appearance: none;
                                appearance: none;
                                cursor: pointer;
                                outline: none;
                                border: 1px solid $beige_Fonce;
                                border-radius: 3px;
                                width: 15px;
                                height: 15px;
                            }
                            
                            input[type="checkbox"]:checked {
                                background-color: rgba($black, 0.025);
                            }
                            
                            input[type="checkbox"]:checked::before {
                                display: block;
                                content: '\2713';
                                color: $beige_Fonce;
                                font-size: 12px;
                                line-height: 15px;
                                text-align: center;
                            }
                            
                            .wrapper_checkbox_product_option {
                                display: flex;
                                align-items: center;
                                gap: 0.25rem;

                                .info {
                                    background-image: url('../svg/info.svg');
                                    width: 1rem;
                                    height: 1rem;
                                }
                            }

                            &+.lightbox {
                                display: block;
                                left: unset;
                                opacity: 1;
                                z-index: 30;
                                text-align: center;
                        
                                .close {
                                    display: none;
                                }
                        
                                .title {
                                    margin-bottom: 1rem;
                                    font-style: italic;
                                    font-size: 1.75rem;
                                    font-family: $secondaryFont;
                                }
                        
                                .description {
                                    padding: 0 1.5rem;
                                    font-size: 0.9rem;
                                    line-height: 1.5;
                                    font-family: $primaryFont;
                                }
                        
                                &.actif {
                                    transform: translateX(0);
                                }
                            }
                        }

                        .dragHandle,
                        .dragOverlay {
                            display: none;
                        }

                        .cart_item_actions {
                            display: none;
                            position: relative;
                            left: -110px;
                            justify-content: center;
                            align-items: center;
                            order: 5;
                            margin-top: 5px;
                            background-color: $beige-clair;
                            padding: 20px;
                            width: calc(100% + 310px);

                            .cart_item_actions_content {
                                width: 100%;

                                .cart_item_actions_close {
                                    position: absolute;
                                    top: 1rem;
                                    right: 1rem;
                                    z-index: 10;
                                    cursor: pointer;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-color: inherit;
                                    width: 1rem;
                                    height: 1rem;
                                    @include bgIconCoord(30, 8, 8);
                                    opacity: 0;
                                    transition: opacity 0.35s ease-in-out;
                                }

                                .cart_item_actions_form {
                                    width: 100%;

                                    .cart_item_actions_bloc {
                                        width: 100%;

                                        .choices_list {
                                            display: flex;
                                            flex-wrap: wrap;
                                            justify-content: center;
                                            width: 100%;

                                            .form_itm {
                                                position: relative;
                                                margin-right: 15px;

                                                &:last-of-type {
                                                    margin-right: 0;
                                                }

                                                [type=radio]:checked,
                                                [type=radio]:not(:checked) {
                                                    position: absolute;
                                                    right: 0;
                                                    left: 0;
                                                    opacity: 0;
                                                    z-index: 1;
                                                    cursor: pointer;
                                                    width: 100%;

                                                    & + label {
                                                        position: relative;
                                                        color: $black;
                                                        font-style: normal;
                                                        font-weight: 400;
                                                        font-size: 13px;
                                                        line-height: normal;
                                                        font-family: $primaryFont;
                                                        letter-spacing: 0.04rem;
                                                        text-align: center;
                                                        white-space: nowrap;

                                                        &:before,
                                                        &:after {
                                                            display: none;
                                                        }
                                                    }

                                                    &:hover:not(.disabled) {
                                                        & + label {
                                                            font-weight: 600;
                                                            text-decoration: underline;
                                                            text-underline-position: under;
                                                        }
                                                    }

                                                    &.disabled {
                                                        cursor: default;

                                                        & + label {
                                                            color: $grey;
                                                        }
                                                    }
                                                }

                                                [type=radio]:checked {
                                                    & + label {
                                                        font-weight: 600;
                                                        text-decoration: underline;
                                                        text-underline-position: under;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .cart_item_actions_close {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .giftProductLine {
                        .cart_product_subtitle_giftcard {
                            display: none;
                        }

                        .cart_product_modify {
                            justify-content: flex-end !important;
                        }

                        /* Prix carte cadeau */
                        .wrapper_cart_product_price {
                            .pricetag {
                                &.was {
                                    display: none;
                                }
                            }
                        }
                    }

                    .cart_main_title.wishlist {
                        display: none;
                    }

                    #panier_custom_message {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                        margin: 1rem 0;
                    }
                }

                &.right_side {
                    width: 33.2%;

                    #cart_total {
                        .bill_line {
                            &.top_nb_products {
                                display: none !important;
                                justify-content: space-between;
                                margin-bottom: 10px;
                                padding: 0 21px;

                                label {
                                    display: flex;
                                    position: relative;
                                    flex-direction: row-reverse;

                                    &:before {
                                        display: block;
                                        width: 3px;
                                        height: 5px;
                                        content: ')';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &:not(.step_1) {
        padding-top: 0 !important;

        #site_head_wrap {
            display: none;
        }

        #main_cart_wrapper {
            .elem_tunnel.right_side {
                #cart_total {
                    .order_gift_form,
                    .mot_personnalise_form {
                        display: none;
                    }

                    .bill_line {
                        &.total_produit {
                            label {
                                .nb_products {
                                    display: inline !important;
                                }
                            }

                            &.under_dropdown {
                                label {
                                    .nb_products {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.step_2 {
        #shad.actif {
            top: 0;
        }

        #main_cart_wrapper {
            margin-top: 43px;
        }
    }

    &.step_2_0 {
        #adresseForm {
            .w-has-tel {
                margin-bottom: 20px;
            }
        }
    }

    .tunnel_track_pusher {
        padding-top: 30px;
        width: 100%;

        .tunnel_track_wrapper {
            margin-bottom: 32px;

            .cart_logo {
                a {
                    display: block;
                    margin: 0 auto 38px;
                    background-image: url(../svg/natalys.svg);
                    width: 135px;
                    height: 72px;
                }
            }

            .tunnel_track {
                display: flex;
                justify-content: center;
                align-items: center;

                .tunnel_step {
                    color: $grey;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    white-space: nowrap;

                    &.on {
                        color: $black;
                    }

                    &:after {
                        display: inline-block;
                        position: relative;
                        margin: 0 17px;
                        content: '>';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1;
                        font-family: $primaryFont;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    #main_cart_wrapper {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;

        .elem_tunnel {
            &.left_side {
                display: flex;
                flex-wrap: wrap;
                width: 64.22%;
                height: fit-content;

                .cart_main_title {
                    order: -1;
                    margin-bottom: 15px;
                    width: 100%;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 1;
                    font-family: $secondaryFont;

                    .nb_products {
                        display: block;
                        margin: 12px 0 0;
                        color: $grey;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 1;
                        font-family: $primaryFont;
                        letter-spacing: 0.75px;

                        .parenthesis {
                            display: none;
                        }
                    }

                    &.wishlist {
                        opacity: 0;
                    }
                }

                .cart_main_table {
                    width: 100%;
                }

                .tunnel_form {
                    width: 100%;
                }

                .precoBasket {
                    .precoBasketIntro {
                        margin-bottom: 2rem;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                    }
                }

                #adresseForm {
                    .delivery_section_title {
                        margin-bottom: 12px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1;
                        font-family: $primaryFont;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                    }

                    .w-form-line {
                        .w-dropdown {
                            border: none;
                            border-bottom: 1px solid $grey;
                            border-radius: 0;
                            height: 40px;

                            &:focus,
                            &:hover,
                            &:focus-visible {
                                outline: none;
                                box-shadow: inherit;
                                border: none;
                                border-bottom: 1px solid $grey;
                            }

                            &::after {
                                border: none;
                                background-image: url(../svg/spritesheet.svg);
                                @include bgIconCoord(29, 8, 8);
                                display: inline-block;
                                transform: rotate(-90deg);
                                width: 14px;
                                height: 14px;
                            }

                            &.w-has-error {
                                box-shadow: inherit;
                                border-color: $red;
                            }

                            .w-value {
                                padding-left: 0;
                            }
                        }

                        .w-date-input {
                            position: relative;

                            .w-input-element {
                                &::-webkit-inner-spin-button,
                                &::-webkit-calendar-picker-indicator {
                                    appearance: none;
                                    opacity: 0;
                                }
                            }
                        }

                        .w-input-container {
                            .w-input {
                                &.w-tel-input {
                                    button.selected-flag {
                                        transition: inherit;
                                        border: none !important;
                                        border-radius: 0 !important;
                                        background-color: transparent !important;
                                        padding: 0;
                                        height: 70% !important;

                                        &:before {
                                            width: 24px;
                                            height: 1rem;
                                        }

                                        &::after {
                                            background-image: url(../svg/spritesheet.svg);
                                            @include bgIconCoord(29, 8, 8);
                                            position: relative;
                                            right: -0.25rem;
                                            transform: rotate(267deg);
                                            opacity: 0.5;
                                            border: 0;
                                            width: 10px;
                                            height: 10px;
                                        }
                                    }
                                }

                                &.w-dropdown {
                                    border: none;
                                    border-bottom: 1px solid $grey;
                                    border-radius: 0;
                                    height: 40px;

                                    .w-input-element {
                                        cursor: pointer;
                                    }

                                    &:focus,
                                    &.w-focused {
                                        outline: none;
                                        box-shadow: none;
                                    }

                                    .w-value {
                                        padding-left: 0;
                                    }

                                    &.w-has-error {
                                        box-shadow: inherit;
                                        border-bottom: 1px solid $red;
                                    }

                                    &::after {
                                        border: none;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(29, 8, 8);
                                        display: inline-block;
                                        top: 54%;
                                        right: 0;
                                        transform: rotate(-90deg);
                                        width: 14px;
                                        height: 14px;
                                    }
                                }

                                &.w-textarea {
                                    .w-input-element {
                                        padding: 28px 0 0;
                                        height: 72px;

                                        & + .w-input-label {
                                            top: -2px;
                                        }
                                    }
                                }
                            }

                            .errormsgadd {
                                display: none;
                            }
                        }

                        &.notice {
                            margin: 25px 0;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;
                        }

                        &.sameBillAddress {
                            .w-input-container {
                                .w-checkbox-input {
                                    p.w-input-label {
                                        display: flex;
                                        align-items: flex-start;
                                        margin-bottom: 13px;
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        font-family: $primaryFont;

                                        &::before {
                                            display: block;
                                            position: relative;
                                            box-shadow: none;
                                            border-radius: 10px;
                                            background: $lightgrey;
                                            width: 23px;
                                            height: 13px;
                                        }

                                        &::after {
                                            display: block;
                                            position: absolute;
                                            top: 1px;
                                            left: 1px;
                                            opacity: 1;
                                            transition: all .2s ease-out;
                                            box-shadow: 0 2px 5px 0 rgba($black, 0.5);
                                            border-radius: 13px;
                                            background: $white;
                                            width: 13px;
                                            height: 13px;
                                            content: '';
                                        }

                                        .multiline {
                                            padding-left: 4px;

                                            strong {
                                                padding-bottom: 4px;
                                                font-weight: 600;
                                                letter-spacing: 1px;
                                                text-transform: uppercase;
                                            }

                                            span {
                                                padding-bottom: 2px;
                                            }
                                        }
                                    }

                                    .w-input-element:checked + .w-input-label::before,
                                    .w-radio-input .w-input-element:checked + .w-input-label::before {
                                        background-color: $grey;
                                    }

                                    .w-input-element:checked + .w-input-label::after,
                                    .w-radio-input .w-input-element:checked + .w-input-label::after {
                                        left: 23px;
                                        transform: translateX(-100%);
                                    }
                                }
                            }
                        }

                        &.add-complementary-line {
                            .w-input-container {
                                .w-checkbox-input {
                                    opacity: 1;

                                    .w-input-label {
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 12px;
                                        line-height: normal;
                                        font-family: $primaryFont;
                                        letter-spacing: 0.6px;
                                        text-decoration: underline;
                                        text-underline-position: under;
                                        text-transform: uppercase;

                                        &::before {
                                            display: inline-block;
                                            font-size: 12px;
                                            border: none;
                                            background-color: transparent;
                                            width: auto;
                                            height: auto;
                                            content: '+ ';
                                        }

                                        span {
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .w-checkbox-input {
                        .w-input-element {
                            & + .w-input-label {
                                font-size: 14px;
                            }
                        }
                    }

                    .accountCreate {
                        .w-input-container {
                            .w-checkbox-input {
                                p.w-input-label {
                                    display: flex;
                                    align-items: flex-start;
                                    margin-bottom: 13px;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    font-family: $primaryFont;

                                    &::before {
                                        display: block;
                                        position: relative;
                                        box-shadow: none;
                                        border-radius: 10px;
                                        background: $lightgrey;
                                        width: 23px;
                                        height: 13px;
                                    }

                                    &::after {
                                        display: block;
                                        position: absolute;
                                        top: 1px;
                                        left: 1px;
                                        opacity: 1;
                                        transition: all .2s ease-out;
                                        box-shadow: 0 2px 5px 0 rgba($black, 0.5);
                                        border-radius: 13px;
                                        background: $white;
                                        width: 13px;
                                        height: 13px;
                                        content: '';
                                    }

                                    .multiline {
                                        padding-left: 4px;

                                        strong {
                                            padding-bottom: 4px;
                                            font-weight: 600;
                                            letter-spacing: 1px;
                                            text-transform: uppercase;
                                        }

                                        span {
                                            padding-bottom: 2px;
                                        }
                                    }
                                }

                                .w-input-element:checked + .w-input-label::before,
                                .w-radio-input .w-input-element:checked + .w-input-label::before {
                                    background-color: $grey;
                                }

                                .w-input-element:checked + .w-input-label::after,
                                .w-radio-input .w-input-element:checked + .w-input-label::after {
                                    left: 23px;
                                    transform: translateX(-100%);
                                }
                            }
                        }
                    }

                    .elem_global.delivery {
                        margin-bottom: 15px;
                    }
                }

                .wrapper_delivery_choice {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    margin-bottom: 10px;

                    .delivery_section_title {
                        margin-bottom: 20px;
                        width: 100%;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1rem;
                        line-height: 1;
                        font-family: $primaryFont;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                    }

                    .delivery_choice_btn {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        order: 3;
                        transition: all ease-in-out 0.35s;
                        cursor: pointer;
                        margin-right: 18px;
                        border: 1px solid $black;
                        background-color: $white;
                        width: 115px;
                        height: 100px;
                        text-align: center;

                        &.selected,
                        &:hover {
                            background-color: $beige-clair;
                        }

                        &:before {
                            position: relative;
                            margin-bottom: 5px;
                            background-image: url(../svg/spritesheet.svg);
                            width: 25px;
                            height: 25px;
                            content: '';
                        }

                        &.home_delivery_btn {
                            order: 1;

                            &:before {
                                @include bgIconCoord(42, 8, 8);
                            }
                        }

                        &.store_delivery_btn {
                            order: 2;

                            &:before {
                                @include bgIconCoord(43, 8, 8);
                            }
                        }

                        &.relay_delivery_btn {
                            order: 3;

                            &:before {
                                @include bgIconCoord(5, 8, 8);
                            }
                        }

                        > span {
                            display: block;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-align: center;
                            text-transform: initial;

                            &:first-of-type {
                                font-size: 13px;
                                text-transform: uppercase;
                            }

                            .price {
                                .no_cents {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                .delivery_section_title.delivery_mode {
                    display: none;
                }

                .delivery_choice {
                    display: none;
                    position: relative;
                    justify-content: space-between;
                    align-items: center;
                    cursor: pointer;
                    border-bottom: 1px solid $lightgrey;
                    padding: 25px 25px 25px 0;
                    width: 100%;

                    &.transporteur_retrait-magasin {
                        .choix_delivery {
                            height: calc(100% - 60px);
                        }
                    }

                    .transporteur_infos,
                    .transporteur_info {
                        order: 2;
                        padding-left: 42px;
                        font-weight: 400;
                        font-family: $primaryFont;

                        .transporteur_type,
                        .transporteur_id {
                            margin-bottom: 5px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        .transporteur_delay {
                            margin: 8px 0;
                            font-size: 14px;
                        }

                        .show_delivery_info {
                            display: inline-block;
                            position: relative;
                            z-index: 2;
                            margin-top: 8px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: .6px;
                            text-decoration: underline;
                            text-underline-position: under;
                            text-transform: uppercase;
                        }

                        .shop_name,
                        .adresse,
                        .warning_point_relais {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                        }

                        .shop_name,
                        .adresse {
                            text-transform: capitalize;
                        }

                        .warning_point_relais {
                            margin-top: 10px;
                            color: $red;
                            font-style: italic;
                        }
                    }

                    .price_tag {
                        position: relative;
                        top: -3px;
                        order: 3;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: normal;
                        font-family: $primaryFont;
                        text-align: right;
                        text-transform: uppercase;
                    }

                    .choix_delivery {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;

                        input[type="radio"],
                        input.choix_livraison {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: 1;
                            cursor: pointer;
                            width: 100%;
                            height: 100%;
                        }

                        .right_delivery {
                            display: flex;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            align-items: center;
                            margin: auto;
                            padding-top: 50px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            font-family: $primaryFont;
                            text-align: right;
                            text-transform: uppercase;
                        }
                    }

                    .checkbox {
                        position: absolute;
                        top: -5px;
                        bottom: 0;
                        left: 2px;
                        margin: auto;
                        box-shadow: inset 0 0 0 10px $white;
                        border: 1px solid $black;
                        border-radius: 100%;
                        background-color: $black;
                        width: 15px;
                        height: 15px;
                    }

                    &.relay_delivery {
                        &.active {
                            .checkbox {
                                box-shadow: inset 0 0 0 2px $white;
                            }
                        }
                    }

                    &:hover,
                    &.active {
                        .checkbox {
                            box-shadow: inset 0 0 0 2px $white;
                        }
                    }

                    &.selected {
                        display: flex;
                    }
                }
            }

            &.right_side {
                display: flex;
                flex-direction: column;
                width: 33.2%;

                .right_bloc_btm_paiement {
                    display: none;
                }

                & > div {
                    order: 2;

                    &.tunnel_sticky {
                        display: flex;
                        flex-direction: column;

                        .push_container {
                            order: 5;
                            margin-top: 10px;
                            color: $white;

                            &.pushBasketWrapper {
                                display: none;
                                position: relative;

                                a {
                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 1;
                                        background-color: transparent;
                                        width: 100%;
                                        height: 100%;
                                        content: '';
                                    }
                                }
                            }

                            &.pushBasketWrapperText {
                                display: flex;
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                flex-wrap: wrap;
                                align-content: center;
                                justify-content: center;
                                margin: auto;
                                margin-bottom: 15px;
                                font-style: italic;
                                font-weight: 400;
                                font-size: 25px;
                                line-height: 1;
                                font-family: $secondaryFont;
                            }
                        }

                        .fidelity_program_bloc {
                            display: none;
                            order: 3;
                            margin: 24px 0;
                            border: 1px solid $lightgrey;
                            background-color: $beige-clair;
                            padding: 25px;

                            .fidelity_program_points {
                                .img {
                                    @include bgIconPath('../svg/natalys_tunnel.svg');
                                    margin: 0 auto 10px;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    width: 29px;
                                    height: 18px;
                                }

                                .title {
                                    margin-bottom: 10px;
                                    padding: 0 21px;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.7px;
                                    text-align: center;
                                    text-transform: uppercase;
                                }

                                .txt {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                    text-align: center;

                                    #loyaltyProgramPoints {
                                        font-weight: 700;
                                    }
                                }

                                strong {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                .addresses_recap {
                    border-top: 1px solid $lightgrey;

                    .address {
                        position: relative;
                        transition: all ease-in-out 0.5s;
                        border-bottom: 1px solid $lightgrey;
                        background-color: $white;
                        padding: 15px 25px;

                        &.show_delivery_info {
                            cursor: pointer;
                        }

                        &:last-of-type {
                            border-bottom: none;
                        }

                        .addr_label {
                            margin-bottom: 2px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        .infos {
                            .addr_line {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                                text-transform: capitalize;
                            }
                        }


                        .modlink {
                            display: inline-block;
                            cursor: pointer;
                            margin-top: 5px;

                            span,
                            &.modify2 {
                                display: inline-block;
                                transition: all ease-in-out 0.5s;
                                border-bottom: 1px solid $black;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                            }
                        }

                        .arrow {
                            position: absolute;
                            top: 0;
                            right: 17px;
                            bottom: 0;
                            margin: auto;

                            &:before {
                                background-image: url(../svg/spritesheet.svg);
                                content: '';
                                @include bgIconCoord(29, 8, 8);
                                display: inline-block;
                                transform: rotate(180deg);
                                width: 1rem;
                                height: 1rem;
                            }
                        }

                        &#delivery_address_recap_other {
                            display: none;
                        }

                        &:hover {
                            .modlink {
                                span,
                                &.modify2 {
                                    border-bottom: 1px solid $white;
                                }
                            }
                        }
                    }
                }

                .deconnection_tunnel {
                    display: flex;
                    justify-content: space-between;
                    order: 1;
                    margin-bottom: 10px;
                    padding: 0 21px;

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;

                        @media only screen and (max-width: 1310px) {
                            font-size: 13px;
                        }
                    }

                    #btn-disconnect {
                        border: none;
                        border-radius: 0;
                        background-color: $white;
                        padding: 0;
                        height: auto;
                        color: $black;

                        span {
                            color: $black;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-decoration: underline;
                            text-underline-position: under;
                            text-transform: uppercase;

                            @media only screen and (max-width: 1310px) {
                                font-size: 10px;
                                letter-spacing: 0;
                            }
                        }
                    }
                }

                #cart_total {
                    display: flex;
                    flex-direction: column;
                    border: 1px solid $lightgrey;
                    padding: 20px 0 15px;

                    .recap_cart_title {
                        margin-bottom: 24px;
                        padding: 0 21px;
                        color: $black;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 25px;
                        line-height: 1;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .bill_line {
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1;
                        font-family: $primaryFont;

                        &.total_produit {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            padding: 0 21px;

                            &.under_dropdown {
                                display: flex !important;
                                order: 1;
                            }

                            &.toggle {
                                position: relative;
                                cursor: pointer;
                                margin: auto;
                                margin-bottom: 15px;
                                border-top: 1px solid $lightgrey;
                                border-bottom: 1px solid $lightgrey;
                                padding: 15px 0 15px;
                                width: calc(100% - 42px);
                                font-style: normal;
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 1;
                                letter-spacing: 0.55px;
                                text-transform: uppercase;

                                label {
                                    cursor: pointer;
                                }

                                .price,
                                .nb_products {
                                    display: none;
                                }

                                &:after {
                                    background-image: url(../svg/spritesheet.svg);
                                    content: '';
                                    @include bgIconCoord(29, 8, 8);
                                    display: inline-block;
                                    position: absolute;
                                    top: 13px;
                                    right: 3px;
                                    transform: rotate(-90deg);
                                    transition: all 0.35s ease-in-out;
                                    width: 1rem;
                                    height: 1rem;
                                }

                                &.open {
                                    margin-bottom: 0;
                                    border-bottom: 0;

                                    &:after {
                                        transform: rotate(90deg);
                                    }

                                    & + .total_produit_dropdown {
                                        opacity: 1;
                                        padding-bottom: 0;
                                    }
                                }
                            }
                        }

                        &.discount,
                        &.hide_shippingfees {
                            display: flex;
                            justify-content: space-between;
                            order: 2;
                            margin-bottom: 10px;
                            padding: 0 21px;

                            #fdp_txt {
                                position: relative;

                                .currency {
                                    /* makeup */
                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        background: $white;
                                        width: 7px;
                                        height: 15px;
                                        content: '';
                                    }
                                }
                            }

                            &.txt {
                                #fdp_txt {
                                    text-transform: uppercase;
                                }
                            }
                        }

                        &.discount {
                            margin-bottom: 20px;
                        }

                        &.total {
                            display: flex;
                            justify-content: space-between;
                            order: 7;
                            border-top: 1px solid $lightgrey;
                            padding: 15px 21px 0;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        &.coupon {
                            position: relative;
                            order: 4;
                            cursor: pointer;
                            box-sizing: border-box;
                            padding: 0 21px;

                            #formPromo {
                                border-top: 1px solid $lightgrey;

                                .w-text-input {
                                    display: flex;
                                    position: relative;
                                    flex-direction: column-reverse;
                                    width: 100%;

                                    #input_codePromo {
                                        display: none;
                                        position: relative;
                                        opacity: 0;
                                        border-radius: 0;
                                    }

                                    .w-input-label {
                                        position: relative;
                                        top: inherit;
                                        transform: inherit;
                                        cursor: pointer;
                                        padding: 20px 0;
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 11px;
                                        line-height: 1;
                                        font-family: $primaryFont;
                                        letter-spacing: 0.55px;
                                        text-transform: uppercase;

                                        &:before {
                                            position: absolute;
                                            top: 18px;
                                            right: 0;
                                            background-image: url(../svg/spritesheet.svg);
                                            width: 14px;
                                            height: 14px;
                                            content: '';
                                            @include bgIconCoord(29, 8, 8);
                                            transform: rotate(-90deg);
                                            transition: all ease-in-out 0.5s;
                                        }
                                    }
                                }

                                .w-input-note {
                                    top: calc(100% - 25px);
                                    font-weight: 400;
                                    font-size: 11px;
                                    font-family: $primaryFont;
                                }

                                .w-submit {
                                    display: none;
                                    position: absolute;
                                    right: 0;
                                    opacity: 0;
                                    z-index: -1;
                                    transition: 0.5 opacity ease-in-out 0.5s;

                                    .w-submit-button {
                                        display: inline;
                                        border: none;
                                        border-radius: 0;
                                        background-color: transparent;
                                        width: auto;
                                        height: auto;

                                        span {
                                            color: $black;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 11px;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.55px;
                                            text-transform: uppercase;
                                        }
                                    }
                                }

                                &.actif {
                                    .w-text-input {
                                        z-index: 2;
                                        padding-bottom: 20px;

                                        .w-input-label {
                                            padding-bottom: 0;

                                            &:before {
                                                transform: rotate(90deg);
                                            }
                                        }
                                    }

                                    #input_codePromo {
                                        opacity: 1;
                                    }

                                    .w-submit {
                                        display: block;
                                        opacity: 1;
                                        z-index: 2;
                                    }
                                }
                            }
                        }

                        &.promo {
                            display: flex;
                            justify-content: space-between;
                            order: 4;
                            margin-bottom: 10px;
                            padding: 0 21px;
                        }

                        &.used_cp {
                            display: flex;
                            position: relative;
                            justify-content: space-between;
                            order: 4;
                            margin: 0 0 10px 0;
                            padding: 0 21px;
                            width: 100%;

                            .code_promo {
                                display: flex;
                                justify-content: space-between;
                                margin-left: 6px;
                                font-weight: 600;

                                .close {
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%);
                                    cursor: pointer;
                                    background-image: url(../svg/spritesheet.svg);
                                    width: 12px;
                                    height: 12px;
                                    @include bgIconCoord(30, 8, 8);
                                    display: block;
                                    margin-left: 8px;

                                    span {
                                        font-size: 0;
                                    }
                                }
                            }

                            &:has(.close) {
                                justify-content: flex-start;
                                margin: 0 0 10px 14px;

                                label {
                                    margin-left: 2px;
                                }
                            }
                        }

                        &.giftcard {
                            display: flex;
                            justify-content: space-between;
                            padding: 0 21px;
                            margin-bottom: 10px;
                            order: 1;
                        }

                        &.total_produit_dropdown {
                            display: none;
                            opacity: 0;
                            margin: 0 auto 15px;
                            border-bottom: 1px solid $lightgrey;
                            padding-bottom: 15px;
                            width: calc(100% - 42px);

                            .product_line_basket {
                                display: flex;
                                padding-bottom: 15px;

                                .cart_product_pic {
                                    margin-right: 10px;
                                    width: 100px;

                                    img {
                                        width: 100%;
                                        height: auto;
                                    }
                                }

                                .cart_product_desc {
                                    display: flex;
                                    flex-direction: column;
                                    width: calc(100% - 110px);
                                }

                                .line_product_desc {
                                    color: $black;
                                    font-family: $primaryFont;

                                    &.cart_product_brand {
                                        margin-bottom: 2px;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: normal;
                                        letter-spacing: 0.04rem;
                                        text-transform: uppercase;
                                    }

                                    &.cart_product_title {
                                        width: calc(100% - 5px);
                                        overflow: hidden;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }

                                    &.cart_product_subtitle {
                                        width: calc(100% - 5px);
                                        overflow: hidden;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 18px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                    }
                                }

                                .wrapper_cart_product_price {
                                    display: flex;

                                    .pricetag {
                                        margin: 2px 0 7px;
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 15px;
                                        line-height: normal;
                                        font-family: $primaryFont;

                                        &.was {
                                            order: -1;
                                            margin-right: 8px;
                                            color: $grey;
                                            text-decoration: line-through;
                                        }
                                    }
                                }

                                .cart_product_sizecol {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: normal;
                                    font-family: $primaryFont;

                                    &.quantity {
                                        order: 1;

                                        span {
                                            display: inline !important;
                                        }
                                    }

                                    &.color {
                                        order: 2;
                                    }

                                    &.size {
                                        order: 3;
                                    }
                                }

                                .cart_product_modify {
                                    display: none;
                                }
                            }
                        }

                        &.unlimited {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            padding: 0 21px;
                            width: 100%;
                        }
                    }

                    .upselling {
                        display: flex;
                        flex-direction: column-reverse;
                        order: 3;
                        margin-bottom: 15px;
                        padding: 0 21px;

                        progress {
                            vertical-align: top;
                            appearance: none;
                            margin-bottom: 6px;
                            border: 1px solid $beige_Fonce;
                            border-radius: 25px;
                            background-color: $white;
                            width: 100%;
                            height: 5px;
                            overflow: hidden;
                        }

                        progress::-webkit-progress-bar {
                            background-color: $white;
                        }

                        progress::-webkit-progress-value {
                            background-color: $beige_Fonce;
                        }

                        progress::-moz-progress-bar {
                            background-color: $beige_Fonce;
                        }

                        p {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;
                            text-align: left;
                        }
                    }

                    .encart_3xcb_panier,
                    #popup_3xcb {
                        display: none;
                    }

                    .order_gift_form,
                    .mot_personnalise_form {
                        order: 4;
                        padding: 0 20px;

                        .w-input-container {
                            border-top: 1px solid $lightgrey;
                            padding: 15px 0;
                        }

                        .w-checkbox-input {
                            height: 21px;

                            .w-input-label {
                                position: relative;
                                top: inherit;
                                transform: inherit;
                                transition: 0.35s padding ease-in-out 0.5s;
                                cursor: pointer;
                                padding: 20px 0;
                                width: 100%;
                                color: $black;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 11px;
                                line-height: 1;
                                font-family: $primaryFont;
                                letter-spacing: 0.55px;
                                text-transform: uppercase;

                                &:before {
                                    opacity: 1;
                                    transition: all ease-in-out 0.35s;
                                    border: 1px solid $grey;
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }
                    }

                    .mot_personnalise_form {
                        order: 4;
                        padding: 0 20px;

                        .w-checkbox-input {
                            .w-input-label {
                                position: relative;
                                top: inherit;
                                transform: inherit;
                                cursor: pointer;
                                padding: 20px 0;
                                width: 100%;
                                color: $black;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 11px;
                                line-height: 1;
                                font-family: $primaryFont;
                                letter-spacing: 0.55px;
                                text-transform: uppercase;

                                &:before {
                                    display: none;
                                }

                                &:after {
                                    position: absolute;
                                    top: 18px;
                                    right: 0;
                                    background-image: url(../svg/spritesheet.svg);
                                    width: 14px;
                                    height: 14px;
                                    content: '';
                                    @include bgIconCoord(29, 8, 8);
                                    transform: rotate(-90deg);
                                    transition: all ease-in-out 0.5s;
                                }
                            }

                            .w-input-element:checked {
                                & + .w-input-label {
                                    &:after {
                                        transform: rotate(90deg);
                                        transition: all ease-in-out 0.5s;
                                    }
                                }
                            }
                        }

                        #mot_personnalise {
                            margin-bottom: 20px;

                            #mot_personnalise_texte {
                                padding: 10px;
                                width: 100%;
                            }
                        }
                    }
                }

                .reassurance_wrapper {
                    overflow: hidden;

                    .reassurance_cart {
                        display: flex;
                        justify-content: center;
                        animation: marquee_cart 25s linear infinite;
                        margin: 15px 0 0;
                        padding: 0 5rem;
                        width: 100%;

                        .txt {
                            display: inline-block;
                            margin-right: 28px;
                            margin-bottom: 0;
                            width: auto;
                            color: $grey;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: normal;
                            font-family: $primaryFont;
                            white-space: nowrap;

                            &:last-of-type {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .wrap_bottom_rightcol {
                    .bloc_question {
                        -ms-overflow-style: none;
                        margin: 20px 0;
                        padding: 0 15px;
                        max-height: 150px;
                        overflow: auto;
                        scrollbar-width: none;
                        text-align: center;
                        scrollbar-width: none;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        .faq_theme_wrapper {
                            .title_question {
                                display: none;
                            }

                            .questions_frequentes_title {
                                margin: 15px 0 8px;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 1;
                                font-family: $primaryFont;
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                            }

                            .faq_questions {
                                .faq {
                                    .intitule_question {
                                        cursor: pointer;
                                        margin-bottom: 5px;
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 11px;
                                        line-height: normal;
                                        font-family: $primaryFont;
                                        text-transform: lowercase;

                                        &:first-letter {
                                            text-transform: uppercase;
                                        }

                                        &.actif {
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }

                            .texte_question {
                                padding-bottom: 8px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 14px;
                                font-family: $primaryFont;
                            }

                            &:first-child {
                                .title_question {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }

                .formPanier_container {
                    order: 8;
                    margin-top: 20px;
                    padding: 0 21px;

                    .form_submit {
                        position: relative;
                        width: 100%;

                        #btn_cmd_valid {
                            padding: 0;
                            width: 100%;

                            span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 46px;
                                text-align: center;
                            }

                            & + .loader {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                z-index: -1;
                                border-radius: 50px;
                                background: $black url(../svg/three_dots.svg) no-repeat center;
                                background-size: 15% auto;
                                width: 100%;
                                height: 48px;

                                span {
                                    font-size: 0;
                                }

                                &.loading {
                                    opacity: 1;
                                    z-index: 1;
                                }
                            }
                        }
                    }

                    .checkCgv {
                        margin-bottom: 7px;

                        .w-checkbox-input {
                            height: auto;

                            .shipment_cgv_link {
                                align-items: flex-start;
                                margin-bottom: 7px;
                                color: $black;

                                span {
                                    display: inline-block;
                                    margin-left: 6px;
                                    width: calc(100% - 23px);
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                }

                                &::before {
                                    border: 1px solid $black;
                                    width: 15px;
                                    height: 15px;
                                }
                            }

                            .w-input-element:checked {
                                +.w-input-label {
                                    &:before {
                                        box-shadow: inset 0 0 0 .15em #fff;
                                    }
                                }
                            }
                        }
                    }

                    #err_cgv_compulsory {
                        margin-bottom: 18px;
                        padding-left: 23px;
                        color: $chili_pepper_red;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: normal;
                        font-family: $primaryFont;
                    }
                }

                /* Cart share block */
                .share_cart {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;
                    border: 1px solid $lightgrey;
                    background-color: $white;
                    padding: 0 22px;
                    height: 45px;

                    .share_cart_header {
                        margin-right: auto;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 45px;
                        font-family: $primaryFont;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }

                    .share_cart_icon {
                        display: block;
                        margin-left: 17px;
                        border: none;
                        border-radius: 50%;
                        background-color: $secondaryColor;
                        width: 28px;
                        height: 28px;

                        &:hover {
                            cursor: pointer;
                        }

                        &:active {
                            transform: translateY(1px);
                        }

                        &.share_cart_copy {
                            @include bgIconPath('../svg/copy.svg');
                            background-position: center;
                            background-size: auto;
                            background-repeat: no-repeat;
                        }
                    }
                }
            }
        }

        .bank_left_side {
            #paymentLinkSentQrCode, #paymentLinkSent {
                p {
                    padding: 1rem;
                    font-size: 15px;
                    line-height: 1.3;
                    font-family: $primaryFont;
                }
            }
        }
    }

    #cart_slider_wrapper,
    #cart_slider_new {
        opacity: 0;

        .full_w_sldr_title {
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
        }

        #cart_slider {
            .swiper-slide {
                margin-right: 5px;
                width: 19.79%;

                .productVisualMulti {
                    margin-bottom: 7px;

                    .wrap_video {
                        display: flex;
                        position: relative;
                        justify-content: center;
                        align-items: center;
                        transition: all 250ms ease-in-out;
                        background-color: $black;
                        padding-top: calc(475 / 380 * 100%);
                        width: 100%;
                        height: 0;
                        overflow: hidden;

                        iframe {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            z-index: 0;
                            transition: .3s ease-in-out;
                            width: 100%;
                            height: 100%;

                            & + a {
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .description_achat_express_wrapper {
                    width: 100%;

                    .wrapper_description {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        box-sizing: border-box;
                        padding: 0 7px;
                        width: 100%;

                        .container_marques {
                            margin-bottom: 5px;
                            width: 100%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 1;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;

                            img {
                                width: auto !important;
                                height: 22px !important;
                            }
                        }

                        .item_title,
                        .item_title_wrapper {
                            width: calc(100% - 150px);

                            .title_site {
                                margin-bottom: 5px;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.04rem;
                                text-transform: uppercase;
                            }

                            .item_title {
                                margin-top: 2px;
                                width: 100%;
                                overflow: hidden;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 1;
                                font-family: $primaryFont;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .item_subtitle {
                                display: block;
                                width: 100%;
                                overflow: hidden;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 25px;
                                font-family: $primaryFont;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }

                        .item_price {
                            display: flex;
                            flex-direction: row-reverse;
                            flex-wrap: wrap;
                            width: 150px;

                            .pricetag {
                                display: inline-block;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: normal;
                                font-family: $primaryFont;
                                text-align: right;

                                span {
                                    display: inline;
                                }

                                & + .pricetag {
                                    margin-right: 8px;
                                    color: $grey;
                                    font-size: 13px;
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart_main_table {
        &.wishlist {
            display: flex;
            opacity: 0;
            padding-bottom: 30px;

            .empty_cart {
                display: none;
            }

            .cart_product_line {
                position: relative;
                margin-right: 5px;
                width: 19.79%;

                .cart_product_pic {
                    margin-bottom: 7px;
                    width: 100%;

                    .product_mod {
                        display: block;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }
                }

                .cart_product_desc {
                    padding: 5px;

                    .wrapper_cart_product_desc {
                        .wrap_titre_prix {
                            display: flex;
                            flex-wrap: wrap;

                            .product_mod {
                                margin-bottom: 10px;
                                width: calc(100% - 130px);

                                .line_product_desc {
                                    width: calc(100% - 5px);
                                    overflow: hidden;
                                    color: $black;
                                    font-family: $primaryFont;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;

                                    &.cart_product_brand {
                                        margin-bottom: 5px;
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: normal;
                                        letter-spacing: 0.04rem;
                                        text-transform: uppercase;
                                    }

                                    &.cart_product_title {
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                    }

                                    &.cart_product_subtitle {
                                        width: 100%;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 18px;
                                    }
                                }

                                .wrapper_cart_product_price {
                                    display: flex;
                                    justify-content: flex-end;
                                    width: 130px;
                                }
                            }

                            .wrapper_cart_product_price {
                                display: flex;
                                flex-direction: row-reverse;
                                width: 130px;
                                text-align: right;

                                .pricetag {
                                    margin-left: 5px;

                                    span {
                                        font-size: 15px;
                                    }

                                    & + .pricetag,
                                    &.is_discounted,
                                    &.was {
                                        position: relative;
                                        top: -1px;
                                        margin-right: 8px;
                                        color: $grey;
                                        text-decoration: line-through;

                                        span {
                                            font-size: 13px;
                                        }
                                    }
                                }
                            }

                            .cart_product_sizecol {
                                margin-right: 20px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: normal;
                                font-family: $primaryFont;
                            }
                        }

                        .cart_product_modify {
                            .wishToBasket {
                                display: inline-block;
                                margin-top: 15px;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-decoration: underline;
                                text-underline-position: under;
                                text-transform: uppercase;
                            }

                            .remove_wish {
                                position: absolute;
                                background-image: url(../svg/spritesheet.svg);
                                width: 14px;
                                height: 14px;
                                @include bgIconCoord(30, 8, 8);
                                display: block;
                                top: 10px;
                                right: 10px;

                                span {
                                    display: block;
                                    width: 14px;
                                    height: 14px;
                                    font-size: 0;
                                }
                            }
                        }
                    }

                    .cart_product_modify {
                        .tunnel_wish_nostock {
                            display: inline-block;
                            margin-top: 15px;
                            color: $red;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: .6px;
                            text-transform: uppercase;
                        }
                    }
                }

                .dragOverlay {
                    display: none;
                }
            }

            .swiper-scrollbar,
            .swiper-pagination {
                top: auto;
                bottom: 0px;
                opacity: 0;
                transition: opacity 0.35s ease-in-out;
                border-radius: 20px;
                background-color: $white;
                height: 4px;

                .swiper-pagination-progressbar-fill {
                    border-radius: 20px;
                    background-color: $black;
                }
            }

            &:not(.swiper-initialized) {
                .swiper-wrapper {
                    justify-content: center;

                    .swiper-slide {
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }

            &:hover {
                .swiper-scrollbar,
                .swiper-pagination {
                    opacity: 1;
                }
            }
        }
    }

    .title_gondole_wrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .cart_main_title,
        .title_gondole {
            display: inline-block;
            order: 2;
            cursor: pointer;
            margin-bottom: 17px;
            width: auto;
            color: $grey;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;

            &.selected {
                cursor: default;
                color: $black;
            }

            &.wishlist {
                order: 1;
                text-transform: capitalize;

                .nb_products {
                    display: none;
                }

                &:after {
                    display: inline-block;
                    position: relative;
                    margin: 0 20px;
                    background: $grey;
                    width: 2px;
                    height: 23px;
                    content: '';
                }
            }

            &:only-of-type {
                &:after {
                    display: none;
                }
            }

        }
    }

    .basket_gondole {
        display: none;

        .title_gondole {
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }

        .basket_gondole_swiper {
            padding-bottom: 40px;

            .swiper-pagination,
            .swiper-scrollbar {
                top: inherit;
                right: 0;
                bottom: 15px;
                left: 0;
                opacity: 0;
                transition: opacity 0.35s ease-in-out;
                margin: auto;
                border-radius: 20px;
                background-color: $white;
                width: calc(100% - 10px);
                height: 4px;

                .swiper-pagination-progressbar-fill {
                    cursor: pointer;
                    border-radius: 20px;
                    background-color: $black;
                }

                .swiper-scrollbar-drag {
                    cursor: pointer;
                    background-color: $black;
                }
            }

            &:not(.swiper-initialized) {
                .swiper-wrapper {
                    justify-content: center;

                    .swiper-slide {
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }

            &:hover {
                .swiper-scrollbar,
                .swiper-pagination {
                    opacity: 1;
                }
            }
        }
    }

    button.w-visibility-toggle {
        transition: inherit !important;
        border: none !important;
        background-color: inherit !important;
        height: 24px !important;
    }

    #shad {
        top: 0;
        z-index: 50;
    }
}

body.in_tunnel,
body.cart {
    /* Connexion */
    &.body_login {
        .promo_bar {
            display: none !important;
        }

        #site_global_wrap {
            .wrapper_bandeau_connexion {
                display: none !important;
            }

            .tunnel_track_wrapper {
                margin-bottom: 0;

                .title_connexion_only {
                    display: block;
                    margin: 30px auto 17px;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 1;
                    font-family: $secondaryFont;
                    text-align: center;
                }
            }

            .login_wrapper {


                .login_bloc {
                    .login_form_wrapper .title {
                        .subtitle {
                            font-size: 13px !important;
                            letter-spacing: 0.4px !important;

                            #subtitleMigration {
                                color: $red;
                                font-size: fs-vw(12, 428);
                                margin-top: 1.2rem;
                            }
                        }
                    }

                    .title {
                        h4 {
                            margin-top: 18px;
                            margin-bottom: 3px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        .subtitle {
                            max-width: 100% !important;
                        }
                    }
                }

                .w-newlogin-form .intro {
                    font-size: 14px;
                }
            }

            .wrapper_tunnel .newlogin_page {
                min-height: 0;

                .login_wrapper {
                    min-height: 0;
                }
            } 
        }
    }
}

/* Page Banque / remerciment */
body.checkout {
    margin: auto;
    width: 100%;

    #site_global_wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
    }

    #outsite_ue_notice {
        margin: 0 auto 25px;
        width: 100%;
        font-style: italic;
        font-size: 14px;
        font-family: $primaryFont;

        @media only screen and (max-width: 1310px) {
            padding: 0 6.25vw;
        }

        @media only screen and (max-width: 1280px) {
            padding: 0 3.25vw;
        }
    }

    #tunnel_right_col {
        #cart_total {
            padding-top: 0 !important;

            .wrapper_payment {
                position: relative;
                left: -1px;
                order: -1;
                border: 1px solid $white;
                width: calc(100% + 2px);

                .payment_addr_wrapper {
                    &.delivery,
                    &.billing {
                        position: relative;
                        border-bottom: 1px solid $lightgrey;
                        padding: 15px 25px;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        a:before {
                            background-image: url(../svg/spritesheet.svg);
                            content: '';
                            @include bgIconCoord(29, 8, 8);
                            display: inline-block;
                            position: absolute;
                            top: 0;
                            right: 17px;
                            bottom: 0;
                            transform: rotate(180deg);
                            margin: auto;
                            width: 1rem;
                            height: 1rem;
                        }

                        .title {
                            margin-bottom: 2px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        .address {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                        }

                        .address_mod {
                            display: inline-block;
                            cursor: pointer;
                            margin-top: 5px;

                            span,
                            &.modify2 {
                                display: inline-block;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-decoration: underline;
                                text-underline-position: under;
                                text-transform: uppercase;
                            }
                        }

                        &#delivery_address_recap_other {
                            display: none;
                        }
                    }

                    &.no_delivery {
                        .title {
                            line-height: 1.25;
                        }
                    }
                }
            }

            .recap_cart_title {
                border-top: 1px solid $lightgrey;
                padding-top: 25px !important;
            }
        }

        .wrap_bottom_rightcol {
            display: none;
        }
    }

    #bank_div {
        width: 63.22%;

        .txt_recommencer_paiement {
            margin-bottom: 20px;
            color: $red;
            font-size: 0;
        }

        #bloc_erreur_order {
            color: $red;
            line-height: 1.2;

            p, span, a {
                color: $red;
            }
        }

        .wrapper_payment_mode {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;

            .selectPaymentModeTitle {
                margin-bottom: 15px;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1;
                font-family: $primaryFont;
                letter-spacing: 0.8px;
                text-transform: uppercase;
            }

            .payment_mode_btn {
                display: flex;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                cursor: pointer;
                margin-right: 18px;
                border: 1px solid $black;
                background-color: $white;
                width: 113px;
                height: 100px;
                color: $black;
                text-align: center;

                &:before {
                    display: inline-block;
                    background-position: center center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    width: 100%;
                    height: 22px;
                    content: '';
                }

                &.loading {
                    &[data-payment-mode="paypal_remote"]:before {
                        margin: 0;
                        background-image: url(../svg/three_dots_black.svg);
                        height: 64px;
                    }

                    span {
                        display: none;
                    }
                }

                &.actif, &:hover {
                    background-color: $beige-clair;
                }
        
                &[data-payment-mode="hosted_hipay"] {
                    &:before {
                        margin-bottom: 10px;
                        background-image: url(../img/banque/credit_card.png);
                    }
                }

                &[data-payment-mode="paypal_remote"] {
                    &:before {
                        margin-bottom: 10px;
                        background-image: url(../img/banque/pp.png);
                    }

                }

                &[data-payment-mode="payment_gift_card"] {
                    &:before {
                        margin-bottom: 10px;
                        background-image: url(../img/banque/cc.png);
                    }
                }

                &[data-payment-mode="payment_virement"] {
                    &:before {
                        margin-bottom: 10px;
                        background-image: url(../img/banque/alma.png);
                    }
                }

                &[data-payment-mode="payment_gift_list"] {
                    position: relative;

                    &:before {
                        position: absolute;
                        top: 20%;
                        margin: 0 auto;
                        background-image: url(../svg/spritesheet.svg);
                        width: 19px;
                        height: 19px;
                        @include bgIconCoord(3, 8, 8);
                    }

                    span {
                        margin-top: 17px;
                    }
                }

                &[data-payment-mode="applepay_hipay"] {
                    &:before {
                        margin-bottom: 1.12rem;
                        background: transparent url(../svg/apple-pay.svg) no-repeat center;
                    }
                }

                &[data-payment-mode="payment_loyalty_check"] {
                    &:before {
                        @include bgIconPath('../svg/natalys_tunnel.svg');
                        margin-bottom: 10px;
                    }
                }

                &[data-payment-mode="sending_link"] {
                    &:before {
                        background: url(../svg/enveloppe.svg) no-repeat center;
                        padding: 1rem 0;
                    }
                }

                &[data-payment-mode="qr_code"] {
                    &:before {
                        background: url(../svg/qr_code.svg) no-repeat center;
                        padding: 1rem 0;
                    }
                }

                span {
                    position: relative;
                    padding: 0 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-transform: uppercase;
                }
            }
        }

        .wrapper_bloc_banque {
            display: none;
            padding: 0;

            .title_wrapper_fold {
                margin-bottom: 20px;
            }

            #hipay-3-form {
                padding: 0;

                .hipay-3-row {
                    .hipay-3-field-container {
                        font-size: 14px;
                        font-family: $primaryFont;

                        .hipay-3-field {
                            height: 27px;
                        }

                        iframe {
                            height: 30px;
                        }

                        .hipay-3-label {
                            position: relative;
                        }
                    }

                    .hipay-3-field-container-half {
                        width: calc(50% - 15px);
                    }
                }

                .hipay-3-elements-container {
                    position: relative;

                    .form_line.saveAlias {
                        #save_alias_hipay {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            width: 1em;
                            height: 1em;

                            &:checked {
                                & + label {
                                    &:before {
                                        background-color: $black;
                                    }

                                    &:after {
                                        left: 13px;
                                    }
                                }
                            }
                        }

                        label {
                            display: flex;
                            position: relative;
                            align-items: flex-start;
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;

                            &:before {
                                position: relative;
                                top: 1px;
                                transition: all ease-in-out 0.35s;
                                margin-right: 5px;
                                box-shadow: none;
                                border: 0;
                                border-radius: 48px;
                                background-color: $lightgrey;
                                width: 26px;
                                height: 14px;
                                content: '';
                            }

                            &:after {
                                position: absolute;
                                top: 2px;
                                left: 1px;
                                cursor: pointer;
                                border-radius: 50%;
                                background-color: $white;
                                width: 12px;
                                height: 12px;
                                content: '';
                            }
                        }
                    }

                    #hipay-3-submit-button {
                        &:disabled {
                            opacity: 0.5;
                            background-color: $black;
                        }
                    }
                }
            }

            .bank_title {
                margin-bottom: 4px;
                font-style: normal;
                font-weight: 400;
                font-size: 1rem;
                line-height: 1;
                font-family: $primaryFont;
                letter-spacing: 0.8px;
                text-transform: uppercase;
            }

            .bank_subtitle,
            .total_transaction,
            .bank_confiance {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                font-family: $primaryFont;

                strong {
                    font-weight: 400;
                }
            }

            .alias_list {
                margin-top: 20px;

                li {
                    position: relative;
                    margin-bottom: 27px;
                    border-bottom: 1px solid $lightgrey;
                    padding-bottom: 27px;
                    overflow: hidden;

                    form.alias {
                        &:before {
                            display: block;
                            position: relative;
                            box-shadow: inset 0 0 0 2px $white;
                            border: 1px solid $black;
                            border-radius: 100%;
                            background: $white;
                            width: 15px;
                            height: 15px;
                            content: '';
                        }

                        .card_type {
                            margin: 0 4.8%;
                            max-width: 6.08%;
                        }

                        .card_numbers {
                            box-sizing: border-box;
                            padding: 0 5px;
                            width: 15%;
                            max-width: 100px;
                            text-align: center;

                            span {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;

                                &:first-child {
                                    display: inline-block;
                                    text-transform: lowercase;

                                    &:first-letter {
                                        text-transform: capitalize;
                                    }
                                }
                            }
                        }

                        .card_name {
                            width: auto;
                            max-width: 30%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            text-align: center;
                        }

                        .card_expiration {
                            display: block !important;
                            width: 15.2%;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;
                        }

                        .btnAliasWrapper {
                            box-sizing: border-box;
                            padding-left: 15px;
                            max-width: 50px;

                            .use_card {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                width: calc(100% - 50px);

                                span {
                                    font-size: 0;
                                }
                            }

                            .delete_card {
                                button.delete {
                                    border-radius: 0;
                                    background-image: url(../svg/spritesheet.svg);
                                    background-color: inherit;
                                    @include bgIconCoord(30, 8, 8);
                                    position: relative;
                                    top: 10px;
                                    border: inherit;
                                    width: 15px;
                                    height: 15px;

                                    span {
                                        font-size: 0;
                                    }
                                }
                            }
                        }
                    }

                    &.selected {
                        form.alias {
                            &:before {
                                background: $black;
                            }
                        }
                    }
                }
            }

            .cards-wrapper {
                display: none;
            }

            &.gift_card {
                padding: 0;

                .w-giftcard-form {
                    position: relative;

                    .giftcard_desc {
                        margin-bottom: 22px;
                    }

                    div:not(.w-form-line) {
                        .w-input-note {
                            position: absolute;
                            top: 60%;
                        }
                    }

                    .w-form-line {
                        &.giftcard-intro {
                            margin-bottom: 15px;
                        }

                        &.giftcard-buttons-container {
                            .w-submit {
                                max-width: 42.579%;

                                .w-submit-button {
                                    width: 100%;
                                }
                            }
                        }

                        .w-input-note {
                            position: absolute;
                            top: 100% !important;
                        }
                    }
                }

                .w-multi-giftcards-form {
                    .multigc_getdata {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1.5rem;

                        .multigc_infos {
                            line-height: 1.5;
                            margin-top: 1.5rem;
                        }

                        .w-input-label {
                            cursor: text;
                        }
                    }

                    .multigc_paidamount span {
                        margin-left: 5px;
                    }

                    .multigc_addcard_text {
                        display: none;
                    }

                    .multigc_cancel,
                    .multigc_add {
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        cursor: pointer;
                    }

                    .multigc_cards_line {
                        flex-wrap: wrap;
            
                        .multigc_card {
                            display: block;
                            width: 100%;
                            max-width: 300px;
                            height: 84px;
                            border: 1px solid $grey;
                            padding: 20px 25px;
                            position: relative;
                            margin-right: 1rem;
                            margin-bottom: 1rem;
            
                            .multigc_amount {
                                .multigc_title {
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 18px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    letter-spacing: 0.07em;
                                    text-transform: uppercase;
                                    margin-bottom: 1rem;
                                }
            
                                .multigc_remove {
                                    content: 'x';
                                    display: block;
                                    width: 14px;
                                    height: 14px;
                                    position: absolute;
                                    top: 20%;
                                    right: 10px;
                                    transform: translateY(-50%);
                                    background-image: url('../svg/spritesheet.svg');
                                    @include bgIconCoord(30,8,8);
                                    cursor: pointer;
                                }
                            }
            
                            .multigc_info {
                                .multigc_number {
                                    color: $primaryColor;
                                    font-family: $primaryFont;
                                    font-size: 13px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 13px;
                                    letter-spacing: 0.07em;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }

                    .slide-fade-enter-active,
                    .slide-fade-leave-active {
                        transition: all 0.3s ease-out;
                    }
            
                    .slide-fade-enter-from,
                    .slide-fade-leave-to {
                        opacity: 0;
                    }
                }
            }

            &.loyalty_check {
                .w-loyaltycheck-form {
                    position: relative;

                    .w-form-line {
                        margin-bottom: 20px;

                        .loyalty_check_title {
                            margin-bottom: 0;
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 22px;
                            font-family: $primaryFont;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                        }

                        .loyaltycheck_desc {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                        }

                        .w-input-container {
                            .w-input-error {
                                margin: 0em 0 0.6em;
                            }
                        }
                    }

                    .w-input-note.w-input-error {
                        bottom: 0;
                        left: 0%;
                    }

                    .loyaltycheck_total_amount {
                        margin-bottom: 10px;

                        span {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;

                            strong {
                                font-weight: 700;
                            }
                        }
                    }

                    .loyaltycheck_remain_amount {
                        margin-bottom: 10px;
                    }

                    .loyaltycheck-buttons-container {
                        .w-form-line {
                            + .w-form-line {
                                margin-left: 50px;
                            }
                        }
                    }
                }
            }

            &.actif:not(.paypal_remote):not(.applepay_hipay) {
                display: block !important;
            }

            &.paypal {
                .bloc_bank_contents {
                    display: block;

                    .title_wrapper {
                        display: block;
                        margin-bottom: 20px;
                        width: 100%;

                        .bank_subtitle {
                            display: none;
                        }
                    }

                    #inputPAYPAL {
                        display: block;
                    }
                }
            }

            &.payment_virement {
                .bank_title {
                    margin-bottom: 20px;
                }

                .bank_choix_paiement_logo {
                    margin-bottom: 15px;

                    img {
                        cursor: pointer;
                    }
                }

                .bank_subtitle {
                    margin-bottom: 15px;
                    color: $black;
                    font-size: 12px;
                    font-family: $primaryFont;
                }

                .tunel_virement_detail {
                    .virement_disable {
                        color: $grey;
                        font-style: italic;
                        font-size: 12px;
                        font-family: $primaryFont;
                    }

                    .content_bloc_paiement {
                        display: none;

                        .bloc_section_virement_bancaire {
                            .montant_trans {
                                margin: 10px 0;

                                span {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }

                #wrapper_virement {
                    form {
                        display: flex;
                        justify-content: flex-start;
                        text-align: left !important;

                        .bank_choix_btn_virement,
                        .btn_noir {
                            transition: all 0.35s ease-in-out;
                            cursor: pointer;
                            margin-right: 15px;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-color: $black;
                            width: 325px;
                            height: 48px;
                            color: $white;
                            color: $white;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;

                            &:hover,
                            &:focus {
                                background-color: $white;
                                color: $black;
                            }
                        }
                    }
                }
            }

            &.gift_list {
                .gift_list_title {
                    margin-bottom: 30px;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 22px;
                    font-family: $primaryFont;
                    letter-spacing: 0.8px;
                    text-transform: uppercase;
                }

                .bank_prize_pools {
                    .gl_bank_prizes_pool {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-bottom: 1px solid $lightgrey;
                        padding: 25px 0;

                        &:first-of-type {
                            padding: 0 0 25px;
                        }

                        .radio {
                            &:before {
                                display: block;
                                position: relative;
                                box-shadow: inset 0 0 0 2px $white;
                                border: 1px solid $black;
                                border-radius: 100%;
                                background: $white;
                                width: 15px;
                                height: 15px;
                                content: '';
                            }

                            &.active {
                                &:before {
                                    background: $black;
                                }
                            }
                        }

                        .label_prizes_pool {
                            margin-left: 40px;
                            width: 700px;
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-align: left;
                            text-transform: uppercase;
                        }

                        .bank_price {
                            .price {
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: normal;
                                font-family: $primaryFont;
                                text-align: right;
                                text-transform: uppercase;
                                    
                                .unit {
                                    text-wrap: nowrap;
                                }
                            }
                        }
                    }
                }

                form {
                    .w-submit-button {
                        display: block;
                        margin: 22px 0;
                        border-radius: 50px;
                        background-color: $black;
                        padding: 18px 0;
                        width: 100%;
                        max-width: 350px;
                        color: $white;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.04rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }

                #glAmountToPay {
                    margin: 22px 0;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $primaryFont;
                    letter-spacing: 0.7px;
                }
            }
            
            &.bloc_sending_link {
                .title_wrapper {
                    margin-bottom: 1rem;
                }
                .title_wrapper_fold {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .mode_email, .mode_sms {
                        display: flex;
                        gap: 1rem;

                        .w-form-line {
                            margin-bottom: 1rem;
                            max-width: 40%;
                        }
                        .loader_container {
                            max-width: 20%;
                            .confirmPaiement {
                                padding: 0 2rem;
                            }
                        }
                    }

                    .mode_sms {
                        #client_tel {
                            box-sizing: border-box;
                            box-shadow: inherit;
                            border: none;
                            border-bottom: 1px solid $grey;
                            border-radius: 0;
                            width: 100%;
                            height: 40px;
                        }
                    }
                }
            }
        }

        #addAliasListElem {
            margin-bottom: 31px;

            #addNewAlias {
                cursor: pointer;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-decoration: underline;
                text-underline-position: under;
                text-transform: uppercase;
            }
        }

        .wrapperSelectAlias {
            position: relative;
            margin-bottom: 30px;

            #selectAliasBtn {
                width: 380px;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-align: center;
                text-transform: uppercase;
            }

            .loader {
                border-radius: 50px;
                width: 380px;
                height: 48px;
            }
        }
    }
}

#hipay-3-submit-button {
    transition: all 0.35s ease-in-out;
    cursor: pointer;
    margin-top: 25px;
    border: 1px solid $black;
    border-radius: 50px;
    background-color: $black;
    width: 100%;
    max-width: 350px;
    height: 48px;
    color: $white;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    font-family: $primaryFont;
    letter-spacing: 0.04rem;
    text-transform: uppercase;

    &:disabled {
        opacity: .5;
        cursor: default;
        background-color: #ccc;
    }

    & + .hipay-loader {
        position: relative;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
        margin-top: 25px;
        border-radius: 50px;
        background: $black url(../svg/three_dots.svg) no-repeat center;
        background-size: 15% auto;
        width: 100%;
        max-width: 350px;
        height: 48px;

        span {
            font-size: 0;
        }
    }

    &.loading {
        & + .hipay-loader {
            opacity: 1;
            z-index: 1;
        }
    }
}

.thanksWrapper {
    display: flex;
    align-items: center;

    .bankThanksTop {
        width: 55.23%;

        #around_me_map {
            width: 100%;
            height: 530px;

            img[src$="marker.png"] {
                width: 37px !important;
                height: auto !important;
            }

            button {
                width: inherit !important;
                border-radius: 0 !important;
            }

            .gm-style {

                img[src$="marker.png"] {
                    width: 1rem !important;
                    height: auto !important;
                }

                .gm-style-iw-c {
                    width: 317px;
                    padding: 58px 31px 25px 31px !important;
                    border-radius: 0;
                    background: $white url('../img/marker.png') center 22px no-repeat;
                    background-size: 28px;

                    &:focus {
                        outline: none;
                    }

                    .infoWindowContent {
                        border: none;
                        text-decoration: none;
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: 0.7px;
                        color: $black;
                        margin-bottom: 6px;
                        display: block;
                        
                        strong {
                            text-transform: uppercase;
                            font-weight: 400;
                        }

                        &:focus {
                            outline: inherit;
                        }
                    }

                    .gm-ui-hover-effect,
                    .horaire_info {
                        display: none !important;
                    }

                    .horaire_today {
                        margin: 10px 0;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        font-family: $primaryFont;

                        .puce {
                            display: inline-block;
                            height: 10px;
                            padding-left: 12px;
                            position: relative;

                            &:before {
                                display: inline-block;
                                content: '•';
                                font-family: sans-serif;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 300;
                                line-height: 10px;
                                position: absolute;
                                left: 0;
                                top: 1px;
                            }

                            &.store_open {
                                &:before,
                                + .mag_open_status {
                                    color: $green;
                                }
                            }

                            &.store_closed {
                                &:before,
                                + .mag_open_status {
                                    color: $red;
                                }
                            }
                        }
                    }

                    .horaire_info {
                        display: none;
                    }

                    .store_localisation_container {
                        display: flex;
                        margin-top: 10px;

                        .btn_voir_magasin {
                            font-family: $primaryFont;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-right: 10px;
                            color: $black;
                            padding-bottom: 2px;

                            a {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .gm-style-iw-d {
                    overflow: auto !important;
                }
            }
        }

        h1 {
            display: none;
        }
    }

    .thanksRecap {
        display: block;
        margin-left: 2.34%;
        width: 42.42%;

        .thanksLeft {
            margin-bottom: 20px;
            width: 100%;
            text-align: center;
        }

        .seeOrderEvo,
        .createPwdTxt {
            margin-bottom: 15px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1rem;
            font-family: $primaryFont;

            .titre {
                display: block;
                margin-bottom: 10px;
                font-style: italic;
                font-weight: 400;
                font-size: 35px;
                line-height: 1;
                font-family: $secondaryFont;

                & + span {
                    display: block;
                    margin-bottom: 8px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    font-family: $primaryFont;
                }

            }

            strong {
                font-weight: 500;
            }
        }

        .seeOrderEvo {
            strong {
                font-weight: 400;
            }
        }

        .thanksTrust {
            display: none;
        }

        .w-newpass-form {
            .w-form-line {
                margin-bottom: 25px;

                .w-input {
                    .w-input-element {
                        box-sizing: border-box;
                        height: 30px;
                    }
                }
            }
        }

        .bankThanksBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: all 0.35s ease-in-out;
            cursor: pointer;
            border: 1px solid $black;
            border: none;
            border-radius: 50px;
            background-color: $black;
            width: 100%;
            height: 48px;
            color: $white;
            line-height: 48px;
            text-align: center;

            span {
                color: $white;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
            }
        }

        .thanksRight {
            width: 100%;

            .thanksRightWrapper {
                margin-bottom: 20px;
                background-color: $beige-clair;
                padding: 21px;
                text-align: center;

                .authorization_id {
                    display: none;
                }

                p {
                    margin-bottom: 0;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 19px;
                    font-family: $primaryFont;

                    &.main_number_card {
                        display: block !important;
                    }

                    &.txt_thanks_transaction {
                        margin-top: 6px;
                        text-transform: initial;
                    }

                    b {
                        font-weight: 400;
                    }
                }
            }
        }

        .thanksBottom {
            position: relative;

            .thanksBottomWrapper {
                .loader {
                    display: block;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    opacity: 0;
                    z-index: -1;
                    margin: auto;
                    border-radius: 50px;
                    background: $black url(../svg/three_dots.svg) no-repeat center;
                    background-size: 15% auto;
                    width: 100%;
                    height: 48px;
        
                    span {
                        display: none;
                    }
        
                    &.loading {
                        opacity: 1;
                        z-index: 2;
                    }
                }
            }
        }
    }
}

.delivery_lightbox {
    display: block !important;
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    transform: translateX(100%);
    opacity: 0;
    z-index: 55;
    transition: transform ease-in-out 0.5s;
    cursor: default;
    box-sizing: border-box;
    background-color: $white;
    padding: 30px 25px 25px;
    width: 380px;
    height: 100vh;
    overflow-y: auto;

    .lightbox_delivery_title {
        margin-bottom: 14px;
        padding: 0 47px;
        font-style: italic;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        font-family: $secondaryFont;
        text-align: center;

        .relay_type {
            display: none;
        }
    }

    .delivery_form_title {
        display: none;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $primaryFont;
    }

    .wrapper_form_adresse {
        #adresseForm {
            .delivery_section_title {
                display: none;
            }

            .w-form-line {
                &.add-complementary-line {
                    .w-input-container {
                        .w-checkbox-input {
                            .w-input-label {
                                &:before {
                                    display: block;
                                    content: '' !important;
                                    transition: all ease-in-out .35s;
                                    border: 1px solid $grey !important;
                                    width: 15px !important;
                                    height: 15px !important;
                                }

                                span {
                                    margin-left: 10px !important;
                                }
                            }

                            .w-input-element:checked {
                                + .w-input-label {
                                    &:before {
                                        background-color: $black !important;
                                        box-shadow: inset 0 0 0 .125em $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .w-form-line {
        &.content_form {
            .w-text-input {
                position: relative;

                &.w-nonempty {
                    .w-input-label {
                        top: 35%;
                        left: 0;
                    }
                }

                &:before {
                    position: absolute;
                    background-image: url(../svg/spritesheet.svg);
                    content: '';
                    @include bgIconCoord(17, 8, 8);
                    top: 13px;
                    right: 0;
                    width: 23px;
                    height: 23px;
                }

                .valid_form {
                    position: absolute;
                    top: 13px;
                    right: 0;
                    width: 23px;
                    height: auto;
                }
            }

            input.w-input-element {
                padding-left: 0;

            }
        }

        .w-input-container {
            .w-address-input {
                .noaddr {
                    top: 3rem;
                    right: 0;
                    font-size: 11px;
                    font-family: $primaryFont;
                }
            }
        }
    }

    .wrapper_relais_top {
        margin-bottom: 15px;
    }

    .wrapper_select_adr {
        .wrapper_form_adresse {
            #adresseForm {
                .w-input-group {
                    margin-bottom: 0;

                    .w-form-line {
                        .w-input-container {
                            margin-bottom: 1rem;
                        }
                    }
                }

                .elem_global {
                    .w-form-line {
                        flex-wrap: wrap;
                        margin-bottom: 0;

                        .w-input-container {
                            margin-bottom: 1rem;
                            margin-left: 0;
                        }

                        &.last-is-hidden {
                            justify-content: space-between;
                            margin-bottom: 0;

                            .w-input-container {
                                width: 47.87878%;

                                &.delivery-country {
                                    margin-top: 1rem;
                                    width: 100%;
                                }
                            }

                            & + .w-form-line {
                                display: none;
                            }
                        }
                    }
                }

                .w-input-label {
                    width: calc(100% - 5px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .w-submit {
                    margin: 1rem auto;
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }
        }

        section {
            .address {
                transition: all ease-in-out 0.5s;
                cursor: pointer;
                border-bottom: 1px solid $lightgrey;
                background-color: $white;
                padding: 15px 0;

                .addr_line {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.7px;

                    &.full_name {
                        margin-bottom: 10px;
                        text-transform: uppercase;
                    }

                    &.full_address {
                        margin-bottom: 5px;
                    }
                }

                .modlinks_wrapper {
                    .modlink {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-decoration: underline;
                        text-underline-position: under;
                        text-transform: uppercase;
                    }
                }

                &:first-of-type {
                    border-top: 1px solid $lightgrey;
                }

                &:hover {
                    background-color: rgba($lightgrey, 0.5);
                    padding-left: 10px;
                }

                &:last-of-type {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    transition: all ease-in-out 0.5s;
                    margin-top: 15px;
                    border: 1px solid $black;
                    border-radius: 50px;
                    background-color: $white;
                    padding: 0;
                    height: 48px;
                    color: $black;

                    .infos {
                        color: $black;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: normal;
                        line-height: 48px;
                        font-family: $primaryFont;
                        letter-spacing: 0.04rem;
                        text-transform: uppercase;

                        .full_address {
                            margin-bottom: 0;
                        }
                    }

                    .add_addr {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                    }

                    &:hover {
                        background-color: $black;
                        padding-left: 0;

                        .infos {
                            .addr_line,
                            .full_address {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    .wrapper_locate_relais {
        .displayed_result,
        .results {
            #map_canvas {
                margin-bottom: 20px;
                width: 100%;
                height: 181px;
            }
        }
    }

    .gm-style .gm-style-iw-c {
        &:focus-visible,
        &:focus {
            outline: none;
            box-shadow: inherit;
        }
    }

    .sidebar-right {
        max-height: calc(100vh - 370px);
        overflow-y: auto;

        .list-right {
            padding-right: 10px;

            .details {
                width: 100%;

                tr {
                    display: block;
                    margin-bottom: 22px;
                    border-bottom: 1px solid $lightgrey;
                    padding-bottom: 22px;

                    .kp {
                        display: flex;
                        flex-direction: column;

                        .name {
                            margin-bottom: 2px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-transform: uppercase;
                        }

                        .address {
                            margin-bottom: 12px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            text-transform: capitalize;
                        }

                        .relayHours {
                            display: none;
                        }

                        .btn_container {
                            order: 5;

                            .relayLink {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-decoration: underline;
                                text-underline-position: under;
                                text-transform: uppercase;
                            }
                        }

                        .current_schedule {
                            position: relative;
                            margin-bottom: 14px;
                            padding-left: 15px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;

                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 1px;
                                left: 0;
                                content: '';
                                content: '•';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 30px;
                                line-height: 10px;
                                font-family: sans-serif;
                            }

                            &.puce_store_open {
                                .mag_open_status {
                                    color: $green;
                                }

                                &:before {
                                    color: $green;
                                }
                            }

                            &.puce_store_closed {
                                color: $red;

                                &:before {
                                    color: $red;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.actif {
        transform: translateX(0%);
        opacity: 1;
    }
}

body.en {
    &.checkout #bank_div {
        .wrapper_payment_mode {
            .payment_mode_btn span {
                width: 65%;
            }
        }
    }
}

.bank_erroralreadypaid {
    .title {
        display: none;
    }

    #bloc_erreur {
        .sorry_txt {
            font-family: $secondaryFont;
            font-size: 35px;
            font-style: italic;
            font-weight: 400;
            text-align: center;
        }

        .sorry_subtxt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 12px;
            margin-top: 20px;
        }

        .rose {
            display: none;
        }
    }
}

@keyframes marquee_cart {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-150%);
    }
}