/* Achat Epress */
.wrap_rolloverproduit {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    opacity: 0;
    z-index: -1;
    transition: all ease-in 0.35s;
    margin: auto;
    background: $white;
    padding: 10px;
    width: calc(100% - 20px);
    height: auto;

    form {
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;

        .achat_express {
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }

        .productSizeFieldset, .productColorFieldset {
            // Apply display: block if the element contains a first and a second .form_itm child
            &:has(.form_itm:nth-of-type(1)):has(.form_itm:nth-of-type(2)) {
                display: block;
            }

            // Hide the element if it does not contain a second .form_itm child
            &:not(:has(.form_itm:nth-of-type(2))) {
                opacity: 0;
                visibility: hidden;
                height: 0;
                width: 0;
                margin: 0;
            }

            // Hide the element if it does not contain a .choices_list child
            &:not(:has(.choices_list)) {
                display: none;
            }
        }

        .ligne_form {
            width: 100%;

            &.productSizeFieldset {
                .size_list,
                .prod_listes {
                    .titre_choices_list {
                        display: none;
                    }

                    .choices_list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        width: 100%;

                        .form_itm {
                            position: relative;
                            margin-right: 15px;

                            &:last-of-type {
                                margin-right: 0;
                            }

                            [type=radio]:checked,
                            [type=radio]:not(:checked) {
                                display: none;


                                & + label {
                                    position: relative;
                                    z-index: 1;
                                    cursor: pointer;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.04rem;
                                    text-align: center;
                                    white-space: nowrap;

                                    &:before,
                                    &:after{
                                        display: none;
                                    }
                                }

                                &:hover {
                                    & + label {
                                        font-weight: 600;
                                        text-decoration: underline;
                                        text-underline-position: under;
                                    }
                                }

                                &.disabled {
                                    & + label {
                                        color: $grey;
                                    }
                                }
                            }

                            [type=radio]:checked {
                                & + label {
                                    font-weight: 600;
                                    text-decoration: underline;
                                    text-underline-position: under;
                                }
                            }
                        }
                    }
                }
            }

            &.productColorFieldset {
                margin-bottom: 10px;

                .titre_choices_list {
                    display: none;
                }

                .choices_list {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    height: 25px;
                    overflow: hidden;

                    .form_itm {
                        display: flex;
                        position: relative;
                        justify-content: center;
                        align-items: center;
                        margin-left: 12px;
                        width: 25px;
                        height: 25px;

                        &:first-of-type {
                            margin-left: 0;
                        }

                        [type=radio] {
                            display: none;
                        }

                        [type=radio]:not(:checked) + label,
                        [type=radio]:checked + label {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            z-index: 1;
                            transition: all .4s ease-in-out;
                            cursor: pointer;
                            margin: 0;
                            box-shadow: inherit;
                            border: solid 2px $white;
                            border-radius: 100%;
                            background-position: center;
                            background-size: contain !important; // override inline style from view
                            width: 18px;
                            height: 18px;

                            &:before {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                border: 1px solid $grey;
                                border-radius: 100%;
                                background-color: transparent;
                                width: 22px;
                                height: 22px;
                                content: '';
                            }
                        }

                        [type=radio]:checked+label {
                            &:before {
                                border: 1px solid $black;
                            }
                        }
                    }

                    .remaining_colors {
                        margin-left: 12px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 1;
                        font-family: $primaryFont;
                        letter-spacing: 0;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .bloc_add_color {
            display: flex;
            position: relative;
            justify-content: flex-end;
            opacity: 0;
            width: 90px;
            height: 0;

            &:before {
                position: absolute;
                top: 6px;
                left: 43px;
                background: $grey;
                width: 1px;
                height: 12px;
                content: '';
            }

            .w-submit-button {
                position: relative;
                border: inherit;
                border-radius: 0;
                background-image: url(../svg/spritesheet.svg);
                background-color: inherit;
                width: 21px;
                height: 21px;
                color: $black;
                @include bgIconCoord(9,8,8);
                transition: inherit;

                span {
                    font-size: 0;
                }

                &.add_cart_preco {
                    span {
                        position: absolute;
                        right: 0;
                        bottom: -12px;
                        left: -50px;
                        margin: auto;
                        width: 120px;
                        color: $black;
                        font-size: 10px;
                        font-family: $primaryFont;
                        letter-spacing: 0;
                        text-align: center;
                        text-transform: none;
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    background-color: inherit;
                }
            }

            .button.loader {
                width: 21px !important;
                height: 21px !important;
                background: white url(../svg/three_dots_black.svg) no-repeat center !important;
                background-size: 100% auto !important;
                border: none;
                border-radius: 0;
                left: auto !important;

                &.loading {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }

        /* Alerte Stock */
        .expressAlertStock,
        div[id^="bloc_add_alert_"] {
            position: relative;
            z-index: 2;
            margin: 0 auto;
            background: $white;
            padding-bottom: 0.75rem;
            width: 100%;

            .alert_stock {
                position: relative;
                margin-bottom: 10px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                font-family: $primaryFont;
                letter-spacing: 0.7px;
                text-align: center;
                text-transform: uppercase;
            }

            .bloc_add_alert_form {
                margin: auto;
                width: calc(100% - 20px);
                max-width: 424px;

                .w-form-line {
                    position: relative;
                    width: 100%;

                    .w-input-container {
                        .w-text-input {
                            .w-input-element {
                                height: 30px;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 1;
                                font-family: $primaryFont;

                                &:placeholder {
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 1;
                                    font-family: $primaryFont;
                                }

                                &.inputErr {
                                    border-color: $red;
                                }
                            }
                        }
                    }

                    .bloc_add_color,
                    .form_submit {
                        position: absolute;
                        top: 10px;
                        right: 0;

                        button {
                            border: none !important;
                            border-radius: 0 !important;
                            background-image: url(../svg/spritesheet.svg);
                            background-color: inherit !important;
                            width: 13px;
                            height: 13px !important;
                            @include bgIconCoord(29,8,8);
                            transform: rotate(180deg);
                            opacity: 0.5;

                            span {
                                font-size: 0 !important;
                            }
                        }
                    }
                }
            }

            .alert_return {
                position: absolute;
                top: 0;
                right: 10px;
                z-index: 10;
                cursor: pointer;
                background-image: url(../svg/spritesheet.svg);
                width: 11px;
                height: 11px;
                font-size: 0;
                @include bgIconCoord(30, 8, 8);
                opacity: 1;
                transition: all ease-in-out 0.5s;

                span {
                    font-size: 0;
                }
            }

            .bloc_add_alert_confirmation {
                padding-top: 6px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                font-family: $primaryFont;
                text-align: center;
            }

            &.inputErr1 {
                .bloc_add_alert_erreur {
                    display: block !important;
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: auto;
                    width: calc(100% - 20px);
                    max-width: 424px;

                    p {
                        margin-top: 5px;
                        color: $red;
                        font-size: 12px;
                        font-family: $primaryFont;

                        & + .w-submit-button {
                            display: none;
                        }
                    }
                }
            }
        }

        .noSizeAvailable {
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            z-index: 2;
            background-color: $white;
            width: 100%;
            height: 100%;
            text-align: center;

            #size_is_not_available {
                background-color: $white;
                padding-right: 5px;
                width: 100%;
                min-height: 2rem;
                color: $red;
                font-size: 15px;
                line-height: 2rem;
                text-align: center;
            }

            .alert_return {
                display: block;
                cursor: pointer;
                background-image: url(../svg/spritesheet.svg);
                width: 1rem;
                height: 1rem;
                font-size: 0;
                @include bgIconCoord(30, 8, 8);
                position: relative;
                right: auto;
                opacity: 1;
                margin-left: 5px;

                span {
                    display: none;
                }
            }
        }
    }

    &:hover {
        form {
            .expressAlertStock,
            div[id^="bloc_add_alert_"] {
                .alert_return {
                    opacity: 0.5;
                }
            }
        }
    }

    .wishlistBtn  {
        position: absolute;
        bottom: 0;
        left: calc(50% - 48px);
        opacity: 0;
        z-index: -1;
        height: 20px;
        overflow: hidden;
    }

    &.size_selected {
        .wishlistBtn  {
            opacity: 1;
            z-index: 1;
        }

        .bloc_add_color {
            opacity: 1;
            margin-top: 14px;
            height: auto;
        }

        .productColorFieldset {
            transition: height ease-in-out 0.35s;
            height: 0;
            overflow: hidden;
        }
    }

    .load_product {
        display: none;
    }
}

.one_size .wrap_rolloverproduit.size_selected {
    .productColorFieldset {
        height: 25px;
    }

    .productSizeFieldset {
        display: none !important;
    }

    .bloc_add_color {
        margin-top: 0;
        position: relative;

        &:before {
            position: absolute;
            top: 6px;
            left: 43px;
            background: $grey;
            width: 1px;
            height: 12px;
            content: '';
        }
    }
}

/* Wishlist */
.wishlistBtn  {
    background-image: url(../svg/spritesheet.svg);
    width: 20px;
    height: 20px;
    @include bgIconCoord(20, 8, 8);
    display: block;
    margin: 0;

    span {
        font-size: 0;
    }

    &.existToWishlistButton {
        @include bgIconCoord(21, 8, 8);
    }
}