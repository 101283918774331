/* Input General style */
button:active,
button:focus,
input:active,
input:focus,
textarea:active,
textarea:focus {
    outline: none;
    box-shadow: inherit !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
}

.w-input-element:not([type=radio]):not([type=checkbox]),
input[type=email].w-input-element,
input[type=password].w-input-element,
input[type=text].w-input-element, textarea.w-input-element {
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid $grey;
    border-radius: 0;
    padding: 0 2px;
    height: 40px;
}

.w-date-input .w-input-element:not([type=radio]):not([type=checkbox]),
.w-date-input input[type=email].w-input-element,
.w-date-input input[type=password].w-input-element,
.w-date-input input[type=text].w-input-element,
.w-date-input textarea.w-input-element,
.w-nonempty:not(.no-label) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-nonempty:not(.no-label) input[type=email].w-input-element,
.w-nonempty:not(.no-label) input[type=password].w-input-element,
.w-nonempty:not(.no-label) input[type=text].w-input-element,
.w-nonempty:not(.no-label) textarea.w-input-element {
    padding-top: 8px;
}

.w-has-error:not(.w-dropdown) .w-input-element:not([type=radio]):not([type=checkbox]),
.w-has-error:not(.w-dropdown) input[type=email].w-input-element,
.w-has-error:not(.w-dropdown) input[type=password].w-input-element,
.w-has-error:not(.w-dropdown) input[type=text].w-input-element,
.w-has-error:not(.w-dropdown) textarea.w-input-element {
    border: none;
    border-bottom: 1px solid $red;
    box-shadow: inherit;
}

.w-radio-group.w-has-error .w-input-element+.w-input-label {
    color: $red !important;

    &::before {
        border-color: $red !important;
        box-shadow: inherit;
    }
}

.w-input-note.w-input-error {
    color: $red;
}

.w-input-label {
    font-family: $primaryFont;
    left: 2px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}