/* Contact */
body.footer_contact,
body.contact_faq {
    .contactForm {
        .wrapperContentFaqForm {
             @media only screen and (max-width: 1160px) {
                padding: 0 6.25vw;
                box-sizing: border-box;
            }

            .titre_contact {
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                font-weight: 400;
                line-height: 35px;
                margin-bottom: 19px;
                text-align: center;
            }

            .subTitle {
                font-family: $primaryFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                text-align: center;
                margin-bottom: 10px;
                color: $black;

                a {
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                    border-bottom: 1px solid $black;
                    color: $black;
                }

                &:first-of-type {
                    margin-bottom: 18px;
                }

                span {
                    white-space: nowrap;
                }
            }

            .wrap_content {
                margin-top: 30px;
            }

            #contact_form {
                text-align: center;

                #mes_err_contact {
                    font-size: 14px;
                    font-family: $primaryFont;

                    .red {
                        color: $red;
                    }
                }

                #message_mail {
                    font-size: 14px;
                    font-family: $primaryFont;

                    .valid {
                        color: $green;
                    }
                }

                .w-contact-form {
                    width: 100%;
                    max-width: 1024px;
                    margin: auto;
                    display: flex;
                    flex-direction: column;

                    .w-form-line {
                        margin-bottom: 15px;
                        position: relative;

                        &:nth-of-type(2) {
                            z-index: 3;
                        }

                        .w-input-label {
                            top: 57%;
                        }

                        .w-nonempty {
                            .w-input-label {
                                font-size: 12px;
                            }
                        }

                        .w-input-container {
                            .w-input {
                                &.w-tel-input {
                                    button.selected-flag {
                                        height: 70% !important;
                                        background-color: transparent !important;
                                        border: none !important;
                                        border-radius: 0 !important;
                                        transition: inherit;
                                        padding: 0;

                                        &:before {
                                            width: 24px;
                                            height: 1rem;
                                        }

                                        &::after {
                                            background-image: url(../svg/spritesheet.svg);
                                            @include bgIconCoord(29,8,8);
                                            width: 10px;
                                            height: 10px;
                                            position: relative;
                                            right: -0.25rem;
                                            border: 0;
                                            transform: rotate(267deg);
                                            opacity: 0.5;
                                        }

                                        & + .w-input-label {
                                            span {
                                                position: relative;

                                                &::after {
                                                    content: '';
                                                    position: absolute;
                                                    right: 0;
                                                    width: 5px;
                                                    height: 7px;
                                                    background-color: $white;
                                                }
                                            }
                                        }
                                    }
                                }

                                &.w-dropdown {
                                    border: none;
                                    border-radius: 0;
                                    border-bottom: 1px solid $grey;
                                    height: 40px;

                                    .w-input-element {
                                        cursor: pointer;
                                    }

                                    &:focus,
                                    &.w-focused {
                                        outline: none;
                                        box-shadow: none;
                                    }

                                    .w-value {
                                        padding-left: 0;
                                    }

                                    &.w-has-error {
                                        box-shadow: inherit;
                                        border-bottom: 1px solid $red;
                                    }

                                    &::after {
                                        border: none;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(29,8,8);
                                        width: 14px;
                                        height: 14px;
                                        transform: rotate(-90deg);
                                        display: inline-block;
                                        right: 0;
                                        top: 54%;
                                    }
                                }

                                &.w-textarea {
                                    height: 64px;
                                    display: flex;
                                    align-items: flex-end;

                                    .w-input-element {
                                        height: auto;
                                        max-height: 46px;
                                        padding: 24px 0 0;

                                        & + .w-input-label {
                                            top: -2px;
                                        }
                                    }
                                }
                            }

                            .errormsgadd {
                                display: none;
                            }
                        }

                        .w-input-note {
                            text-align: left;
                            line-height: 1;
                            font-size: 11px;
                        }

                        &.w-submit {
                            max-width: 350px;
                            margin: auto;
                        }
                    }

                    .file-input-row {
                        margin-top: 32px;

                        .w-form-line {
                            &.column {
                                margin-bottom: 0;
                            }

                            .ajout_pj_label {
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                                padding: 0;
                                width: auto;
                                display: inline-block;
                                border-radius: 0;
                                border: none !important;
                                height: auto !important;
                                border-bottom: 1px solid $black !important;
                            }
                        }
                    }

                    .file-list {
                        .closePj {
                            border: none;
                            background-color: inherit;
                        }
                    }
                }

                .wrapperErrors{
                    #mes_err_fieldset {
                        .red {
                            color: $red;
                            margin-bottom: 1.5rem;
                        }
                    }
                }

                .g-recaptcha {
                    margin: 5px auto 12px;
                    display: flex;
                    justify-content: center;

                    & > div {
                        transform: scale(0.79);
                    }
                }
            }

            .has-flag-icn.fr {
                background-position: 45.45455% 27.27273%;
            }

            .has-flag-icn.gb {
                background-position: 54.54545% 27.27273%;
            }
        }
    }
}

/* Faq */
body.faq {
    #breadcrumbs {
        margin: 30px auto 15px;
    }

    .faq_content{
        @media screen and(max-width: 1024px) {
            padding: 0 6.25vw;
        }

        .nav_moncompte {
            margin-bottom: 24px;

            .title {
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                font-weight: 400;
                line-height: 35px;
                text-align: center;
                margin: 0 0 20px 14px;
            }

            .account_name {
                display: none;
            }
        }

        .faq_page_search {
            width: 100%;
            max-width: 1024px;
            margin: 0 auto;
            position: relative;

            .search_results {
                display: none;
                background: $white;
                position: absolute;
                top: 100%;
                width: 100%;
                z-index: 2;
                padding: 0 1em;
                font-size: 12px;
                letter-spacing: .03rem;
                transition: all 0.2s ease-out;
                max-height: 30rem;
                border: 1px solid $lightgrey;
                font-family: $primaryFont;

                li {
                    min-height: 2.5rem;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                }
            }

            input[type=text].w-input-element {
                height: 30px;
            }
        }

        #theme_global {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 1280px;
            margin: 32px auto 40px;

            .theme {
                display: inline-flex;
                width: auto;
                cursor: pointer;
                align-items: center;

                &:after {
                    content: '';
                    display: inline-block;
                    width: 2px;
                    height: 10px;
                    background: rgba($black, 0.4);
                    margin: 0 11px;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                .intitule {
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    @media screen and (max-width: 1096px) {
                        letter-spacing: 0;
                    }
                }

                &.active {
                    .intitule {
                        font-weight: 700;
                    }
                }
            }
        }

        #theme_page {
            margin: 0 0 34px;
        }

        .faq_bottom_texte {
            width: 100%;
            text-align: center;
            font-family: $primaryFont;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 70px;
            margin-left: 0.3%;
        }

        .theme_detail {
            width: 100%;
            max-width: 1280px;
            margin: auto;
            border-bottom: 2px solid $lightgrey;

            .faq_element {
                &.faq_level_1 {
                    .faq_title  {
                        display: none;
                    }

                    .faq_question {
                        font-family: $primaryFont;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 50px;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        color: $black;
                        cursor: pointer;
                        border-top: 2px solid $lightgrey;
                        position: relative;

                        &:after {
                            content: '';
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            width: 10px;
                            height: 12px;
                            transform: rotate(-90deg);
                            display: inline-block;
                            position: absolute;
                            top: 19px;
                            right: 0;
                            transition: all 0.35s ease-in-out;
                        }

                        &.active {
                            &:after {
                                transform: rotate(-270deg);
                            }
                        }
                    }

                    .faq_reponse,
                    ul li {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        padding-bottom: 22px;
                    }

                    strong {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

/* Plan du site */
body.footer_sitemap {
    .page_satellite {
        width: 100%;
        padding: 0 6.25vw;

        .sitemap_title {
            font-family: $secondaryFont;
            font-size: 35px;
            font-style: italic;
            font-weight: 400;
            line-height: 1;
            text-align: center;
            margin-bottom: 18px;
        }

        .satellite_content {
            h2 {
                font-family: $primaryFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 0.7px;
                text-transform: uppercase;
                margin-bottom: 30px;
                text-align: center;
                color: $black;
                width: 100%;
            }
            .sitemap_section {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 0;

                .sitemap_section {
                    width: calc(96.9% / 3);
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    margin-bottom: 68px;
                    margin-right: 1.5%;

                    &:nth-of-type(3n) {
                        margin-right: 0;
                    }

                    h3 {
                        font-family: $primaryFont;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        color: $black;
                        margin-bottom: 17px;
                        width: 100%;
                    }

                    .article {
                        width: 45.32%;

                        h4 {
                            font-family: $primaryFont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            text-transform: uppercase;
                            margin-bottom: 5px;
                            display: none;
                        }

                       .site_map_ul {
                            li {
                                font-family: $primaryFont;
                                font-size: 13px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 25px;
                            }
                        }

                        & + .article {
                            margin-left: 9.36%;

                            h4 {
                                display: block;
                            }
                        }

                        @media only screen and (max-width: 1160px) {
                            width: 100%;

                            .site_map_ul {
                                margin-bottom: 0.5rem;
                            }

                            & + .article {
                                margin-left: 0;

                                .site_map_ul {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Guide des tailles */
body.guide_taille {
    .page_satellite {
        .satellite_title {
            text-align: center;
            font-family: $secondaryFont;
            font-size: 35px;
            font-style: italic;
            font-weight: 400;
            line-height: 35px;
            color: $black;
            margin-bottom: 32px;
        }
    }

    .satellite_content {
        .switch_gdt {
            min-height: 40vh;
            min-height: 40dvh;

            .switch_gdt_btn {
                .swiper-wrapper {
                    display: flex;
                    justify-content: center;

                    .swiper-slide {
                        width: auto;
                    }
                }
            }
        }
        .module {
            &.module_meli_melo_7 {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                margin-bottom: 70px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background: $white;
                    height: 10px;
                    z-index: 2;
                }

                .col {
                    width: 24.14698%;
                    margin-right: 5px;

                    &.col_3,
                    &.col_4 {
                        width: 25.82677%;
                    }

                    &.col_4 {
                        margin-right: 0;
                    }

                    .content_pic {
                        width: 100%;
                        position: relative;
                        display: block;
                        margin-bottom: 5px;

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }

                        .content_text {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: auto;
                            width: 100%;
                            height: 300px;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            flex-wrap: wrap;
                            text-align: center;

                            .content_title {
                                font-family: $secondaryFont;
                                font-size: 35px;
                                font-style: italic;
                                font-weight: 400;
                                line-height: 35px;
                                color: $white;
                                margin-bottom: 22px;
                                display: block;
                                width: 100%;
                            }

                            .content_cta {
                                display: inline-block;
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                                color: $white;
                                border-bottom: 1px solid $white;
                            }
                        }
                    }
                }

                @media only screen and (max-width: 1160px) {
                    flex-wrap: wrap;

                    .col {
                        width: calc(50% - 2.5px) !important;

                        &.col_2,
                        &.col_4 {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.switch_gdt {
    margin: 0 auto 70px;

    .switch_gdt_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 40px;

        .thumblnk {
            color: $black;
            font-family: $primaryFont;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: .6px;
            text-transform: uppercase;
            white-space: nowrap;

            &:after {
                content: '';
                display: inline-block;
                width: 2px;
                height: 10px;
                background: rgba($black, 0.4);
                margin: 0 12px;
            }

            &:last-of-type {
                &:after {
                    display: none;
                }
            }

            &.actif {
                font-weight: 600;
            }
        }
    }

    .switch_gdt_tables {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;

        .thumbslide {
            order: 1;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            opacity: 0;

            table {
                width: 100%;
                overflow: hidden;
                border: 1px solid $black;
                border-bottom: none;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    height: 1000vh;
                    width: 1px;
                    top: 0;
                    left: 0px;
                    background-color: $black;
                    z-index: 2;
                }

                &:after {
                    content: '';
                    position: absolute;
                    height: 1px;
                    width: 100%;
                    top: -1px;
                    left: 0;
                    background-color: $black;
                    z-index: 2;
                }

                tbody {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        height: 1000vh;
                        width: 1px;
                        top: 0;
                        right: -1px;
                        background-color: $black;
                        z-index: 2;
                    }
                }

                tr {
                    th {
                        font-family: $secondaryFont;
                        font-size: 18px;
                        font-style: italic;
                        font-weight: 400;
                        color: $white;
                        background-color: $black;
                        height: 50px;
                        line-height: 48px;
                        text-align: center;
                        width: calc(100% / 4);
                        position: relative;
                        z-index: 3;

                        &:after {
                            content: '';
                            position: absolute;
                            height: 1000vh;
                            height: 1000dvh;
                            width: 1px;
                            top: 0;
                            right: 0;
                            background-color: $black;
                            z-index: 2;
                        }

                        &:last-of-type {
                            &:after {
                                display: none;
                            }
                        }
                    }

                    td {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 50px;
                        height: 50px;
                        color: $black;
                        text-align: center;
                        transition: background 0.35s ease-in-out;
                        position: relative;
                        cursor: url(../svg/cursor_gdt.svg), auto;
                        border: 1px solid $black;

                        span {
                            position: relative;
                            z-index: 3;
                            background: transparent;
                            position: absolute;
                            display: inline-block;
                            height: 50px;
                            width: 100%;
                            top: 0;
                            left: 0;
                            border-bottom: 1px solid $black;
                        }

                        &:hover {
                            background-color: $beige-clair;
                            font-weight: 700;

                            span {
                                &:before {
                                    content:'';
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    border-left: 1px solid $black;
                                    border-right: 1px solid $black;
                                    position: absolute;
                                    top: 0;
                                    left: -1px;
                                    background-color: transparent;
                                }
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                height: 50px;
                                width: 1280px;
                                top: 0;
                                right: 0;
                                background-color: $beige-clair;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                height: 1000vh;
                                width: 100%;
                                bottom: 0;
                                right: 0;
                                background-color: $beige-clair;
                            }
                        }
                    }
                }
            }

            .content_tog_wrapper {
                display: flex;
                justify-content: center;

                .content_tog_content {
                    width: 40%;

                    .guide_tog_question {
                        margin-bottom: 1rem;

                        strong {
                            font-weight: 600;
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                        }
                    }

                    .guide_tog_reponse {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                    }
                }

                .content_tog_img {
                    width: 58%;
                    max-width: 595px;
                    margin-left: 2%;

                    img {
                        width: 100%;
                        max-width: 595px;
                        height: auto;
                    }
                }
            }

            &.actif {
                order: 5;
                opacity: 1;
            }
        }
    }

    @media only screen and (max-width: 1360px) {
        padding: 0 6.25vw;
    }
}

/* Magazine */
body.magazines {
    .satellite_title {
        font-family: $secondaryFont;
        font-size: 35px;
        font-style: italic;
        font-weight: 400;
        line-height: 1;
        text-align: center;
        margin-bottom: 32px;
    }

    .bloc_magazine {
        .magazine_visu {
            img {
                width: 100%;
                height: auto;
            }
        }

        .magazine_content {
            width: 100%;
            max-width: 820px;
            margin: auto;

            .magazine_title {
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                font-weight: 400;
                line-height: 35px;
                margin-bottom: 1rem;

                &.center {
                    text-align: center;
                }
            }

            .magazine_text {
                font-family: $primaryFont;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-bottom: 20px;

                &.center {
                    text-align: center;
                }

                p {
                    margin-bottom: 15px;
                }
            }

            .magazine_cta {
                text-align: center;
                font-family: $primaryFont;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                a {
                    border: none;
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }

        &.bloc_magazine_01 {
            margin: 0 auto 70px;
            padding: 0 6.25vw;

            .magazine_visu {
                width: 100%;
                margin-bottom: 40px;
                display: flex;

                .cover {
                    width: 38.392%;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                iframe {
                    width: 61.608%;
                    height: auto;
                }
            }
        }

        &.bloc_magazine_02,
        &.bloc_magazine_04 {
            display: flex;
            justify-content: space-between;
            margin: 0 auto 40px;

            .magazine_content {
                width: 24.7916%;
                margin-right: 5px;

                .magazine_title {
                    padding: 0 16%;
                }

                .magazine_text {
                    padding: 0 13.235%;
                }
            }

            .magazine_visu {
                width: calc(75.2084% - 5px);
                display: flex;
                justify-content: space-between;

                .visu_01 {
                    width: 32.0781%;
                    margin-right: 5px;
                }

                .visu_02 {
                    width: 67.921%;
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;
                justify-content: center;
                flex-direction: column-reverse;
                padding: 0 6.25%;

                .magazine_content {
                    width: 100%;
                    margin: 0 auto 70px;

                    .magazine_text {
                        padding: 0;
                    }
                }

                .magazine_visu {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }

        &.bloc_magazine_03,
        &.bloc_magazine_05 {
            display: flex;
            justify-content: space-between;
            margin: 0 auto 40px;

            .magazine_content {
                width: 24.7916%;
                margin-left: 5px;

                .magazine_title {
                    padding: 0 16%;
                }

                .magazine_text {
                    padding: 0 13.235%;
                }
            }

            .magazine_visu {
                width: calc(75.2084% - 5px);
                display: flex;
                justify-content: space-between;

                .visu_01 {
                    width: 32.0781%;
                    margin-right: 5px;
                }

                .visu_02 {
                    width: 67.921%;
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;
                justify-content: center;
                padding: 0 6.25%;

                .magazine_content {
                    width: 100%;
                    margin: 0 auto 70px;

                    .magazine_text {
                        padding: 0;
                    }
                }

                .magazine_visu {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
}

/* Location */
body.location {
    .page_satellite {
        .satellite_content {
            .slider_location {
                width: 100%;
                position: relative;

                #breadcrumbs {
                    position: absolute;
                    top: 93px;
                    z-index: 3;

                    .breadcrumb,
                    .breadcrumb a,
                    .breadcrumb:after {
                        color: $white;
                    }
                }

                .slider_location_container {
                    margin: 0 auto 63px;

                    .swiper-wrapper {
                        .swiper-slide {
                            img {
                                width: 100%;
                                height: auto;
                                display: block;
                            }
                        }
                    }
                }

                .slider_location_content_wrapper {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    height: 100%;
                    width: 100%;
                    z-index: 2;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-wrap: wrap;

                    .slider_location_content_title {
                        font-family: $secondaryFont;
                        font-size: 35px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 1;
                        color: $white;
                        margin-bottom: 17px;
                        width: 100%;
                    }

                    .slider_location_cta_wrapper {
                        display: flex;
                        justify-content: center;
                        align-content: flex-end;
                        width: 100%;

                        a {
                            display: inline-block;
                            font-family: $primaryFont;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                            color: $white;
                            margin-right: 30px;
                            border-bottom: 1px solid $white;
                            
                            &:last-of-type {
                                margin-right: 0;
                            }

                            h2 {
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.6px; 
                            }
                        }
                    }
                }
            }

            .etapier_4_blocs {
                margin: 0 auto 63px;
                padding: 0 6.25vw;

                .title {
                    font-family: $secondaryFont;
                    font-size: 35px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 1;
                    margin-bottom: 27px;
                    text-align: center;
                }

                .bloc_4_wrapper {
                    display: flex;
                    justify-content: center;

                    .bloc {
                        padding: 38px 36px 30px;
                        text-align: center;
                        background: $beige-clair;
                        margin-right: 13px;
                        width: 25%;
                        max-width: 310px;
                        box-sizing: border-box;

                        .title_bloc {
                            font-family: $primaryFont;
                            font-size: 1rem;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 1;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                            margin-bottom: 1rem;

                            span {
                                padding-right: 5px;
                            }
                        }

                        .text_bloc {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                        }

                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                }
            }

            .tdg_nos_offre {
                margin: 0 auto 70px;

                .title_tdg {
                    font-family: $secondaryFont;
                    font-size: 35px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 1;
                    margin-bottom: 17px;
                    text-align: center;
                    position: relative;

                    .swiper_wrapper_buttons {
                        position: absolute;
                        right: 11px;
                        width: 72px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        top: 0;
                        bottom: 0;
                        margin: auto;

                        .tete_de_gondole-button-prev,
                        .tete_de_gondole-button-next {
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;

                            &.swiper-button-lock {
                                display: none;
                            }

                            &.disabled {
                                opacity: 0.5;
                                display: block;
                            }
                        }

                        .tete_de_gondole-button-next {
                            transform: rotate(180deg);
                        }
                    }
                }

                .tdg_nos_offre_slider_wrapper {
                    .tdg_nos_offre_slider_container {
                        padding-bottom: 27px;

                        .swiper-wrapper {
                            display: flex;

                            .swiper-slide {
                                width: calc(100% / 3.9);
                                margin-right: 5px;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: auto;
                                    display: block;
                                }

                                .offre_info {
                                    background-color: $white;
                                    padding: 30px 20px;
                                    position: absolute;
                                    bottom: 21px;
                                    left: 0;
                                    right: 0;
                                    margin: auto;
                                    width: 91.38%;
                                    display: flex;
                                    justify-content: space-between;
                                    align-content: center;
                                    flex-wrap: wrap;

                                    .titre_n_prix {
                                        .titre {
                                            font-family: $primaryFont;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 21px;
                                            letter-spacing: 0.7px;
                                            text-transform: uppercase;
                                        }

                                        .prix_offre {
                                            font-family: $primaryFont;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: normal;

                                            strong {
                                                font-weight: 700;
                                            }
                                        }
                                    }

                                    .cta_offre {
                                        height: 15px;
                                        margin: auto 0;
                                        .cta {
                                            font-family: $primaryFont;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;
                                            letter-spacing: 0.6px;
                                            text-transform: uppercase;
                                            display: inline-block;
                                            border-bottom: 1px solid $black;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .swiper-pagination {
                        top: inherit;
                        bottom: 5px;
                        background-color: $white;
                        height: 3px;
                        width: calc(100% - 10px);
                        left: 0;
                        right: 0;
                        margin: auto;
                        opacity: 1;
                        border-radius: 20px;

                        .swiper-pagination-progressbar-fill {
                            background-color: $black;
                            border-radius: 20px;
                            cursor: pointer;
                        }

                        .swiper-scrollbar-drag {
                            background-color: $black;
                            cursor: pointer;
                        }
                    }
                }
            }

            .bloc_image_texte_wrapper,
            .bloc_texte_image_wrapper {
                display: flex;
                justify-content: center;
                width: 100%;
                max-width: 951px;
                margin: 0 auto 63px;

                .bloc_text_container_wrapper {
                    width: 50%;
                    text-align: center;
                    display: flex;
                    align-content: center;
                    flex-wrap: wrap;

                    .bloc_text_container {
                        padding: 0 13.15%;
                        box-sizing: border-box;

                        .title {
                            font-family: $secondaryFont;
                            font-size: 35px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: 1;
                            margin-bottom: 17px;
                        }

                        .txt {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            margin-bottom: 20px;

                            p {
                                margin-bottom: 1rem;
                            }
                        }

                        .bloc_text_cta {
                            .cta {
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                                color: $black;
                                border-bottom: 1px solid $black;
                            }
                        }
                    }
                }
            }

            .location_faq_wrapper {
                margin: 0 auto 63px;

                .title {
                    text-align: center;
                    font-family: $secondaryFont;
                    font-size: 35px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 1;
                    margin-bottom: 24px;
                }

                .bloc_faq_location {
                    width: 100%;
                    max-width: 1280px;
                    margin: 0 auto 20px;
                    border-bottom: 2px solid $lightgrey;

                    .mes_questions {
                        color: $black;
                        font-family: $primaryFont;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 50px;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        cursor: pointer;
                        border-top: 2px solid $lightgrey;
                        position: relative;

                        &:after {
                            content: '';
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            width: 10px;
                            height: 12px;
                            transform: rotate(-90deg);
                            display: inline-block;
                            position: absolute;
                            top: 19px;
                            right: 0;
                            transition: all 0.35s ease-in-out;
                        }

                        &.active {
                            padding-bottom: 22px;

                            &:after {
                                transform: rotate(-270deg);
                            }
                        }
                    }

                    .mes_reponses {
                        display: none;
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        text-transform: none;
                        letter-spacing: 0;

                        ul {
                            margin-bottom: 22px;

                            li {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                padding-left: 20px;
                                margin-bottom: 6px;
                                letter-spacing: 0;

                                strong {
                                    font-weight: 600;
                                }

                                &:before {
                                    content: '';
                                    width: 3px;
                                    height: 3px;
                                    background: $black;
                                    position: relative;
                                    left: -8px;
                                    top: -3px;
                                    border-radius: 100%;
                                    display: inline-block;
                                }
                            }
                        }

                        p {
                            margin-bottom: 5px;
                            font-family: $primaryFont;

                            strong {
                                font-weight: 500;
                            }
                        }
                    }
                }

                .bloc_text_cta {
                    text-align: center;
                    margin-top: 20px;

                    .cta {
                        font-family: $primaryFont;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        text-decoration: underline;
                        text-underline-position: under;
                    }
                }

                @media only screen and (max-width: 1360px) {
                    padding: 0 6.25vw;
                }
            }
        }
    }

    /* Sticky */
    .sticky_location_bar {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 80px;
        background: $beige-clair;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;
        padding: 0 6.25vw;
        box-sizing: border-box;

        .text_sticky {
            font-family: $primaryFont;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 1;
            text-align: left;
            width: 30%;
            display: flex;
            align-content: center;
            flex-wrap: wrap;
        }

        .sticky_buttons_wrapper {
            display: flex;
            justify-content: flex-end;
            width: 70%;

            .cta {
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 48px;
                letter-spacing: 0.04rem;
                text-transform: uppercase;
                width: 50%;
                max-width: 378px;
                text-align: center;
                color: $beige-clair;

                &:hover {
                    color: $black;
                    background: $beige-clair;
                }

                &.black {
                    margin-left: 2.577%;
                }

                &:not(.black) {
                    background: $beige-clair;
                    color: $black;

                    &:hover {
                        background: $black;
                        color: $beige-clair;
                    }
                }
            }
        }
    }

    &.bandeauDelivery {
        .page_satellite {
            .satellite_content {
                .slider_location {
                    #breadcrumbs {
                        top: 150px;
                    }
                }
            }
        }
    }
}

/* Parrainage */
body.parrainage_index {
    #site_global_wrap {
        .mon_compte_img_wrapper {
            &.not_connected {
                #bloc_no_parrainage {
                    .wrapper_parrainage {
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        width: 100%;
                        box-sizing: border-box;
                        padding: 0 6.25vw;

                        .parrainage_img {
                            width: 42.619%;
                            margin-right: 7.14285%;

                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }

                        .content_parrainage {
                            width: 50.238%;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            flex-wrap: wrap;
                            padding: 0;

                            .content_titre {
                                text-align: center;

                                h1 {
                                    font-family: $secondaryFont;
                                    font-size: 35px;
                                    font-style: italic;
                                    font-weight: 400;
                                    line-height: 1;
                                    margin-bottom: 22px;
                                }
                            }

                            .txt_parrainage {
                                display: block;
                                width: 568px;
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                margin-bottom: 30px;

                                a {
                                    text-decoration: underline;
                                }
                            }

                            .wrapper_offert {
                                .wrapper_offert_content {
                                    &.wrapper_offert_number_txt1 {
                                        display: none;
                                    }
                                }
                            }

                            .btn_parrainage_connect {
                                width: 100%;
                                margin: 0 auto;
                                display: flex;
                                justify-content: center;

                                .btn_mon_compte {
                                    height: 48px;
                                    border-radius: 50px;
                                    background-color: $black;
                                    border: 1px solid $black;
                                    color: $white;
                                    transition: all 0.35s ease-in-out;
                                    cursor: pointer;
                                    display: block;
                                    width: 100%;
                                    max-width: 415px;
                                    font-size: 13px;
                                    font-family: $primaryFont;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    letter-spacing: 0.04rem;
                                    text-transform: uppercase;
                                    line-height: 48px;
                                    text-align: center;
                                    transition: all 0.35s ease-in-out;

                                    &:hover,
                                    &:focus {
                                        background-color: $white;
                                        color: $black;
                                    }
                                }
                            }

                            .texte_bottom {
                                display: none;
                            }
                        }
                    }
                }
            }
             /* Connected */
            .wrapper_moncompte_main  {
                width: 100%;

                .content_parrainage {
                    .intro {
                        text-align: left;
                        width: 100%;
                        max-width: 1280px;
                        margin: 0 auto 12px;

                        .title {
                            display: block;
                            font-family: $secondaryFont;
                            font-size: 35px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: 1;
                            margin-bottom: 22px;
                            display: none;

                            & + span {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px;
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .moncompte_myinfos_img_holder {
                img {
                    display: none;
                }
            }

            .wrapper_moncompte {
                .top_container {
                    .nav_moncompte {
                        text-align: center;

                        .account_name {
                            font-family: $secondaryFont;
                            font-size: 35px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: 1;
                            margin-bottom: 32px;
                        }
                    }

                    #wrapper_moncompte {
                        width: 100%;
                        padding: 0 6.25vw;
                    }

                    .wrapper_menu_compte {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 40px;

                        div {
                            &:last-of-type {
                                &:after {
                                    display: none;
                                }
                            }

                            &.actif {
                                a {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }

            #parrainage_form {
                width: 100%;
                max-width: 1280px;
                margin: 0 auto 36px;
                box-sizing: border-box;

                .bloc_parrainage_form {
                    .w-form-line {
                        &.column {
                            display: flex;
                            justify-content: space-between;
                            flex-direction: row;
                            margin-bottom: 20px;

                            .w-input-container {
                                width: 32%;
                            }
                        }

                        &.w-submit {
                            .w-submit-button {
                                height: auto;
                                width: auto;
                                border-radius: 0;
                                background-color: inherit;
                                border: none;
                                color: $black;

                                span {
                                    color: $black;
                                    text-decoration: underline;
                                    text-underline-position: under;
                                    font-family: $primaryFont;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    letter-spacing: 0.6px;
                                    text-transform: uppercase;
                                }
                            }
                        }

                        input[name^="prenomfilleul"],
                        input[name^="nomfilleul"],
                        input[name^="mailfilleul"] {
                            & + .w-input-label {
                                span {
                                    position: relative;

                                    &:after {
                                        content: '*';
                                        width: 10px;
                                        height: auto;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }
                }

                .elem_parrainage {
                    .label_parrainage {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 1;
                        letter-spacing: 0.7px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .w-textarea {
                        .w-input-element {
                            height: auto;
                            padding: 0;
                            line-height: 18px;
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            margin-top: 1rem;
                            padding-bottom: 2px;
                        }

                        &.w-nonempty {
                            .w-input-label {
                                transform: translateY(-55%);
                            }
                        }
                    }

                    .w-submit {
                        max-width: 350px;
                    }
                }
            }

            .error_parrainage {
                margin: 0 auto 30px;
                width: 100%;
                max-width: 1280px;

                p {
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    margin-bottom: 22px;
                    color: $black;
                }

                &#parrainage_error {
                    p {
                        color: $red;
                    }
                }

                &#parrainage_error {
                    p {
                        color: $red;
                    }
                }

                &#parrainage_ok {
                    p {
                        color: $green;
                    }
                }
            }

            .wrapper_bons_achats {
                width: 100%;
                max-width: 1280px;
                margin: auto;

                .text_parrainage {
                    color: $black;
                    font-family: $primaryFont;
                }

                .title_intro {
                    color: $black;
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                    margin-bottom: 7px;
                }

                .intro {
                    margin:0 0 15px !important;
                    width: 100%;
                    max-width: 947px;
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                }

                #bloc_tableau_parrainage {
                    #no_filleul {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                    }

                    .table_mon_compte {
                        margin-top: 30px;

                        .parrain_header {
                            th {
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 28px;
                                color: $black;
                                height: 28px;

                                &:first-letter {
                                    text-transform: uppercase;
                                }

                                &:nth-child(1) {
                                    text-align: left;
                                    padding-left: 1.95%;
                                }
                            }
                        }

                        td {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 50px;
                            border: 1px solid $beige-clair;
                            color: $black;
                            text-align: center;

                            &:nth-child(1) {
                                text-align: left;
                                padding-left: 1.95%;
                            }

                            &.compte {
                                position: relative;

                                &:before {
                                    content: '';
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(32,8,8);
                                    width: 22px;
                                    height: 22px;
                                    display: inline-block;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    position: absolute;
                                    margin: auto;
                                }
                            }
                        }

                        tr {
                            opacity: 0.5;
                            transition: all ease-in-out 0.35s;

                            &:first-of-type {
                                opacity: 1;
                            }

                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Fidelite */
body.programme_fidelite {
    #breadcrumbs {
        @media screen and(max-width:1024px) {
            padding: 0 2.25vw;
        }
    }
    .page_satellite {
        width: 100%;
        margin: 0 auto 70px;
        padding: 0 6.25vw;

        @media screen and(max-width:1024px) {
            padding: 0 1vw;
        }

        .satellite_content {
            display: flex;
            align-content: center;

            .content_left {
                width: 42.619%;
                margin-right: 80px;

                img {
                    width: 100%;
                    height: auto;
                }

                .img_moncompte {
                    display: none;
                }
            }

            .content_right {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 45.83333%;
                text-align: center;

                .title_fidelite {
                    font-family: $secondaryFont;
                    font-size: 35px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 1;
                    margin-bottom: 30px;
                    max-width: 550px;
                }

                .text_fidelite {
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    max-width: 550px;

                    p:not(:last-child) {
                        margin-bottom: 20px;
                    }

                    #joinFidelityProgramForm {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        margin: 0 auto;

                        .w-form-line {
                            .w-input-container {
                                width: auto;
                                margin: 0 auto;

                                .w-input.w-checkbox-input {
                                    .w-input-label {
                                        span {
                                            color: $black;
                                            font-family: $primaryFont;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 18px;
                                        }
            
                                        &:before {
                                            width: 26px;
                                            height: 14px;
                                            border: 0;
                                            border-radius: 48px;
                                            box-shadow: none;
                                            background-color: $lightgrey;
                                            position: relative;
                                            top: 0px;
                                        }
                
                                        &:after {
                                            content: '';
                                            position: absolute;
                                            width: 12px;
                                            height: 12px;
                                            border-radius: 50%;
                                            background-color: $white;
                                            top: 3px;
                                            left: 1px;
                                        }
                                    }

                                    .w-input-element {
                                        &:checked +.w-input-label {
                                            &:before {
                                                background-color: $black;
                                            }
                    
                                            &:after {
                                                left: 13px;
                                            }
                                        }
                                    }
                                }

                                .w-error {
                                    display: inline-block;
                                    color: $red;
                                    padding-top: 0.5rem;
                                }
                            }

                            &.w-submit {
                                max-width: 350px;
                            }
                        }
                    }
                }

                .bloc_fidelite {
                    background: $beige-clair;
                    padding: 37px 4%;
                    margin-top: 30px;

                    .fidelite_content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        &:not(:last-child) {
                            padding-bottom: 30px;
                            margin-bottom: 30px;
                            border-bottom: 2px solid $black;
                        }

                        .subtitle {
                            font-family: $secondaryFont;
                            font-size: 25px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: 1;
                            margin-bottom: 22px;
                        }

                        ul {
                            display: inline-flex;
                            flex-direction: column;

                            li {
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 23px;

                                span {
                                    display: inline-block;
                                    padding-left: 20px;

                                    &:before {
                                        content: '';
                                        width: 3px;
                                        height: 3px;
                                        background: $black;
                                        position: relative;
                                        left: -8px;
                                        top: -3px;
                                        border-radius: 100%;
                                        display: inline-block;
                                    }
                                }
                            }
                        }

                        .text {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            max-width: 550px;

                            p {
                                padding-bottom: 15px;

                                &:last-of-type {
                                    padding-bottom: 0;
                                }
                            }
                        }

                        .cta {
                            display: block;
                            margin: 30px auto 0;

                            .w-submit-button {
                                height: 48px;
                                line-height: 48px;
                                text-align: center;
                                width: 100%;
                                max-width: 350px;
                                display: block;
                                margin: 0 auto;
                                padding: 5px;

                                &:hover {
                                    background-color: $beige-clair;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Marque */
body.marque_index {
    .main_wrapper {
        #brandWrapper {
            .brand_title {
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                font-weight: 400;
                line-height: 35px;
                text-align: center;
                margin-bottom: 1.65rem;
            }

            h2.brand_title {
                margin-bottom: 2.25rem;
            }

            .brand_container_swiper {
                margin-bottom: 40px;

                .top_brand_img {
                    position: relative;
                    margin-right: 5px;
                    width: calc(100% / 5 - 1px);

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .top_brand_title {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;
                    font-family: $primaryFont;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 32.565px;
                    letter-spacing: 0.8px;
                    text-transform: uppercase;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .brand_bloc_container {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                max-width: 1275px;
                margin: 0 auto;

                @media only screen and (max-width: 1360px) {
                    padding: 0 3.25vw; 
                }

                .brand_bloc {
                    width: 14.28%;
                    margin-bottom: 40px;

                    .brand_letter_container {
                        .brand_letter{
                            font-family: $primaryFont;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: normal;
                            margin-bottom: 15px;
                        }
                    }

                    .brand_list {
                        .brand_list_item {
                            font-family: $primaryFont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 25px;
                        }
                    }
                }
            }
        }
    }
}

body.parrainage_index.en {
    .bloc_parrainage_form {
        input[name^="prenomfilleul"],
        input[name^="nomfilleul"],
        input[name^="mailfilleul"] {
            & + .w-input-label {
                span {
                    &:after {
                        display: none !important;
                    }
                }
            }
        }
    }
}