.cms-page-module-body {
    width: 100%;

    img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    div,
    p,
    span {
        box-sizing: border-box;
    }

    .cms-page-module-title {
        font-family: $secondaryFont;
        font-size: 35px;
        font-style: italic;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 17px;
        width: 100%;
    }

    .cms-page-module-text {
        margin-bottom: 22px;
        font-family: $primaryFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;

        u,a {
            text-underline-offset: 2px;
        }

        a {
            text-decoration: underline;
        }
    }

    .cms-page-module-sutitle {
        font-family: $primaryFont;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 1rem;
        width: 100%;
    }

    .cms-page-module-cta {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.6px;
        font-family: $primaryFont;
        text-decoration: underline;
        text-underline-position: under;
        width: 100%;

        a,
        span {
            text-decoration: underline;
            text-underline-position: under;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
        
        &:hover {
            text-decoration: none;
        }
    }

    &:not(.look-module) .cms-page-module-txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        display: grid;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        padding: 6rem;

        &.align-left {
            justify-content: flex-start;

            &.align-top,
            &.align-bottom {
                transform: translateX(-50%);
            }
        }

        &.align-right {
            justify-content: flex-end;
            &.align-top,
            &.align-bottom {
                transform: translateX(-50%);
            }
        }

        &.align-top {
            align-items: flex-start;
            top: 0;

            &.align-center {
                transform: translateX(-50%);
            }
        }
        
        &.align-bottom {
            align-items: flex-end;
            top: unset;
            bottom: 0;

            &.align-center {
                transform: translateX(-50%);
            }
        }

        .cms-page-module-title,
        .cms-page-module-cta,
        .cms-page-module-text {
            max-width: 350px;
        }
    }

    .cms-page-column-txt {
        position: relative;
    }

    .slide_video {
        display: block;
        position: relative;
        background-color: $black;
        padding-bottom: calc((920 / 1920) * 100%);
        width: 100%;
         height: 0;

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background: $black;
            width: 100%;
            height: 100%;
        }

        .block_redirect {
            display: block;
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }

    .imgWrapper {
        display: block;

        span {
            display: block;
        }
    }

    .swiper-container {
        .swiper-slide {
            position: relative;

            .imgWrapper {
                display: block;
                position: relative;
                cursor: pointer;

                &:before {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 2;
                    margin: auto;
                    width: 100%;
                    height: 100%;
                    content: '';
                }
            }
        }
    }

    .cms-page-module-image,
    .cms-page-column:not(.cms-page-column-txt) {
        position: relative;

        .tag {
            position: absolute;
            text-decoration: none;

            .tag_button {
                position: relative;
                z-index: 2;
                cursor: pointer;
                border-radius: 100%;
                background: $white;
                width: 13px;
                height: 13px;
                color: $white;

                &:after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                    animation-name: pulse;
                    border: 1px solid $white;
                    border-radius: 50%;
                    background-color: rgba($white, .1);
                    width: 15px;
                    height: 15px;
                    content:'';
                }

                & + .item_product,
                & + .item.gondole {
                    position: fixed;
                    top: 0;
                    right: 0;
                    transform: translateX(100%);
                    z-index: 52;
                    transition: transform ease-in-out 0.5s;
                    cursor: default;
                    box-sizing: border-box;
                    background: $white;
                    padding: 24px 0 110px;
                    width: 380px;
                    height: 100vh;

                    .close_look_lightbox {
                        display: block !important;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        z-index: 10;
                        cursor: pointer;
                        background-image: url(../svg/spritesheet.svg);
                        width: 11px;
                        height: 11px;
                        font-size: 0;
                        @include bgIconCoord(30, 8, 8);
                    }

                    .bloc_look_wrapper {
                        width: 100%;
                        max-height: calc(100vh - 134px);
                        max-height: calc(100dvh - 134px);
                        overflow-y: auto;
                    }

                    .wrap_rolloverproduit {
                        .ligne_form.productColorFieldset {
                            .choices_list {
                                padding-left: 2px;

                                .form_itm {
                                    width: auto;
                                }
                            }
                        }
                    }

                    .productVisualMulti {
                        margin-bottom: 1rem;

                        .swiper_container_look_buttons {
                            opacity: 1;

                            .swiper-button-next,
                            .swiper-button-prev {
                                top: 0;
                                right: auto;
                                left: 0;
                                cursor: pointer;
                                margin: auto;
                                background-color: $white;
                                width: 26px;
                                height: 100%;
                            }

                            .swiper-button-next {
                                right: 0;
                                left: auto;
                                transform: rotate(180deg);
                            }
                        }

                        .block_lnk {
                            margin-bottom: 0;
                        }
                    }

                    .swiper_container_look {
                        position: relative;
                        padding: 0 26px;
                    }

                    .brand_n_price_wrapper {
                        padding: 0 26px;

                        .brand_and_description {
                            width: 100%;
                        }

                        .item_price {
                            display: flex;
                            position: absolute;
                            right: 26px;
                            flex-direction: column;
                            margin-top: 1rem;
                            text-align: right;

                            .pricetag {
                                & + .pricetag {
                                    margin-right: 0;
                                }
                            }
                        }
                    }

                    .wishlist_title_wrapper {
                        display: flex;
                        flex-wrap: wrap;
                        align-content: center;
                        justify-content: space-between;
                        margin-bottom: 12px;

                        .wishlist_brand_wrapper {
                            display: flex;
                            align-content: center;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            width: 100%;

                            .brand {
                                width: calc(100% - 30px);
                                min-height: 22px;
                            }
                        }

                        .item_title_wrapper {
                            width: calc(100% - 85px);

                            .container_marques {
                                margin-bottom: 15px;
                            }

                            .item_title,
                            .item_subtitle {
                                font-style: normal;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 25px;
                                font-family: $primaryFont;
                            }

                            .item_subtitle {
                                font-weight: 400;
                            }
                        }

                        .item_price {
                            margin-top: 30px;
                            width: 100px;
                        }

                        .wrapper_description {
                            margin-bottom: 12px;
                            padding: 0;
                        }

                        .description_produit {
                            display: block !important;
                            order: 5;
                            border-bottom: 1px solid $lightgrey;

                            .desc_pannel {
                                .toggleCarac {
                                    position: relative;
                                    cursor: pointer;
                                    border-top: 1px solid $lightgrey;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 45px;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.6px;
                                    text-transform: uppercase;

                                    .ico {
                                        img {
                                            display: inline-block;
                                            position: relative;
                                            top: 8px;
                                            margin-right: 8px;
                                            width: auto;
                                            height: 26px;
                                        }
                                    }

                                    &:after {
                                        background-image: url(../svg/spritesheet.svg);
                                        content: '';
                                        @include bgIconCoord(29,8,8);
                                        display: inline-block;
                                        position: absolute;
                                        top: 12px;
                                        right: 0;
                                        transform: rotate(-90deg);
                                        transition: all 0.35s ease-in-out;
                                        width: 1rem;
                                        height: 1rem;
                                    }

                                    & + .content {
                                        display: none;
                                        transition: max-height 0.5s linear;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-family: $primaryFont;

                                        .product_ref {
                                            margin-bottom: 7px;
                                            color: $grey;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 1;
                                            font-family: $primaryFont;
                                        }
                                    }


                                    &.actif {
                                        border-bottom: 1px solid $white;

                                        & + .content {
                                            padding-bottom: 20px;
                                        }

                                        &:after {
                                            transform: rotate(90deg);
                                        }
                                    }

                                    &.toggleLocation {
                                        display: flex;
                                        position: relative;
                                        align-items: center;

                                        &:before {
                                            background-image: url(../svg/spritesheet.svg);
                                            width: 26px;
                                            height: 26px;
                                            content: '';
                                            @include bgIconCoord(19,8,8);
                                            display: block;
                                            margin-right: 0.7rem;
                                        }
                                    }
                                }
                            }

                            &.cache {
                                display: block;
                                width: 100%;
                            }
                        }

                        .wrap_rolloverproduit {
                            form {
                                div[id^=bloc_add_alert_] {
                                    margin: 0 auto 15px;

                                    .alert_stock {
                                        text-align: left;
                                    }
                                }
                            }
                        }

                        .title_site {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 22px;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;
                        }

                        .addToWishlistButtonContainer {
                            .btnWishlist {
                                background-image: url(../svg/spritesheet.svg);
                                width: 22px;
                                height: 22px;
                                @include bgIconCoord(20,8,8);
                            }
                        }

                        .wrap_rolloverproduit {
                            position: inherit;
                            bottom: inherit;
                            opacity: 1;
                            z-index: initial;
                            padding: 0;
                            width: 100%;

                            form {
                                position: inherit;

                                .ligne_form {
                                    &.productColorFieldset .choices_list,
                                    &.productSizeFieldset .choices_list {
                                        justify-content: flex-start;
                                    }

                                    .os-content {
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: flex-start;
                                    }

                                    &.productColorFieldset {
                                        .choices_list {
                                            margin-bottom: 0;
                                            padding-top: 2px;
                                            height: auto;
                                            max-height: 150px;
                                            overflow: auto;

                                            .form_itm {
                                                display: flex;
                                                position: relative;
                                                align-content: center;
                                                margin: 0 30px 20px 0;

                                                input[type=radio] {
                                                    display: block;
                                                    position: absolute;
                                                    left: 0;
                                                    opacity: 0;
                                                    z-index: 0;
                                                    cursor: pointer;
                                                    width: 100%;
                                                }

                                                &:after {
                                                    display: block;
                                                    position: relative;
                                                    margin-left: 8px;
                                                    width: auto;
                                                    height: 20px;
                                                    content: attr(data-color);
                                                    color: $grey;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    font-size: 13px;
                                                    line-height: normal;
                                                    line-height: 20px;
                                                    font-family: $primaryFont;
                                                    text-transform: capitalize;

                                                    &:firt-letter {
                                                        text-transform: uppercase;
                                                    }
                                                }

                                                &.check {
                                                    &:after {
                                                        color: $black;
                                                    }
                                                }
                                            }

                                            .remaining_colors {
                                                display: none;
                                            }
                                        }
                                    }
                                }

                                .expressAlertStock {
                                    position: relative;
                                    margin-top: 15px;
                                    width: 100%;

                                    .bloc_add_alert_form {
                                        .w-form-line {
                                            .form_submit {
                                                top: 0;
                                            }
                                        }
                                    }

                                    .alert_return {
                                        position: absolute;
                                        top: 0;
                                        right: 0;
                                        z-index: 10;
                                        cursor: pointer;
                                        background-image: url(../svg/spritesheet.svg);
                                        width: 12px;
                                        height: 12px;
                                        font-size: 0;
                                        @include bgIconCoord(30, 8, 8);
                                    }
                                }

                                .achat_express {
                                    position: relative;
                                    top: -22px;

                                    .ligne_form {
                                        &.productSizeFieldset {
                                            .no_size_selected {
                                                display: block !important;
                                                position: absolute;
                                                bottom: -18px;
                                                left: 0;
                                                opacity: 0;
                                                z-index: -1;
                                                transition: opacity ease-in-out 0.5s;
                                                color: $red;
                                                font-size: 13px;

                                                &.show {
                                                    opacity: 1;
                                                    z-index: 1;
                                                }
                                            }
                                        }
                                    }
                                }

                                .noSizeAvailable {
                                    top: 2.5rem;
                                    height: 3rem;
                                }
                            }

                            .wishlistBtn {
                                position: relative;
                                top: -70px;
                                left: inherit;
                                opacity: 1;
                                z-index: 1;
                                height: 22px;
                            }

                            .disabled_whislist {
                                position: absolute;
                                top: -70px;
                                right: 0;
                                left: inherit;
                                z-index: 3;
                                background-color: transparent;
                                width: 22px;
                                height: 22px;
                            }

                            &.size_selected {
                                .productColorFieldset {
                                    height: auto;
                                }
                            }
                        }
                    }

                    .bloc_add_color {
                        display: block;
                        position: absolute;
                        bottom: 50px;
                        opacity: 1;
                        box-sizing: border-box;
                        padding: 0 25px;
                        width: 100%;
                        height: auto;

                        &.inactiv {
                            .w-submit-button {
                                opacity: 0.5;
                                pointer-events: none;
                            }
                        }

                        &.loading {
                            .loader {
                                opacity: 1;
                                z-index: 1;
                            }
                        }

                        &:before {
                            display: none;
                        }

                        .w-submit-button {    
                            opacity: 1;
                            transition: all .35s ease-in-out;
                            cursor: pointer;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-image: inherit;
                            background-color: $black;
                            width: 100%;
                            height: 48px;
                            color: $white;

                            span {
                                color: $white;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.04rem;
                                text-transform: uppercase;
                            }

                            & + .loader {
                                position: absolute;
                                right: 0;
                                left: 0;
                                margin: auto;
                                width: calc(100% - 52px);
                            }
                        }
                    }

                    .eclat_rond {
                        top: 30px;
                        right: 32px;
                    }

                    .description_produit_2 {
                        order: 4;
                        box-sizing: border-box;
                        margin-top: 10px;

                        .desc_pannel {
                            border-bottom: 1px solid $lightgrey;

                            .toggleCarac {
                                position: relative;
                                cursor: pointer;
                                border-top: 1px solid $lightgrey;
                                color: $black;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 45px;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-align: left;
                                text-transform: uppercase;

                                .ico {
                                    img {
                                        display: inline-block;
                                        position: relative;
                                        top: 8px;
                                        margin-right: 8px;
                                        width: auto;
                                        height: 26px;
                                    }
                                }

                                &:after {
                                    background-image: url(../svg/spritesheet.svg);
                                    content: '';
                                    @include bgIconCoord(29,8,8);
                                    display: inline-block;
                                    position: absolute;
                                    top: 12px;
                                    right: 0;
                                    transform: rotate(-90deg);
                                    transition: all 0.35s ease-in-out;
                                    width: 1rem;
                                    height: 1rem;
                                }

                                & + .content {
                                    display: none;
                                    transition: max-height 0.5s linear;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;

                                    .product_ref {
                                        color: $grey;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 1;
                                        font-family: $primaryFont;
                                    }
                                }


                                &.actif {
                                    border-bottom: 1px solid $white;

                                    & + .content {
                                        padding-bottom: 20px;
                                    }

                                    &:after {
                                        transform: rotate(90deg);
                                    }
                                }

                                &.toggleLocation {
                                    display: flex;
                                    position: relative;
                                    align-items: center;

                                    &:before {
                                        background-image: url(../svg/spritesheet.svg);
                                        width: 26px;
                                        height: 26px;
                                        content: '';
                                        @include bgIconCoord(19,8,8);
                                        display: block;
                                        margin-right: 0.7rem;
                                    }
                                }
                            }

                            .content {
                                padding-right: 10px;
                                padding-bottom: 10px;
                                max-height: 8vh;
                                max-height: 8dvh;
                                overflow-y: auto;
                            }
                        }

                        &.cache {
                            display: block;
                            width: 100%;
                        }
                    }

                    &.actif {
                        display: block;
                        transform: translateX(0%);
                    }
                }

                &.actif {
                    & + .item_product {
                        display: block;
                        transform: translateX(0%);
                    }
                }
            }

            &.leftPos .tooltip-module {
                left: 100%;
            }

            &.rightPos .tooltip-module {
                right: 100%;
            }

            &.topPos .tooltip-module {
                top: 100%;
            }

            &.bottomPos .tooltip-module {
                bottom: 100%;
            }
        }

        .imgWrapper {
            position: relative;
        }
    }

    /* MODULE 1– SLIDER COVER MODULE */
    &.slider-cover-module {
        position: relative;
        margin: 0 auto 5px;
        width: 100%;

        .swiper-container {
            .swiper-slide {
                position: relative;

                img {
                    display: block;
                    width: 100%;
                    height: auto;
                }

                .slide_video {
                    display: block;
                    position: relative;
                    background-color: $primaryColor;
                    padding-bottom: calc((920 / 1920) * 100%);
                    width: 100%;
                    height: 0;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .pagination-cover-home {
                bottom: 22px;

                .swiper-pagination-bullet {
                    opacity: 0.5;
                    margin: 0 7.5px;
                    background-color: $white;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
        }

        .pagination-cover-cms {
            bottom: 22px;

            .swiper-pagination-bullet {
                opacity: 0.5;
                margin: 0 7.5px;
                background-color: $white;

                &.swiper-pagination-bullet-active {
                    opacity: 1;
                }
            }
        }
    }

    /* MODULE 2– IMAGE TEXTE */
    &.text-image-1 {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            padding: 0 6.25vw;

            .cms-page-column {
                width: 50%;

                &.cms-page-column-txt {
                    .cms-page-module-txt {
                        padding: 0 28.57%;

                        @media only screen and (max-width: 1450px) {
                            padding: 0 18%;
                        }

                        @media only screen and (max-width: 1160px) {
                            padding: 0 8%;
                        }

                        .cms-page-module-title {
                            margin-bottom: 15px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1rem;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 3 – TEXTE IMAGE */
    &.image-text-1 {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            padding: 0 6.25vw;

            .cms-page-column {
                width: 50%;

                &.cms-page-column-txt {
                    .cms-page-module-txt {
                        padding: 0 28.57%;

                        @media only screen and (max-width: 1450px) {
                            padding: 0 18%;
                        }

                        @media only screen and (max-width: 1160px) {
                            padding: 0 8%;
                        }

                        .cms-page-module-title {
                            margin-bottom: 15px;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1rem;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.8px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 4 – IMAGE */
    &.cover {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            padding: 0 6.25vw;

            .cms-page-column {
                .imgWrapper {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }

    /* MODULE 5 – BLOC SEO */
    &.text-module {
        margin: 0 auto 44px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 25px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 20px;
        }

        .cms-page-columns-container {
            padding: 24px 6.25vw;

            .cms-page-column {
                text-align: center;

                .cms-page-module-title {
                    margin-bottom: 27px;

                    @media only screen and (max-width: 1450px) {
                        margin-bottom: 22px;
                    }
                }

                .cms-page-module-sutitle {
                    margin-bottom: 27px;

                    @media only screen and (max-width: 1450px) {
                        margin-bottom: 22px;
                    }
                }

                .cms-page-module-text {
                    margin: auto;
                    width: 100%;
                    max-width: 980px;
                    line-height: normal;
                }

                .cms-page-module-cta {
                    margin-top: 27px;
                    text-align: center;
                }
            }
        }
    }

    /* MODULE 6 –3 IMAGES */
    &.three-images,
    &.image-3 {
        margin: 0 auto 50px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            .cms-page-module-title {
                margin: 0 auto 17px;
                text-align: center;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 1;
                font-family: $primaryFont;
                letter-spacing: .8px;
                text-transform: uppercase;
            }

            .swiper_wrapper {
                display: flex;
                justify-content: space-between;
            }

            .cms-page-column {
                position: relative;
                margin-right: 6px;
                width: 33.125%;

                .slide_video {
                    display: block;
                    position: relative;
                    background-color: $primaryColor;
                    padding-bottom: calc((760 / 636) * 100%);
                    width: 100%;
                    height: 0;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }

            &.blocs {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    /*  MODULE 7 –2 IMAGES */
    &.push-2 {
        margin: 0 auto 63px;
        text-align: center;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            .cms-page-module-title {
                margin: 0 auto 17px;
            }

            &.blocs {
                display: flex;
                justify-content: space-between;

                .cms-page-column {
                    position: relative;
                    width: 49.84375%;

                    .imgWrapper {
                        display: block;
                        position: relative;
                        cursor: pointer;

                        &:before {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 2;
                            margin: auto;
                            width: 100%;
                            height: 100%;
                            content: '';
                        }
                    }

                    .cms-page-module-txt {
                        position: absolute;
                        z-index: 1;
                        padding: 10%;
                    }

                    .slide_video {
                        display: block;
                        position: relative;
                        background-color: $black;
                        padding-bottom: calc((900 / 957) * 100%);
                        width: 100%;
                        height: 0;

                        iframe {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 0;
                            background: $black;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 8 – 4 IMAGES */
    &.categories {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            align-content: center;
            justify-content: space-between;
            text-align: center;

            & > .cms-page-module-cta {
                display: flex;
                justify-content: center;
                transition: all 0.35s ease-in-out;
                cursor: pointer;
                margin: 40px auto 0;
                border: 1px solid rgba($black, 0.075);
                border-radius: 50px;
                width: 100%;
                max-width: 350px;
                height: 50px;
                line-height: 48px;
                text-align: center;
                text-decoration: none;
            
                a {
                    transition: all 0.35s ease-in-out;
                    cursor: pointer;
                    width: 350px;
                    line-height: 50px;
                    text-align: center;
                    text-decoration: none;
                }
            
                span {
                    text-decoration: none;
                }
            }

            .cms-page-column {
                position: relative;
                width: 24.8%;
                max-width: 476px;

                .cms-page-module-txt {
                    padding: 13.2%;
                }

                .slide_video {
                    display: block;
                    position: relative;
                    background-color: $black;
                    padding-bottom: calc((575 / 476) * 100%);
                    width: 100%;
                    height: 0;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .cms-page-column {
                    margin-bottom: 6px;
                    width: 49.7%;
                    max-width: inherit;
                }
            }
        }
    }

    /* MODULE 9 – TEXTE + IMAGE + IMAGE */
    &.text-image-4 {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            justify-content: space-between;

            .cms-page-column {
                width: 33.125%;

                .cms-page-module-txt {
                    padding: 13.2%;
                }

                .slide_video {
                    display: block;
                    position: relative;
                    background-color: $black;
                    padding-bottom: calc((760 / 636) * 100%);
                    width: 100%;
                    height: 0;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .cms-page-column {
                    width: 49.7%;

                    &.cms-page-column-txt {
                        margin-bottom: 20px;
                        width: 100%;

                        .cms-page-module-text {
                            padding: 0 9%;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 10 – SHOP THE LOOK */
    &.lookbook {
        margin: 0 auto 63px;
        width: 100%;
        max-width: 1024px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;

            .cms-page-column {
                &.cms-page-column-image {
                    width: 58%;
                }

                &.cms-page-column-txt {
                    width: 37.11%;

                    .cms-page-module-title {
                        text-align: center;
                    }

                    .product-tag {
                        .item_product {
                            position: relative;
                        }
                    }

                    .wishlist_brand_wrapper {
                        display: flex;
                        justify-content: space-between;

                        .wishlist_wrapper {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 11 – IMAGE 1/4 + IMAGE 2/4 + TEXTE */
    &.image-image-text {
        margin: 0 auto 63px;
        width: 100%;
        max-width: 100%;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            align-content: center;
            justify-content: space-between;
            align-items: stretch;

            .cms-page-column {
                &.cms-column-mini-img {
                    width: 23.958333%;

                    .slide_video {
                        padding-bottom: calc((575 / 460) * 100%);
                    }
                }

                &.cms-column-big-img {
                    width: 50.729%;

                    .slide_video {
                        padding-bottom: calc((575 / 974) * 100%);
                    }
                }

                &.cms-page-column-txt {
                    width: 24.791666%;

                    .cms-page-module-txt {
                        padding: 13.2%;
                    }
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .cms-page-column {
                    order: 2;

                    &.cms-column-mini-img {
                        width: 31.9814%;
                    }

                    &.cms-column-big-img {
                        width: 67.67%;
                    }

                    &.cms-page-column-txt {
                        order: 1;
                        margin-bottom: 20px;
                        width: 100%;

                        .cms-page-module-txt {
                            justify-content: center !important;
                            padding: 9%;
                            text-align: center !important;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 12 – MÉLI-MÉLO 7 IMAGES */
    &.multiple-bloc {
        margin: 0 auto 63px;
        width: 100%;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            position: relative;
            justify-content: space-between;
            box-sizing: border-box;

            .cms-page-column {
                &.column-1 {
                    margin-right: 5px;
                    width: 23.958%;
                }

                &.column-2 {
                    margin-right: 5px;
                    width: 23.958%;
                }

                &.column-3 {
                    margin-right: 5px;
                    width: 25.625%;
                }

                &.column-4 {
                    width: 25.625%;
                }

                .picture-container {
                    position: relative;
                    margin-bottom: 5px;
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .cms-page-column {
                    width: calc(50% - 2.5px) !important;

                    &.column-2,
                    &.column-4 {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    /* MODULE 13 – TÊTE DE GONDOLE */
    &.tg-module {
        position: relative;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }
        
        .cms-page-columns-container {
            &:first-of-type {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                z-index: 6;
                margin: auto;
                width: calc(100% - 120px);
            }

            .tg_button_wrapper {
                display: block;
                display: none;
                position: absolute;
                top: 0;
                right: 5px;
                width: 60px;
                height: 56px;

                @media only screen and (max-width: 1450px) {
                    display: block;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(29,8,8);
                    right: auto;
                    left: 0;
                    cursor: pointer;
                    width: 1rem;
                    height: 1rem;

                    &:after {
                        display: none;
                    }

                    &.swiper-button-lock {
                        display: none;
                    }

                    &.disabled {
                        display: block;
                        opacity: 0.5;
                    }
                }

                .swiper-button-next {
                    right: 0;
                    left: auto;
                    transform: rotate(180deg);
                }
            }

            .cms-page-column {
                display: flex;
                justify-content: space-between;
                padding: 0 25px;

                .title_wrapper {
                    display: flex;
                    justify-content: center;
                    width: calc(100% - 60px);

                    &.both_tg {
                        .cms-page-module-title {
                            &:first-of-type {
                                &:after {
                                    display: inline-block;
                                    position: relative;
                                    margin: 0 20px;
                                    background: $grey;
                                    width: 2px;
                                    height: 23px;
                                    content: '';
                                }
                            }
                        }
                    }
                }

                .cms-page-module-title {
                    position: relative;
                    cursor: pointer;
                    margin-bottom: 21px;
                    width: auto;
                    color: $grey !important;

                    &.selected {
                        color: $black !important;
                    }
                }
            }

            & + .cms-page-columns-container {
                padding: 0;

                .cms-page-module-tg {
                    display: none;

                    &.selected {
                        display: block;
                        padding: 56px 0 30px;

                        .tg_button_wrapper {
                            display: block;
                        }
                    }
                }

                .cms-page-module-tg {
                    .swiper-slide,
                    .item {
                        margin-right: 5px;
                        width: 19.79166%;
                    }

                    .swiper-pagination {
                        top: inherit;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        margin: auto;
                        border-radius: 20px;
                        background-color: $white;
                        width: calc(100% - 10px);
                        height: 5px;

                        .swiper-pagination-progressbar-fill {
                            border-radius: 20px;
                            background-color: $black;
                        }

                        .swiper-scrollbar-drag {
                            cursor: pointer;
                            border-radius: 20px;
                            background-color: $black;
                        }
                    }


                    &:hover {
                        .swiper-pagination {
                            opacity: 1;
                        }
                    }

                    &:not(.swiper-initialized) {
                        .tg_button_wrapper {
                            display: none;
                        }
                        
                        .swiper-wrapper {
                            justify-content: center;
                        }

                        .swiper-slide,
                        .item {
                            margin-right: 5px;
                            width: 19.79%;

                            @media only screen and (max-width: 1450px) {
                                width: calc(100% / 4 - 4px);
                            }

                            @media only screen and (max-width: 1160px) {
                                width: calc(100% / 3 - 4px);
                            }
                        }
                    }

                    @media only screen and (max-width: 1450px) {
                        padding-bottom: 30px;

                        .swiper-pagination {
                            display: block;
                            opacity: 1;
                            
                            .swiper-pagination-progressbar-fill {
                                border-radius: 20px;
                                background-color: $beige_Fonce;
                            }
                        }
                    }
                }
            }
        }
    }

    /*MODULE 14 – MÉLI -MÉLO 4 IMAGES*/
    &.four-images {
        margin: 0 auto 63px;
        width: 100%;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            justify-content: space-between;

            .cms-page-column {
                width: calc(50% - 3px);

                .picture {
                    position: relative;
                    margin-bottom: 6px;

                    .cms-page-module-txt {
                        position: absolute;
                        z-index: 5;
                    }
                }
            }
        }
    }

    /* MODULE 15 – IMAGE 1/4 + IMAGE 2/4 + TEXTE */
    &.text-3-images {
        margin: 0 auto 63px;
        width: 100%;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .cms-page-column {
                display: block;
                width: calc(100% / 3 - 3.5px);

                .picture {
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                .cms-page-module-txt {
                    padding: 13.2%;
                }
            }

            @media only screen and (max-width: 1160px) {
                .cms-page-column {
                    order: 2;
                    width: calc(50% - 2.5px);

                    &:nth-of-type(3) {
                        order: 1;
                        margin-bottom: 22px;
                        width: 100%;
                    }
                }
            }
        }
    }

    /* MODULE 16 –TEXTE + IMAGE */
    &.text-image  {
        margin: 0 auto 63px;
        width: 100%;
        max-width: 924px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: space-between;
            align-items: center;

            .cms-page-column {
                width: 51.5151%;

                .slide_video {
                    padding-bottom: calc((575 / 476) * 100%);
                }

                &.cms-page-column-txt {
                    width: 37.87878%;
                }
            }
        }

        @media only screen and (max-width: 1160px) {
            max-width: 906px;

            .cms-page-column {
                width: 52.5386%;

                & + .cms-page-column-txt {
                    width: 38.631346%;
                }
            }
        }
    }

    /* MODULE 17 – INSTAGRAM */
    &.ten-images {
        margin: 0 auto 63px;
        text-align: center;

        .cms-page-module-title {
            margin-bottom: 17px;
            text-align: center;
        }

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        #hashtag_module_frame {
            .swiper-wrapper {
                .swiper-slide {
                    margin-right: 5px;
                    width: 16.45%;
                    height: 16.45vw;
                    overflow: hidden;

                    &:nth-of-type(6) {
                        margin-right: 0;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &:before {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        transition: opacity 0.5s ease-in-out;
                        margin: auto;
                        background-color: rgba($black, 0.5);
                        width: 100%;
                        height: 100%;
                        content: '';
                    }

                    &:after {
                        background-image: url(../svg/spritesheet.svg);
                        content: '';
                        @include bgIconCoord(37,8,8);
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        transition: opacity 0.75s ease-in-out;
                        margin: auto;
                        width: 32px;
                        height: 32px;
                    }

                    &:hover {
                        &:before,
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 18 – NEWSLETTER */
    &.form-contact-module {
        height: 330px;

        .cms-page-columns-container {
            .cms-page-column {
                display: flex;
                position: relative;
                flex-wrap: wrap;
                align-content: center;
                justify-content: center;
                width: 100%;
                overflow: hidden;

                img {
                    width: auto;
                    max-width: inherit;
                    height: 100%;
                }

                .wrapperNewsletter {
                    position: absolute;
                    z-index: 5;
                    margin: auto;
                    padding: 0 6.25vw;
                    color: $white;

                    &.align-top {
                        top: 15%;
                    }

                    &.align-bottom {
                        bottom: 15%;
                    }

                    &.align-left {
                        left: 10%;
                        text-align: left;
                    }

                    &.align-right {
                        right: 10%;
                        text-align: right;
                    }

                    &.align-middle {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        height: 165px;
                    }

                    &.align-center {
                        right: 0;
                        left: 0;
                        margin: auto;
                        text-align: center;
                    }

                    .cms-page-module-title {
                        margin-bottom: 1rem;
                        width: 100%;
                        color: $white !important;
                        text-align: center;
                    }

                    .cms-page-module-txt {
                        width: 100%;
                    }

                    .newsletterForm {
                        width: 100%;

                        .w-newsletter-form {
                            margin: auto;
                            width: 100%;
                            max-width: 507px;

                            .w-email-input {
                                .w-input-element {
                                    border-bottom: 1px solid $white;
                                    border-radius: 0;
                                    height: 30px;
                                    color: $white;
                                }

                                .w-input-label {
                                    color: $white;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                }

                                input:-webkit-autofill,
                                input:-webkit-autofill:active,
                                input:-webkit-autofill:focus,
                                input:-webkit-autofill:hover,
                                input:-internal-autofill-selected {
                                    -webkit-background-clip: text !important;
                                    box-shadow: none !important;
                                    background-color: transparent !important;
                                    -webkit-text-fill-color: $white !important;
                                }
                            }

                            .w-submit {
                                width: 15px;
                                height: 30px;

                                .w-btn-wrapper {
                                    .w-submit-button {
                                        border: none !important;
                                        border-radius: 0 !important;
                                        background-image: url(../svg/spritesheet.svg);
                                        background-color: inherit !important;
                                        width: 15px;
                                        height: 15px !important;
                                        @include bgIconCoord(33,8,8);
                                        transform: rotate(180deg);

                                        span {
                                            font-size: 0 !important;
                                        }
                                    }
                                }
                            }

                            .w-input-note {
                                text-align: left;

                                &.w-input-error {
                                    color: $candy_red;
                                }
                            }
                        }
                    }

                    .wrapperSocials {
                        width: 100%;

                        .social {
                            display: flex;
                            align-content: center;
                            justify-content: center;

                            a {
                                display: block;
                                margin-right: 8px;
                                background-image: url(../svg/spritesheet.svg);
                                width: 30px;
                                height: 30px;

                                &.facebook {
                                    @include bgIconCoord(16,8,8);
                                }

                                &.instagram {
                                    @include bgIconCoord(24,8,8);
                                }

                                &.pinterest {
                                    @include bgIconCoord(26,8,8);
                                }

                                &.tiktok {
                                    margin-right: 0;
                                    @include bgIconCoord(28,8,8);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.image-bloc-texte {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .cms-page-columns-container {
            display: flex;
            justify-content: space-between;
        }

        .cms-page-column {
            width: 66.5%;

            img {
                display: block;
                width: 100%;
                height: auto;
            }

            &.cms-page-column-txt {
                width: 33.5%;

                .cms-page-module-txt {
                    padding: 22.5%;

                    @media only screen and (max-width: 1360px) {
                        padding: 10.5%;
                    }
                }
            }
        }
    }

    &.image-3 {
        .cms-page-columns-container {
            .swiper_wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

#cms_page_btn_back,
#cms_page_title {
    display: none;
}

#cms_page_ariane.categoryNav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem clamp(1rem, fs-vw(30, 1920), 30px);

    @media only screen and (max-width: 1440px) {
        left: 2.2%;
    }

    #breadcrumbs {
        margin: 0;
        padding: 0;
    }
}

#cms {
    .page_title {
        padding: 0 2.2%;
        width: 100%;
        text-align: center;

        .title {
            margin-bottom: 26px;
            width: 100%;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
        }
    }

    #cms_subcategory_menu {
        margin: 0 auto 30px;
        width: 100%;
        max-width: 1680px;

        ul {
            display: flex;
            align-content: center;
            justify-content: center;

            li {
                display: inline-flex;
                position: relative;
                cursor: pointer;
                width: auto;
                -webkit-text-stroke-width: 0px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                h2 {
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;
                }

                &:after {
                    position: relative;
                    top: 2px;
                    margin: 0 12px;
                    background: $black;
                    width: 1px;
                    height: 10px;
                    content: '';
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                &:hover,
                &.selected {
                    -webkit-text-stroke-width: 0.7px;
                }

                &.see_all {
                    display: block !important;
                }
            }
        }
    }

    .main_visu_cms_page {
        position: relative;
        margin-bottom: 40px;
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }

        .txt_wrapper {
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            transform: translate(-50%, -50%);
            z-index: 3;
            width: 100%;
            max-width: 495px;
            color: $white;
            text-align: center;

            .title {
                margin-bottom: 15px;
                width: 100%;
                font-style: italic;
                font-weight: 400;
                font-size: 35px;
                line-height: 1;
                font-family: $secondaryFont;
            }

            .txt {
                margin-bottom: 20px;
                width: 100%;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: normal;
                font-family: $primaryFont;
            }

            .cta {
                display: none;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                a {
                    color: $white;
                    text-decoration: underline;
                    text-underline-position: under;
                }

            }
        }
    }

    #content_previsu_cms_page {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }

    .previsu_cms_page_wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }

    .previsu_cms_page {
        box-sizing: border-box;
        margin-right: 5px;
        margin-bottom: 40px;
        background-color: $beige-clair;
        padding: 0;
        width: calc(25% - 4px);

        &:nth-child(4),
        &:nth-child(11),
        &:nth-child(4n + 11) {
            margin-right: 0;
        }

        a {
            display: flex;
            position: relative;
            flex-direction: column;
            flex-wrap: wrap;

            img {
                display: block;
                margin-bottom: 0;
                width: 100%;
                height: auto;
            }

            .cms_page_previsu_items {
                background-color: $beige-clair;
                padding: 28px 40px;
                min-height: 176px;
                text-align: left;

                .cms_page_previsu_title {
                    margin-bottom: 13px;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 30px;
                    font-family: $secondaryFont;
                }

                .cms_page_previsu_desc {
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    margin-bottom: 15px;
                    max-width: 100%;
                    -webkit-line-clamp: 3;
                    overflow: hidden;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: normal;
                    font-family: $primaryFont;
                }

                .cms_page_previsu_btn {
                    width: 100%;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-decoration: underline;
                    text-underline-position: under;
                    text-transform: uppercase;
                }
            }
        }

        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(7) {
            width: calc((100% / 3) - 3.5px);

            a {
                min-height: 300px;

                .cms_page_previsu_items {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: transparent;
                    padding: 1.5rem;
                    width: 100%;
                    color: $white;
                    text-align: center;

                    .cms_page_previsu_title {
                        margin-bottom: 20px;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 1;
                        font-family: $secondaryFont;
                    }

                    .cms_page_previsu_desc {
                        display: none;
                    }
                }
            }
        }

        &:nth-of-type(7) {
            margin-right: 0;
        }
    }
}

body.cms_category {
    #breadcrumbs {
        .breadcrumbs {
            color: $grey;

            .breadcrumb {
                &:nth-last-child(2) {
                    a {
                        color: $black;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}

.cms_page {
    .cms_page_content {
        min-height: 800px;
    }
}