/*General mon compte*/
.wrapper_moncompte  {
    margin: 0 auto;
    width: 100%;
    max-width: 1281px;

    @media only screen and (max-width: 1440px) {
        padding: 0 2.2%;
    }

    @media screen and (max-width: 1024px) {
        padding: 0 3.2%;
    }

    .wrapper_moncompte {
        padding: 0 !important;
    }

    .wishlist_cnt {
        display: none;
    }

    .leftPart {
        display: none;
    }

    .wrapper_menu_compte {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        div {
            display: inline-flex;
            align-items: center;
            width: auto;
            text-align: center;

            &:after {
                display: inline-block;
                margin: 0 11px;
                background: rgba($black, 0.4);
                width: 2px;
                height: 10px;
                content: '';

                @media only screen and (max-width: 1360px) {
                    margin: 0 6px;
                }
            }

            &:last-of-type {
                &:after {
                    display: none;
                }
            }

            a {
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;

                @media only screen and (max-width: 1360px) {
                    letter-spacing: 0.3px;
                }
            }

            &.actif {
                a {
                    font-weight: 700;
                }
            }
        }
    }

    .rightPart,
    .left-container,
    .yourFamily,
    .customer_addresses {
        .account_name,
        .accountBannerTop {
            margin-bottom: 1.7rem;
            color: $black;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 35px;
            font-family: $secondaryFont;
            text-align: center;
        }

        .wrapper_menu_compte {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            div {
                display: inline-flex;
                align-items: center;
                width: auto;
                text-align: center;

                &:after {
                    display: inline-block;
                    margin: 0 11px;
                    background: rgba($black, 0.4);
                    width: 2px;
                    height: 10px;
                    content: '';

                    @media only screen and (max-width: 1360px) {
                        margin: 0 6px;
                    }
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                a {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    @media only screen and (max-width: 1360px) {
                        letter-spacing: 0.3px;
                    }
                    
                    &:hover {
                        font-weight: 700;
                    }

                    &::after {
                        content:attr(data-text);
                        height: 0;
                        visibility: hidden;
                        overflow: hidden;
                        user-select: none;
                        pointer-events: none;
                        font-weight: 700;
                        letter-spacing: .6px;
            
                        @media speech {
                            display: none;
                        }
                    }
                }

                &.actif {
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .wrapper_form {
        form {
            .w-form-line.w-has-tel {
                z-index: 0;

                button {
                    border: none !important;
                    background-color: $white !important;
                    padding: 0 !important;
                    width: 41px !important;
                    height: 1rem !important;
                }

                .errormsgadd {
                    font-family: $primaryFont;
                }
            }
        }
    }
}

.accountBannerTop {
    margin-bottom: 1.5rem;
    color: $black;
    font-style: italic;
    font-weight: 400;
    font-size: 35px;
    line-height: 35px;
    font-family: $secondaryFont;
    text-align: center;
}

/* Newsletter */
.newsletter {
    #site_global_wrap {

        form.w-newspreferences-form {
            .w-form-line {
                margin-bottom: 0;

                &:first-of-type {
                    z-index: 20;
                    margin-bottom: 30px;
                    max-width: 440px;
                }

                & > .w-input-container {
                    margin-bottom: 1rem;
                }

                &.optin-select-line {
                    flex-wrap: wrap;

                    .text_newsletter {
                        margin-bottom: 1rem;
                        margin-left: 50px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 18px;
                        font-family: $primaryFont;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                    }
                }

                .w-input-container {
                    .w-input {
                        &.w-email-input {
                            width: 220px;

                            .w-input-element,
                            .w-input-label {
                                left: 0;
                                border-bottom: inherit;
                                padding: 0;
                                height: auto;
                                color: $grey;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 21px;
                                font-family: $primaryFont;
                            }
                        }
                    }

                    + .w-input-container {
                        margin-left: 0;
                        .w-tel-input {
                            .w-input-element,
                            .w-input-label {
                                left: 0;
                                border-bottom: inherit;
                                height: auto;
                                color: $grey;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 21px;
                                font-family: $primaryFont;
                            }
                            
                            .w-input-label {
                                padding-left: 54px;
                            }

                            button.selected-flag {
                                transition: inherit;
                                border: none !important;
                                border-radius: 0 !important;
                                background-color: transparent !important;
                                padding: 0;
                                height: 70% !important;

                                &:before {
                                    width: 24px;
                                    height: 16px;
                                }

                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }

                    .errormsgadd {
                        display: none;
                    }
                }

                &.w-submit {
                    margin-top: 30px;
                    max-width: 350px;
                }
            }

            .w-checkbox-input {
                height: auto;

                .w-input-element {
                    +.w-input-label {
                        left: 0;
                        align-items: flex-start;

                        span {
                            margin-left: 24px;
                        }

                        &:before {
                            position: relative;
                            box-shadow: none;
                            border: 0;
                            border-radius: 48px;
                            background-color: $grey;
                            width: 26px;
                            height: 14px;
                        }

                        &:after {
                            position: absolute;
                            top: 1px;
                            left: 1px;
                            border-radius: 50%;
                            background-color: $white;
                            width: 12px;
                            height: 12px;
                            content: '';
                        }

                        .multiline {
                            strong {
                                margin-left: 0;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;

                                & + span {
                                    display: none;
                                }
                            }

                            span {
                                margin-left: 0;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                            }
                        }
                    }

                    &:checked +.w-input-label {
                        &:before {
                            background-color: $black;
                        }

                        &:after {
                            left: 13px;
                        }
                    }
                }
            }

            & + .update_success,
            & + .update_failed {
                margin-top: 1em;
            }

            & + .update_success {
                color: green;
            }

            & + .update_failed {
                color: $red;
            }
        }
    }
}

body.customer {
    .wrapper_moncompte {
        margin: 0 auto;
        width: 100%;

        .account_name {
            padding-left: 8px;
        }

        .wrapper_menu_compte {
            margin-bottom: -6px !important;
        }

        .title_fidelite {
            display: none;
        }

        .text_fidelite {
            margin-top: 30px;
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;
            text-align: center;
        }

        #joinFidelityProgramForm {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 30px auto 0;
            width: 100%;

            .w-form-line {
                .w-input-container {
                    margin: 0 auto;
                    width: auto;

                    .w-input.w-checkbox-input {
                        .w-input-label {
                            span {
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                            }

                            &:before {
                                position: relative;
                                top: 0px;
                                box-shadow: none;
                                border: 0;
                                border-radius: 48px;
                                background-color: $lightgrey;
                                width: 26px;
                                height: 14px;
                            }
    
                            &:after {
                                position: absolute;
                                top: 3px;
                                left: 1px;
                                border-radius: 50%;
                                background-color: $white;
                                width: 12px;
                                height: 12px;
                                content: '';
                            }
                        }

                        .w-input-element {
                            &:checked +.w-input-label {
                                &:before {
                                    background-color: $black;
                                }
        
                                &:after {
                                    left: 13px;
                                }
                            }
                        }
                    }
                }

                &.w-submit {
                    max-width: 350px;
                }
            }

            .error_msg {
                color: $red;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                font-family: $primaryFont;
                margin-top: 10px;
                text-align: center;
            }
        }

        .wrapper_form {
            margin-top: 35px;
            
            #iconErrclientTel {
                display: none;
            }

            .update_success.myInfos {
                margin-left: 0;
                color: $green;
            }

            .w-form-line.w-submit {
                margin-top: -19px;
                width: 100% !important;
                max-width: 350px;
            }
        }

        .w-info-form {
            display: flex;
            row-gap: 25px;
            flex-wrap: wrap;
            justify-content: space-between;

            &.w-login-form {
                padding-bottom: 0;
            }

            .w-form-line {
                column-gap: 22px;
                margin-bottom: 0;

                &:last-of-type:not(.w-submit) {
                    margin-bottom: 5px;
                }

                &.mail-pass {
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 22px;
                    margin-bottom: 1rem;

                    .pass-1,
                    .pass-2 {
                        order: 1;
                    }

                    .w-input-container {
                        margin: 0;
                        width: calc(50% - 18px);
                    }
                }

                &.mail-pass-2 {
                    margin-bottom: 30px;
                }

                input.w-input-element {
                    font-family: $primaryFont;
                }

                .w-dropdown {
                    border: none;
                    border-bottom: 1px solid $grey;
                    border-radius: 0;
                    height: 40px;
                    font-family: $primaryFont;

                    .w-value {
                        padding-left: 2px;
                    }

                    .w-input-element {
                        padding: 2%;
                        font-family: $primaryFont;
                    }

                    &.w-focused {
                        outline: none;
                        box-shadow: none;
                    }

                    &.w-has-error {
                        box-shadow: none;
                    }

                    &:after {
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(29,8,8);
                        top: 43%;
                        right: 0.3rem;
                        transform: rotate(267deg);
                        border: 0;
                        width: 1rem;
                        height: 1rem;
                    }
                }

                .w-input.w-tel-input {
                    .selected-flag {
                        &::after {
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            top: 43%;
                            right: -0.1rem;
                            transform: rotate(267deg);
                            border: 0;
                            width: 8px;
                            height: 8px;
                        }
                    }
                }

                .w-input-note.w-input-error {
                    top: 90%;
                }
            }

            & + .update_success {
                margin-top: 1.5rem;
                padding: 0 .5%;
                color: $green;
            }

            & + .update_success,
            & + .update_failed {
                font-size: 0.75rem;
            }
        }

        section.yourFamily {
            padding: 36px 0;

            h2 {
                margin-left: 7px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.7px;
                text-transform: uppercase;

                + span {
                    margin-left: 7px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 21px;
                }
            }


            .title_form {
                display: none;
            }

            .w-form-line {
                &.w-submit {
                    margin-top: 4px;
                    width: 100% !important;
                    max-width: 350px;
                }
            }

            #childrenFormWrapper.w-login-form {
                 margin-top: 4px;
             }

            #childrenOpenButton {
                position: absolute;
                transition: all 0.35s ease-in-out;
                cursor: pointer;
                margin-top: 17px;
                margin-left: 7px;
                border: 1px solid $black;
                border-radius: 50px;
                background-color: $black;
                padding: 15.5px 85.84px;
                color: $white;
                color: $white;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-transform: uppercase;

                &:hover,
                &:focus {
                    background-color: $white;
                    color: $black;

                    span {
                        color: $black;
                    }
                }
            }

            #yourFamilyChildren {
                display: flex;
                column-gap: 13px;
                row-gap: 13px;
                flex-wrap: wrap;
                margin-top: 14px;
                padding-left: 7px;

                .clientChild {
                    position: relative;
                    border: 1px solid $grey;
                    padding: 2.2%;
                    width: 310px;
                    height: 180px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $primaryFont;

                    .clientChildName {
                        margin-bottom: 0.4%;
                        text-transform: uppercase;
                    }

                    .clientChildDelete {
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(30,8,8);
                        position: absolute;
                        top: 5%;
                        right: 3%;
                        cursor: pointer;
                        width: 12px;
                        height: 12px;
                    }

                }

                .clientChildUpdate {
                    cursor: pointer;
                    padding-top: 16%;
                    font-weight: 500;
                    font-size: 13px;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .customer_addresses {
        margin: 2.2% auto 0;
        width: 100%;

        .wrapper_adresses_title,
        .store_pref {
            display: none;
        }

        .conteneur_adresse {
            display: flex;
            flex-wrap: wrap;
            gap: 11px;

            .adresse {
                position: relative;
                border: 1px solid $grey;
                padding: 2% 3%;
                width: 310px;
                height: 180px;

                .addr_label {
                    margin-bottom: 0.9%;
                    font-size: 14px;
                    line-height: 21px;
                    text-transform: uppercase;
                }

                .infos {
                    margin-bottom: 5%;
                    font-size: 14px;
                    line-height: 18px;

                    .full_name {
                        text-transform: capitalize;
                    }
                }

                a {
                    position: absolute;
                }

                .modlinks_wrapper {
                    .modify {
                        font-size: 12px;
                        letter-spacing: 0.7px;
                        text-decoration: underline;
                        text-underline-offset: 4px;
                        text-transform: uppercase;
                    }

                    .delete {
                        top: 10px;
                        right: 10px;
                        font-size: 0;

                        &:before {
                            display: block;
                            width: 15px;
                            height: 15px;
                            content: '';
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(30, 8, 8);
                        }
                    }
                }
            }
        }

        a.add_new_address {
            display: flex;
            justify-content: center;
            margin-top: 2.3%;
            border: 1px solid $black;
            border-radius: 30px;
            background-color: $black;
            width: 350px;
            height: 50px;
            color: $white;

            span {
                display: flex;
                align-items: center;
                font-size: 13px;
                line-height: 15px;
                letter-spacing: 0.7px;
                text-transform: uppercase;
            }
        }
    }

    #adresseForm {
        overflow: hidden;

        .elem_global {
            &.delivery {
                padding-right: 10px;
                height: auto;
            }
        }

        .w-has-tel {
            .w-input-container {
                .selected-flag {
                border: none!important;
                background-color: $white!important;
                padding: 0!important;
                width: 41px!important;
                height: 1rem!important;
                }
            }
        }

        .w-form-line {
            .w-input.w-dropdown {
                border: none;
                border-bottom: 1px solid $grey;
                border-radius: 0;
                height: 40px;
                font-family: $primaryFont;

                &.w-focused {
                    outline: none;
                    box-shadow: none;
                }

                &.w-has-error {
                    box-shadow: none;
                }

                .w-value {
                    padding-left: 0;
                }

                &::after {
                    @include bgIconPath('../svg/spritesheet.svg');
                    @include bgIconCoord(29,8,8);
                    top: 43%;
                    right: 0.3rem;
                    transform: rotate(267deg);
                    border: 0;
                    width: 1rem;
                    height: 1rem;
                }
            }
        }

        .valid_form  {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            max-width: 30px!important;
        }

        .iconValidadresseLiv {
            display: none;
        }
    }

    #lightboxCustomerAddress {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: auto;
        transform: translateX(100%);
        transition: all .3s ease-in-out;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        padding: 1.3% 1.2%;
        width: 380px;
        height: 100%;

        .popup_container {
            #wrapper_form_adresse {
                #adresseForm {
                    .delivery_section_title {
                        margin-bottom: 6%;
                        font-style: italic;
                        font-size: 25px;
                        line-height: 30px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .w-input-group {
                        margin-bottom: 0.275rem;
                    }

                    .w-form-line {
                        flex-wrap: wrap;
                        margin-bottom: 1.275rem;

                        &.last-is-hidden {
                            display: grid;

                            .w-input-container {
                                margin-bottom: 0;

                                &:first-child {
                                    grid-row: 1 / 1;
                                }

                                &:nth-child(2) {
                                    grid-row: 1 /2;
                                }

                                &.delivery-country,
                                &:nth-child(3){
                                    grid-column: 1 / 3;
                                    margin-top: 1.275rem;
                                    margin-left: 0;
                                }

                                .w-value {
                                    padding-left: 0;
                                }
                            }

                            & + .w-form-line {
                                display: none;
                            }
                        }

                        .noaddr {
                            top: 16%;
                            font-size: 12px;
                        }

                        &.notice {
                            display: none;
                        }

                        &.add-complementary-line {
                            margin-bottom: 0;

                            + .w-form-line + .w-form-line {
                                display: grid;
                                margin-bottom: 0.275rem;

                                .w-input-container {
                                    margin-bottom: 1.275rem;
                                    margin-left: 0;
                                }
                            }
                        }

                        .w-input-container {
                            margin-bottom: 1.275rem;

                            &:only-of-type {
                                margin-bottom: 0;
                            }

                            &:empty {
                                display: none;
                            }

                            & + .w-input-container {
                                margin-left: 0;
                            }

                            .w-input-label {
                                width: calc(100% - 1rem);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }

                    .w-form-line.w-submit {
                        margin-top: 1.5rem;
                        width: 100%!important;
                        max-width: 100%!important;
                    }

                    .addrFullDisplay {
                        + .w-form-line {
                            display: grid;
                            margin-bottom: 0.275rem;

                            .w-input-container {
                                margin-bottom: 1.275rem;
                                margin-left: 0;

                                .w-value {
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.actif {
            transform: translateX(0);
        }
    }

    /* Fidelité */
    .wrapper-fidelity {
        margin-top: 40px;
        width: 100%;
        max-width: 1280px;

        .fidelity-details {
            .number_fidelity {
                margin-bottom: 23px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                font-family: $primaryFont;
                letter-spacing: 0.7px;
                text-align: center;
                text-transform: uppercase;
            }

            .fidelity_point {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 24px;

                .title {
                    margin-bottom: 8px;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                    text-align: right;
                }

                .number {
                    margin-bottom: 25px;
                    color: $black;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 35px;
                    font-family: $secondaryFont;
                    text-align: center;
                }

                .upselling {
                    margin-bottom: 50px;
                    width: 100%;
                    max-width: 520px;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 11px;
                    line-height: normal;
                    font-family: $primaryFont;
                    text-align: center;

                    .montant_gras {
                        color: $black;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: normal;
                        font-family: $primaryFont;
                    }

                    progress {
                        position: relative;
                        margin: 1rem auto 0.35rem;
                        background-color: $white;
                        width: 100%;
                        height: 5px;
                        color: $beige_Fonce;
                
                        &::-webkit-progress-bar {
                            border: 1px solid $beige_Fonce;
                            border-radius: 50px;
                            background-color:  $white; 
                            width: 100%;
                        }

                        &::-webkit-progress-value {
                            background-color:  $beige_Fonce !important;
                        }

                        &::-moz-progress-bar {
                            background-color:  $beige-clair  !important;
                        }
                    }

                    button {
                        width: 100%;
                        max-width: 350px;
                        text-transform: uppercase;
                    }
                }

                &+.fidelity_point {
                    position: relative;
                    margin-bottom: 0;

                    .title {
                        text-transform: uppercase;
                    }

                    .swiper {
                        width: 100%;
                        max-width: 1280px;

                        &:not(.swiper-initialized) .swiper-wrapper {
                            justify-content: center;
                        }
                        
                        .swiper-wrapper {
                            display: flex;

                            .swiper-slide {
                                margin-right: 5px;
                                background-color: $beige_Fonce;
                                padding: 30px 104px;
                                max-width: 423px;

                                @media screen and(max-width:1024px) {
                                    margin-right: 5px;
                                    padding: 30px 50px;
                                    max-width: 300px;
                                }

                                .title {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 1rem;
                                    line-height: 21px;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.8px;
                                    text-align: center;
                                    text-transform: uppercase;
                                }

                                .loyalty_code {
                                    margin-bottom: 13px;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                    text-align: center;

                                    .code {
                                        display: inline-block;
                                        border: 1px dashed $black;
                                        padding: 3px 9px;
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-family: $primaryFont;
                                        text-align: center;
                                    }

                                    .copy_code {
                                        position: absolute;
                                        content: '';
                                        @include bgIconPath('../svg/spritesheet.svg');
                                        @include bgIconCoord(31,8,8);
                                        margin-top: 5px;
                                        margin-left: 13px;
                                        width: 1rem;
                                        height: 1rem;
                                        cursor: pointer;
                                    }
                                }

                                .expiration {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .swiper_wrapper_buttons {
                        display: flex;
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 78%;
                        justify-content: space-between;
                        align-items: center;
                        margin: auto;
                        width: 72px;

                        .swiper-button-prev,
                        .swiper-button-next {
                            background-image: url('../svg/spritesheet.svg');
                            @include bgIconCoord(29,8,8);
                            cursor: pointer;
                            width: 1rem;
                            height: 1rem;

                            &:after {
                                display: none;
                            }

                            &.swiper-button-lock {
                                display: none;
                            }

                            &.disabled {
                                display: block;
                                opacity: 0.5;
                            }
                        }

                        .swiper-button-next {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .fidelity_wallet {
                margin: 30px auto 0;
                background-image: url('../img/compte/fidelite_carte.png');
                background-size: 100%;
                background-repeat: no-repeat;
                padding: 30px 0;
                width: 100%;
                height: 100%;
                max-height: 200px;

                @media screen and (max-width:1024px) {
                    padding: 15px 0;
                }

                .fidelity_details {
                    padding-left: 86px;
                    width: 100%;
                    max-width: 700px;
                    text-align: center;

                    @media screen and (max-width:1024px) {
                        padding-left: 0;
                    }

                    .title {
                        margin-bottom: 20px;
                        color: $black;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 25px;
                        line-height: 30px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .description {
                        margin-bottom: 15px;
                        width: 750px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: normal;
                        font-family: $primaryFont;
                        text-align: center;

                        @media screen and (max-width:1024px) {
                            width: 710px;
                            font-size: 13px;
                        }

                        a {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: normal;
                            font-family: $primaryFont;
                            text-decoration: underline;
                            text-transform: lowercase;
                        }
                    }

                    a {
                        color: $black;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-align: center;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                }
            }
        }

        .page_satellite {
            .satellite_content {
                display: flex;
                align-items: center;

                .left_img {
                    margin-right: 5px;
                    width: 49%;

                    .img_programfid {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 600px;
                    }
                }

                .content_right {
                    width: 49%;
                    height: 600px;
                    text-align: center;
    
                    .bloc_fidelite {
                        height: 100%;
                        background: $beige-clair;
                        padding: 60px;

                        @media screen and(max-width:1024px) {
                            padding: 23px 11.1%;
                        }
    
                        .fidelite_content {
                            &:not(:last-child) {
                                margin-bottom: 30px;
                                border-bottom: 2px solid $black;
                                padding-bottom: 30px;
                            }
    
                            .subtitle {
                                margin-bottom: 22px;
                                font-style: italic;
                                font-weight: 400;
                                font-size: 25px;
                                line-height: 1;
                                font-family: $secondaryFont;
                            }
    
                            ul {
                                display: inline-flex;
                                flex-direction: column;
    
                                li {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 23px;
                                    font-family: $primaryFont;
                                    text-align: center;
    
                                    span {
                                        display: inline-block;
                                        padding-left: 20px;
    
                                        &:before {
                                            display: inline-block;
                                            position: relative;
                                            top: -3px;
                                            left: -8px;
                                            border-radius: 100%;
                                            background: $black;
                                            width: 3px;
                                            height: 3px;
                                            content: '';
                                        }
                                    }
                                }
                            }
    
                            .text {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;

                                p {
                                    padding-bottom: 15px;

                                    &:last-of-type {
                                        padding-bottom: 0;
                                    }
                                }
                            }
    
                            .cta {
                                display: none;
                                margin: 30px auto 0;
    
                                .w-submit-button {
                                    display: block;
                                    margin: 0 auto;
                                    padding: 5px;
                                    width: 100%;
                                    max-width: 350px;
                                    height: 48px;
                                    line-height: 48px;
                                    text-align: center;
    
                                    &:hover {
                                        background-color: $beige-clair;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// PAGE COMMAND
.right-container.customer_orders {
    padding: 0 0.5%;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    font-family: $primaryFont;


    .wrapper_menu_tab {
        margin: 41px 0 32px;

        .filtres_commandes {
            display: flex;
            position: relative;

            select {
                outline: none;
                border: 0;
                background-color: $white;
                padding-bottom: 0.7%;
                width: 100%;
                letter-spacing: 0.7px;
            }

            &::after {
                position: absolute;
                bottom: 0;
                background-color: $grey;
                width: 100%;
                height: 1px;
                content: "";
            }
        }
    }

    #orders_by_type {
        #breadcrumbs {
            display: none;
        }

        .header {
            background-color: $beige-clair;
            line-height: 29px;

            tr {
                border: 1px solid $beige-clair;

                th {
                    padding: 6px 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1;
                    letter-spacing: 0;
                }

                .label-bloc {
                    @media screen and (max-width: 1130px) {
                        width: 11vw;
                    }
                }
            }
        }

        .content {
            .rollover {
                border: 1px solid $lightgrey;
                border-top: 0;
                height: 160px;

                td {
                    vertical-align: middle;

                    img {
                        display: flex;
                        width: 70%;
                    }

                    .btn_detail {
                        padding-right: 5px;
                        font-weight: 500;
                        font-size: 12px;
                        letter-spacing: 0.6px;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }

                    .order_number {
                        text-transform: lowercase;
                    }
                }
            }

            &.empty {
                tr {
                    td {
                        padding-top: 15px;
                    }
                }
            }
        }
    }
}

// Fracturation
body.order {
    .wrapper_moncompte {
        .rightPart {
            .detailCmdTop {
                margin-top: -2%;
                margin-bottom: 2%;
                font-size: 12px;
                text-align: left;

                a {
                    span {
                        margin-left: 2%;
                        text-decoration: underline;

                        > span {
                            margin-left: 0;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        @include bgIconPath('../svg/spritesheet.svg');
                        @include bgIconCoord(29,8,8);
                        width: 12px;
                        height: 12px;
                    }
                }

            }

            .detailCmdWrapper {
                display: flex;
                gap: 33px;
                width: 100%;
                max-width: 1281px;

                .left_cmd_items_wrapper {
                    width: 100%;
                    max-width: 822px;
                }

                .order_detail_head {
                    margin-bottom: 3.5%;
                    border: 1px solid $lightgrey;
                    padding: 2.8% 4% 3.8%;
                    width: 100%;
                }

                .num_product {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: $primaryFont;
                    letter-spacing: 0.05em;
                    text-align: left;
                    text-transform: uppercase;
                }

                .cmd_items {
                    display: grid;
                    position: relative;
                    grid-template-columns: 1fr 1fr;
                    gap: 1.5rem;
                    
                    .product-link {
                        display: flex;
                        margin-top: 2%;

                        .cart_thumb_holder {
                            width: 100px;

                            img {
                                width: 100%;
                            }
                        }

                        .cart_detail_box {
                            padding-left: 10px;
                            width: calc(100% - 110px);

                            .container_marques {
                                width: 100%;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 1;
                                font-family: $primaryFont;
                                letter-spacing: 0.04rem;
                                text-transform: uppercase;
                            }

                            span {
                                font-size: 15px;
                                line-height: 18px;
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                            }

                            .cart_product {
                                .title {
                                    color: $black;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 18px;
                                    letter-spacing: 0;

                                    span {
                                        font-weight: 400;
                                        font-size: 14px;
                                        letter-spacing: 0;
                                        text-transform: lowercase;
                                    }
                                }

                                .wrapper_price {
                                    margin-bottom: 16px;
                                    font-size: 15px;
                                    line-height: 17px;

                                    .pricetag {
                                        .price_tag {
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 15px;
                                            line-height: 17px;
                                            font-family: $primaryFont;

                                            + .price_tag {
                                                color: $grey;

                                                .price {
                                                    position: relative;

                                                    &:after {
                                                        display: block;
                                                        position: absolute;
                                                        top: 48%;
                                                        right: 0;
                                                        left: 0;
                                                        margin: 0 auto;
                                                        background-color: $grey;
                                                        width: 100%;
                                                        height: 1px;
                                                        content: '';
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .itemFeat {
                                font-size: 11px;
                                line-height: 15px;
                            }

                            .container_marques {
                                font-size: 13px;
                                text-transform: uppercase;
                            }
                        }
                    }

                    .product-link-options {
                        display: flex;
                        flex-direction: column;
                        gap: 0.1rem;
                        font-size: 11px;

                        .options {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            background-color: var(--opt-color, $beige-clair);
                            padding: 5px;
                            width: 100%;
                        }
                    }
                }
            }

            .cmd_title {
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                margin: 0 0 6px 5px;
            }

            .wrapper_infos_cmd {
                display: flex;
                gap: 167px;
                font-weight: 400;
                font-size: 14px;

                @media screen and (max-width: 1300px) {
                    gap: 100px;
                }

                @media screen and (max-width: 1200px) {
                    gap: 30px;
                }

                .status_date {
                    display: grid;
                    row-gap: 25px;
                    padding-top: 8px;

                    @media screen and(max-width: 1024px) {
                        row-gap: 0px;
                        width: 27%;
                    }

                    .cmd_status_10 {
                        color: $green;

                        span {
                            color: $black;
                        }
                    }
                }

                .tracking {
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;

                    .num_suivi {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 30px;
                        font-family: $primaryFont;
                        letter-spacing: 0em;
                        text-align: left;
                        white-space: nowrap;
    
                        a {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 30px;
                            font-family: $primaryFont;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
    
                    span {
                        font-weight: 600;
                    }
    
                    .link {
                        padding-left: 7px;
                        white-space: nowrap;
    
                        a {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 30px;
                            letter-spacing: 0.7px;
                            text-decoration: underline;
                            text-transform: uppercase;
                        }
                    }
                }
            }

            .right_cmd_items_wrapper {
                width: 100%;
                max-width: 425px;
                text-align: center;

                @media screen and (max-width:1108px) {
                    width: 67%;
                }

                .wrapper_delivery_addr {
                    margin-bottom: 1.2%;
                    border-top: 1px solid $lightgrey;
                    padding: 3% 6% 1%;
                    text-align: start;

                    .addr_title {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.7px;
                        text-transform: uppercase;
                    }

                    p {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }

                .detailRecapTop {
                    margin-top: 4.4%;
                    border: 1px solid $lightgrey;

                    .wrap_total_commande {
                        position: relative;
                        padding: 3% 4.5% 1.2%;

                        .recapTitle {
                            margin-bottom: 28px;
                            font-style: italic;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 30px;
                            font-family: $secondaryFont;
                            text-align: center;
                        }

                        .formRow {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 13px;
                            font-size: 14px;
                            font-family: $primaryFont;

                            .montants {
                                font-size: 15px;

                                .offered {
                                    text-transform: uppercase;
                                }
                            }

                            &.total {
                                padding-top: 22px;
                                text-transform: uppercase;

                                &::before {
                                    position: absolute;
                                    bottom: 53px;
                                    left: 0;
                                    background-color: $lightgrey;
                                    width: 100%;
                                    height: 1px;
                                    content: '';
                                }
                            }
                        }
                    }
                }

                .wrapper_cmd_retour {
                    margin: 25px 0;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;

                    #comment_retourner_cmd {
                        .intro {
                            margin-bottom: 15px
                        }

                        .links_comment_retourner_cmd {
                            display: none;
                        }

                        #createNumRetour {
                            #liste_motif_retour {
                                margin: 15px 0 30px;
                            }
                        }
                    }

                    #motif_retour_etape {
                        margin-top: 12px;

                        #printPdf {
                            .print-return {
                                .texte_num_retour {
                                    display: none;
                                }

                                .print-pdf {
                                    .btn_container  {
                                        margin-bottom: 15px;
                                        border: none;
                                        background-color: $white;
                                        height: auto;
                                        color: $black;
                                        line-height: 1;
                                        text-decoration: underline;
                                        text-underline-position: under;

                                        .button {
                                            color: $black;
                                            font-size: 12px;
                                        }
                                    }
                                }

                                div[id^="divBtnPrintPdf_"] {
                                    .button {
                                        span {
                                            display: inline-block;
                                             font-size: 12px;
                                            text-decoration: underline;
                                            text-underline-position: under;
                                            text-transform: uppercase;
                                        }
                                    }
                                }
                            }
                        }

                        .return_popup_title {
                            display: none;
                        }
                    }

                    .text_info_return {
                        a {
                            text-decoration: underline;
                        }
                    }
                }

                .button.secondary.order_detail_pfd {
                    margin: 3.5% 0;
                }

                .button.secondary.order_detail_pfd,
                .button.primary.order_detail_pfd {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                }

                .button.primary.order_detail_pfd {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 15px;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                    text-transform: uppercase;
                }
            }
        }
    }

    .lightbox.popup_numero_retour {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        left: auto;
        transform: translateX(100%);
        transition: all .5s ease-in-out;
        box-shadow: 0 5px 10px rgba(0,0,0,.1);
        padding: 1.3% 1.3%;
        width: 380px;
        height: 100%;
        
        .texte_num_retour {
            padding-bottom: 6px;
            font-style: italic;
            font-size: 25px;
            line-height: 30px;
            font-family: $secondaryFont;
            text-align: center;
        }

        .lb_retour {
            .recommandation {
                font-size: 14px;
                line-height: 18px;
                font-family: $primaryFont;
                text-align: center;
            }

            .holder_form {
                margin-top: 18px;

                .holder_return_product {
                    margin-bottom: 26px;

                    &.none {
                        opacity: 0.5;
                        pointer-events: none;
                    }

                    .productReturnListWrapper {
                        display: flex;
                        margin-bottom: 19px;

                        .imgWrapper {
                            width: 100px;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }

                        .formWrapper {
                            padding-left: 14px;
                            width: calc(100% - 100px);

                            .holder_title {
                                .title {
                                    font-size: 13px;
                                    line-height: 15px;
                                    letter-spacing: 0.7px;
                                    text-transform: uppercase;
                                }

                                .sub_title {
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                }

                                .sub_title_two {
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                }

                                .wrapper_price {
                                    margin-top: 7px;
                                    font-size: 15px;
                                    font-family: $primaryFont;
                                }
                            }

                            .holder_subs {
                                padding-top: 9px;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: 15px;
                            }
                        }
                    }

                    .js-select-motif {
                        display: flex;
                        gap: 30px;

                        .return_quantity {
                            width: 59px;
                        }

                        .return_choice {
                            width: 220px;
                            color: $black;
                        }

                        .return_quantity,
                        .return_choice {
                            outline: none;
                            border: 5px;
                            border-bottom: 1px solid $grey;
                            background-color: $white;
                            padding-bottom: 5px;

                            option {
                                color: $black;
                            }
                        }

                        .error_message {
                            padding-top: 5px;
                            color: $red;
                            font-size: 14px;
                        }
                    }
                }

                .divBtnPrintPdf {
                    position: absolute;
                    bottom: 50px;

                    .button {
                        margin: 0 auto;
                        width: 330px;
                        text-transform: uppercase;
                    }

                    .loader {
                        position: absolute;
                        top: 0;
                        opacity: 0;
                        z-index: -1;
                        border-radius: 50px;
                        background: $black url(../svg/three_dots.svg) no-repeat center;
                        background-size: 15% auto;
                        width: 100%;
                        height: 50px;

                        span {
                            font-size: 0;
                        }

                        &.loading {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }

                .holder_wrapper {
                    height: 74vh;
                    overflow-y: scroll;
                    overflow-x: hidden;
                }
            }
        }

        .print-pdf {
            margin: 15px 0;

            div[id^="divBtnPrintPdf_"] {
                display: block;
                margin: 15px 0;
                width: 100%;

                .button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50px;
                    background-color: $black;
                    width: 100%;
                    height: 50px;
                    text-transform: uppercase;

                    span {
                        color: $white;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.04rem
                    }
                }
            }
        }

        &.actif {
            transform: translateX(0);
            transition: all .5s ease-in-out;
        }
    }

    .print-return {
        .content {
            margin: 15px 0 20px;
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;
            text-align: center;

            br {
                display: none;
            }
        }

        .print-pdf {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .btn_container.two {
                justify-content: center;
                transition: all 0.35s ease-in-out;
                cursor: pointer;
                border: 1px solid $black;
                border-radius: 50px;
                background-color: $black;
                width: 100%;
                max-width: 330px;
                height: 50px;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 43px;
                letter-spacing: 0.1em;
                text-align: center;
                text-transform: uppercase;

                .button.dark {
                    display: block;
                    height: 100%;
                    color: $white;
                    letter-spacing: 0.7px;
                }

                &:hover {
                    background-color: $white;

                    .button.dark {
                        color: $black;
                    }
                }
            }
        }
    }
}

/* Wishlist */
body.wishlist {
    .wrapper_moncompte {
        max-width: 100%;
        
        .rightPart {
            width: 100%;
            max-width: 100%;

            .nav_moncompte {
                .wrapper_compte_hello,
                .account_name {
                    display: none;
                } 
            }

            .wrapper_wishlist {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 0 auto;
                width: 100%;
                max-width: 1281px;

                .wishlist_container {
                    width: 100%;
                    max-width: 990px;

                    .w-left-bloc {
                        .wish_liste_product {
                            display: flex;
                            flex-wrap: wrap;

                            .product_ctn {
                                position: relative;
                                box-sizing: border-box;
                                margin: 0 0 37px 0;
                                width: calc(50% - 45px);

                                &:nth-of-type(odd) {
                                    margin-right: 90px;
                                }

                                .remove_top_wishlist {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    z-index: 1;
                                    cursor: pointer;
                                    background-image: url('../svg/spritesheet.svg');
                                    background-position: 71.42857% 42.85714%;
                                    background-size: 800% auto;
                                    width: 11px;
                                    height: 11px;
                                }

                                .wrapper_product {
                                    display: flex;
                                    align-items: flex-start;
                                    width: 100%;
                                    height: 100%;

                                    .photo_product {
                                        width: 180px;
                                        min-height: 225px;

                                        .wrapper_img {
                                            width: 100%;

                                            a {
                                                display: block;
                                                width: 100%;
                                            }
                                        }

                                        img {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .product_desc {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        margin-left: 20px;
                                        width: calc(100% - 200px);
                                        height: 100%;
                                        position: relative;

                                        .container_marques {
                                            margin-bottom: 5px;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 1;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.04rem;
                                            text-transform: uppercase;

                                            img {
                                                width: auto;
                                                height: 22px;
                                            }
                                        }
                                        
                                        .wrap_txt {
                                            .title,
                                            .subtitle {
                                                color: $black;
                                                font-style: normal;
                                                font-weight: 600;
                                                font-size: 14px;
                                                line-height: 18px;
                                                font-family: $primaryFont;
                                            }

                                            .subtitle {
                                                font-weight: 400;
                                                text-transform: lowercase;
                                            }
                                        }

                                        .wrapper_price {
                                            margin-bottom: 20px;

                                            .bloc_price {
                                                display: flex;
                                                flex-wrap: wrap;

                                                .price_tag {
                                                    color: $black;
                                                    font-style: normal;
                                                    font-weight: 400;
                                                    font-size: 15px;
                                                    line-height: normal;
                                                    font-family: $primaryFont;
    
                                                    & + .price_tag {
                                                        position: relative;
                                                        order: -1;
                                                        margin-right: 12px;
                                                        color: $grey;
    
                                                        &:after {
                                                            display: block;
                                                            position: absolute;
                                                            top: 50%;
                                                            left: 0;
                                                            margin-top: -0.5px;
                                                            background-color: $grey;
                                                            width: 100%;
                                                            height: 1px;
                                                            content: '';
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        .wrap_details {
                                            margin-bottom: 12px;

                                            .wp_quantity,
                                            .wp_color,
                                            .wp_size {
                                                color: $black;
                                                font-weight: 400;
                                                font-size: 11px;
                                                line-height: 15px;
                                                font-family: $primaryFont;
                                            } 
                                        }

                                        .addToCartFromWishlist {
                                            cursor: pointer;
                                            border-radius: 50px;
                                            background-color: $black;
                                            width: 250px;
                                            height: 50px;

                                            div {
                                                display: block;
                                                border-radius: 50px;
                                                width: 100%;
                                                height: 50px;
                                                color: $white;
                                                font-weight: 500;
                                                font-size: 13px;
                                                line-height: 15px;
                                                font-family: $primaryFont;
                                                letter-spacing: 0.05em;
                                                text-align: center;

                                                span {
                                                    display: flex;
                                                    justify-content: center;
                                                    align-items: center;
                                                    width: 100%;
                                                    height: 50px;
                                                }
                                            }

                                            &.loading {
                                                div {
                                                    background: $black url(../svg/three_dots.svg) no-repeat center;
                                                    background-size: 15% auto;
                                                    font-size: 0;
                                                }
                                            }
                                        }

                                        .report.error {
                                            display: flex;
                                            justify-content: flex-end;
                                            width: 100%;

                                            .bloc_add_alert {
                                                background-color: $white;
                                                width: 100%;
                                                max-width: 250px;
                                                
                                                .btnAlertStock {
                                                    cursor: pointer;
                                                    border: 1px solid $black;
                                                    border-radius: 50px;
                                                    padding: 15px;
                                                    color: $black;
                                                    font-weight: 500;
                                                    font-size: 13px;
                                                    line-height: 15px;
                                                    font-family: $primaryFont;
                                                    letter-spacing: 0.05em;
                                                    text-align: center;
                                                    text-transform: uppercase;
                                                }

                                                .keep_alive_stock {
                                                    .w-alertestock-form {
                                                        width: 250px;

                                                        .w-form-line {
                                                            margin-bottom: 0.5rem;

                                                            &.w-submit {
                                                                position: absolute;
                                                                top: 20px;
                                                                right: 0;
                                                                width: 13px;
                        
                                                                .w-submit-button {
                                                                    border: none !important;
                                                                    border-radius: 0 !important;
                                                                    background-image: url(../svg/spritesheet.svg);
                                                                    background-color: inherit !important;
                                                                    width: 13px;
                                                                    height: 13px !important;
                                                                    @include bgIconCoord(29,8,8);
                                                                    transform: rotate(180deg);
                                                                    opacity: 0.5;
                        
                                                                    span {
                                                                        font-size: 0 !important;
                                                                    }
                                                                }
                                                            }

                                                            .w-input-container {
                                                                .w-text-input {
                                                                    .w-input-element {
                                                                        height: 45px;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }

                                                    .alert_return {
                                                        @include bgIconPath('../svg/spritesheet.svg');
                                                        @include bgIconCoord(30,8,8);
                                                        position: absolute;
                                                        top: 0px;
                                                        right: 0;
                                                        z-index: 1;
                                                        cursor: pointer;
                                                        display: block;
                                                        width: 11px;
                                                        height: 11px;
    
                                                        span {
                                                            font-size: 0;
                                                        }
                                                    }
                                                }

                                                .bloc_add_alert_confirmation {
                                                    p {
                                                        color: $green;
                                                        font-weight: 500;
                                                        font-size: 13px;
                                                        line-height: 15px;
                                                        font-family: $primaryFont;
                                                        margin-bottom: 0 !important;
                                                    }
                                                }
                                            }
                                        }

                                        .report.error,
                                        .addToCartFromWishlist {
                                            position: absolute;
                                            bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .wishlist_empty {
                    margin: 2rem auto;
                    color: $grey;
                    font-size: 15px;
                    font-family: $primaryFont;
                    text-align: center;
                }

                .wrapper_btn_add {
                    .form_submit {
                        #btn_add_wishlist {
                            display: flex;
                            margin: 0 auto 18px;
                            border-radius: 50px;
                            background-color: $black;
                            width: 355px;
                            height: 50px;
                            color: $white;
                            text-align: center;

                            span {
                                margin: 0 auto;
                                padding: 17px 33px 18px 32px;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 15px;
                                font-family: $primaryFont;
                                letter-spacing: 0.05em;
                                text-transform: uppercase;
                            }
                        }

                        #bloc_btn_loader {
                            display: none;
                        }

                        #btn_share_wishlist {
                            cursor: pointer;
                            margin: 0 auto;
                            text-align: center;
                            text-decoration: underline;
                            text-underline-offset: 5px;
                            text-transform: uppercase;

                            span {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 14px;
                                font-family: $primaryFont;
                                letter-spacing: 0.05em;
                            }
                        }
                    }
                }
            }

            .product_associations_wrapper {
                margin: 70px auto 63px;
                width: 100%;
                max-width: 1920px;
        
                .swiper_swap_button {
                    margin-bottom: 17px;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 1;
                    font-family: $secondaryFont;
                    text-align: center;
                }

                #productVisitedSwiper {
                    margin: 70px auto 90px;
                    width: 100%;
                    max-width: 1920px;
            
                    .swiper-wrapper {
                        .item {
                            &.swiper-slide {
                                margin-right: 5px;
                                width: calc(20% - 2.5px);
                
                                img {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: auto;
                                }
                            }
            
                            .imgWrapper {
                                .block_lnk {
                                    .wrapper_description {
                                        width:calc(100% - 90px);
                
                                        .item_title {
                                            width: 100%;
                                            overflow: hidden;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 25px;
                                            font-family: $primaryFont;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                
                                        .item_subtitle {
                                            display: block;
                                            width: 100%;
                                            overflow: hidden;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 1;
                                            font-family: $primaryFont;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                        }
                                    }
                                }
                
                                .item_price {
                                    position: absolute;
                                    top: calc(100% - 50px);
                                    right: 5px;
                
                                    .pricetag {
                                        margin-bottom: 3px;
                
                                        & + .pricetag {
                                            color: $grey;
                                            text-decoration: line-through;
                                        }
                                    }
                                }
                
                                .wrap_rolloverproduit {
                                    bottom: 77px;
                                }
                
                                .productVisualMulti {
                                    .rollover_rayonsNext,
                                    .rollover_rayonsPrev {
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(29,8,8);
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        right: auto;
                                        bottom: 0;
                                        left: 10px;
                                        opacity: 0;
                                        z-index: 5;
                                        cursor: pointer;
                                        margin: auto;
                                        width: 1rem;
                                        height: 1rem;
                
                                        &:after {
                                            display: none;
                                        }
                                    }
                
                                    .rollover_rayonsNext {
                                        right: 10px;
                                        left: auto;
                                        transform: rotate(180deg);
                                    }
                
                                    &:hover {
                                        .rollover_rayonsNext,
                                        .rollover_rayonsPrev {
                                            opacity: 1;

                                            &.swiper-button-lock {
                                                display: none;
                                            }

                                            &.disabled,
                                            &.swiper-button-disabled{
                                                display: block;
                                                opacity: 0.5;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &:not(.swiper-initialized) {
                        & > .swiper-wrapper {
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    #sendwishlistbox {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 692px;
        height: fit-content;

        .close_pop {
            span {
                display: none;
            }
        }

        .w-sendwishlist-form,
        #wishlistform {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: flex-start;
            background-color: $white;
            padding: 50px 0 35px;
            width: 100%;

            #content_sendwishlistbox,
            #content_wishlistbox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 0 auto;
                width: 510px;
                text-align: center;

                .popup_title {
                    margin-bottom: 47px;
                    width: 100%;

                    .title {
                        margin-bottom: 15px;
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 26px;
                        font-family: $primaryFont;
                        text-transform: uppercase;
                    }

                    .subtitle {
                        font-weight: 300;
                        font-size: 17px;
                        line-height: 12px;
                        font-family: $primaryFont;
                    }
                }

                #wrapper_sendwishlist {
                    margin: 0 auto;
                    width: 510px;
                    text-align: center;

                    .form_title {
                        display: flex;
                        margin-bottom: 15px;
                        font-size: 18px;
                        line-height: 15px;
                        font-family: $secondaryFont;
                        text-transform: uppercase;
                    }

                    input#sendWishListToMails,
                    input#sendWishListYourMail,
                    input#sendWishListYourName {
                        margin-bottom: 10px;
                        outline: 0;
                        border: 1px solid $grey;
                        border-radius: 0;
                        background-color: transparent;
                        padding: unset;
                        width: 100%;
                        height: 3.42857em;
                        min-height: 2.3rem;
                        font-size: .875rem;
                        font-family: brownpro,sans-serif;
                        text-indent: 0.4rem;
                    }

                    input#sendWishListYourMail {
                        margin-bottom: 9px;
                    }
                    
                    textarea#sendWishListToMessage {
                        padding: 1em 0.85714em;
                        padding-top: 1.75em;
                        width: 96%;
                        height: 8.5em;
                        resize: none;
                        line-height: 1.14286;
                        text-indent: -0.3rem;
                    }
                    
                    .w-has-error {
                        input#sendWishListToMails,
                        textarea#sendWishListToMessage,
                        input#sendWishListYourMail,
                        input#sendWishListYourName {
                            border: 1px solid $red;
                        }
                    }

                    .receive_copy {
                        display:flex;
                    }

                    #sendfriend_form_to {
                        margin-bottom: 10px;
                        margin-left: 0;

                        .form_title {
                            margin-bottom: 15px;
                            font-size: 1rem;
                            line-height: 14px;
                            font-family: $primaryFont;
                            text-transform: uppercase;

                            & + .w-form-line{
                                .w-input-error{
                                    margin-top: 1.3rem;
                                }
                            }
                        }

                        .w-form-line {
                            display: flex;
                            position: relative;
                            align-items: center;
                            margin-bottom: 1rem;
                            width: 100%;       

                            .w-input-container {
                                margin: 0 0 20px;

                                .w-input-note {    
                                    margin-top: 5px;

                                    &:not(.w-input-error) {
                                        font-weight: 300;
                                        font-size: 15px;
                                        line-height: 14px;
                                        font-family: $primaryFont;
                                        text-align: center;
                                    }

                                    &.w-input-error {
                                        margin-top: 0px;
                                        text-align: left;
                                    }
                                }

                                .w-textarea {
                                    textarea.w-input-element {
                                        padding: 1em 0.5em;

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    &.w-has-error {
                                        textarea.w-input-element {
                                            box-shadow: 0 0 0 1px $red !important;
                                        }
                                    }

                                    .w-input-element {
                                        border: 1px solid $grey;
                                    }
                                }

                                .w-input-label {
                                    top: 40%;
                                    color: $black;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-family: $primaryFont;

                                    span {
                                        margin-left: 5px;
                                    }

                                    &:nth-child(1) {
                                        transform: translateY(100%);
                                    }
                                }

                                .w-nonempty {
                                    .w-input-element {
                                        padding-top: 1rem;
                                    }

                                    .w-input-label {
                                        top: 50%;
                                    }
                                }

                                .w-textarea {
                                    .w-input-element {
                                        padding-top: 2rem;
                                    }
                                }

                                .w-textarea {
                                    &.w-nonempty:not(.no-label) textarea.w-input-element + .w-input-label {
                                        transform: translateY(25%);
                                    }

                                    textarea.w-input-element {
                                        padding: 1.5em 0.5em;
                                    }

                                    .w-input-label {
                                        top: 0;
                                    }
                                }
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &:nth-child(3) {
                                .w-input-container {
                                    margin-bottom: 17px;

                                    .w-input-note {
                                        &.w-input-error {
                                            margin-top: 2px;
                                            text-align: left;
                                        }
                                    }

                                    .w-input {
                                        .w-input-label {
                                            top: -8px;
                                            left: 0.4em;
                                            transform: translateY(100%);
                                        }

                                        &.w-nonempty {
                                            textarea.w-input-element {
                                                padding: 1.5em 1em;
                                            }

                                            .w-input-label {
                                                top: -18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                        
                    #sendfriend_form_froms {
                        .form_title {
                            display: flex;
                            margin-bottom: 15px;
                            font-size: 1rem;
                            line-height: 14px;
                            font-family: $primaryFont;
                            text-transform: uppercase;
                        }

                        .w-form-line {
                            margin-bottom: 0;
                            
                            &:nth-child(2) {
                                .w-input-container { 
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                    .w-input {
                                        input#sendWishListYourMail {
                                            margin-bottom: 9px;  
                                        }
                                    }
                                }
                            }

                            &:nth-child(4) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin-bottom: 22px;

                                        .w-checkbox-input {
                                            height: auto;
                                        }
                                    }
                                }
                            }

                            .w-input-container { 
                                margin: 0 0 30px;

                                &:nth-child(1) {
                                    margin: 0 0 29px;
                                }

                                .w-input-note {
                                    &:not(.w-input-error) {
                                        display: flex;
                                        font-size: 1rem;
                                        line-height: 7px;
                                        font-family: $primaryFont;
                                    }

                                    &.w-input-error {
                                        margin-top: 0px;
                                        text-align: left;
                                    }
                                }

                                .w-input-label {
                                    top: 40%;
                                    color: $black;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-family: $primaryFont;

                                    span {
                                        margin-left: 5px;
                                    }
                                }

                                .w-nonempty {
                                    .w-input-element {
                                        padding-top: 1rem;
                                    }
                                    .w-input-label {
                                        top: 50%;
                                    }
                                }
                            }
                        }

                        .g-recaptcha {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    #sendfriend_form_buttons {
                        position: relative;
                        margin-top: 19px;
                        margin-right: auto;
                        margin-left: auto;
                        width: 225px;
                        height: 40px;

                        .w-btn-wrapper {
                            .w-submit-button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid $black;
                                background-color: $black;
                                width: 227px;
                                color: $white;
                                font-size: 15px;
                                line-height: 40px;
                                font-family: $primaryFont;

                                &:not(.w-loader):hover span {
                                    border-radius: 10px;
                                    background-color: $black;
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                #sendfriend_alert_mail {
                    margin: 0 auto;
                }

                .g-recaptcha {
                    display: flex;
                    justify-content: center;
                }
                
                .w-submit {
                    margin: 0 auto;
                }
            }
        }

        &.disconnect{
            .popup_title {
                margin-bottom: 47px;

                .title {
                    margin-bottom: 30px;
                    font-size: 40px;
                    line-height: 26px;
                    font-family: $secondaryFont;
                    text-transform: uppercase;
                }

                .subtitle {
                    font-weight: 300;
                    font-size: 17px;
                    line-height: 12px;
                    font-family: $primaryFont;
                }
            }

            #wrapper_sendwishlist {
                .plusieur_add {
                    display: flex;
                    margin-bottom: 20px;
                    font-weight: 300;
                    font-size: 1rem;
                    line-height: 7px;
                    font-family: Rokkitt,sans-serif;
                    text-align: left;
                }

                textarea#sendWishListToMessage {
                    padding: 1em 0.85714em;
                    padding-top: 1.75em;
                    width: 96%;
                    height: 6em !important;
                    resize: none;
                    line-height: 1.14286;
                }

                .inputErr {
                    border: 1px solid $red !important;
                }

                .form_title {
                    margin-top: 10px;
                    width: 95%;
                }

                input#sendWishListYourMail {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .receive_copy {
                    margin-bottom: 20px;

                    label {
                        margin-left: 5px; 
                    }
                }

                #sendfriend_form_buttons {
                    .button {
                        margin-top: 27px;
                    }
                }
            }
        }
    }
}

/* Wishlist Disconnect */
body.script_renderwishlistguest {
    .wrapper_wishlist {
        margin: 0 auto;
        width: 96%;
        max-width: 1281px;

        .wishlist_container {
            width: 100%;
            max-width: 990px;

            .w-left-bloc {
                .wish_liste_product {
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: 11px;

                    .product_ctn {
                        position: relative;
                        box-sizing: border-box;
                        margin: 0 0 37px 0;
                        width: calc(50% - 45px);

                        &:nth-of-type(odd) {
                            margin-right: 90px;
                        }

                        .remove_top_wishlist {
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(30,8,8);
                            position: absolute;
                            top: 0;
                            right: 0;
                            z-index: 1;
                            cursor: pointer;
                            width: 11px;
                            height: 11px;
                        }

                        .wrapper_product {
                            display: flex;
                            align-items: flex-start;
                            width: 100%;

                            .photo_product {
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .product_desc {
                                margin-left: 10px;

                                .container_marques {
                                    margin-bottom: 5px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 1;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.04rem;
                                    text-transform: uppercase;

                                    img {
                                        width: auto;
                                        height: 22px;
                                    }
                                }
                                
                                .wrap_txt {
                                    .title,
                                    .subtitle {
                                        color: $black;
                                        font-style: normal;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 18px;
                                        font-family: $primaryFont;
                                    }

                                    .subtitle {
                                        font-weight: 400;
                                        text-transform: lowercase;
                                    }
                                }

                                .wrapper_price {
                                    margin-bottom: 10px;

                                    .bloc_price {
                                        display: flex;
                                        flex-wrap: wrap;

                                        .price_tag {
                                            color: $black;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 15px;
                                            line-height: normal;
                                            font-family: $primaryFont;

                                            & + .price_tag {
                                                position: relative;
                                                order: -1;
                                                margin-right: 12px;
                                                color: $grey;

                                                &:after {
                                                    display: block;
                                                    position: absolute;
                                                    top: 50%;
                                                    left: 0;
                                                    margin-top: -0.5px;
                                                    background-color: $grey;
                                                    width: 100%;
                                                    height: 1px;
                                                    content: '';
                                                }
                                            }
                                        }
                                    }
                                }

                                .wrap_details {
                                    margin-bottom: 12px;

                                    .wp_quantity,
                                    .wp_color,
                                    .wp_size {
                                        color: $black;
                                        font-weight: 400;
                                        font-size: 11px;
                                        line-height: 15px;
                                        font-family: $primaryFont;
                                    } 
                                }

                                .addToCartFromWishlist {
                                    position: absolute;
                                    bottom: 0;
                                    cursor: pointer;
                                    border-radius: 50px;
                                    background-color: $black;
                                    padding: 17px 17px 18px 15px;
                                    width: 250px;
                                    height: 50px;

                                    div {
                                        color: $white;
                                        font-weight: 500;
                                        font-size: 13px;
                                        line-height: 15px;
                                        font-family: $primaryFont;
                                        letter-spacing: 0.05em;
                                        text-align: center;
                                    }

                                    &.loading {
                                        div {
                                            background: $black url(../svg/three_dots.svg) no-repeat center;
                                            background-size: 15% auto;
                                            font-size: 0;
                                        }
                                    }
                                }

                                .report.error {
                                    .bloc_add_alert {
                                        background-color: $white;
                                        width: 250px;
                                        height: 50px;
                                        
                                        .btnAlertStock {
                                            cursor: pointer;
                                            border: 1px solid $black;
                                            border-radius: 50px;
                                            padding: 15px;
                                            color: $black;
                                            font-weight: 500;
                                            font-size: 13px;
                                            line-height: 15px;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.05em;
                                            text-align: center;
                                            text-transform: uppercase;
                                        }

                                        .keep_alive_stock {
                                            .w-alertestock-form {
                                                width: 250px;

                                                .w-form-line {
                                                    color: red;
                                                    margin-bottom: 0.5rem;

                                                    .w-submit-button {
                                                        margin-top: 0.875rem;
                                                    }
                                                }
                                            } 
                                        }

                                        .bloc_add_alert_confirmation {
                                            p {
                                                color: $green;
                                                font-weight: 500;
                                                font-size: 13px;
                                                line-height: 15px;
                                                font-family: $primaryFont;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .wishlist_empty {
            margin: 2rem auto;
            color: $grey;
            font-size: 15px;
            font-family: $primaryFont;
            text-align: center;
        }

        .wrapper_btn_add {
            .form_submit {
                #btn_add_wishlist {
                    display: flex;
                    margin: 0 auto 18px;
                    border-radius: 50px;
                    background-color: $black;
                    width: 355px;
                    height: 50px;
                    color: $white;
                    text-align: center;

                    span {
                        margin: 0 auto;
                        padding: 17px 33px 18px 32px;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 15px;
                        font-family: $primaryFont;
                        letter-spacing: 0.05em;
                        text-transform: uppercase;
                    }
                }

                #bloc_btn_loader {
                    display: none;
                }

                #btn_share_wishlist {
                    cursor: pointer;
                    margin: 0 auto;
                    text-align: center;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    text-transform: uppercase;

                    span {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        font-family: $primaryFont;
                        letter-spacing: 0.05em;
                    }
                }
            }
        }
    }

    .product_associations_wrapper {
        margin: 70px auto 63px;
        width: 100%;
        max-width: 1920px;

        .swiper_swap_button {
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }

        #productVisitedSwiper {
            margin: 70px auto 90px;
            width: 100%;
            max-width: 1920px;
    
            .swiper-wrapper {
                .item {
                    &.swiper-slide {
                        margin-right: 5px;
                        width: calc(20% - 2.5px);
        
                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }
                    }
    
                    .imgWrapper {
                        .block_lnk {
                            .wrapper_description {
                                width:calc(100% - 90px);
        
                                .item_title {
                                    width: 100%;
                                    overflow: hidden;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 25px;
                                    font-family: $primaryFont;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
        
                                .item_subtitle {
                                    display: block;
                                    width: 100%;
                                    overflow: hidden;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 25px;
                                    font-family: $primaryFont;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }
                            }
                        }
        
                        .item_price {
                            position: absolute;
                            top: calc(100% - 50px);
                            right: 5px;
        
                            .pricetag {
                                margin-bottom: 3px;
        
                                & + .pricetag {
                                    color: $grey;
                                    text-decoration: line-through;
                                }
                            }
                        }
        
                        .wrap_rolloverproduit {
                            bottom: 70px;
                        }
        
                        .productVisualMulti {
                            .rollover_rayonsNext,
                            .rollover_rayonsPrev {
                                background-image: url(../svg/spritesheet.svg);
                                @include bgIconCoord(29,8,8);
                                display: block;
                                position: absolute;
                                top: 0;
                                right: auto;
                                bottom: 0;
                                left: 10px;
                                opacity: 0;
                                z-index: 5;
                                cursor: pointer;
                                margin: auto;
                                width: 1rem;
                                height: 1rem;
        
                                &:after {
                                    display: none;
                                }
                            }
        
                            .rollover_rayonsNext {
                                right: 10px;
                                left: auto;
                                transform: rotate(180deg);
                            }
        
                            &:hover {
                                .rollover_rayonsNext,
                                .rollover_rayonsPrev {
                                    opacity: 1;

                                    &.swiper-button-lock {
                                        display: none;
                                    }

                                    &.disabled,
                                    &.swiper-button-disabled{
                                        display: block;
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:not(.swiper-initialized) {
                & > .swiper-wrapper {
                    justify-content: center;
                }
            }
        }
    }

    #sendwishlistbox {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 692px;

        .close_pop {
            span {
                display: none;
            }
        }

        .w-sendwishlist-form,
        #wishlistform {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: flex-start;
            background-color: $white;
            padding: 50px 0 35px;
            width: 100%;

            #content_sendwishlistbox,
            #content_wishlistbox {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                margin: 0 auto;
                width: 510px;
                text-align: center;

                .popup_title {
                    margin-bottom: 47px;
                    width: 100%;

                    .title {
                        margin-bottom: 15px;
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 26px;
                        font-family: $primaryFont;
                        text-transform: uppercase;
                    }

                    .subtitle {
                        font-weight: 300;
                        font-size: 17px;
                        line-height: 12px;
                        font-family: $primaryFont;
                    }
                }

                #wrapper_sendwishlist {
                    margin: 0 auto;
                    width: 510px;
                    text-align: center;

                    .form_title {
                        display: flex;
                        margin-bottom: 15px;
                        font-size: 18px;
                        line-height: 15px;
                        font-family: $secondaryFont;
                        text-transform: uppercase;
                    }

                    input#sendWishListToMails,
                    input#sendWishListYourMail,
                    input#sendWishListYourName {
                        margin-bottom: 10px;
                        outline: 0;
                        border: 1px solid $grey;
                        border-radius: 0;
                        background-color: transparent;
                        padding: unset;
                        width: 100%;
                        height: 3.42857em;
                        min-height: 2.3rem;
                        font-size: .875rem;
                        font-family: brownpro,sans-serif;
                        text-indent: 0.4rem;
                    }

                    input#sendWishListYourMail {
                        margin-bottom: 9px;
                    }
                    
                    textarea#sendWishListToMessage {
                        padding: 1em 0.85714em;
                        padding-top: 1.75em;
                        width: 96%;
                        height: 8.5em;
                        resize: none;
                        line-height: 1.14286;
                        text-indent: -0.3rem;
                    }
                    
                    .w-has-error {
                        input#sendWishListToMails,
                        textarea#sendWishListToMessage,
                        input#sendWishListYourMail,
                        input#sendWishListYourName {
                            border: 1px solid $red;
                        }
                    }

                    .receive_copy {
                        display:flex;
                    }

                    #sendfriend_form_to {
                        margin-bottom: 10px;
                        margin-left: 0;

                        .form_title {
                            margin-bottom: 15px;
                            font-size: 1rem;
                            line-height: 14px;
                            font-family: $primaryFont;
                            text-transform: uppercase;

                            & + .w-form-line{
                                .w-input-error{
                                    margin-top: 1.3rem;
                                }
                            }
                        }

                        .w-form-line {
                            display: flex;
                            position: relative;
                            align-items: center;
                            margin-bottom: 1rem;
                            width: 100%;       

                            .w-input-container {
                                margin: 0 0 20px;

                                .w-input-note {    
                                    margin-top: 5px;

                                    &:not(.w-input-error) {
                                        font-weight: 300;
                                        font-size: 15px;
                                        line-height: 14px;
                                        font-family: $primaryFont;
                                        text-align: center;
                                    }

                                    &.w-input-error {
                                        margin-top: 0px;
                                        text-align: left;
                                    }
                                }

                                .w-textarea {
                                    textarea.w-input-element {
                                        padding: 1em 0.5em;

                                        &:focus {
                                            outline: none;
                                        }
                                    }

                                    &.w-has-error {
                                        textarea.w-input-element {
                                            box-shadow: 0 0 0 1px $red !important;
                                        }
                                    }

                                    .w-input-element {
                                        border: 1px solid $grey;
                                    }
                                }

                                .w-input-label {
                                    top: 40%;
                                    color: $black;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-family: $primaryFont;

                                    span {
                                        margin-left: 5px;
                                    }

                                    &:nth-child(1) {
                                        transform: translateY(100%);
                                    }
                                }

                                .w-nonempty {
                                    .w-input-element {
                                        padding-top: 1rem;
                                    }

                                    .w-input-label {
                                        top: 50%;
                                    }
                                }

                                .w-textarea {
                                    .w-input-element {
                                        padding-top: 2rem;
                                    }
                                }
                            }

                            &:last-of-type {
                                margin-bottom: 0;
                            }

                            &:nth-child(3) {
                                .w-input-container {
                                    margin-bottom: 17px;

                                    .w-input-note {
                                        &.w-input-error {
                                            margin-top: 2px;
                                            text-align: left;
                                        }
                                    }

                                    .w-input {
                                        .w-input-label {
                                            top: -8px;
                                            left: 0.4em;
                                            transform: translateY(100%);
                                        }

                                        &.w-nonempty {
                                            textarea.w-input-element {
                                                padding: 1.5em 1em;
                                            }

                                            .w-input-label {
                                                top: -18px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                        
                    #sendfriend_form_froms {
                        .form_title {
                            display: flex;
                            margin-bottom: 15px;
                            font-size: 1rem;
                            line-height: 14px;
                            font-family: $primaryFont;
                            text-transform: uppercase;
                        }

                        .w-form-line {
                            margin-bottom: 0;
                            
                            &:nth-child(2) {
                                .w-input-container { 
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                }
                            }

                            &:nth-child(3) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin: 0 0 18px;
                                    }
                                    .w-input {
                                        input#sendWishListYourMail {
                                            margin-bottom: 9px;  
                                        }
                                    }
                                }
                            }

                            &:nth-child(4) {
                                .w-input-container {
                                    &:nth-child(1) {
                                        margin-bottom: 22px;

                                        .w-checkbox-input {
                                            height: auto;
                                        }
                                    }
                                }
                            }

                            .w-input-container { 
                                margin: 0 0 30px;

                                &:nth-child(1) {
                                    margin: 0 0 29px;
                                }

                                .w-input-note {
                                    &:not(.w-input-error) {
                                        display: flex;
                                        font-size: 1rem;
                                        line-height: 7px;
                                        font-family: $primaryFont;
                                    }

                                    &.w-input-error {
                                        margin-top: 0px;
                                        text-align: left;
                                    }
                                }

                                .w-input-label {
                                    top: 40%;
                                    color: $black;
                                    font-size: 14px;
                                    line-height: 21px;
                                    font-family: $primaryFont;

                                    span {
                                        margin-left: 5px;
                                    }
                                }

                                .w-nonempty {
                                    .w-input-element {
                                        padding-top: 1rem;
                                    }
                                    .w-input-label {
                                        top: 50%;
                                    }
                                }
                            }
                        }

                        .g-recaptcha {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    #sendfriend_form_buttons {
                        position: relative;
                        margin-top: 19px;
                        margin-right: auto;
                        margin-left: auto;
                        width: 225px;
                        height: 40px;

                        .w-btn-wrapper {
                            .w-submit-button {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1px solid $black;
                                background-color: $black;
                                width: 227px;
                                color: $white;
                                font-size: 15px;
                                line-height: 40px;
                                font-family: $primaryFont;

                                &:not(.w-loader):hover span {
                                    border-radius: 10px;
                                    background-color: $black;
                                    color: $white;
                                }
                            }
                        }
                    }
                }

                #sendfriend_alert_mail {
                    margin: 0 auto;
                }

                .g-recaptcha {
                    display: flex;
                    justify-content: center;
                }
                
                .w-submit {
                    margin: 0 auto;
                }
            }
        }

        &.disconnect{
            .popup_title {
                margin-bottom: 47px;

                .title {
                    margin-bottom: 30px;
                    font-size: 40px;
                    line-height: 26px;
                    font-family: $secondaryFont;
                    text-transform: uppercase;
                }

                .subtitle {
                    font-weight: 300;
                    font-size: 17px;
                    line-height: 12px;
                    font-family: $primaryFont;
                }
            }

            #wrapper_sendwishlist {
                .plusieur_add {
                    display: flex;
                    margin-bottom: 20px;
                    font-weight: 300;
                    font-size: 1rem;
                    line-height: 7px;
                    font-family: Rokkitt,sans-serif;
                    text-align: left;
                }

                textarea#sendWishListToMessage {
                    padding: 1em 0.85714em;
                    padding-top: 1.75em;
                    width: 96%;
                    height: 6em !important;
                    resize: none;
                    line-height: 1.14286;
                }

                .inputErr {
                    border: 1px solid $red !important;
                }

                .form_title {
                    margin-top: 10px;
                    width: 95%;
                }

                input#sendWishListYourMail {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .receive_copy {
                    margin-bottom: 20px;

                    label {
                        margin-left: 5px; 
                    }
                }

                #sendfriend_form_buttons {
                    .button {
                        margin-top: 27px;
                    }
                }
            }
        }
    }
}

/* Moyens de paiement */
body.paymentOption {
    .right-container {
        &.account_payment_options {
            margin: 0 auto;
            width: 100%;
            max-width: 1280px;

            .wrapper_form {
                display: none;

                &.displayed {
                    display: block;
                }

                .accountPageTitle {
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                }

                .button {
                    &.addNewCard {
                        display: none;
                    }
                }

                .accountAliasInfo {
                    display: none;
                }

                .wrapperAlias {
                    position: relative;
                    margin-bottom: 27px;
                    border-bottom: 1px solid $lightgrey;
                    padding-bottom: 27px;
                    overflow: hidden;

                    input[type="radio"] {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        opacity: 0;
                        z-index: 1;
                        cursor: pointer;
                        margin: auto;
                        width: calc(100% - 200px);
                        height: 100%;

                        & + .alias {
                            display: flex;
                            position: relative;
                            align-items: center;

                            &:before {
                                display: block;
                                position: relative;
                                margin-right: 2.73%;
                                box-shadow: inset 0 0 0 2px $white;
                                border: 1px solid $black;
                                border-radius: 100%;
                                background: $white;
                                width: 1rem;
                                height: 1rem;
                                content: '';
                            }

                            .cardLogo {
                                width: 8.43%;

                                img {
                                    width: auto;
                                    max-width: 45px;
                                    height: auto;
                                }
                            }

                            .aliasInfos {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                width: calc(88.84% - 18px);

                                .cardNum,
                                .cardName,
                                .cardExp {
                                    box-sizing: border-box;
                                    padding-right: 10px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                    text-align: left;
                                }

                                .cardNum {
                                    width: 16%;
                                }

                                .cardName {
                                    width: 17.55%;
                                    text-transform: capitalize;
                                }

                                div {
                                    &:last-of-type {
                                        position: absolute;
                                        right: 0;
                                    }

                                    .delete {
                                        border-radius: 0;
                                        background-image: url(../svg/spritesheet.svg);
                                        background-color: inherit;
                                        @include bgIconCoord(30,8,8);
                                        display: block;
                                        position: relative;
                                        cursor: pointer;
                                        border: inherit;
                                        width: 12px;
                                        height: 12px;

                                        span {
                                            font-size: 0;
                                        }
                                    }
                                }
                            }
                        }

                        &:checked {
                            & + .alias {
                                &:before {
                                    background-color: $black;
                                }
                            }
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 18px;
                        border-bottom: none;
                    }
                }
            }

            #psp_form {
                .accountPageTitle {
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                }

                form#hipay-3-form {
                    justify-content: space-between;
                    padding: 0;

                    .hipay-3-elements-container {
                        .form_line {
                            margin-bottom: 30px;

                            input[type="checkbox"] {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                width: 1em;
                                height: 1em;

                                & + label {
                                    display: flex;
                                    position: relative;
                                    align-items: center;
                                    transition: all 0.35s linear;
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;

                                    &:before {
                                        display: block;
                                        position: relative;
                                        top: 1px;
                                        transition: all 0.35s linear;
                                        cursor: pointer;
                                        margin-right: 8px;
                                        box-shadow: none;
                                        border: 0;
                                        border-radius: 48px;
                                        background-color: $grey;
                                        width: 26px;
                                        height: 14px;
                                        content: '';
                                    }

                                    &:after {
                                        display: block;
                                        position: absolute;
                                        top: 2px;
                                        left: 1px;
                                        transition: all 0.35s linear;
                                        cursor: pointer;
                                        border-radius: 50%;
                                        background-color: $white;
                                        width: 12px;
                                        height: 12px;
                                        content: '';
                                    }
                                }

                                &:checked {
                                    & + label {
                                        color: $black;

                                        &:before {
                                            background-color: $black;
                                        }

                                        &:after {
                                            left: 13px;
                                        }
                                    }
                                }
                            }
                        }

                        button {
                            transition: all 0.35s ease-in-out;
                            cursor: pointer;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-color: $black;
                            width: 100%;
                            max-width: 350px;
                            height: 48px;
                            color: $white;

                            span {
                                color: $white;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.04rem;
                                text-transform: uppercase;
                            }

                            &:hover,
                            &:focus {
                                background-color: $white;
                                color: $black;

                                span {
                                    color: $black;
                                }
                            }

                            & + .hipay-loader {
                                position: relative;
                                bottom: 0;
                                left: 0;
                                opacity: 0;
                                z-index: -1;
                                margin-top: 25px;
                                border-radius: 50px;
                                background: $black url(../svg/three_dots.svg) no-repeat center;
                                background-size: 15% auto;
                                width: 100%;
                                max-width: 350px;
                                height: 48px;

                                span {
                                    font-size: 0;
                                }
                            }

                            &.loading {
                                & + .hipay-loader {
                                    opacity: 1;
                                    z-index: 1;
                                }
                            }
                        }
                    }

                    .hipay-3-field-container-half {
                        width: calc(50% - 15px);
                    }
                }
            }
        }
    }
}

/* Mes listes */
body.gift_list_create,
.gift_list_manage,
.gift_list_show,
.gift_list_update {
    .wrapper_moncompte {
        .gl_title_form,
        #giftListFormWrapper {
            margin: 0 auto;
            width: 100%;

            .w-form-line {
                .w-input-container {
                    .w-input {
                        .w-input-element {
                            text-transform: inherit !important;
                        }
                    }

                    .w-textarea {
                        .w-input-element {
                            height: 2em;
                        }
                    }
                }
            }
        }

        .account_name {
            margin-bottom: 1.5rem;
            color: $black;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 35px;
            font-family: $secondaryFont;
            text-align: center;
        }

        .wrapper_menu_compte {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;

            div {
                display: inline-flex;
                align-items: center;
                width: auto;
                text-align: center;

                &:after {
                    display: inline-block;
                    margin: 0 11px;
                    background: rgba($black, 0.4);
                    width: 2px;
                    height: 10px;
                    content: '';

                    @media only screen and (max-width: 1360px) {
                        margin: 0 6px;
                    }
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }

                a {
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-transform: uppercase;

                    @media only screen and (max-width: 1360px) {
                        letter-spacing: 0.3px;
                    }
                }

                &.actif {
                    a {
                        font-weight: 700;
                    }
                }
            }
        }

        .gl_title_form {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            font-family: $primaryFont;
            letter-spacing: 0.05em;
            text-align: left;
            text-transform: uppercase;
        }

        .w-giftlist-form {
            .w-form-line {
                .w-input-container {
                    .w-input {
                        .w-input-label {
                            span {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                                letter-spacing: 0em;
                                text-align: left;
                            }
                        }
                    }

                    .w-input.w-textarea {
                        &.w-nonempty {
                            .w-input-label {
                                display: none;
                            }
                        }

                        .w-input-element {
                            padding: 0;
                            height: 3.5em;
                        }

                        .w-input-label {
                            top: -18px;
                            transform: translateY(100%);
                        }
                    }

                    .w-input.w-dropdown {
                        border: none;
                        border-bottom: 1px solid $grey;
                        border-radius: 0;
                        height: 2.5em;

                        &.w-focused {
                            box-shadow: none;
                            border: none;
                            border-bottom: 1px solid $grey;
                        }

                        &.w-has-error {
                            box-shadow: none;
                        }

                        &.w-nonempty {
                            .w-value {
                                padding: 1em 0.2em 0;
                            }
                        }

                        &:after {
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(29,8,8);
                            top: 43%;
                            right: 0.3rem;
                            transform: rotate(267deg);
                            border: 0;
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    .w-input.w-checkbox-input {
                        .w-input-label {
                            span {
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                            }

                            &:before {
                                position: relative;
                                top: 0px;
                                box-shadow: none;
                                border: 0;
                                border-radius: 48px;
                                background-color: $lightgrey;
                                width: 26px;
                                height: 14px;
                            }
    
                            &:after {
                                position: absolute;
                                top: 3px;
                                left: 1px;
                                border-radius: 50%;
                                background-color: $white;
                                width: 12px;
                                height: 12px;
                                content: '';
                            }
                        }

                        .w-input-element {
                            &:checked +.w-input-label {
                                &:before {
                                    background-color: $black;
                                }
        
                                &:after {
                                    left: 13px;
                                }
                            }
                        }
                    }
                }

                &.w-submit {
                    display: flex;
                    border-radius: 50px;
                    max-width: 100%;

                    .w-submit-button {
                        background-color: $black;
                        width: 100%;
                        max-width: 350px;
                        color: $white;

                        span {
                            color: $white;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-align: center;
                            text-transform: uppercase;
                        }
                    }
                }

                .w-valid-message {
                    position: absolute;
                    top: 65px;

                    span {
                        margin-left: 5px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                    }
                }
            }

            .file-input-row {
                margin-bottom: 26px;

                .w-form-line.column {
                    justify-content: center;
                    align-items: center;

                    .gl_form_preview {
                        border-radius: 120px;

                        & + #empty_img_preview {
                            display: none;
                        }
                    }

                    #empty_img_preview {
                        border-radius: 120px;
                        background-image: url(../img/profile_image.png);
                        background-position: 100%;
                        background-repeat: no-repeat;
                        width: 170px;
                        height: 170px;
                        object-fit: cover;
                    }

                    .gl_form_add_img_label {
                        display: inline-block;
                        position: absolute;
                        bottom: 2px;
                        left: 52%;
                        cursor: pointer;
                        border: 1px solid $grey;
                        border-radius: 50%;
                        background: $white;
                        background-image: url('../svg/cross.svg');
                        background-position: 6px;
                        background-repeat: no-repeat;
                        width: 29px;
                        height: 29px;
                        color: $grey;
                    }

                    .gl_input_image {
                        display: none;
                    }

                    .w-input-error {
                        text-align: center;
                    }
                }
            }
        }

        .giftlist_infos {
            .gl_picture {
                margin-bottom: 10px;

                img {
                    border-radius: 120px;
                }
            }

            .gl_title {
                color: $black;
                font-style: italic;
                font-weight: 400;
                font-size: 25px;
                line-height: 30px;
                font-family: $secondaryFont;
                text-align: center;
            }

            .gl_date_owner {
                display: flex;
                flex-direction: column;
                width: 100%;

                .gl_subtitle {
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 18px;
                    font-family: $primaryFont;
                    text-align: center;
                }

                .modifier_liste {
                    margin: 15px 0 30px;
                    color: $black;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-align: center;
                    text-decoration: underline;
                    text-transform: uppercase;
                }

                .share_list {
                    display: block;
                    margin: 0 auto 30px;
                    border-radius: 50px;
                    background-color: $black;
                    padding: 18px 0;
                    width: 100%;
                    max-width: 350px;
                    color: $white;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-align: center;
                    text-transform: uppercase;
                }

                .gl_amount {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .title {
                        margin-bottom: 10px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                        text-align: right;
                    }

                    .number {
                        margin-bottom: 10px;
                        color: $black;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 35px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .upselling {
                        margin-bottom: 43px;
                        width: 100%;
                        max-width: 520px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: normal;
                        font-family: $primaryFont;
                        text-align: center;
    
                        .price {
                            color: $black;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 11px;
                            line-height: normal;
                            font-family: $primaryFont;
                        }
    
                        progress {
                            position: relative;
                            margin: 1rem auto 0.35rem;
                            background-color: $white;
                            width: 100%;
                            height: 5px;
                            color: $beige_Fonce;
                    
                            &::-webkit-progress-bar {
                                border: 1px solid $beige_Fonce;
                                border-radius: 50px;
                                background-color:  $white; 
                                width: 100%;
                            }
    
                            &::-webkit-progress-value {
                                background-color:  $beige_Fonce !important;
                            }
    
                            &::-moz-progress-bar {
                                background-color:  $beige-clair !important;
                            }
                        }
    
                        button {
                            width: 100%;
                            max-width: 350px;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

body.pwdforget,
body.script_renderpwdforget {
    padding-top: 0px !important;

    #site_global_wrap {
        #wrapper_moncompte {
            position: relative;
            margin: 0 auto;
            width: 100%;
            overflow: hidden;

            img.bandeau_mdp_oublie {
                display: block;
                width: 100%;
                height: auto;

                @media only screen and (max-width: 1360px) {
                    margin: 0 auto;
                    width: auto;
                    height: auto;
                    min-height: 640px;
                }
            }

            .wrapper_password {
                &.not_logged {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    margin: auto;
                    background: $white;
                    padding: 40px 10px;
                    width: 100%;
                    max-width: 800px;
                    height: 350px;
                }

                .bandeau_top_compte {
                    .title {
                        margin-bottom: 1.5rem;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 1;
                        font-family: $secondaryFont;
                        text-align: center;
                    }
                }

                #clientForm {
                    margin: 0 auto;
                    max-width: 600px;

                    .w-form-line {
                        &.w-submit {
                            .w-btn-wrapper {
                                margin: auto;
                                width: 100%;
                                max-width: 415px;
                            }
                        }

                        button.w-visibility-toggle {
                            transition: inherit;
                            border: none;
                            background-color: inherit;
                            padding: 0;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }
}