/* Store locator general style */
.store_locator {
    .wrapper_store {
        .wrapper_retailers_map {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 63px;

            .page-title{
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                font-weight: 400;
                line-height: 35px;
                margin-bottom: 40px;
                text-align: center;
                width: 100%;
            }

            .storeloc_form {
                width: 100%;
                margin: 0 auto 40px;
                order: 1;

                .form_itm {
                    width: calc(100% - 12.5vw);
                    max-width: 1024px;
                    margin: auto;

                    &.country_select {
                        display: none;
                    }

                    &.zip_input {
                        div {
                            position: relative;

                            #search_cp {
                                box-sizing: border-box;
                                border: none;
                                border-bottom: 1px solid $grey;
                                border-radius: 0;
                                padding: 0 2px;
                                height: 40px;
                                width: 100%;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                color: $black;
                            }

                            p.ou {
                                display: none;
                            }
                            .magnifying_glass {
                                cursor: pointer;
                                width: 20px;
                                height: 20px;
                                border: none;
                                background-color: $white;
                                position: absolute;
                                right: 0;
                                top: 10px;
                                padding: 0;
                                background-image: url('../svg/spritesheet.svg');
                                @include bgIconCoord(17, 8, 8);
                            }

                            #btn_geoloc {
                                display: none;
                                cursor: pointer;
                                width: 20px;
                                height: 20px;
                                border: none;
                                background-color: $white;
                                position: absolute;
                                right: 0;
                                top: 10px;
                                padding: 0;

                                &:before {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }

            #retailers_map {
                height: 650px;
                width: 66.875% !important;
                margin-right: 7.395833%;
                order: 2;

                @media only screen and (max-width: 1160px) {
                    width: 72%;
                    margin-right: 5%;
                }

                button {
                    width: inherit !important;
                    border-radius: 0 !important;
                }

                .gm-style {

                    img[src$="marker.png"] {
                        width: 1rem !important;
                        height: auto !important;
                    }

                    .gm-style-iw-c {
                        width: 317px;
                        padding: 58px 31px 25px 31px !important;
                        border-radius: 0;
                        background: $white url('../img/marker.png') center 22px no-repeat;
                        background-size: 28px;

                        &:focus {
                            outline: none;
                        }

                        .info_popup {
                            .name {
                                a {
                                    border: none;
                                    text-decoration: none;
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 21px;
                                    letter-spacing: 0.7px;
                                    text-transform: uppercase;
                                    color: $black;
                                    margin-bottom: 6px;
                                    display: block;

                                    &:focus {
                                        outline: inherit;
                                    }
                                }
                            }

                            .adresse {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                margin-bottom: 6.5px;
                            }

                            .coord {
                                display: none;
                            }
                        }

                        .gm-ui-hover-effect,
                        .horaire_info {
                            display: none !important;
                        }

                        .horaire_today {
                            margin: 10px 0;
                            font-size: 11px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            font-family: $primaryFont;

                            .puce {
                                display: inline-block;
                                height: 10px;
                                padding-left: 12px;
                                position: relative;

                                &:before {
                                    display: inline-block;
                                    content: '•';
                                    font-family: sans-serif;
                                    font-size: 30px;
                                    font-style: normal;
                                    font-weight: 300;
                                    line-height: 10px;
                                    position: absolute;
                                    left: 0;
                                    top: 1px;
                                }

                                &.store_open {
                                    &:before,
                                    + .mag_open_status {
                                        color: $green;
                                    }
                                }

                                &.store_closed {
                                    &:before,
                                    + .mag_open_status {
                                        color: $red;
                                    }
                                }
                            }
                        }

                        .horaire_info {
                            display: none;
                        }

                        .store_localisation_container {
                            display: flex;
                            margin-top: 10px;

                            .btn_voir_magasin {
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-right: 10px;
                                color: $black;
                                padding-bottom: 2px;

                                a {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }

                    .gm-style-iw-d {
                        overflow: auto !important;
                    }
                }
            }

            .img-loader-container {
                display: none !important;

                img {
                    width: 50px;
                    height: auto;
                }
            }

            .section-list-store {
                width: 18.177%;
                order: 3;

                .search-result {
                    font-family: $secondaryFont;
                    font-size: 25px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 25px;

                    .search-result-text {
                        display: inline;
                    }
                }

                #store-container {
                    min-height: 150px;
                    max-height: 585px;
                    overflow: auto;

                    #list-store {
                        height: auto;
                        padding-right: 16.216%;
                        display: block;

                        @media only screen and (max-width: 1160px) {
                            padding-right: 8%;
                        }

                        .liste_distributeur {
                            .no_result {
                                font-size: 0;
                            }
                        }

                        .elem-list-store {
                            font-family: $primaryFont;
                            padding-bottom: 22px;
                            margin-bottom: 22px;
                            border-bottom: 1px solid $lightgrey;

                            &:last-of-type {
                                padding-bottom: 2px;
                                margin-bottom: 0;
                                border-bottom: none;
                            }

                            .nom_store h2 {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px;
                                letter-spacing: 0.7px;
                                text-transform: uppercase;
                                margin-bottom: 6px;

                                .distance {
                                    display: inline-block;
                                }
                            }

                            .detail_store {
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;

                                .col-1 {
                                    span {
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                    }
                                }

                                .ville_store {
                                    display: block;
                                }

                                .horaire_today {
                                    margin: 10px 0;
                                    font-size: 11px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    display: inline-block;
                                    min-height: 10px;
                                    padding-left: 12px;
                                    position: relative;

                                    &:before {
                                        display: inline-block;
                                        content: '•';
                                        font-family: sans-serif;
                                        font-size: 30px;
                                        font-style: normal;
                                        font-weight: 300;
                                        line-height: 10px;
                                        position: absolute;
                                        left: 0;
                                        top: 1px;
                                    }

                                    &.store_open {
                                        &:before,
                                        + .mag_open_status {
                                            color: $green;
                                        }
                                    }

                                    &.store_closed {
                                        &:before,
                                        + .mag_open_status {
                                            color: $red;
                                        }
                                    }


                                    &.puce_store_open {
                                        &:before,
                                        .mag_open_status {
                                            color: $green;
                                        }
                                    }

                                    &.puce_store_closed {
                                        &:before,
                                        .mag_open_status,
                                        span.txt {
                                            color: $red;
                                        }
                                    }
                                }
                            }

                            .tel_store {
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin: 6px 0 10px;
                                display: flex;
                                align-items: center;
                                text-decoration: underline;

                                &:before {
                                    content: '';
                                    width: 15px;
                                    height: 15px;
                                    background-image: url('../svg/spritesheet.svg');
                                    @include bgIconCoord(18, 8, 8);
                                    margin-right: 6px;
                                }
                            }

                            .addfavorite,
                            .horaire_info {
                                display: none;
                            }

                            .store_more {
                                a {
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    letter-spacing: 0.6px;
                                    text-transform: uppercase;
                                    text-decoration: underline;
                                    text-underline-position: under;
                                }
                            }

                            .button.secondary {
                                @media only screen and (max-width: 1260px) {
                                    font-size: 11px;
                                    letter-spacing: 0.2px;
                                }

                                @media only screen and (max-width: 1160px) {
                                    font-size: 10px;
                                }

                                @media only screen and (max-width: 1060px) {
                                    font-size: 9px;
                                }
                            }
                        }
                    }
                }
            }
        }


        .wrapper_retailers {
            display: flex;
            justify-content: flex-start;
            width: calc(100% - 6.25vw);
            margin-bottom: 70px;

            .wrapper_retailers_map {
                width: 71.333%;
                margin-right: 7.888%;

                @media only screen and (max-width: 1160px) {
                    width: 72%;
                    margin-right: 5%;
                }

                button {
                    width: inherit !important;
                    border-radius: 0 !important;
                }
            }

            .main_content_store {
                width: 20.779%;
                position: relative;

                @media only screen and (max-width: 1160px) {
                    width: 23%;
                }

                .resultat_recherche {
                    font-family: $secondaryFont;
                    font-size: 25px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: 30px;
                    margin-bottom: 25px;
                }

                #loading-image {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: -1;

                    img {
                        width: 5vmin;
                    }
                }
            }
        }
    }

    /* Boutique virtuel */ 
    #virtualStore {
        .title {
            font-family: $secondaryFont;
            font-size: 35px;
            font-style: italic;
            font-weight: 400;
            line-height: 35px;
            text-align: center;
            margin-bottom: 17px;
        }

        .bloc_wrapper_virtual_store {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto 70px;

            .bloc_virtual {
                width: calc(50% - 2.5px);
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                }

                &.bloc_virtual_01 {
                    margin-right: 5px;
                }

                span {
                    color: $white;
                    display: block;
                }

                .bloc_text {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    height: 80px;
                    width: 100%;
                    text-align: center;

                    .txt {
                        font-family: $secondaryFont;
                        font-size: 35px;
                        font-style: italic;
                        font-weight: 400;
                        line-height: 1;
                        margin-bottom: 14px;
                    }

                    .cta {
                        a {
                            color: $white;
                            font-family: $primaryFont;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                            border-bottom: 1px solid $white;
                        }
                    }
                }
            }
        }
    } 
}

/* Boutique detail */
.satellite_store_details  {
    .wrapper_moncompte {
        &.masgasin_pages {
            width: calc(100% - 6.25vw);
            max-width: inherit;
            margin: 0;
        }
    }

    .mian_div {
        display: flex;
        justify-content: flex-start;

        .left_bloc {
            width: 56.111%;

            .store_img {
                margin-bottom: 5px;
                width: 100%;

                .swiper-container {
                    .swiper-slide {
                        width: 100%;

                        img {
                            width: 100%;
                            height: auto;
                            display: block;
                        }
                    }

                    .store_img_button {
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(29,8,8);
                        width: 14px;
                        height: 14px;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        opacity: 0;

                        &::after {
                            display: none;
                        }

                        &.swiper-button-next {
                            transform: rotate(180deg);
                        }
                    }

                    &.swiper-initialized {
                        .store_img_button {
                            opacity: 1;

                            &.disabled,
                            &.swiper-button-disabled {
                                opacity: 0.5;
                                display: block;
                                cursor: default;
                            }
                        }
                    }
                }
            }

            #store_detail_map {
                height: 500px;
                width: 100%;

                button,
                .button {
                    border-radius: 0;
                }
            }
        }

        .right_bloc {
            width: 37.222%;
            margin-left: 6.75%;

            @media only screen and (max-width: 1060px) {
                padding-right: 10px;
                box-sizing: border-box;
            }

            .return-link {
                font-family: $primaryFont;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration-line: underline;
                display: flex;
                align-items: flex-end;
                margin-bottom: 31px;
                text-transform: capitalize;

                &:before {
                    content:'';
                    width: 8px;
                    height: 8px;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(29,8,8);
                    display: inline-block;
                    margin-right: 3px;

                }
            }

            .content_info {
                margin-bottom: 22px;
                padding-bottom: 22px;
                border-bottom: 1px solid $lightgrey;

                .store_infos {
                    .nom_mag {
                        .title_details {
                            font-family: $primaryFont;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 25px;
                            color: $black;
                            margin-bottom: 17px;
                        }

                        .favorite {
                            display: none;
                        }
                    }

                    .storeAddress_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-content: flex-end;

                        .storeAddress {
                            font-family: $primaryFont;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            color: $black;
                            width: calc(100% - 210px);
                            display: flex;
                            align-content: flex-end;
                            flex-wrap: wrap;
                            text-transform: capitalize;

                            p, div {
                                width: 100%;
                            }

                            .contact {
                                margin-top: 9px;

                                .tel {
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                    margin: 6px 0 10px;
                                    display: flex;
                                    align-items: center;
                                    text-decoration: underline;
                                    text-underline-position: under;

                                    &:before {
                                        content: '';
                                        width: 15px;
                                        height: 15px;
                                        background-image: url('../svg/spritesheet.svg');
                                        @include bgIconCoord(18, 8, 8);
                                        margin-right: 6px;
                                    }
                                }
                            }

                            .storeAddress_state {
                                font-family: $primaryFont;
                                font-size: 11px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                margin-top: 17px;
                                text-transform: none;

                                p span {
                                    margin-right: 3px;
                                    position: relative;
                                    color: $red;

                                    &.puce {
                                        &::before {
                                            content: '';
                                            width: 7px;
                                            height: 7px;
                                            background-color: $red;
                                            display: block;
                                            position: absolute;
                                            border-radius: 50%;
                                            top: 3px;
                                            left: -5px;
                                        }
                                    }

                                    &.mag_open_status {
                                        margin-left: 15px;
                                        color: $green;

                                        &::before {
                                            content: '';
                                            width: 7px;
                                            height: 7px;
                                            background-color: $green;
                                            display: block;
                                            position: absolute;
                                            border-radius: 50%;
                                            top: 3px;
                                            left: -13px;
                                        }
                                    }

                                    &.txt {
                                        margin-left: 8px;
                                        display: inline-block;
                                    }
                                }

                                .store_open~.txt {
                                    color: $green;
                                }

                                .store_close~.txt {
                                    color: $red;
                                }
                            }
                        }

                        .store_direction {
                            width: 200px;
                            display: flex;
                            align-content: flex-end;
                            flex-wrap: wrap;
                            justify-content: center;

                            .btn_send_info {
                                display: block;
                                text-align: center;
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 48px;
                                letter-spacing: 0.6px;
                                height: 48px;
                                border-radius: 50px;
                                text-transform: uppercase;
                                background: $white !important;
                                color: $black !important;
                                border: 1px solid $black !important;
                                margin-bottom: 20px;
                                width: 192px;

                                span {
                                    color: $black !important;
                                }

                                &:hover {
                                    background: $black !important;
                                    color: $white !important;

                                    span {
                                        color: $white !important;
                                    }
                                }
                            }

                            button#btn_to_store {
                                text-transform: uppercase;
                                background: $white;
                                color: $black;
                                border: none;
                                height: auto;
                                line-height: 1;
                                border-radius: 0;
                                padding: 0;
                                text-decoration: underline;
                                text-underline-position: under;

                                span {
                                    color: $black;
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }

            .block_horraire_wrapper {
                margin-bottom: 22px;
                padding-bottom: 22px;
                border-bottom: 1px solid $lightgrey;

                .block-txt {
                    .storeInfos {
                        .storeHours {
                            .label {
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                                display: block;
                                margin-bottom: 10px;
                            }

                            .time_table {
                                .date_time {
                                    display: flex;
                                    justify-content: flex-start;

                                    .day {
                                        width: 125px;
                                    }

                                    div {
                                        font-family: $primaryFont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 21px;
                                    }

                                    &.actualday {
                                        div {
                                            font-weight: 600;
                                        }
                                    }

                                    &:last-child {
                                        margin-bottom: 5px;
                                    }
                                }
                            }

                            .holiday {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                            }

                            .alert {
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 21px;
                                color: $black;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }

            .block_magasin_description {
                margin-bottom: 22px;
                padding-bottom: 22px;
                border-bottom: 1px solid $lightgrey;

                .storeAbout {
                    .title {
                        font-family: $primaryFont;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;
                        margin-bottom: 20px;
                    }

                    .description {
                        font-family: $primaryFont;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px
                    }
                }
            }
        }
    }
}