/* Module home */
.home-module-body {
    img {
        max-width: 100%;
        display: block;
        width: 100%;
        height: auto;
    }

    .home-module-title {
        font-family: $secondaryFont;
        font-size: 35px;
        font-style: italic;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 17px;
        width: 100%;
    }

    .home-module-text {
        margin-bottom: 22px;
        font-family: $primaryFont;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100%;
    }

    .home-module-sutitle {
        font-family: $primaryFont;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
        margin-bottom: 1rem;
        width: 100%;
    }

    .home-module-cta {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.6px;
        font-family: $primaryFont;
        text-decoration: underline;
        text-underline-position: under;
        width: 100%;

        a,
        span {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }

        &:hover {
            text-decoration: none;
        }
    }

    &:not(.look-module) .home-module-txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
        display: grid;
        grid-template-rows: auto auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 6rem;

        &.align-left {
            justify-content: flex-start;
            left: 10%;
            transform: translate(0, -50%);

            &.align-top,
            &.align-bottom {
                transform: translate(0, -50%);
            }
        }

        &.align-right {
            justify-content: flex-end;
            right: 10%;
            transform: translate(0, -50%);
            
            &.align-top,
            &.align-bottom {
                transform: translate(0, -50%);
            }
        }

        &.align-top {
            align-items: flex-start;
            top: 20%;

            &.align-center {
                transform: translateX(-50%);
            }
        }

        &.align-bottom {
            align-items: flex-end;
            top: unset;
            bottom: 0;

            &.align-center {
                transform: translateX(-50%);
            }
        }

        .home-module-title,
        .home-module-cta,
        .home-module-text {
            max-width: 350px;
        }
    }

    .slide_video {
        display: block;
        width: 100%;
         height: 0;
        padding-bottom: calc((920 / 1920) * 100%);
        position: relative;
        background-color: $black;

        iframe {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background: $black;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        .block_redirect {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }

    .imgWrapper {
        display: block;

        span {
            display: block;
        }
    }

    .home-column-image,
    .home-column:not(.home-column-txt) {
        position: relative;

        .tag {
            position: absolute;
            z-index: 52;
            text-decoration: none;

            .tag_button {
                position: relative;
                width: 13px;
                height: 13px;
                border-radius: 100%;
                background: $white;
                color: $white;
                cursor: pointer;

                &:after {
                    content:'';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: rgba($white, .1);
                    border: 1px solid $white;
                    animation-name: pulse;
                    animation-duration: 2s;
                    animation-iteration-count: infinite;
                }

                & + .item_product {
                    .wrap_rolloverproduit {
                        .ligne_form.productColorFieldset {
                            .choices_list {
                                padding-left: 2px;

                                .form_itm {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }

            &.leftPos .tooltip-module {
                left: 100%;
            }

            &.rightPos .tooltip-module {
                right: 100%;
            }

            &.topPos .tooltip-module {
                top: 100%;
            }

            &.bottomPos .tooltip-module {
                bottom: 100%;
            }
             /* Lookbook lightbox */
            .item_product {
                position: fixed;
                height: 100vh;
                top: 0;
                right: 0;
                width: 380px;
                z-index: 52;
                padding: 24px 0 110px;
                box-sizing: border-box;
                background: $white;
                cursor: default;
                transform: translateX(100%);
                transition: transform ease-in-out 0.5s;

                .close_look_lightbox {
                    display: block !important;
                    position: absolute;
                    z-index: 10;
                    top: 10px;
                    right: 10px;
                    width: 11px;
                    height: 11px;
                    font-size: 0;
                    cursor: pointer;
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(30, 8, 8);
                }

                .bloc_look_wrapper {
                    max-height: calc(100vh - 134px);
                    max-height: calc(100dvh - 134px);
                    width: 100%;
                    overflow-y: auto;
                }

                .productVisualMulti {
                    margin-bottom: 1rem;

                    .swiper_container_look {
                        &.swiper-initialized {
                            .swiper_container_look_buttons {
                                display: block !important;
                            }
                        }
                    }

                    .swiper_container_look_buttons {
                        opacity: 1;

                        .swiper-button-next,
                        .swiper-button-prev {
                            background-color: $white;
                            width: 26px;
                            height: 100%;
                            cursor: pointer;
                            left: 0;
                            right: auto;
                            top: 0;
                            margin: auto;
                        }

                        .swiper-button-next {
                            transform: rotate(180deg);
                            right: 0;
                            left: auto;
                        }
                    }

                    .block_lnk {
                        margin-bottom: 0;
                    }
                }

                .swiper_container_look {
                    position: relative;
                    padding: 0 26px;
                }

                .brand_n_price_wrapper {
                    padding: 0 26px;

                    .brand_and_description {
                        width: 100%;
                    }

                    .item_price {
                        position: absolute;
                        right: 26px;
                        display: flex;
                        flex-direction: column;
                        text-align: right;
                        margin-top: 1rem;

                        .pricetag {
                            & + .pricetag {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .wishlist_title_wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-content: center;
                    margin-bottom: 12px;
                    flex-wrap: wrap;

                    .wishlist_brand_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-content: center;
                        width: 100%;
                        margin-bottom: 10px;

                        .brand {
                            width: calc(100% - 30px);
                            min-height: 22px;
                        }
                    }

                    .wrapper_description {
                        margin-bottom: 12px;

                        &.tgHome {
                            padding: 0;

                            .item_title_wrapper {
                                width: calc(100% - 85px);

                                .container_marques {
                                    margin-bottom: 15px;
                                }

                                h2 {
                                    line-height: 10px;
                                }

                                .item_title,
                                .item_subtitle {
                                    display: block;
                                    width: 100%;
                                    font-family: $primaryFont;
                                    font-size: 20px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: 25px;
                                }

                                .item_subtitle {
                                    font-weight: 400;
                                }

                                & + .item_price {
                                    width: 100px;
                                    margin-top: 30px;
                                }
                            }
                        }
                    }

                    .title_site {
                        color: $black;
                        font-family: $primaryFont;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 22px;
                        letter-spacing: 0.04rem;
                        text-transform: uppercase;
                    }

                    .addToWishlistButtonContainer {
                        .btnWishlist {
                            width: 22px;
                            height: 22px;
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(20,8,8);
                        }
                    }

                    .wrap_rolloverproduit {
                        position: inherit;
                        opacity: 1;
                        z-index: initial;
                        padding: 0;
                        width: 100%;
                        bottom: inherit;

                        form {
                            position: inherit;

                            .ligne_form {
                                &.productColorFieldset .choices_list,
                                &.productSizeFieldset .choices_list {
                                    justify-content: flex-start;
                                }

                                .os-content {
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                }

                                &.productColorFieldset {
                                    .choices_list {
                                        height: auto;
                                        max-height: 150px;
                                        padding-top: 2px;
                                        margin-bottom: 0;
                                        overflow: auto;

                                        .form_itm {
                                            position: relative;
                                            display: flex;
                                            align-content: center;
                                            margin: 0 30px 20px 0;

                                            &:after {
                                                content: attr(data-color);
                                                width: auto;
                                                display: block;
                                                margin-left: 8px;
                                                font-family: $primaryFont;
                                                font-size: 13px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: normal;
                                                color: $grey;
                                                position: relative;
                                                height: 20px;
                                                line-height: 20px;
                                                text-transform: capitalize;

                                                &:firt-letter {
                                                    text-transform: uppercase;
                                                }
                                            }

                                            &.check {
                                                &:after {
                                                    color: $black;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .expressAlertStock {
                                width: 100%;
                                margin-top: 0;
                                position: relative;

                                .bloc_add_alert_form {
                                    .w-form-line {
                                        .form_submit {
                                            top: 0;
                                        }
                                    }
                                }

                                .alert_return {
                                    position: absolute;
                                    z-index: 10;
                                    top: 0;
                                    right: 0;
                                    width: 12px;
                                    height: 12px;
                                    font-size: 0;
                                    cursor: pointer;
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(30, 8, 8);
                                }
                            }

                            .achat_express {
                                position: relative;
                                top: -22px;

                                .ligne_form {
                                    &.productSizeFieldset {
                                        .no_size_selected {
                                            position: absolute;
                                            bottom: -18px;
                                            left: 0;
                                            display: block !important;
                                            font-size: 13px;
                                            color: $red;
                                            opacity: 0;
                                            z-index: -1;
                                            transition: opacity ease-in-out 0.5s;

                                            &.show {
                                                opacity: 1;
                                                z-index: 1;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .disabled_whislist {
                            position: absolute;
                            top: -70px;
                            right: 0;
                            left: inherit;
                            width: 22px;
                            height: 22px;
                            background-color: transparent;
                            z-index: 3;
                        }

                        .wishlistBtn {
                            position: relative;
                            height: 22px;
                            left: inherit;
                            top: -70px;
                            opacity: 1;
                            z-index: 1;
                        }

                        &.size_selected {
                            .productColorFieldset {
                                height: auto;
                            }
                        }
                    }

                    .description_produit {
                        order: 4;
                        border-bottom: 1px solid $lightgrey;
                        margin-top: 10px;

                        .desc_pannel {
                            .toggleCarac {
                                font-family: $primaryFont;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 45px;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                                color: $black;
                                cursor: pointer;
                                position: relative;
                                border-top: 1px solid $lightgrey;

                                .ico {
                                    img {
                                        display: inline-block;
                                        height: 26px;
                                        width: auto;
                                        margin-right: 8px;
                                        position: relative;
                                        top: 8px;
                                    }
                                }

                                &:after {
                                    content: '';
                                    background-image: url(../svg/spritesheet.svg);
                                    @include bgIconCoord(29,8,8);
                                    width: 1rem;
                                    height: 1rem;
                                    transform: rotate(-90deg);
                                    display: inline-block;
                                    position: absolute;
                                    top: 12px;
                                    right: 0;
                                    transition: all 0.35s ease-in-out;
                                }

                                & + .content {
                                    transition: max-height 0.5s linear;
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 18px;
                                    display: none;

                                    .product_ref {
                                        font-family: $primaryFont;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 1;
                                        color: $grey;
                                        margin-bottom: 1rem;
                                    }
                                }


                                &.actif {
                                    border-bottom: 1px solid $white;

                                    & + .content {
                                        padding-bottom: 20px;
                                    }

                                    &:after {
                                        transform: rotate(90deg);
                                    }
                                }

                                &.toggleLocation {
                                    position: relative;
                                    display: flex;
                                    align-items: center;

                                    &:before {
                                        content: '';
                                        width: 26px;
                                        height: 26px;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(19,8,8);
                                        margin-right: 0.7rem;
                                        display: block;
                                    }
                                }
                            }
                        }

                        &.cache {
                            display: block;
                            width: 100%;
                        }
                    }
                }

                .bloc_add_color {
                    position: absolute;
                    width: 100%;
                    padding: 0 25px;
                    box-sizing: border-box;
                    display: block;
                    bottom: 50px;
                    height: auto;
                    opacity: 1;

                    &:before {
                        display: none;
                    }

                    &.inactiv {
                        .w-submit-button {
                            pointer-events: none;
                            opacity: 0.5;
                        }
                    }

                    .w-submit-button {
                        height: 48px;
                        border-radius: 50px;
                        background-color: $black;
                        border: 1px solid $black;
                        color: $white;
                        transition: all .35s ease-in-out;
                        cursor: pointer;
                        opacity: 1;
                        width: 100%;
                        background-image: inherit;

                        span {
                            color: $white;
                            font-family: $primaryFont;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;
                        }

                        & + .loader {
                            width: calc(100% - 52px);
                            left: 0;
                            right: 0;
                            margin: auto;
                        }
                    }

                    &.loading {
                        .w-submit-button {
                            & + .loader {
                                opacity: 1;
                                z-index: 1;
                            }
                        }
                    }
                }

                &.actif {
                    display: block;
                    transform: translateX(0%);
                }
            }
        }

        .imgWrapper {
            position: relative;
        }
    }

    /* MODULE 1– SLIDER COVER MODULE */
    &.slider-module {
        width: 100%;
        margin: 0 auto 5px;
        position: relative;

        & + .home-module-body:not(.seo-wording) {
            margin-top: 63px;
        }

        .swiper-container {
            .swiper-slide {
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }

                .slide_video {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((920 / 1920) * 100%);
                    position: relative;
                    background-color: $primaryColor;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        pointer-events: none;
                    }
                }

                .home-module-txt {
                    width: auto;
                    padding: 0;
                }
            }

            .pagination-cover-home {
                bottom: 22px;

                .swiper-pagination-bullet {
                    background-color: $white;
                    margin: 0 7.5px;
                    opacity: 0.5;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
        }

        .home-module-txt {
            .home-module-title,
            .home-module-cta,
            .home-module-text {
                max-width: 570px;
            }
        }
    }

    /* MODULE 2 – SLIDER COVER MODULE 2 images */
    &.cover-module {
        width: 100%;
        margin: 0 auto 5px;
        position: relative;

        & + .home-module-body:not(.seo-wording) {
            margin-top: 63px;
        }

        .swiper-wrapper {
            justify-content: center;
        }

        .swiper-container {
            .swiper-slide {
                width: 50%;
                position: relative;

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                }
            }

            .pagination-cover-home {
                bottom: 22px;

                .swiper-pagination-bullet {
                    background-color: $white;
                    margin: 0 7.5px;
                    opacity: 0.5;

                    &.swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                }
            }
        }

        .slide_video {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: calc((920 / 960) * 100%);
            position: relative;
            background-color: $black;

            iframe {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                background: $black;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
        }
    }

    /* MODULE 3 - Texte SEO */
    &.seo-wording {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            padding: 68px 6.25vw;

            .home-column {
                text-align: center;

                .home-module-title {
                    margin-bottom: 22px;
                }

                .home-module-sutitle {
                    margin-bottom: 22px;
                }

                .home-module-text {
                    line-height: normal;
                    width: 100%;
                    max-width: 980px;
                    margin: auto;
                }

                .home-module-cta {
                    margin-top: 27px;
                    text-align: center;
                }
            }
        }
    }

    /* MODULE 4 – PUSH categories 3 Images */
    &.image-image-image {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            &:not(.blocs) .home-module-title {
                margin: 0 auto 17px;
                text-align: center;
            }

            .home-column {
                width: 33.125%;
                position: relative;

                &:not(:has(.imgWrapper)) {
                    padding-top: calc((760 / 636) * 33.125%);
                }

                .imgWrapper {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((760 / 636) * 100%);
                    position: relative;

                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .slide_video {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((760 / 636) * 100%);
                    position: relative;
                    background-color: $primaryColor;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }
            }

            &.blocs {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    /* MODULE 5 – PUSH categories 2 Images */
    &.image-image {
        margin: 0 auto 63px;
        text-align: center;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            &:not(.blocs) .home-module-title {
                margin: 0 auto 17px;
            }

            &.blocs {
                display: flex;
                justify-content: space-between;

                .home-column {
                    width: 49.84375%;
                    position: relative;

                    .slide_video {
                        display: block;
                        width: 100%;
                        height: 0;
                        padding-bottom: calc((900 / 957) * 100%);
                        position: relative;
                        background-color: $black;

                        iframe {
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: $black;
                            width: 100%;
                            height: 100%;
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 6 – Image + Image + Texte + Image */
    &.image-image-image-image {
        max-width: 1920px;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;
            align-content: center;

            .home-column {
                width: 24.8%;
                max-width: 476px;
                position: relative;

                .slide_video {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((575 / 476) * 100%);
                    position: relative;
                    background-color: $black;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }

                &:hover {
                    .home-module-cta {
                        text-decoration: none;

                        span {
                            text-decoration: none;
                        }
                    }
                }
            }

            .home-module-txt {
                padding: 3rem;
                pointer-events: none;
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .home-column {
                    width: 49.7%;
                    max-width: inherit;
                    margin-bottom: 6px;
                }
            }

            & + .home-columns-container {
                display: none;
                margin: 50px auto 0;

                .home-module-cta {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    text-decoration: none;
                    height: 50px;
                    border-radius: 50px;
                    transition: all 0.35s ease-in-out;
                    cursor: pointer;
                    width: 350px;
                    text-align: center;
                    line-height: 48px;
                    text-decoration: none;
                    border: 1px solid rgba($black, 0.075);
                    margin: auto;

                    a {
                        transition: all 0.35s ease-in-out;
                        cursor: pointer;
                        width: 350px;
                        text-align: center;
                        line-height: 50px;
                        text-decoration: none;
                        color: inherit;
                    }
                }
            }
        }
    }

    /* MODULE 7 – TEXTE + IMAGE + IMAGE */
    &.text-image-image {
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;

            .home-column {
                position: relative;
                width: 33.125%;

                .slide_video {
                    display: block;
                    width: 100%;
                    height: 0;
                    padding-bottom: calc((760 / 636) * 100%);
                    position: relative;
                    background-color: $black;

                    iframe {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $black;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                    }
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .home-column {
                    width: 49.7%;

                    &.home-column-txt {
                        width: 100%;
                        margin-bottom: 20px;

                        .home-module-txt {
                            padding: 0 9%;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 8 – SHOP THE LOOK */
    &.look-module {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;

            .home-column {
                &.home-column-image {
                    width: 58%;
                }

                &.home-column-txt {
                    position: relative;
                    width: 37.11%;

                    .home-module-title {
                        text-align: center;
                        margin-bottom: 22px;
                    }

                    .product-tag {
                        .item_product {
                            position: relative;
                        }
                    }

                    .wishlist_brand_wrapper {
                        display: flex;
                        justify-content: space-between;

                        .wishlist_wrapper {
                            display: none;
                        }
                    }

                    .brand_n_price_wrapper {
                        .brand_and_description {
                            .container_marques {
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
            }
        }
    }

    /* MODULE 9 – IMAGE 1/4 + IMAGE 2/4 + TEXT */
    &.image-image-text {
        width: 100%;
        max-width: 100%;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
            position: relative;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: stretch;

            .home-column {
                &.home-column-mini-img {
                    width: 23.958333%;

                    .slide_video {
                        padding-bottom: calc((575 / 460) * 100%);
                    }
                }

                &.home-column-big-img {
                    width: 50.729%;

                    .slide_video {
                        padding-bottom: calc((575 / 974) * 100%);
                    }
                }

                &.home-column-txt {
                    position: relative;
                    width: 24.791666%;
                    padding: 3rem;
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .home-column {
                    order: 2;

                    &.home-column-mini-img {
                        width: 31.9814%;
                    }

                    &.home-column-big-img {
                        width: 67.67%;
                    }

                    &.home-column-txt {
                        order: 1;
                        width: 100%;
                        margin-bottom: 20px;

                        .home-module-txt {
                            justify-content: center !important;
                            text-align: center !important;
                            padding: 0 9%;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 10 –TEXTE + IMAGE */
    &.image-text {
        width: 100%;
        max-width: 924px;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;

            .home-column {
                width: 51.5151%;

                .slide_video {
                    padding-bottom: calc((575 / 476) * 100%);
                }

                & + .home-column-txt {
                    position: relative;
                    width: 48.4849%;
                }
            }
        }

        @media only screen and (max-width: 1160px) {
            max-width: 906px;

            .home-column {
                width: 52.5386%;

                & + .home-column-txt {
                    width: 38.631346%;
                }
            }
        }
    }

    /* MODULE 11 – MÉLI-MÉLO 7 IMAGES */
    &.multiple-bloc {
        width: 100%;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            position: relative;

            .home-column {
                &.column-1 {
                    width: 23.958%;
                    margin-right: 5px;
                }

                &.column-2 {
                    width: 23.958%;
                    margin-right: 5px;
                }

                &.column-3 {
                    width: 25.625%;
                    margin-right: 5px;
                }

                &.column-4 {
                    width: 25.625%;
                }

                .picture-container {
                    position: relative;
                    margin-bottom: 5px;
                }
            }

            @media only screen and (max-width: 1160px) {
                flex-wrap: wrap;

                .home-column {
                    width: calc(50% - 2.5px) !important;

                    &.column-2,
                    &.column-4 {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    /* MODULE 12 – TÊTE DE GONDOLE */
    &.tg-module {
        margin: 0 auto 58px;
        position: relative;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            &:first-of-type {
                position: absolute;
                top: 0;
                width: calc(100% - 120px);
                left: 0;
                right: 0;
                margin: auto;
                z-index: 6;
            }

            .tg_button_wrapper {
                width: 60px;
                display: block;
                position: absolute;
                right: 5px;
                top: 0;
                height: 56px;
                display: none;

                @media only screen and (max-width: 1450px) {
                    display: block;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    background-image: url(../svg/spritesheet.svg);
                    @include bgIconCoord(29,8,8);
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                    left: 0;
                    right: auto;

                    &:after {
                        display: none;
                    }

                    &.swiper-button-lock {
                        display: none;
                    }

                    &.disabled {
                        opacity: 0.5;
                        display: block;
                    }
                }

                .swiper-button-next {
                    transform: rotate(180deg);
                    right: 0;
                    left: auto;
                }
            }

            .home-column {
                display: flex;
                justify-content: space-between;
                padding: 0 25px;

                .title_wrapper {
                    width: calc(100% - 60px);
                    display: flex;
                    justify-content: center;

                    &.both_tg {
                        .home-module-title {
                            &:first-of-type {
                                &:after {
                                    content: '';
                                    position: relative;
                                    width: 2px;
                                    height: 23px;
                                    background: $grey;
                                    margin: 0 20px;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }

                .home-module-title {
                    color: $grey !important;
                    margin-bottom: 21px;
                    position: relative;
                    cursor: pointer;
                    width: auto;

                    &.selected {
                        color: $black !important;
                    }
                }
            }

            & + .home-columns-container {
                padding: 0;

                .home-module-tg {
                    display: none;

                    &.selected {
                        display: block;
                        padding: 56px 0 35px;
                    }
                }

                .swiper_tdg_home {
                    .swiper-slide,
                    .item {
                        width: 19.79166%;
                        margin-right: 5px;

                        .productVisualMulti_look {
                            .swiper-slide,
                            .item {
                                width: 100%;
                                margin-right: 0;

                                span {
                                    &.imgProd {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    .swiper-pagination {
                        top: inherit;
                        bottom: 5px;
                        background-color: $white;
                        height: 3px;
                        width: calc(100% - 10px);
                        left: 0;
                        right: 0;
                        margin: auto;
                        opacity: 0;
                        border-radius: 20px;

                        .swiper-pagination-progressbar-fill {
                            background-color: $black;
                            border-radius: 20px;
                            cursor: pointer;
                        }

                        .swiper-scrollbar-drag {
                            background-color: $black;
                            cursor: pointer;
                        }
                    }

                    &:not(.swiper-initialized) {
                        .tg_button_wrapper {
                            display: none;
                        }

                        .swiper-slide,
                        .item {
                            width: 19.79%;
                            margin-right: 5px;

                            @media only screen and (max-width: 1450px) {
                                width: calc(100% / 4 - 4px);
                            }

                            @media only screen and (max-width: 1160px) {
                                width: calc(100% / 3 - 4px);
                            }
                        }
                    }

                    .productVisualMulti_look {
                        &.productSwiper {
                            .block_lnk {
                                position: relative;

                                & > span {
                                    opacity: 1;
                                    transition: opacity ease-in-out 0.5s;

                                    & + span {
                                        opacity: 0;
                                        transition: opacity ease-in-out 0.5s;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                    }
                                }

                                &:hover {
                                    & > span {
                                        opacity: 0;

                                        & + span {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }


                    &:hover {
                        .swiper-pagination {
                            opacity: 1;
                        }
                    }

                    @media only screen and (max-width: 1450px) {
                        padding-bottom: 30px;

                        .swiper-pagination {
                            display: block;
                            opacity: 1;

                            .swiper-pagination-progressbar-fill {
                                background-color: $beige_Fonce;
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    /* MODULE 13 – IMAGE 1/4 + IMAGE 2/4 + TEXTE */
    &.image-image-image-text {
        width: 100%;
        margin: 0 auto 63px;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;

            .home-column {
                width: calc(100% / 3 - 3.5px);
                display: block;

                .picture {
                    &:not(:last-child) {
                        margin-bottom: 5px;
                    }
                }

                .home-module-txt {
                    padding: 13.2%;
                }
            }

            @media only screen and (max-width: 1160px) {
                .home-column {
                    order: 2;
                    width: calc(50% - 2.5px);

                    &:nth-of-type(3) {
                        order: 1;
                        width: 100%;
                        margin-bottom: 22px;
                    }
                }
            }
        }
    }

    /*MODULE 14 – MÉLI-MÉLO 4 IMAGES */
    &.four-images {
        margin: 0 auto 63px;
        width: 100%;

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        .home-columns-container {
            display: flex;
            justify-content: space-between;

            .home-column {
                width: calc(50% - 3px);

                .picture {
                    position: relative;
                    margin-bottom: 6px;
                }
            }
        }
    }

    /* MODULE 15 – INSTAGRAM */
    &.social-module {
        margin: 0 auto 0;
        text-align: center;

        .home-module-title {
            text-align: center;
            margin-bottom: 17px;
        }

        @media only screen and (max-width: 1450px) {
            margin: 0 auto 43px;
        }

        @media only screen and (max-width: 1160px) {
            margin: 0 auto 33px;
        }

        #hashtag_module_frame {
            .swiper-wrapper {
                .swiper-slide {
                    width: 16.45%;
                    margin-right: 5px;
                    height: 16.45vw;
                    overflow: hidden;

                    &:nth-of-type(6) {
                        margin-right: 0;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }

                    &:before {
                        content: '';
                        background-color: rgba($black, 0.5);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        opacity: 0;
                        transition: opacity 0.5s ease-in-out;
                    }

                    &:after {
                        content: '';
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(37,8,8);
                        width: 21px;
                        height: 21px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        opacity: 0;
                        transition: opacity 0.75s ease-in-out;
                    }

                    &:hover {
                        &:before,
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    /* MODULE 16 - NEWSLETTER*/
    &.signup-images-module {
        .home-columns-container {
            .home-column {
                position: relative;
                width: 100%;
                min-height: 250px;
                overflow: hidden;

                img {
                    width: auto;
                    height: auto;
                    max-width: inherit;
                    margin: auto;
                }

                .wrapperNewsletter {
                    position: absolute;
                    display: flex;
                    flex-wrap: wrap;
                    width: fit-content;
                    height: fit-content;
                    margin: auto;
                    color: $white;
                    z-index: 2;

                    &.align-top {
                        top: 15%;
                    }

                    &.align-bottom {
                        bottom: 15%;
                    }

                    &.align-left {
                        left: 10%;
                        text-align: left;
                    }

                    &.align-right {
                        right: 10%;
                        text-align: right;
                    }

                    &.align-middle {
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }

                    &.align-center {
                        left: 0;
                        right: 0;
                        margin: auto;
                        text-align: center;
                    }

                    .home-module-title {
                        text-align: center;
                        width: 100%;
                        color: $white !important;
                        margin-bottom: 1rem;
                    }

                    .home-module-text {
                        width: 100%;
                    }

                    .newsletterForm {
                        width: 100%;

                        .w-newsletter-form {
                            width: 100%;
                            max-width: 507px;
                            margin: auto;

                            .w-email-input {
                                .w-input-element {
                                    border-radius: 0;
                                    border-bottom: 1px solid $white;
                                    height: 30px;
                                    color: $white;
                                }

                                .w-input-label {
                                    color: $white;
                                    font-family: $primaryFont;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 18px;
                                }

                                input:-webkit-autofill,
                                input:-webkit-autofill:active,
                                input:-webkit-autofill:focus,
                                input:-webkit-autofill:hover,
                                input:-internal-autofill-selected {
                                    box-shadow: none !important;
                                    background-color: transparent !important;
                                    -webkit-background-clip: text !important;
                                    -webkit-text-fill-color: $white !important;
                                }
                            }

                            .w-submit {
                                width: 15px;
                                height: 30px;

                                .w-btn-wrapper {
                                    .w-submit-button {
                                        height: 15px !important;
                                        width: 15px;
                                        border-radius: 0 !important;
                                        border: none !important;
                                        background-color: inherit !important;
                                        background-image: url(../svg/spritesheet.svg);
                                        @include bgIconCoord(33,8,8);
                                        transform: rotate(180deg);

                                        span {
                                            font-size: 0 !important;
                                        }
                                    }
                                }
                            }

                            .w-input-note {
                                text-align: left;

                                &.w-input-error {
                                    color: $candy_red;
                                }
                            }
                        }
                    }

                    .wrapperSocials {
                        width: 100%;

                        .social {
                            display: flex;
                            justify-content: center;
                            align-content: center;

                            a {
                                width: 30px;
                                height: 30px;
                                display: block;
                                margin-right: 8px;
                                background-image: url(../svg/spritesheet.svg);

                                &.facebook {
                                    @include bgIconCoord(16,8,8);
                                }

                                &.instagram {
                                    @include bgIconCoord(24,8,8);
                                }

                                &.pinterest {
                                    @include bgIconCoord(26,8,8);
                                }

                                &.tiktok {
                                    margin-right: 0;
                                    @include bgIconCoord(28,8,8);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* Page inactive */
    &.images-signup-module {
        position: relative;
        width: 100%;
        margin: 0 auto 65px;
        background: $white;

        .home-columns-container {
            width: 100%;
            max-width: 800px;
            margin: auto;

            .home-column {
                text-align: center;

                .home-module-title {
                    margin-bottom: 19px;
                }

                .home-module-subtitle {
                    font-family: $primaryFont;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1;
                    margin-bottom: 25px;
                }

                .w-form.w-newsletter-form {
                    width: 100%;

                    .w-form-line {
                        .w-email-input {
                            .w-input-element {
                                border-radius: 0;
                                border-bottom: 1px solid $grey;
                                height: 30px;
                                color: $grey;
                            }

                            .w-input-label {
                                color: $white;
                                font-family: $primaryFont;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 18px;
                                color: $grey;
                            }
                        }

                        .w-input-note {
                            text-align: left;
                        }

                        &.w-submit {
                            position: relative;
                            width: 100%;
                            max-width: 350px;
                            height: auto;
                            margin: 30px auto 0;

                            .w-submit-button {
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }
}

.homepage {
    .categories {
        display: none;

        &.show {
            display: block;
        }
    }
    #copyright {
        padding-top: 0;
        margin-top: 0;
    }
}

@keyframes pulse {
    0%   {}
    33%  {}
    33%  {width: 25px; height: 25px;}
    80%  {width: 35px; height: 35px;}
    100% {opacity: 0}
}