.lb-v-dummy {
    display: block;
    width: 100%;
    height: 1px;
}
.lb-h-dummy {
    display: block;
    width: 1px;
    height: 100%;
}
.lb-clear {
    clear: both;
}
.lb-wrap {
    position: relative;
    z-index: 0;
    overflow: auto;
}
.lb-v-scrollbar {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 1px;
    height: 100%;
    background: #e7e7e5;
    border-radius: 5px;
    opacity: 1;
    z-index: 1;
}
.lb-h-scrollbar {
    z-index: 1;
    position: absolute;
    bottom: 2px;
    left: 2px;
    width: 100%;
    height: 5px;
    background: transparent;
    opacity: 1;
    border-radius: 5px;
    background-color: #e7e7e5;
}
.lb-v-scrollbar-slider {
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 50px;
    background: #000;
    border-radius: 5px;
}
.lb-h-scrollbar-slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 1px;
    background: #000;
    border-radius: 5px;
}
