.order_cgv,
.politique_confidentialite,
.centre_confientialite,
.mentions_legales,
.misc_cookiesinfo {

    .wrapper_content_satelite {
        max-width: 1920px;
        margin: 0 auto;
        padding: 0 4.7%;

        .wrapper_content_sat {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 23px;
            margin-bottom: 50px;
            padding-left: 1%;

            .title {
                font-weight: 400;
                font-family: $secondaryFont;
                font-size: 35px;
                font-style: italic;
                text-align: center;
                justify-content: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                row-gap: 1rem;
                padding-left: 0.6%;
            }

            .content_sat {
                width: 75%;
                font-family: $primaryFont;
                font-size: 14px;
                letter-spacing: 0.7px;

                li {
                    list-style: inside;
                    margin-left: 8px;
                }

                .title-sub {
                    text-transform: uppercase;
                    font-size: 14px;
                }

                .content {
                    margin-bottom: 25px;

                    .title-sub {
                        font-weight: 400;
                    }
                }

                .content_inside {
                    font-weight: 400;
                    line-height: 16.44px;

                    strong {
                        u {
                            font-weight: 450;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .back_top {
            position: fixed;
            width: 30px;
            height: 30px;
            top: 92vh;
            right: 34px;
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transform: rotate(90deg);

            &.show {
                opacity: 1;
                z-index: 3;
            }

            .back_up {
                width: 20px;
                height: 20px;
                background-image: url(../svg/spritesheet.svg);
                @include bgIconCoord(29,8,8)
            }
        }
    }
}