/* Carte cadeau */
body.giftcard_index {
    .pageGiftCard{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        opacity: 0;
        padding: 0 6.25vw;
        width: 100%;

        @media only screen and (max-width: 1440px) {
            padding: 0 2.2%;
        }

        .new_section {
            display: flex;
            flex-wrap: wrap;
            width: 50.238%;

            section {
                width: 100%;

                &.pageGiftCard__main--infos {
                    order: 1;
                }

                &.pageGiftCard__header {
                    order: 2;
                    opacity: 0;
                }

                &.pageGiftCard__main--amountinfos {
                    display: none;

                    & > div:not(.discover-btn) {
                        display: none;
                    }

                    &:not(.logged) > small {
                        display: none;
                    }

                    & > div:first-child,
                    small {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1;
                        font-family: $primaryFont;
                    }

                    #formGiftCard {
                        .w-form-line {
                            &.container__input-code {
                                display: block;

                                .w-submit {
                                    margin-top: 25px;
                                    max-width: 415px;

                                    .w-submit-button {
                                        width: 100%;
                                    }
                                }

                                .w-input-note {
                                    position: absolute;
                                    bottom: 0;
                                }
                            }
                        }
                    }

                    .giftcard_desc {
                        display: none;
                    }
                }
            }
        }

        .pageGiftCard__header {
            &.visible {
                opacity: 1 !important;
                border-bottom: 2px solid $lightgrey;
            }

            &:has(.showAmountBtn.open) {
                border-bottom: none;
            }

            .headerTitles {
                div {
                    display: none;
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 35px;
                    font-family: $secondaryFont;
                    text-align: center;

                    & + div {
                        display: none;
                    }
                }
            }

            #showAmount {
                .showAmountBtn {
                    &  + .showAmountBtn {
                        display: none;
                    }
                }
            }

            .pageGiftCard__main--amountinfos {
                display: none;
            }
        }

        .pageGiftCard__asideImg {
            margin-right: 7.142857%;
            width: 42.619%;

            img {
                width: 100%;
                height: auto;
            }
        }

        .pageGiftCard__main {
            &.pageGiftCard__main--infos {
                .pageGiftCard__bloc {
                    display: flex;
                    flex-wrap: wrap;
                    border-bottom: 2px solid $lightgrey;
                    padding-bottom: 25px;

                    h1 {
                        order: 1;
                        margin-bottom: 30px;
                        width: 100%;
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        line-height: 35px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }


                    & > div {
                        order: 2;
                        margin-bottom: 15px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                    }

                    small {
                        display: none;
                    }

                    .infosList {
                        order: 3;
                        padding-right: 100px;

                        li {
                            position: relative;
                            padding-left: 22px;

                            &:before {
                                position: absolute;
                                top: 10px;
                                left: 10px;
                                border-radius: 100%;
                                background: $black;
                                width: 3px;
                                height: 3px;
                                content: '';
                            }

                            div {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 23px;
                                font-family: $primaryFont
                            }
                        }
                    }

                    .cgvLink {
                        display: none;
                    }
                }

                .giftCard_btn {
                    border-bottom: 2px solid $lightgrey;

                    #openOrderGiftCard {
                        .offerCardBtn {
                            position: relative;
                            cursor: pointer;
                            border: none;
                            border-radius: inherit;
                            background-color: $white;
                            padding: 0;
                            width: 100%;
                            color: $black;
                            text-align: left;

                            &:after {
                                background-image: url(../svg/spritesheet.svg);
                                content: '';
                                @include bgIconCoord(29,8,8);
                                display: inline-block;
                                position: absolute;
                                top: 12px;
                                right: 0;
                                transform: rotate(-90deg);
                                transition: all 0.35s ease-in-out;
                                width: 1rem;
                                height: 1rem;
                            }

                            &.open {
                                &:after {
                                    transform: rotate(90deg);
                                }
                            }

                            span {
                                color: $black;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 45px;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }

        .showAmountBtn {
            position: relative;
            cursor: pointer;
            border: none;
            border-radius: inherit;
            background-color: $white;
            padding: 0;
            width: 100%;
            color: $black;
            text-align: left;

            &:after {
                background-image: url(../svg/spritesheet.svg);
                content: '';
                @include bgIconCoord(29,8,8);
                display: inline-block;
                position: absolute;
                top: 12px;
                right: 0;
                transform: rotate(-90deg);
                transition: all 0.35s ease-in-out;
                width: 1rem;
                height: 1rem;
            }

            &.open {
                &:after {
                    transform: rotate(90deg);
                }
            }

            p {
                color: $black;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 45px;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-transform: uppercase;
            }

            &:focus {
                background-color: $white;
            }
        }

        .discover-btn {
            margin: 1rem 0 1.5rem;
            width: 100%;

            .button {
                width: 100%;
                max-width: 415px;
                height: 48px;

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-transform: uppercase;
                }
            }
        }

        .giftcard_result--container {
            display: block;
            position: relative;
            top: -78px;
            box-sizing: border-box;
            background-color: $beige-clair;
            padding: 43px;
            width: 100%;
            text-align: center;

            .giftcard_amount {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                font-family: $primaryFont;
                letter-spacing: 0.7px;
                text-transform: inherit;
            }

            &:before {
                position: absolute;
                top: 100%;
                right: 0;
                left: 0;
                margin: auto;
                background-color: $white;
                width: 100%;
                height: 80px;
                content: '';
            }
        }
    }

    #lightboxOrderGiftCard {
        display: none;
        padding: 0;
        width: 100%;

        #orderGiftCardForm {
            padding: 0;
            width: 100%;

            .step {
                display: none;
                width: 100%;
                color: $black;
                font-family: $primaryFont;
                text-align: left;

                &#step1 {
                    .giftDetails {
                        #chooseBalance {
                            .selectBalanceLabel {
                                display: block;
                                margin: 6px 0;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 11px;
                                line-height: 1;
                                font-family: $primaryFont;
                                letter-spacing: 0.55px;
                                text-transform: uppercase;
                            }

                            .definir_montant_offrir {
                                margin-bottom: 10px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 1;
                                font-family: $primaryFont;
                            }

                            .input {
                                #selectBalance {
                                    margin: 0 0 25px;
                                    border: none;
                                    border-bottom: 1px solid $grey;
                                    padding-left: 0;
                                    width: 100%;
                                    height: 23px;
                                }
                            }
                        }

                        .giftDetails {
                            .infos {
                                .js-textDeliveryMail {
                                    margin-bottom: 5px;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 11px;
                                    line-height: 1;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.55px;
                                    text-transform: uppercase;
                                }

                                .customize_your_message {
                                    margin-bottom: 10px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 1;
                                    font-family: $primaryFont;
                                }

                                .infos_msg {
                                    .formPerso {
                                        input[type="text"] {
                                            margin-bottom: 15px;
                                            border: none;
                                            border-bottom: 1px solid $grey;
                                            padding-left: 0;
                                            width: 100%;
                                            height: 25px;
                                            color: $black;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 14px;
                                            line-height: 18px;
                                            font-family: $primaryFont;
                                        }
                                    }

                                    .formText {
                                        .input {
                                            input {
                                                border:none;
                                                border-bottom: 1px solid $grey;
                                                padding: 0;
                                                height: 25px;
                                            }
                                        }
                                    }
                                }
                            }

                            #btn_container {
                                position: relative;

                                .loader {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    opacity: 0;
                                    z-index: -1;
                                    border-radius: 50px;
                                    background: $black url('../svg/three_dots.svg') no-repeat center;
                                    background-size: 15% auto;
                                    width: 100%;
                                    max-width: 415px;
                                    height: 48px;
            
                                    span {
                                        display: none;
                                    }
            
                                    &.loading {
                                        opacity: 1;
                                        z-index: 1;
                                    }
                                }
                            }
                        }

                        #sendMethods {
                            width: 100%;
                        }
                    }

                    .input {
                        position: relative;

                        .error_msg_cc {
                            position: absolute;
                            bottom: -16px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 1;
                            font-family: $primaryFont;
                            color: $red;
                        }
                    }
                }

                .input {
                    display: block;

                    .placeholder {
                        color: $grey;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 1;
                        font-family: $primaryFont;
                    }

                    .w-input {
                        &.inputErr {
                            border-color: $red !important;
                        }
                    }
                }

                .btn_container {
                    margin: 25px 0;
                    max-width: 415px;
                }

                .info_comment_offrir {
                    margin-top: 25px;

                    .info_comment_offrir_txt {
                        margin-bottom: 3px;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 11px;
                        line-height: 1;
                        font-family: $primaryFont;
                        letter-spacing: 0.55px;
                        text-transform: uppercase;
                    }

                    ol {
                        padding-left: 8px;
                        list-style-type: decimal;
                        list-style-position: inside;

                        li {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 23px;
                            font-family: $primaryFont; 
                        }
                    }
                }

                &#step3 {
                    .giftDetails {
                        .infos {
                            .js-textDeliveryMail {
                                    margin-top: 0;
                                    margin-bottom: 0;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    letter-spacing: .7px;
                                    text-transform: uppercase;
                            }
                        }
                    }

                    .stepsList {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin: 1rem 0 0.5rem 0;
                        padding: 0;

                        li {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 32%;
                        }

                        p {
                            color: $black;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: .7px;
                            text-align: center;
                            text-transform: uppercase !important;

                            &:first-of-type {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 50%;
                                background: $black;
                                padding: 10px;
                                width: 45px;
                                height: 45px;
                                color: $white;
                                font-weight: 500;
                                font-size: 24px;
                                font-family: $primaryFont;
                                text-align: center;
                            }

                            &:before {
                                display: none;
                            }
                        }

                        .title_step_3 {
                            margin-top: 10px;
                            width: 90%;
                            color: $black;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            text-transform: none;
                        }
                    }

                    .resumeList {
                        position: relative;
                        margin: 60px 0 10px;
                        background-color: $beige-clair;
                        padding: 1.5rem;

                        li {
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;
                            align-items: center;
                            align-items: baseline;

                            &:nth-of-type(1) {
                                h3 {
                                    &:nth-of-type(1) {
                                        display: none;
                                    }

                                    &:nth-of-type(2) {
                                        display: block !important;
                                    }
                                }
                            }

                            h3 {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 24px;
                                font-family: $primaryFont;
                                letter-spacing: 0;
                                text-transform: uppercase;
                            }

                            &:not(:last-child) {
                                margin-bottom: .9rem;
                            }

                            p,
                            small {
                                margin-left: 4rem;
                            }

                            p {
                                margin-left: 0;
                                color: $primaryColor;
                                font-weight: 400;
                                font-size: 15px;
                                font-family: $primaryFont;
                                text-align: left;
                            }

                            small {
                                font-weight: 400;
                                font-size: 15px;
                                font-family: $primaryFont;
                                text-decoration: underline;
                                text-underline-offset: 3px;
                            }

                            .modify {
                                position: absolute;
                                right: 1.5rem;
                                cursor: pointer;
                                color: $primaryColor;
                                text-transform: capitalize;
                            }
                        }
                    }

                    .btn_container {
                        margin-top: 25px;
                        width: 100%;
                        max-width: 100%;

                        .button {
                            width: 49%;
                            max-width: 415px;

                            &#js-submitStep3 {
                                position: relative;

                                span {
                                    margin-top: 0;
                                }
                            }

                            .loader {
                                position: absolute;
                                top: 0;
                                left: 0;
                                opacity: 0;
                                z-index: -1;
                                border-radius: 50px;
                                background: $black url('../svg/three_dots.svg') no-repeat center;
                                background-size: 15% auto;
                                width: 100%;
                                max-width: 415px;
                                height: 48px;
        
                                span {
                                    display: none;
                                }
        
                                &.loading {
                                    opacity: 1;
                                    z-index: 1;

                                    span {
                                        opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.fr {
        .giftcard_validity_fr {
            display: block !important;
            margin-top: 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: normal;
            font-family: $primaryFont;
            text-transform: initial;
        }
    }

    &.en {
        .giftcard_validity_en {
            display: block !important;
            margin-top: 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: normal;
            font-family: $primaryFont;
            text-transform: initial;
        }
    }
}