/* Buttons */
button:not(.loc_btn):not(.toggleFilter):not(.w-visibility-toggle),
.w-submit-button {
    height: 48px;
    border-radius: 50px;
    background-color: $black;
    border: 1px solid $black;
    color: $white;
    transition: all 0.35s ease-in-out;
    cursor: pointer;

    span {
        color: $white;
        font-family: $primaryFont;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.04rem;
        text-transform: uppercase;
    }

    &:focus {
        background-color: $black;
        color: $white;

        span {
            color: $white;
        }
    }

    &:hover {
        background-color: $white;
        color: $black;

        span {
            color: $black;
        }
    }

    &.w-loader,
    &.button.loader,
    &+.loader,
    &.loader {
        background: $black url(../svg/three_dots.svg) no-repeat center;
        background-size: 15% auto;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -1;

        span {
            font-size: 0;
        }

        &.loading {
            opacity: 1;
            z-index: 1;
        }

        &.bankThanksBtn {
            position: initial;
            transition: opacity ease-in-out 0.35s;
        }
    }

    &.w-secondary-btn {
        background-color: $white;
        color: $black;

        span {
            color: $black;
        }

        &:hover {
            background-color: $black;
            color: $white;

            span {
                color: $white;
            }
        }
    }
}

.button.secondary {
    color: $black;
    text-transform: uppercase;
    font-family: $primaryFont;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.6px;
    border-bottom: 1px solid $black;
    display: inline-block;
}