/**
MAIN SPRITESHEET USING A SINGLE CLASS
exemple :
<div class="bgSprite bfr search"></div> will display the search icon in a :before content
 */

.bgSprite {
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 100%;
        height: 100%;
    }

    @include spriter('../svg/spritesheet.svg', 8, 8, (
            1:  'search',
            2:  'search-w',
            3:  'birth',
            4:  'birth-w',
            5:  'locator',
            6:  'locator-w',
            7:  'account',
            8:  'account-w',
            9:  'cart',
            10: 'cart-w',
            11: 'secured',
            12: 'delivery',
            13: 'sav',
            14: 'return',
            15: 'facebook',
            16: 'facebook-w',
            17: 'target',
            18: 'phone',
            19: 'heart',
            20: 'wishlist',
            21: 'exist-wishlist',
            22: 'mini-logo',
            23: 'instagram',
            24: 'instagram-w',
            25: 'pinterest',
            26: 'pinterest-w',
            27: 'tiktok',
            28: 'tiktok-w',
            29: 'arrow_left',
            30: 'close',
            31: 'code',
            32: 'select_commande',
            33: 'arrow_left_w',
            34: 'account_full',
            35: 'birth_full',
            36: 'cart_full',
            37: 'insta_module',
            38: 'play_w',
            39: 'produit_disponible',
            40: 'produit_limite',
            41: 'produit_indisponible',
            42: 'domicile',
            43: 'boutique',
            44: 'accept',
            45: 'menu_burger_w',
            46: 'menu_burger_b',
            47: 'arrow_search',
            48: 'cross_color',
            49: 'locator_full'
        )
    );
}