/* Global vars */
 @import "var/fonts";                        // Project font faces
 @import "var/colors";                       // Project colors
 @import "components/reset";                 // CSS reset
 @import "components/product_tooltip";       // Vars for the tooltip "choose a size/color" on FP to unblock add to cart BTN

 /* _app imports */
@import "../../../_app/_src/scss/desktop/app";
@import "../../../_app/_src/scss/desktop/components/add_cart_product_tooltip";
@import "../../../_app/_src/scss/desktop/pages/bank/bank_thanks";


@import "var/sprites";                      // Sprites import

 /* Project pplugins */
 @import "plugins/swiper-bundle";            // Swiper js
 @import "plugins/OverlayScrollbars";        // OverlayScrollbars
 @import "plugins/lionbars";                 // Lionbars (needed in cart delivery)
 @import "plugins/slick";                    // Slick slider (needed for Algolia)


/* Main components */
@import "components/global";                // Global styles
@import "components/lightbox";              // Lightboxs styles
@import "components/w-forms";               // forms styles
@import "components/button";
@import "components/achat_express";
@import "components/product_item";

/* pages */
@import "pages/login";
@import "pages/account";
@import "pages/store_locator";
@import "pages/satellites";
@import "pages/_contenue_texte";
@import "pages/home";
@import "pages/rayon";
@import "pages/menu";
@import "pages/header";
@import "pages/footer";
@import "pages/product";
@import "pages/cms";
@import "pages/cart";
@import "pages/naissance";
@import "pages/gift_card";