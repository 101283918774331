#copyright {
    .footer_container {
        margin: 0 auto;
        padding: 36px 0 18px;
        width: 100%;
        max-width: 1680px;

        @media screen and (max-width: 1690px) {
            padding: 30px 2.2% 18px;
        }

        @media screen and (max-width: 1024px) {
            padding: 30px 3.2% 18px;
        }
        
        .wrapperSocials {
            display: none;
        }

        .footer_content {
            display: flex;
            justify-content: right;
            
            .footer_content {
                justify-content: center;
                margin-right: 230px;
            }

            .col {
                margin-right: 230px;
                font-weight: 400;
                font-size: 13px;
                line-height: 1;
                font-family: $primaryFont;

                @media only screen and (max-width: 1680px) {
                    margin-right: 80px;
                }

                @media only screen and (max-width: 1240px) {
                    margin-right: 50px;
                }

                @media only screen and (max-width: 1024px) {
                    margin-right: 30px;
                }

                &:last-of-type {
                    margin-right: 0;
                }

                .col_title {
                    margin-bottom: 13px;
                    font-weight: 600;
                }

                .col_content {
                    .col_links {
                        display: block;
                        margin-bottom: 11px;
                        width: 100%;
                        line-height: 1rem;
                    }
                }

                &.switch_language {
                    margin-right: 0;
                    width: 130px;

                    &.en .col_content span:after {
                        right: -10px;
                    }

                    .col_content {
                        position: relative;
                        margin-bottom: 2rem;

                        span {
                            display: inline-block;
                            position: relative;
                            width: auto;
                            line-height: 1;

                            &::after {
                                position: absolute;
                                top: 2px;
                                right: -10px;
                                margin: auto;
                                background-image: url(../svg/spritesheet.svg);
                                content: '';
                                @include bgIconCoord(29, 8, 8);
                                transform: rotate(269deg);
                                width: 6px;
                                height: 6px;
                            }
                        }

                        span,
                        .col_links {
                            cursor: pointer;
                            font-style: normal;
                            font-size: 11px;
                            letter-spacing: 0.5px;
                            text-transform: uppercase;
                        }

                        .lang_switch {
                            display: block;
                            position: absolute;
                            top: 22px;
                            transition: height 0.5s ease;
                            height: 0;
                            overflow: hidden;
                            line-height: 1;

                            a {
                                text-transform: uppercase;
                            }
                        }

                        &:hover {
                            .lang_switch {
                                height: 20px;
                            }
                        }
                    }

                    .legalMentions {
                        display: block;
                        position: relative;
                        width: 100%;
                        color: $grey;
                        font-size: 13px;
                        line-height: 23px;
                        font-family: $primaryFont;
                        white-space: nowrap;

                        a.footer_logo {
                            position: absolute;
                            top: 17px;
                            left: 68px;
                        }
                    }
                }
            }
        }
    }

    .detail_footer {
        display: flex;
        position: absolute;
        left: 0;
        justify-content: center;
        align-items: center;
        background-color: $beige-clair;
        width: 100%;
        height: 35px;

        #mention_legales_popup {
            .txt_alert {
                .header_lightbox {
                    padding: 4%;

                    .light_title {
                        font-style: italic;
                        font-weight: 400;
                        font-size: 35px;
                        font-family: $secondaryFont;
                        text-align: center;
                    }

                    .pop_mention_detail {
                        padding-top: 3%;
                        text-align: center;
                    }
                }
            }

            a {
                font-weight: 400;
            }
        }
    }
}

.reinsurance {
    display: flex;
    justify-content: space-between;
    margin: 0 auto 1.08%;
    padding: 0 0.5% 0.5%;
    width: 100%;
    max-width: 1037px;

    @media screen and(max-width:1440px) {
        padding: 0 5.5% 0.5%;
    }

    a {
        position: relative;
        padding-top: 2.45rem;
    }

    .bgSprite {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
        line-height: 1rem;
        font-family: $primaryFont;
        text-align: center;

        &::before {
            position: absolute;
            top: -2.45rem;
        }

        &.secured,
        &.delivery {
            &::before {
                width: 39px;
                height: 35px;
            }
        }

        &.sav,
        &.return {
            &::before {
                width: 32px;
                height: 33px;
            }
        }
    }
}

/* Cookies */
#cookiesLightbox {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    padding: 18px 6.3% 13px;
    width: 100%;
    max-width: 1920px;

    @media screen and (max-width: 1440px) {
        padding: 20px 2.2%;
    }

    @media screen and (max-width: 1024px) {
        padding: 20px 3.2%;
    }

    &.notAccepted {
        display: grid;

        &.hideCookies {
            display: none;
        }
    }

    h3,
    .cookies_title {
        margin-bottom: 3px;
        font-weight: 400;
        font-size: 14px;
        font-family: $primaryFont;
        letter-spacing: 0.7px;
        text-transform: uppercase;
    }

    .cookiesLightbox_container {
        display: grid;
        position: relative;
        grid-template-columns: 80% 20%;
        column-gap: 1rem;

        .cookiesLightbox_content {
            grid-row: 1;
            grid-column: 1;
        }
        
        .cookiesLightbox_preferences {
            grid-row: 2;
            grid-column: 1;
        }

        .cookiesLightbox_accept_btn {
            display: flex;
            grid-row: 1/3;
            grid-column: 2;
            flex-direction: column;
            justify-content: center;

            button:not(.loc_btn):not(.toggleFilter):not(.w-visibility-toggle).w-loader {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .cookiesLightbox_custom_btn {
            position: absolute;
            bottom: 0;
            left: 0;
            width: auto;
        }

        .cookiesLightbox_custom_btn {
            margin: 0;
            padding: 0;
            #jsCookieCustom {
                position: relative;
                z-index: 10;
                margin-top: 0;
                border: none;
                background-color: transparent;
                height: auto !important;
                color: $black;
                text-decoration: underline;
                text-underline-position: under;

                span {
                    color: $black;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    section {
        width: 100%;
        max-width: 1920px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $primaryFont;

        a {
            color: $black;
            font-size: 12px;
            text-underline-offset: 0px;
        }

        .toggleCookiePrefs {
            margin-top: 1rem;
            font-weight: 500;
            font-size: 12px;
            font-family: $primaryFont;

            &:after {
                display: none;
            }
        }

        .cookiesInfos {
            .h4_style {
                .icon {
                    display: inline-block!important;
                    position: absolute;
                    top: -2px;
                    right: -25px;
                }
            }

            li {
                span {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    color: $black;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }

            .cookieBlocInfo {
                text-transform: initial;
            }
        }

        &:nth-child(2) {
            text-transform: uppercase;
        }

        #js_cookie_refuse {
            margin-top: 6px;
            margin-left: 200px;
            border: 0;
            border-radius: inherit;
            background: $white;
            width: auto;
            height: auto;

            span {
                color: $black;
                font-weight: 500;
                font-size: 12px;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-decoration: underline;
                text-underline-position: under;
                text-transform: uppercase;
            }
        }
    }

    .right {
        position: relative;
    }

    .w-form-line {
        &.w-submit {
            display: block;
            margin-top: 0;
            max-width: 100%;

            .w-btn-wrapper {
                margin: 1.1rem 0 0 2rem;
                width: 100%;
                max-width: 350px;

                @media only screen and (max-width: 1360px) {
                    max-width: 300px;
                }

                &:nth-of-type(1) {
                    position: absolute;
                    right: 0;
                    bottom: 1rem;
                    min-height: 50px;
                }

                &:nth-of-type(2) {
                    display: none;
                }

                &:nth-of-type(3) {
                    position: absolute;
                    bottom: 1px;
                    margin: 0;
                    width: auto;

                    #jsCookieCustom {
                        position: relative;
                        z-index: 10;
                        margin-top: 0;
                        border: none;
                        background-color: $white;
                        height: auto !important;
                        color: $black;
                        text-decoration: underline;
                        text-underline-position: under;

                        span {
                            color: $black;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                        }

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .w-checkbox-input {
        .w-input-element {
            +.w-input-label {
                &:after {
                    top: 3px;
                }
            }
        }
    }
}